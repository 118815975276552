import React, { Component } from "react";

import { Box , Link,  styled} from "@material-ui/core";

type MyProps = {
    bannerClass: string;
    children: any;
};

const PromotionBox = styled(Box)({
    "&.advertContainer":{
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
        "& img":{
            maxWidth: "100%"
        }
    }
});

export default class Promotion extends Component<MyProps> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const {bannerClass, children} = this.props;
        return (
            <PromotionBox className={`advertContainer ${bannerClass}`}>
                {Array.isArray(children) ?
                    children.map((item, index) => {
                        return (<Link key={index} href={item.url}><img src={item.image} alt="" /></Link>)
                    }) 
                : 
                    (<Link href={children?.url}><img src={children?.image} alt="" /></Link>)
                }
            </PromotionBox>
        );
    }
}

