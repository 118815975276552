import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
const assets = require("./assets");
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from "i18next";
type MenuItem = {
  icon: string
  name: string
  path: string
  groupId?: string
}

type MenuArrayType = {
  id: number,
  name: string
}

interface UserData {
  data: {
    attributes: {
      user_type: string,
      permission_groups: MenuArrayType[],
      image: string | null
    }
  }
}
interface NotificationType {
  id: string;
  attributes: {
    is_read: boolean
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  wrapperStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  pageBackgroundMode?: "light" | "grey";
  children: React.ReactNode;
  handleSearch?: (text?: string) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  roleInfoData: MenuArrayType[],
  roleType: string,
  avatar: string,
  profileMenuAnchorEl: HTMLElement | null,
  textSearch: string;
  language: string;
  numberOfUnreadNoti?: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      roleInfoData: [],
      roleType: "",
      avatar: "",
      profileMenuAnchorEl: null,
      textSearch: "",
      language: "en",
      numberOfUnreadNoti: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getNotiCallId) {
        const numberOfUnreadNoti = responseJson.data.filter((notification:NotificationType) => !notification.attributes.is_read).length
        this.setState({numberOfUnreadNoti})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let userData = await getStorageData("roleInfoData")
    if (!userData) return;

    const lang = await getStorageData('language') || "en";
    const token = await getStorageData('authToken')
    languageConvertor(lang);
    this.setState({ language: lang, token: token }, () => {
      let userInfoData = JSON.parse(userData)
      this.handleData(userInfoData)
      languageConvertor(lang);
      this.getNotifications()
    });
  }

  getMenuItems = (role: string, menuArray: MenuArrayType[]): MenuItem[] => {
    const isVendor = role === 'vendor'
    const isAdmin = role === "admin"
    let sideBarList: MenuItem[] = []
    menuArray.forEach((item) => {
      switch (item.name) {
        case "Admin Dashboard":
          return isAdmin && sideBarList.unshift({
            icon: assets.dashboardIcon,
            name: i18n.t("DASHBOARD.DASHBOARDTXT"),
            path: '/dashboard'
          });
        case "Vendor Dashboard":
          return isVendor && sideBarList.unshift({
            icon: assets.dashboardIcon,
            name: i18n.t("DASHBOARD.DASHBOARDTXT"),
            path: '/vendordashboard'
          });
        case "Products":
          return sideBarList.push({
            icon: assets.productIcon,
            name: i18n.t("PRODUCTTXT"),
            path: '/ProductList',
            groupId: 'product',
          });
        case "Orders":
          return sideBarList.push({
            icon: assets.orderIcon,
            name: i18n.t("ORDERSTXT"),
            path: isVendor ? "/OrderListVendor" : '/OrderListAdmin'
          });
        case "Customer":
          return sideBarList.push({
            icon: assets.customerIcon,
            name: i18n.t("CUSTOMERSTXT"),
            path: '/CustomerList'
          });
        case "Transactions": return sideBarList.push(isVendor ?
          {
            icon: assets.payoutIcon,
            name: i18n.t("PAYOUTSTXT"),
            path: '/payouts'
          } : {
          icon: assets.transactionIcon,
          name: i18n.t("TRANSACTIONTXT"),
          path: '/TransactionList'
        });
        case "Inventory": return sideBarList.push({
            icon: assets.inventoryIcon,
            name: i18n.t("INVENTORYTXT"),
            path: '/InventoryManagement'
          });
        case "Vendor": return sideBarList.push({
          icon: assets.vendorIcon,
          name: i18n.t("VENDORTXT"),
          path: '/VendorList'
        });
        case "Settings": return sideBarList.push({
          icon: assets.settingIcon,
          name: i18n.t("SETTINGSTXT"),
          path: '/settings'
        });
        default:
      }
    })
    return sideBarList
  }

  handleData(userData: UserData) {
    const { attributes } = userData.data
    const { user_type, permission_groups, image } = attributes
    this.setState({ roleInfoData: permission_groups, roleType: user_type, avatar: image || "" })
  }

  handleLogout = () => {
    removeStorageData("authToken");
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }

  handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault()
    this.props.handleSearch && this.props.handleSearch(this.state.textSearch)
  }
  handleTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ textSearch: event.target.value })
  }

  navigateToTarget = (target: string) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), target);
    request.addData(getName(MessageEnum.NavigationScreenNameMessage), target);
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getNotiCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.notificationEndPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
       token: this.state.token,
      language: this.state.language
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);}
  // Customizable Area End
}
