import React from "react"
import { Box, styled } from "@material-ui/core"
import { BarChart, CartesianGrid, XAxis, ResponsiveContainer, Tooltip, YAxis, Bar, TooltipProps } from "recharts";

type CustomBarChartProps = {
    data: Array<{weekday: string} & {[key: string]: number | string}>
    items: Array<{
        label: string
        color: string
        dataKey: string
    }>
}

const CustomTooltipWrapper = styled(Box)({
    background: "white",
    padding: 5,
    border: "solid 1px #EEE"
})

const CustomTooltip = ({active, payload, label} : TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
        return (
            <CustomTooltipWrapper>
                {label}: {payload[0].value?.toLocaleString()}
            </CustomTooltipWrapper>
        )
    }
    return null
}


const CustomBarChart = (props: CustomBarChartProps) => {
    const { data, items } = props

    return (
        <Box>
            <CustomBarChartWrapper height={300}>
                <BarChart
                    className="bar-chart"
                    data={data}
                >
                    <CartesianGrid strokeDasharray="2 2" vertical={false} />
                    <XAxis dataKey="weekday" tickLine={false} axisLine={{ stroke: "lightgray", strokeWidth: 1.5 }} tickMargin={11} />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value: number) => {
                            if (value >= 1000) return (value % 1000 ? (value / 1000).toFixed(1) : value / 1000) + "k"
                            return value.toString()
                        }}
                        tickMargin={11}
                    />
                    <Tooltip content={<CustomTooltip />}/>
                    {
                        items.map((item, index) => (
                            <Bar
                                key={index}
                                barSize={20}
                                radius={index === items.length - 1 ? [4,4,0,0] : undefined}
                                stackId='sales-volume'
                                dataKey={item.dataKey}
                                fill={item.color}
                            />
                        ))
                    }
                </BarChart>

            </CustomBarChartWrapper>
            <Box display="flex" gridGap={20} alignItems="center" fontFamily="Inter" marginY={2.5} fontSize={11}>
                {
                    items.map(item => (
                        <Box key={item.label} display="flex" alignItems="center" gridGap={5}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                <circle cx="6.05652" cy="5.50037" r="5.43396" fill={item.color} />
                            </svg>
                            <Box>
                                {item.label}
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

const CustomBarChartWrapper = styled(ResponsiveContainer)({
    "& .bar-chart": {
        fontFamily: "Inter",
        fontSize: 11,
        margin: "10px 30px 0 -25px"
    },
})

export default CustomBarChart