import React, { Component } from "react";
import { hidePassword } from './assets';
import { TextField, styled } from '@material-ui/core';

type MyProps = {
    name?: string;
    placeholder?: string;
    value: string;
    onValueChange: (event: any) => void;
    onBlur?: React.FocusEventHandler;
    inputStyles?: any;
    id?: string;
    dataTestId?: string;
    display?: 'primary' | 'secondaryBorderless' | 'secondaryWithBorder';
    labelText?: string;
    labelStyles?: any;
    fieldError?: string;
    inputType?: 'text' | 'password';
    prefix?: string;
    multiline?: boolean;
    disabled?:boolean;
    onFocusInput?: () => void
};

type MyState = {
    showPassword: boolean;
};

const InputText = styled(TextField)({
    "&.primaryTextInput .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#A6A6A6",
        },
        "&:hover fieldset": {
            borderColor: "#A6A6A6",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#A6A6A6",
            borderWidth: '1px',
        },
        "& input": {
            color: '#333333',
            "@media (max-width: 420px)": {
                padding: "14px",
            },

        },
        "& input::placeholder": {
            color: '#000',
            fontWeight: 400,
            fontFamily: 'Roboto',
        }
    },

    "&.secondaryWithBorderTextInput .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "#A6A6A6",
        },
        "&:hover fieldset": {
            borderColor: "#A6A6A6",
        },
        "&.Mui-focused fieldset": {
            borderColor: "#A6A6A6",
            borderWidth: '1px',
        },
        "& input": {
            backgroundColor: '#EEEEEE',
            color: '#000000',
        },
        "& input::placeholder": {
            color: '#333333',
        }
    },

    "&.secondaryBorderlessTextInput .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none",
        },
        "&:hover fieldset": {
            border: "none",
        },
        "&.Mui-focused fieldset": {
            border: "none",
        },
        "& input": {
            backgroundColor: '#EEEEEE',
            color: '#000000',
        },
        "& input::placeholder": {
            color: '#333333',
        }
    },

    "& .MuiFormHelperText-root": {
        marginTop: '0.5rem',
        marginLeft: '0',

        "&.Mui-error": {
            color: '#DC2626',
            fontSize: '0.875rem',
            fontWeight: 400,
        }
    }
});

export default class TextInput extends Component<MyProps, MyState> {

    constructor(props: any) {
        super(props);

        this.state = {
            showPassword: false,
        };
    }

    showHidePassword = () => {
        this.setState((prevState: MyState) => ({
            showPassword: !prevState.showPassword
        }));
    };

    onInputBlur = (event: any) => {
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(event);
        } 
    };

    render(): React.ReactNode {
        const labelStyles = this.props.labelStyles || {};
        const inputId = this.props.id || this.props.labelText + 'TextInput' || '';
        
        return (
            <>
                {this.props.labelText ? (
                    <div>
                        <label style={{...commonLabelStyles, ...labelStyles}} htmlFor={inputId}>{this.props.labelText}</label>
                    </div>
                ) : ''}
                
                <div style={inputWrapper}>
                    <InputText 
                        name={this.props.name || ''}
                        id={this.props.id || ''}
                        data-test-id={this.props.dataTestId || ''}
                        placeholder={this.props.placeholder || ''}
                        className={`${this.props.display || 'primary'}TextInput`}
                        error={!!this.props.fieldError}
                        helperText={this.props.fieldError || ''}
                        type={this.props.inputType === 'password' && !this.state.showPassword ? 'password' : 'text'}
                        value={this.props.value}
                        onChange={(event: any) => this.props.onValueChange(event)}
                        onBlur={(event: any) => this.onInputBlur(event)}
                        variant='outlined'
                        fullWidth
                        multiline = {this.props.multiline}
                        inputProps={{
                            style: this.props.inputStyles,
                        }}      
                        disabled={this.props.disabled || false}
                        onFocus={this.props.onFocusInput}
                    />
                    
                    {this.props.inputType === 'password' ? (<img onClick={(e) => this.showHidePassword()} style={{...hidePasswordIcon}} src={hidePassword} alt="Hide Password" />) : ''}
                </div>
            </>
            
        );
    }
}

const commonLabelStyles: any = {
    fontSize: '1rem',
    color: '#000000',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
};

const inputWrapper: any = {
    position: 'relative',
    marginTop: '0.5rem',
};

const hidePasswordIcon: any = {
    position: 'absolute',
    cursor: 'pointer',
    top: '1.8rem',
    transform: 'translateY(-50%)',
    right: '0.5rem',
};
