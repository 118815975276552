const resources = {
  addNewItem: {
    en: "Add New Item",
    ar: "تفاصيل المنتج",
  },
  editItem: {
    en: "Edit Item",
    ar: "تفاصيل المنتج"
  },
  productName: {
    label: {
      en: "Product Name",
      ar: "اسم المنتج",
    },
    error: {
      en: "Please enter your product name.",
      ar: "Please enter your product name.",
    },
  },
  manufacturerName: {
    label: {
      en: "Manufacturer Name",
      ar: "اسم الشركة المصنعة",
    },
    error: {
      en: "Please enter Manufacturer Name.",
      ar: "Please enter your Manufacturer Name.",
    },
  },
  category: {
    label: {
      en: "Category",
      ar: "الفئة",
    },
    error: {
      en: "Please enter your category name.",
      ar: "Please enter your category name.",
    },
  },
  subCategory: {
    label: {
      en: "Sub Category",
      ar: "فئة فرعية"
    }
  },
  price: {
    label: {
      en: "Price",
      ar: "السعر",
    },
    error: {
      en: "Please enter your price.",
      ar: "Please enter your price.",
    },
  },
  salePrice: {
    label: {
      en: "Sale Price",
      ar: "سعر البيع",
    },
    error: {
      en: "Please enter your sale price.",
      ar: "Please enter your sale price.",
    },
  },
  discountType: {
    label: {
      en: "Discount Type",
      ar: "نوع الخصم",
    },
    error: {
      en: "Please enter your discount type.",
      ar: "Please enter your discount type.",
    },
  },
  keywords: {
    label: {
      en: "Keywords",
      ar: "الكلمات الرئيسية",
    },
    error: {
      en: "Please enter your keywords.",
      ar: "Please enter your keywords.",
    },
  },
  stockQuantity: {
    label: {
      en: "Stock Quantity",
      ar: "الكمية في المخزن",
    },
    error: {
      en: "Please enter your Stock Quantity.",
      ar: "Please enter your Stock Quantity.",
    },
  },
  descriptions: {
    label: {
      en: "Descriptions",
      ar: "إضافة مواصفة",
    },
    error: {
      en: "Please enter your Descriptions.",
      ar: "Please enter your Descriptions.",
    },
  },
  specification: {
    label: {
      en: "Specification",
      ar: "إدخال المواصفات",
    },
    error: {
      en: "Please enter your Specification.",
      ar: "Please enter your Specification.",
    },
  },
  warranty: {
    label: {
      en: "Warranty",
      ar: "الضمان",
    },
    error: {
      en: "Please enter your Warranty details.",
      ar: "Please enter your Warranty details.",
    },
  },
  image: {
    label: {
      en: "Image",
      ar: "الصورة",
    },
    error: {
      en: "Please upload image.",
      ar: "Please upload image.",
    },
  },
  addImage: {
    label: {
      en: "Add image",
      ar: "إضافة صورة",
    },
  },
  currency: {
    label: {
      en: "SAR",
      ar: "دولار\u200E"
    }
  }
};

export default resources;
