import React from "react";

//Customizable Area Start

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { Box, styled } from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import i18n from "i18next";
const assets = require("./assets");
const ForrgotPwdWrapper = styled(Box)({
    "& .formBox":{
        "@media (max-width: 420px)": {
            display:"grid",
            justifyContent:"center",
            justifyItems:"center"
          },
    },
    "& .loginBtn":{
        width: "100%",
        backgroundColor: "rgb(37, 99, 235)",
        color: "white",
        marginTop: "32px",
        "@media (max-width: 420px)": {
            width: "100% !important",
          },
    },
    "& .submit":{
        width:"100%"
    },
    "& .passwordheaderText": {
        "@media (max-width: 420px)": {
            fontSize: "1.5rem"
        },
    }
  });
const validationSchema = Yup.object().shape({
    email: Yup.string().email()
        .required("Please enter your email")
});
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";


//Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    render() {
        return (
            <ForrgotPwdWrapper sx={webStyle.container} >
                <FrontHeader navigation={this.props.navigation} headerType="signin" signUpData={{target: "EmailAccountRegistration",
    isSignIn: true, userType: "customer"}} allowGuestAccess/>
                <Box sx={webStyle.modalWrapper}>
                    <Box style={webStyle.heading} >
                        <Typography color="black" size="xxl" weight="bold">
                            <Box className="passwordheaderText">
                            {i18n.t("PASSWORDRECOVERYTXT")}
                            </Box>   
                        </Typography>
                    </Box>
                    {!this.state.loginClicked ?
                        <>
                            <Box style={webStyle.forgotHeading}>
                                <Typography weight="semiBold">
                                    {i18n.t("FORGOTPASSWORDTXT")}
                                </Typography>
                            </Box>
                            <Box style={webStyle.subHeading}>
                                <Typography>{i18n.t("FORGOTPWDDETAILSTXT")}</Typography>
                            </Box>
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                data-testid="forgotform"
                                validationSchema={validationSchema}
                                onSubmit={(values) => { this.handleLoginClick(values) }}

                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    touched,
                                    handleChange,
                                    values, handleBlur
                                }: any) => (
                                    <Form autoComplete="off" id="formikFormComp" className="formBox">
                                        <Box sx={webStyle.emailInputBox}>
                                            <Box style={webStyle.text}>
                                            </Box>
                                            <TextInput
                                                name="email"
                                                labelText={i18n.t("EMAILLABEL")+"*"}
                                                display="primary"
                                                placeholder={i18n.t("EMAILPLACEHOLDER")}
                                                value={values.email}
                                                onValueChange={handleChange}
                                                onBlur={handleBlur}
                                                fieldError={touched.email && errors.email}
                                                id="companyNameField"
                                            />
                                        </Box>
                                        {
                                            !this.state.loginClicked &&
                                            <div className="submit">
                                            <CustomButton
                                                 dataTestId="loginbtnclick"
                                                onClickHandle={() => handleSubmit()}
                                                themes="primary"
                                                className="loginBtn"
                                                styles={{
                                                    height: '3.5rem',
                                                    width: '22.5rem',
                                                }}
                                            >{i18n.t("SUBMITTXT")}</CustomButton>
                                        </div>
                                        }
                                    </Form>
                                )}
                            </Formik>
                            <Box style={webStyle.linkWrapper}>
                                <Box style={webStyle.para}>
                                    <Typography >{i18n.t("BACKTOTXT")} <span data-test-id="loginhandle" onClick={this.handlelLogin} style={webStyle.link}>{i18n.t("SIGNINTXT")}</span></Typography>
                                </Box>
                            </Box>
                        </>
                        : <>
                            <Box style={webStyle.forgotHeading}>
                                <Typography >
                                    {i18n.t("CHECKYOURMAILTXT")}
                                </Typography>
                            </Box>
                            <Box style={webStyle.subHeading}>
                                <Typography >
                                    {i18n.t("PWDRESTLINKTXT")} {this.state.forgotEmail}
                                </Typography>
                            </Box>
                            <Box style={webStyle.linkWrapper}>
                                <Box style={webStyle.para}>
                                    <Typography ><span data-test-id="spanClick" onClick={()=>this.handleLoginClick({email:this.state.forgotEmail})} style={webStyle.link}>{i18n.t("RESENDTXT")} {i18n.t("EMAILLABEL")}</span></Typography>
                                </Box>
                                <Box style={webStyle.para}>
                                    <Typography >{i18n.t("BACKTOTXT")} <span data-test-id="spanClick2" onClick={this.handlelLogin} style={webStyle.link}>{i18n.t("SIGNINTXT")}</span></Typography>
                                </Box>
                            </Box>
                        </>}

                </Box>
            </ForrgotPwdWrapper >
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        flex: 1,
        backgroundImage: `url(${assets.backgroundImage})`,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffffffff",
        display: "flex",
        backgroundPosition: 'center',
        alignItems: "center",
        flexDirection: "column" as "column",
        gap: "170px",
        "& *": {
            fontFamily: "Roboto !important",
            boxSizing: "border-box"
        },
        "@media (max-width: 420px)": {
            gap: "90px",
        },
    },
    modalWrapper: {
        borderRadius: '4px',
        boxShadow: "24px",
        p: 4,
        backgroundColor: "white",
        padding: "70px",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 420px)": {
            padding: "30px 24px",
            maxWidth: "35rem",
            minWidth: "20rem",
            width: "50%",
          },
    },
    heading: {
        fontWeight: "600",
        fontSize: "32px",
        fontFamily: "Roboto",
    },
    forgotHeading: {
        fontSize: "24px",
        fontWeight: "500",
        marginTop: "40px",
    },
    subHeading: {
        fontSize: "16px",
        fontWeight: "400",
        textAlign: "center" as "center",
        marginTop: "16px",
    },
    emailInputBox: {
        width: "360px",
        height: "83px",
        marginTop: "32px",
        "@media (max-width: 420px)": {
            width: "initial",
          },
    },
    text: {
        fontSize: "16px",
        fontWeight: "500",
    },
    input: {
        width: "100%",
        marginTop: "8px"
    },
    para: {
        marginTop: "24px",
        fontSize: "16px",
        fontWeight: "400",
    },
    linkWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center" as "center",
        gap: "31px"
    },
    link: {
        fontWeight: "700",
        color: "rgb(37, 99, 235)",
        cursor: "pointer",
    }
};
