import React, { Component } from "react";
import { Button, styled } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "../src/design-system/Typography.web";

type Props = {
    title: string,
    content: string,
};

const AccordionBox = styled(Accordion)({
    root: {
        width: '100%',
    },
    "&.MuiAccordion-root":{
        boxShadow:"none !important"
    },
    "&.MuiPaper-elevation1": {
        boxShadow:"none"
    },
    "&.panel1a-header": {
        borderTop:"none"
    }
});

export default class CustomAccordion extends Component<Props> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        return (
            <AccordionBox>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="panel1a-header"
                >
                    <Typography size="sm" weight="medium" color="charcoal" >{this.props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                       {this.props.content}
                    </Typography>
                </AccordionDetails>
            </AccordionBox>
        );
    }
}
