// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Grid
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Formik } from "formik";
import i18n from "i18next";
import TalkToUsController, { Props, TalkToUsForm, initialFormValue } from "./TalkToUsController";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import UserSidebar from "../../navigationmenu/src/UserSidebar.web";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import StyledTextArea from "../../../components/src/design-system/StyledTextArea.web";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector.web";
import Loader from "../../../components/src/Loader.web";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";

export default class TalkToUs extends TalkToUsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = i18n

    return (
      <TalkToUsWrapper>
        <Loader loading={this.state.loading} />
        <FrontHeader navigation={this.props.navigation} customStyle={{ background: "white", ...!this.state.token && { borderBottom: "solid 1px #DFDFDF" } }} allowGuestAccess/>
        <Container className="container">
          <UserSidebar navigation={this.props.navigation} />
          <Box className="form-container">
            <Box fontSize={30} lineHeight="40px">
              <Typography font="Inter" weight="bold" color="dark">
                {t("CONTACTUSFORM.TITLE")}
              </Typography>
            </Box>
            <Box color="#475569" margin="20px 0 35px">
              <Typography font="Inter" >
                {t("CONTACTUSFORM.DESCRIPTION")}
              </Typography>
            </Box>


            <Box>
              <Formik
                enableReinitialize
                initialValues={initialFormValue}
                validate={this.handleValidateForm}
                onSubmit={this.handleSubmitForm}
                data-test-id="talk-to-us-form"
                innerRef={this.formikRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => {
                  const fieldErrorValue = (field: keyof TalkToUsForm) => (touched[field] && errors[field]) || ""
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container className="form-wrapper" spacing={3}>
                        <Grid item xs={12}>
                          <TextInput
                            name="name"
                            labelText={t('NAMETXT')}
                            labelStyles={webStyle.labelStyle}
                            placeholder=""
                            value={values.name}
                            onValueChange={this.handleChangeName}
                            onBlur={handleBlur}
                            fieldError={fieldErrorValue("name")}
                            data-test-id="name-field"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextInput
                            name="email"
                            labelText={t("EMAILADDRESSTXT")}
                            labelStyles={webStyle.labelStyle}
                            display="primary"
                            placeholder=""
                            value={values.email}
                            onValueChange={handleChange}
                            onBlur={handleBlur}
                            fieldError={fieldErrorValue("email")}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <label style={webStyle.labelStyle}>{t("CONTACTNUMBERTXT")}</label>
                          <Box className="contact-number-input">
                            <Box className="country-code-selector">
                              <CountryCodeSelector
                                navigation={this.props.navigation}
                                id=""
                                disable={false}
                                allowPropChange
                                value={this.state.country.countryId}
                                handleChangeValue={this.handleChangeCountryCode}
                              />
                            </Box>
                            <TextInput
                              name="contactNumber"
                              display="primary"
                              placeholder=""
                              value={`${this.state.language === 'ar' ? '\u200E' : ''}${values.contactNumber}`}
                              onValueChange={this.handleChangeContactNumber}
                              onBlur={handleBlur}
                              dataTestId="contact-number-field"
                            />
                          </Box>
                          <Box className="field-error">
                            <Typography>{fieldErrorValue("contactNumber")}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <TextInput
                            name="subject"
                            labelText={t("SUBJECTTXT")}
                            display="primary"
                            labelStyles={webStyle.labelStyle}
                            placeholder={t("CONTACTUSFORM.SUBJECTPLACEHOLDER")}
                            value={values.subject}
                            onValueChange={handleChange}
                            onBlur={handleBlur}
                            fieldError={fieldErrorValue("subject")}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography size="xs" weight="bold" font="Inter">
                            {t("DETAILSTXT")}
                          </Typography>
                          <StyledTextArea
                            name="details"
                            placeholder={t('CONTACTUSFORM.DETAILSPLACEHOLDER')}
                            value={values.details}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <Box className="field-error">
                            <Typography>{fieldErrorValue("details")}</Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <CustomButton
                            dataTestId="btn-submit"
                            onClickHandle={handleSubmit}
                            themes="primary"
                            styles={webStyle.buttonStyle}
                          >
                            {t('SENDMESSAGETXT')}
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </form>
                  )
                }}
              </Formik>
            </Box>
          </Box>
          {
            this.state.showToast
            && <CustomSnackBar
              data-test-id="CustomSnackBar"
              open
              errorMessage={t('CONTACTUSFORM.SUCCESSMESSAGE')}
              onClose={this.handleCloseSnackBar}
              severity="success"
            />
          }
        </Container>
      </TalkToUsWrapper>
    );
  }
}

const TalkToUsWrapper = styled(Box)({
  background: "white",
  height: "100vh",
  overflow: "auto",
  boxSizing: "border-box",
  "& .container": {
    display: "flex",
    padding: "16px 40px",
    maxWidth: 1440,
    gap: 20
  },
  "& .form-container": {
    padding: "30px 54px 8px",
    background: "white",
    width: 628,
    borderRadius: "4px",
  },
  "& .form-wrapper": {
    "& .MuiInputBase-root": {
      borderRadius: 8,
      fontFamily: "Inter",
      "& .MuiInputBase-input": {
        color: "#0F172A",
        "&::placeholder": {
          fontFamily: "Inter",
          color: "#64748B"
        }
      },
    },
    "& .MuiFormHelperText-root": {
      textAlign: "unset"
    },
    "& .contact-number-input": {
      display: "flex",
      alignItems: "center",
      minWidth: 200,
      height: 56,
      minHeight: 47,
      borderRadius: 8,
      marginTop: "0.5rem",
      border: "solid 1px #A6A6A6",
      paddingRight: 10,
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          padding: 0
        },
        "& fieldset": {
          border: 0
        }
      },
      "& > div:last-of-type": {
        marginTop: "0px !important",
        flex: 1,
      }
    },
    "& .country-code-selector": {
      gap: 5,
      display: "flex",
      alignItems: "center",
      fontFamily: "Twemoji Country Flags",
      "& .control": {
        width: 48,
        display: "flex",
        alignItems: "center",
        "& > div:first-of-type": {
          "& > div:first-of-type": {
            fontFamily: "Twemoji Country Flags"
          },
          overflow: "visible"
        }
      },
      "& .indicator-container": {
        "& > div": {
          padding: 0
        }
      }
    },
    "& .field-error": {
      marginTop: "0.5rem",
      color: "#DC2626",
      fontSize: "0.875rem",
      lineHeight: 1.66
    }
  }
})

const webStyle = {
  labelStyle: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 700,
    color: "#64748B"
  },
  buttonStyle: {
    padding: "12px 16px",
    borderRadius: 8,
    maxWidth: "fit-content",
    marginTop: 30
  }
}
// Customizable Area End