import React from "react";

// Customizable Area Start
import { Formik, FormikValues } from "formik";
import { styled, Grid, createTheme, Box } from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Typography from "../../../components/src/design-system/Typography.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import i18n from "i18next";
const LoginWrapper = styled('div')({
  '&.login-page': {
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat !important',
      overflow: 'auto',
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      backgroundPosition:"center !important"
  },
  '& .login-container': {
      width: '100%',
      maxWidth: '35rem',
      backgroundColor: '#fff',
      borderRadius: '0.25rem',
      margin: '7rem 0 2.5rem 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      "@media (max-width: 768px)": {
        width: '50%',
        minWidth:'15rem',
        margin: "4rem 0 2.5rem 0",
      },
  },
  '& .login-header': {
      textAlign: 'center',
      margin: '3.87rem 0 2.5rem 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.5rem',
      '& .welcome-text': {
        width: '19.67rem',
      
      },
      "@media (max-width: 420px)": {
        margin: '2rem 0 2rem 0',
      },
  },
  '& .login-form': {
      width: '100%',
      maxWidth: '22.5rem',
  },
  '& .forgot-password': {
      textAlign: 'right',
  },
  '& #forgotPasswordLink': {
      cursor: 'pointer',
  },
  '& .submit': {
      margin: '1.25rem 0 1.25rem 0',
  },
  "& .submit_bottom":{
    margin: '1.25rem 0 2.5rem 0',
  },
  '& .login-link': {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '3rem',
      gap: '0.5rem',
      "@media (max-width: 420px)": {
        flexDirection: "column",
        textAlign: "center"
    },
  },
  '& .signup-link': {
      cursor: 'pointer',
  },
  "& .subtitle_text":{
    "@media (max-width: 420px)": {
        fontSize: "16px",
        padding: "0 18px",
    },
  }
});
export const initialLoginFormValues = {
  email: '',
  phoneNumber: '',
  password: '',
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
       <LoginWrapper style={this.getLoginWrapperStyle(this.state.userType)} className="login-page">
       <FrontHeader navigation={this.props.navigation} headerType="signin" signUpData={{target: "EmailAccountRegistration",
    isSignIn: true, userType: this.state.userType}} allowGuestAccess/>
       <div className="login-container">
           <div className="login-header">
               <Typography color="black" size="xxl" weight="bold">{i18n.t("SIGNINTXT")}</Typography> 
                    <div className="welcome-text">
                        <Typography color="charcoal" size="sm" weight="medium" >
                            <Box className="subtitle_text"> {this.state.userType === 'customer' ? i18n.t("CUSTOMERLOGTITLE") : i18n.t("VENDORLOGTITLE")}</Box>
                        </Typography>
                    </div>
           </div>

           <div className="login-form">
               <Formik
                   initialValues={initialLoginFormValues}
                   validate={this.validateLoginForm}
                   onSubmit={(values) => this.onLogin(values)}
               >
                   {({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit
                   }: FormikValues) => (
                       <form onSubmit={handleSubmit} autoComplete="off">
                           <Grid container spacing={3}>
                               <Grid item xs={12}>
                                   <TextInput
                                       name="email"
                                       labelText={i18n.t("EMAILLABEL")}
                                       display="primary"
                                       placeholder={i18n.t("EMAILPLACEHOLDER")}
                                       value={values.email}
                                       onValueChange={handleChange}
                                       onBlur={handleBlur}
                                       fieldError={touched.email && errors.email}
                                       inputStyles={this.getInputErrorStyles(touched, errors, 'email')}
                                   />
                               </Grid>

                                {this.state.userType === 'customer' && (
                                    <Grid item xs={12}>
                                        <TextInput
                                            name="phoneNumber"
                                            labelText={i18n.t("PHONENUMBERLABEL")}
                                            display="primary"
                                            placeholder={i18n.t("PHONEINPUTPLACEHOLDER")}
                                            value={values.phoneNumber}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.phoneNumber && errors.phoneNumber}
                                        />
                                    </Grid>
                                )}

                               <Grid item xs={12}>
                                   <TextInput
                                       name="password"
                                       labelText={i18n.t("PASSWORDLABEL")}
                                       display="primary"
                                       placeholder={i18n.t("PASSWORDINPUTPLACEHOILDER")}
                                       value={values.password}
                                       onValueChange={handleChange}
                                       onBlur={handleBlur}
                                       fieldError={touched.password && errors.password}
                                       inputStyles={{...this.getpwdstyle(), ...this.getInputErrorStyles(touched, errors, 'password')}}
                                       inputType="password"
                                    
                                   />
                               </Grid>

                               <Grid item xs={12}>
                                   <div className="forgot-password">
                                       <div id="forgotPasswordLink" onClick={(e) => this.goToForgotPassword()}>
                                           <Typography color="primary" size="xs">{i18n.t("FORGOTPASSWORDTXT")}</Typography>
                                       </div>
                                   </div>
                               </Grid>

                           </Grid>

                           <div className={this.state.userType==="customer"?"submit":"submit_bottom"}>
                               <CustomButton
                                   dataTestId="signupSubmit"
                                   onClickHandle={() => handleSubmit()}
                                   themes="primary"
                                   styles={{
                                       height: '3.5rem',
                                       width: '22.5rem',
                                   }}
                               >{i18n.t("SIGNINTXT")}</CustomButton>
                           </div>
                                {
                                    this.state.userType === "customer" ? 
                                        <div className="login-link">
                                            <Typography font="Inter" size="sm" color="dark">{i18n.t("DONTHAVEACCOUNTTXT")}</Typography>
                                            <div id="signUpLink" className="signup-link" onClick={(e) => this.goToSignup()}>
                                                <Typography size="sm" font="Inter" color="primary" weight="bold" >{i18n.t("SIGNUPTXT")}</Typography>
                                            </div>
                                        </div> : ""
                                }
                       </form>
                   )}
               </Formik>
           </div>

       </div>
   </LoginWrapper>
       // Customizable Area End
    );
  }
}
