import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface CategoryTypes {id: number, title: string, image: string}
import { removeStorageData, getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../utilities/src/create-request-message";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import {LandingDataTypes} from "./MainLandingController.web";
import i18n from "i18next";
export interface ProductTypes {
  item:number,
  data: Array<CategoryTypes>
}
export interface ProductNormalTypes {
  id: string,
  name: string,
  image: string,
  qty: number,
  price: string,
  language: string
}

export interface CategoryDataTypes {
  id: number,
  title: string,
  image: string | null,
  products: Array<ProductNormalTypes>
}

export interface BrandsType {
  id: string,
  name: string,
  image: string,
  pStyle: string,
  language: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: Array<CategoryTypes>
  brands: Array<BrandsType>
  token: string;
  loading: boolean;
  products: Array<ProductTypes>
  activeCategory: Array<number>;
  language: string;
  landingData: Array<CategoryDataTypes>
  new_products: Array<ProductNormalTypes>,
  dashboard_data: Array<LandingDataTypes>;
  newLaunchesId:number|null
  featureBrandsId:number|null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      category: [],
      brands: [],
      token: "",
      products: [],
      loading:false,
      activeCategory: [0],
      language: "en",
      landingData: [],
      new_products: [],
      dashboard_data: [],
      newLaunchesId:null,
      featureBrandsId:null
    };
    this.goToHome = this.goToHome.bind(this);
    this.goToCategoryPage = this.goToCategoryPage.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let landing_lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(landing_lang != undefined && landing_lang != this.state.language){
        this.setState({language: landing_lang}, ()=>{
          this.getToken();
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      if (responseJson?.errors){        
        if(responseJson?.errors[0].token) this.handleLogout(); 
        this.parseApiErrorResponse(responseJson);
      } 
      this.setState({loading: false})
      this.recieveCategory(message, apiRequestCallId);
      this.recieveBrands(message, apiRequestCallId);
      this.recieveProduct(message, apiRequestCallId);
      this.recieveNewProduct(message, apiRequestCallId);
      this.recieveDashboard(message, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  categoriesCallBackID:string = "";
  brandCallBackID:string = "";
  newProductCallID:string = "";
  productCallBackId:string = "";
  landingCallID:string = "";

  async componentDidMount() {
    super.componentDidMount();
    const landing_lang = await getStorageData('language') || "en";
    this.setState({language: landing_lang}, ()=>{
      this.getToken();
      languageConvertor(landing_lang);
    });
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  }

  goToCategoryPage = (event: Event, category_id: string | number= 0 ) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), 'Categoriessubcategories');
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      request.addData(getName(MessageEnum.NavigationScreenNameMessage), category_id);
    return this.send(request);
  }

  goToCategoryPageNewLaunch = (event: Event, category_id: string | number= 0 ) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), 'Categoriessubcategories');
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if(this.state.newLaunchesId){
      request.addData(getName(MessageEnum.NavigationScreenNameMessage),this.state.newLaunchesId);
    }
    return this.send(request);
  }
  goToCategoryPageBrands= (event: Event, category_id: string | number= 0 ) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), 'Categoriessubcategories');
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if(this.state.featureBrandsId){
      request.addData(getName(MessageEnum.NavigationScreenNameMessage),this.state.featureBrandsId);
    }
    return this.send(request);
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if(token){
      this.setState({token: token}, ()=> {
        this.getCategories();
        this.getBrands();
        this.getProducts();
        this.getNewProducts();
        this.getDashboardPage();
      });
    }else{
      this.handleLogout()
    }
  };

  handleLogout = () => {
    removeStorageData("authToken");
    const requestMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    requestMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
    requestMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    return this.send(requestMsg);
  }

  recieveProduct = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(messageID === this.productCallBackId){
      const landing_record = [];
      for (const item of responseJson.data) {
          let inner_item = {id: item.id, title: item.name_locale, image: item.banner_image, products: [] as Array<ProductNormalTypes>}
          if(item.product_details.data.length > 0){
            let products: Array<ProductNormalTypes> = [];
            for (const inner_item_data of item.product_details.data) {
              const product_image = inner_item_data.attributes.images != null ? inner_item_data.attributes.images[0].url : "";
              products.push({
                id: inner_item_data.id, 
                name: inner_item_data.attributes.name_locale, 
                image: product_image,
                price: `SAR ${inner_item_data.attributes.price}`,
                qty: inner_item_data.attributes.stock_qty,
                language: this.state.language
              });
            }
            inner_item.products = products
          }
          landing_record.push(inner_item);
      }
      
       this.setState({landingData: landing_record})
       return this.state.landingData
    }
  }

  recieveNewProduct = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(messageID === this.newProductCallID){
      let newLaunch : Array<ProductNormalTypes> = [];
      for (const record of responseJson.data) {
        const product_image = record.attributes.images != null ? record.attributes.images[0].url : "";
        newLaunch.push({
          id: record.id, 
          name: record.attributes.name_locale, 
          image: product_image,
          price: `${i18n.t("SARTXT")} ${record.attributes.price}`,
          qty: record.attributes.stock_qty,
          language: this.state.language
        });
      }
      return this.setState({new_products: newLaunch})
    }
  }

  recieveCategory = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let category: Array<CategoryTypes> = [];
    if(messageID === this.categoriesCallBackID){
      for (const item of responseJson.data) {
        if(item.attributes.is_show_header){
          let inner_item = ({id: item.attributes.id, title: item.attributes.name_locale, image: item.attributes.image});
          category.push(inner_item);
        }
      }
      const newLaunchObj= responseJson.data.find((obj:any) => obj.attributes.name =="New Launches");
      const featureBrandsObj=responseJson.data.find((obj:any) => obj.attributes.name =="Featured Brands");
      if (newLaunchObj) {
        this.setState({ newLaunchesId: newLaunchObj.id })
      }
      if (featureBrandsObj) {
        this.setState({ featureBrandsId: featureBrandsObj.id })
      }
      return this.setState({category: category})
    }
  }

  recieveBrands = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let brands_data: Array<BrandsType> = [];
    if(messageID === this.brandCallBackID){
      for (const item of responseJson.data) {
          let inner_item = ({id: item.attributes.id, name: item.attributes.name_locale, image: item.attributes.image, pStyle: "brands", language: this.state.language});
          brands_data.push(inner_item);
      }
      this.setState({brands: brands_data})
    }
  }

  recieveDashboard = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(messageID === this.landingCallID){
      this.setState({dashboard_data: responseJson.data})
    }
  }

  getCategories = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.categoriesCallBackID = requestMessage.messageId;
    this.setState({loading: true});
    
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getCategoryEndPoint}`,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language},
      token: this.state.token
    });
  }

  getBrands = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.brandCallBackID = requestMessage.messageId;
    this.setState({loading: true});
    
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.getBrandsEndPoint}`,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language},
      token: this.state.token
    });
  }

  getNewProducts = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.newProductCallID = requestMessage.messageId;
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.getNewProductEndPoint,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language},
      token: this.state.token
    });
  }

  isTheme = (image: string | null) => {
    return image!=null ? 'dark' : 'light';
  }

  getProducts = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.productCallBackId = requestMessage.messageId;
    this.setState({loading: true});
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.customerLandingEndPoint}`,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language},
      token: this.state.token
    });
  }

  getDashboardPage = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.landingCallID = requestMessage.messageId;
    this.setState({loading: true});
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.customerPageEndPoint}&language=${this.state.language}`,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language},
      token: this.state.token
    });
  }
  // Customizable Area End
}
