// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import resources from "./utilities";
import { WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

type GetCategoriesApiResponse = Array<{
  id: string
  attributes: {
    name: string;
    ar_category_name: string;
    sub_categories: {
      data: Array<{
        id: number
        attributes: {
          name: string
          ar_sub_categories_name: string
        }
      }>
    }
  }
}>

type GetProductsApiResponse = Array<{
  id: string
  attributes: {
    name: string;
    sku: string;
    categories: {
      id: string
      name: string;
    }[];
    sub_categories: Array<{
      id: number
      name: string
      ar_sub_categories_name: string
    }>
  }
}>

type CreateInventoryApiResponse = {
  data: {
    type: string;
    attributes: {
      errors?: {
        [key: string]: string[]
      }
    }
  }
}

type typeCreateNumberInputs = {
  name: string,
  type: "text" | "number";
  title: string,
}[]

type Category = {
    id: string
    name: string
    arabicName: string
    subCategories: Array<{
      id: string
      name: string
      arabicName: string
    }>
  }

type Product = {
  id: string
  name: string
  categories: string[];
  subCategory: string[];
  sku: string
}

type inventoryCategoryResponseJson = {
  data: GetCategoriesApiResponse
}
type inventoryProductResponseJson = {
  data: GetProductsApiResponse
}
export interface InventoryFormErrors {
  [key: string]: string;
}

export interface Image {
  preview: string;
  file: Blob
}

export interface InventoryForm {
  productName: string;
  category: string[];
  categoryIds: string[];
  subCategory: string[];
  subCategoryIds: string[];
  price: string;
  SKU: string;
  incomming: string;
  commited: string;
  numberAvailable: string;
  refund_status: string;
}

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  classes: WithStyles["classes"];
}

interface S {
  loading: boolean;
  token: string;
  categories: Category[];
  products: Product[];
  images: Image[];
  createNumberInputs: typeCreateNumberInputs
  selectStatus: string
  language: string
}

interface SS {
  id: string;
}

export default class CreateInventoryController extends BlockComponent<
  Props,
  S,
  SS
> {

  formikRefCreate = React.createRef<FormikProps<InventoryForm>>();
  getCategoriesApiCallId = ""
  getProductsApiCallId = ""
  createInventoryApiCallId = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      categories: [],
      products: [],
      loading: false,
      images: [],
      token: "",
      createNumberInputs: [],
      selectStatus: 'selectWithYes',
      language: 'en'
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageCreateInventory(landing_lang)
    const token = (await getStorageData("authToken")) as string;
    if (token) {
      this.callGetCategoriesApi(token)
      this.callGetProductsApi(token)
    }
    this.setState({ 
        token: token,
      });
  }

  componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): void {
    if(oldState.language !== this.state.language){
      this.handleChangeLanguageCreateInventory(this.state.language)
    }
  }

  validateInventoryForm = (values: InventoryForm) => {
    const errors: InventoryFormErrors = {};

    const printError = (
      value: string | undefined,
      name: string,
      msg: string
    ) => {
      if (!value?.trim() && name) {
        errors[name] = msg;
      }
    };

    printError(
      values.productName,
      "productName",
      i18n.t('SELECT_PRODCUT_NAME_ERROR'),
    );

    printError(
      values.SKU,
      "SKU",
      ''
    );

    printError(
      values.incomming,
      "incomming",
      i18n.t('INCOMMING_ERROR')
    );


    printError(
      values.commited,
      "commited",
      i18n.t('COMMITTED_ERROR')
    );


    printError(
      values.numberAvailable,
      "numberAvailable",
      i18n.t('AVAILABLE_ERROR')
    );


    printError(
      values.refund_status,
      "Status",
      resources.status.error.en
    );

    if (!values.category.length) {
      errors.category = resources.category.error.en
      errors.arabicCategory = resources.category.error.ar
    }

    return errors;
  };

  getCategoryOption = (fieldName: "category" | "arabicCategory") => 
  fieldName === "category" 
  ? this.state.categories.map(category => ({ key: category.id, name: category.name }))
  : this.state.categories.map(category => ({ key: category.id, name: category.arabicName }))

  getFilteredSubCategories = (selectedCategoryIds: string[]) => {
    return this.state.categories
    .filter(category => selectedCategoryIds.includes(category.id))
    .map(category => category.subCategories).flat()
  }


  callGetCategoriesApi = (token?: string) => {

    const headers = {
      "Content-Type": configJSON.getCategoriesApiContentType,
      token: token || this.state.token,
    };

    this.setState({ loading: true });


    const apiInventoryGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCategoriesApiCallId = apiInventoryGetMessage.messageId;

    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesApiEndpoint
    );

    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoriesApiMethodType
    );
    runEngine.sendMessage(apiInventoryGetMessage.id, apiInventoryGetMessage);
  };

  callGetProductsApi = (token?:string) => {

    const headers = {
      "Content-Type": configJSON.getProductsApiContentType,
      token: token || this.state.token,
    };

    this.setState({ loading: true });


    const apiInventoryGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = apiInventoryGetMessage.messageId;

    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductsApiEndpoint
    );

    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiInventoryGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getProductsApiMethodType
    );
    runEngine.sendMessage(apiInventoryGetMessage.id, apiInventoryGetMessage);
  };

  handleCancel = () => {
    this.props.navigation.navigate("InventoryList", {})
  }



  callCreateInventoryApi = (formData: InventoryForm) => {
    this.setState({ loading: true });
    const requestBody = this.convertToFormData(formData);

    const headers = {
      token: this.state.token,
    };
    const createInventoryMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createInventoryApiCallId = createInventoryMsg.messageId;

    createInventoryMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createInventoryApiEndpoint
    );

    createInventoryMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createInventoryMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createInventoryApiMethodType
    );
    createInventoryMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );
    runEngine.sendMessage(createInventoryMsg.id, createInventoryMsg);
  }

  convertToFormData = (productData: InventoryForm) => {
    const formData = new FormData();
    formData.append("catalogue_id", productData.productName);
    formData.append("incoming", productData.incomming);
    formData.append("committted", productData.commited);
    formData.append("available", productData.numberAvailable);
    formData.append("refund_status", productData.refund_status === 'Yes' ? 'true' : 'false');
    
    this.state.images.forEach((image, index) => {
      formData.append(`catalogue_images_attributes[${index}][image]`, image.file)
    })
    return formData;
  };

  async receive(from: string, message: Message) {
    this.recieveLanguageCreateInventory(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let updateStateData: Partial<S> = {
        loading: false,
      };

      const inventoryApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const inventoryResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const inventoryErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiErrorResponse(inventoryResponseJson);
      this.parseApiCatchErrorResponse(inventoryErrorReponse);

      if (inventoryApiRequestCallId === this.getCategoriesApiCallId) {
        updateStateData.categories = this.mapCategoriesData(inventoryResponseJson)
      }
      
      if (inventoryApiRequestCallId === this.getProductsApiCallId) {
        updateStateData.products = this.mapProductsData(inventoryResponseJson)
      }

      
      if (inventoryApiRequestCallId === this.createInventoryApiCallId && inventoryResponseJson?.data) {
        this.handleCreateInventoryResult(inventoryResponseJson)
      }
      this.setState(updateStateData as Pick<S, keyof S>);
    }
  }

  mapCategoriesData(inventoryResponseJson: inventoryCategoryResponseJson){
  return inventoryResponseJson?.data
        ? (inventoryResponseJson.data).map((record) => ({
          id: record.id,
          name: record.attributes.name,
          arabicName: record.attributes.ar_category_name,
          subCategories: record.attributes?.sub_categories.data?.map(sub_category => ({
            id: sub_category.id.toString(),
            name: sub_category.attributes.name,
            arabicName: sub_category.attributes.ar_sub_categories_name
          }))
        }))
        : [];
  }

  mapProductsData(inventoryResponseJson: inventoryProductResponseJson){
    return inventoryResponseJson?.data
    ? (inventoryResponseJson.data).map((record) => ({
      id: record.id,
      name: record.attributes.name,
      categories: record.attributes.categories.map(category => (category.id.toString())) ?? [],
      subCategory: record.attributes.sub_categories.map((sub_category: { id: number }) => sub_category.id.toString()) ?? [],
      sku: record.attributes.sku,
    }))
    : [];
  }

  handleCreateInventoryResult(inventoryResponseJson: CreateInventoryApiResponse) {
    if (inventoryResponseJson.data.type === "product") {
      this.formikRefCreate.current?.resetForm()
      this.setState({images: []})
      this.showAlert(i18n.t('SUCCESSTXT'), i18n.t('INVENTORYTXT') + ' ' + i18n.t('ADDEDTXT'))
    }
    
    if (inventoryResponseJson.data.type === "error") {
      this.parseCreateInventoryApiErrorResponse(inventoryResponseJson)
    }
  }

  parseCreateInventoryApiErrorResponse(inventoryresponseJson: CreateInventoryApiResponse) {
    if (!inventoryresponseJson.data.attributes.errors || !Object.keys(inventoryresponseJson.data.attributes.errors).length ) {
      return;
    }
    const { errors } = inventoryresponseJson.data.attributes;

    let allerrors = '';
    Object.keys(errors).forEach((errorKey) => {
      const val = errors[errorKey]
        if (val.length > 0) {
          const message = `${errorKey}: ${val}`
          if (allerrors.length <= 0) {
            allerrors = message;
          } else {
            allerrors = `${allerrors}\n${message}`;
          }
        }
    });

    this.showAlert('Error', allerrors);
  }

  handleChangeLanguageCreateInventory = (lang: string) => {
    languageConvertor(lang);
      this.setState({
        language: lang,
        createNumberInputs: [{
          name: "incomming",
          title: i18n.t('INCOMMINGTXT'),
          type: "number",
        },{
          name: "commited",
          title: i18n.t('COMMITTEDTXT'),
          type: "number"
        },{
          name: "numberAvailable",
          title: i18n.t('AVAILABLETXT'),
          type: "number"
        }]
    })
  };
  recieveLanguageCreateInventory = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
}

// Customizable Area End
