import {IBlock} from "../../../framework/src/IBlock";
import {Message} from "../../../framework/src/Message";
import {BlockComponent} from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import {runEngine} from "../../../framework/src/RunEngine";

// Customizable Area Start
import {Alert} from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation?: object;
    id?: string;
    // Customizable Area Start
    postID: number;
    userToken: string;
    style?: object;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    contentData: { id: number; reason: string }[];
    showFlag: boolean;
    selectedValue: {
        id: number;
        reason: string;
    };
    showSuccessModal: boolean;
    successmessage: string;
    // Customizable Area End
}

interface SS {
    id: number;
    // Customizable Area Start
    // Customizable Area End
}

export default class ContentFlagController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getReasonId: string = "";
    flagContentId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

// Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            contentData: [],
            showFlag: false,
            selectedValue: {id: 0, reason: ""},
            showSuccessModal: false,
            successmessage: "",
            // Customizable Area End
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId == this.getReasonId) {
                if (responseJson) {
                    this.setState({contentData: responseJson.success[0].flag_reason});
                }
            }
            if (apiRequestCallId === this.flagContentId) {
                if (!responseJson.errors) {
                    this.setState({
                        successmessage: responseJson.success[0].message,
                        selectedValue: {
                            id: 0,
                            reason: "",
                        },
                    });
                    Alert.alert("Success", responseJson.success[0].message, [
                        {
                            text: "OK",
                            onPress: () =>
                                this.setState({
                                    selectedValue: {
                                        id: 0,
                                        reason: "",
                                    },
                                    showFlag: false,
                                }),
                        },
                    ]);
                }
            }
            this.parseApiCatchErrorResponse(errorResponse);
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.fetchReasons(this.props.userToken);
        // Customizable Area End
    }

    handleFlagIconClick = () => {
        this.setState({
            showFlag: !this.state.showFlag,
        });
    };
    handleFlagButtonClick = () => {
        this.setState({
            showSuccessModal: !this.state.showSuccessModal,
        });
    };
    selectContent = (item: { id: number; reason: string }) => {
        this.setState({
            selectedValue: {id: item.id, reason: item.reason},
        });
    };

    fetchReasons = async (token: string) => {
        const fetchReasonsHeader = {
            "Content-Type": configJSON.contentFlagApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getReasonId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllFlagReasonsEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(fetchReasonsHeader)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getReasonApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    doFlagContent(token: string, postid: number, flagid: number) {
        if (!this.state.selectedValue.reason) {
            this.showAlert("Error", configJSON.emptyLableError);
            return false;
        }
        const doFlagContentHeader = {
            "Content-Type": configJSON.contentFlagApiContentType,
            token,
        };
        const httpBody = {
            post_id: postid,
            reason_id: flagid,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.flagContentId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.flagPostEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(doFlagContentHeader)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postFlagAPiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area Start
    // Customizable Area End
}
