import React from "react";
import {RadioProps, Radio} from "@material-ui/core";
import {radioCheckedWebIcon} from "./assets";

export default class StyledRadio extends React.Component<RadioProps> {
    constructor(props: RadioProps) {
        super(props)
    }
    render(): React.ReactNode {
        return (
            <Radio
                {...this.props}
                checkedIcon={this.props.checkedIcon || radioCheckedWebIcon}
            />
        )
    }
}