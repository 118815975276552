import React from "react";
// Customizable Area Start
import { Box, Grid, MenuItem, Select } from "@material-ui/core";
import {
  createStyles,
  createTheme,
  StyleRules,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import { FormikValues } from "formik/dist/types";
import TextInput from "../../../components/src/design-system/TextInput.web";
import { Formik } from "formik";
import resources from "./utilities";
import { closeIcon, imageUploadIcon } from "./assets";
import { FormHeader } from "@builder/component-library";
import { CloseIcon } from "../../../components/src/assets";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomSelect from "../../../components/src/design-system/CustomSelect.web";
import Typography from "../../../components/src/design-system/Typography.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Uploadmedia3 from "../../uploadmedia3/src/Uploadmedia3.web";
import Loader from "../../../components/src/Loader.web";
import i18n from "i18next";

export const initialInventoryFormValues: InventoryForm = {
  productName: "",
  category: ["1"],
  subCategory: ["1"],
  categoryIds: ["1"],
  subCategoryIds: ["1"],
  price: "0",
  SKU: '',
  incomming: "0",
  commited: "0",
  numberAvailable: "0",
  refund_status: "Yes",
};

export const statusOptions = [{id:'Yes', name:'Yes'},{id:'No', name:'No'}]


interface FormCreateData {
  name: string;
  type?: 'text' | 'number';
  title: string;
  selectItems?: {id: string, name: string}[]
  multipleSelectItems?: {key: string, name: string}[]
}

// Customizable Area End

import CreateInventoryController, {
  InventoryForm,
  Props,
} from "./CreateInventoryController";

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      input: {
        borderRaduis: "50px",
      },
    },
  },
  palette: {
    primary: {
      main: "#6200EA",
      contrastText: "#fff",
    },
  },
});

export class CreateInventory extends CreateInventoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <NavigationMenu id="" navigation={this.props.navigation}>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <Formik
              initialValues={initialInventoryFormValues}
              validate={this.validateInventoryForm}
              data-test-id="formik"
              onSubmit={this.callCreateInventoryApi}
              innerRef={this.formikRefCreate}
            >
              {({
                values,errors,
                touched,handleChange,
                handleBlur,handleSubmit,setFieldValue,
              }):
               FormikValues => (<form onSubmit={handleSubmit} className="product-form">
                  <Grid className="product-form-header" container>
                    <Grid item xs={6}>
                      <FormHeader
                        containerStyle={{ border: "none" }}
                        titleText={i18n.t('ADDNEWITEMTXT')}
                      />
                    </Grid>
                  </Grid>

                  <Grid  className="product-form-body" container>
                    <Grid item xs={6}>
                      <Box className="mb-30">
                        <label className="label-text">{i18n.t('PRODUCTNAMETXT')}</label>
                        {this.renderSelect(
                            { 
                              title: i18n.t('PRODUCTNAMETXT'),
                              name: 'productName',
                              selectItems: this.state.products
                            },
                            values,
                            handleChange,
                            handleBlur,
                            touched as { [key: string]: string },
                            errors as { [key: string]: string | undefined },
                            setFieldValue,
                          )}
                      </Box>

                      <Box className="mb-30">
                        <label className="label-text">{i18n.t('CATEGORYTXT')}</label>
                        {this.renderMultipleSelect(
                            { name: 'category',
                              title: i18n.t('CATEGORYTXT'),
                              multipleSelectItems: this.getCategoryOption('category')
                            },
                            values,
                            true,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            touched as { [key: string]: string },
                            errors as { [key: string]: string | undefined },
                          )}
                      </Box>

                      <Box className="mb-30">
                        {this.renderEditTextInput(
                              { name: 'SKU', title: i18n.t('SKU'), type: 'text'},
                              values,
                              true,
                              touched as { [key: string]: string },
                              errors as { [key: string]: string | undefined },
                              setFieldValue,
                              handleChange,
                              handleBlur,
                          )}
                      </Box>


                      <Box className="input-wrap mb-30" data-test-id="inputWrap">
                          {this.state.createNumberInputs.map((input, index) =>
                              <Box className="input-item" key={index}>
                                  {this.renderEditTextInput(
                                          input,
                                          values,
                                          false,
                                          touched as { [key: string]: string },
                                          errors as { [key: string]: string | undefined },
                                          setFieldValue,
                                          handleBlur,
                                          handleChange,
                                      )}
                              </Box>
                          )}
                      </Box>

                      <Box className="mb-30">
                        <label className="label-text">{i18n.t('REFUNDSTATUSTXT')}</label>
                        {this.renderSelect(
                            { name: 'refund_status',
                              title: 'Status',
                              selectItems: statusOptions
                            },
                            values,
                            handleChange,
                            handleBlur,
                            touched as { [key: string]: string },
                            errors as { [key: string]: string | undefined },
                            setFieldValue,
                          )}
                      </Box>

                      <Box>
                       <Box className="upload-image-field">
                        {this.renderImageInput(
                              { name: 'image', title: i18n.t('IMAGETXT')},
                              handleChange,
                              handleBlur,
                              values,
                              touched as { [key: string]: string },
                              errors as { [key: string]: string | undefined },
                              setFieldValue,
                            )}
                      </Box>
                      </Box>

                    </Grid>
                  </Grid>

                  {/* START FORM BODY */}

                  {/* START FORM FOOTER */}
                  <Grid container className="product-form-footer">
                    <Grid item xs={6}>
                      <CustomButton
                        onClickHandle={this.handleCancel}
                        dataTestId="productFormCancel"
                        themes="dark"
                        styles={{ background: "#E6EEFF", width:"132px" }}
                        display="secondary"
                      >
                        {i18n.t('CANCELTXT')}
                      </CustomButton>
                      <CustomButton
                        styles={{width:"132px" }}
                        onClickHandle={() => handleSubmit()}
                        dataTestId="productFormSubmit"
                        display="primary"
                        themes="dark"
                      >{i18n.t('ADDTXT')}</CustomButton>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                  {/* START FORM FOOTER */}
                </form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }

  renderMultipleSelect = (
    formData: FormCreateData,
    values: InventoryForm,
    disabled: boolean,
    handleChange: (event: React.ChangeEvent<unknown>) => void,
    handleBlur: React.FocusEventHandler,
    setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    ) => {
    
    const field = formData.name as keyof InventoryForm
    const subCategories = this.getFilteredSubCategories(values.category);
    const subCategoryOption =
      subCategories.map(subCategory => ({ key: subCategory.id, name: subCategory.name }))

    return (
      <>
      <Box mt={"8"} className="category-select">
      <CustomSelect
        name={formData.name}
        variant="outlined"
        disabled={disabled}
        className="multi-select"
        multiple
        options={formData.multipleSelectItems ?? []}
        value={values[field] as string}
        onChange={(event) =>
          setFieldValue(field, event.target.value as string[])
        }
        onBlur={handleBlur}
      />
{
          Boolean(touched[formData.name]) && Boolean(errors[formData.name])
          && <Typography>
            {
              errors[formData.name]
            }
          </Typography>
        }
    </Box>
      {
        subCategoryOption.length > 0
        &&
        <Box pt="48">
          <label className="label-text">Sub Category
          </label>
          <Box mt="8" className="category-select">
            <CustomSelect
              name="subCategory"
              variant="outlined"
              className="multi-select"
              multiple
              disabled={disabled}
              options={subCategoryOption}
              value={values.subCategory}
              onChange={(event) =>
                setFieldValue("subCategory", event.target.value as string[])
              }
              onBlur={handleBlur}
            />
          </Box>
        </Box>
      }
      </>
    );
  }

  renderEditTextInput = (
    formData: FormCreateData,
    values: InventoryForm,
    disabled: boolean,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
    handleChange: (event: React.ChangeEvent<unknown>) => void,
    handleBlur: React.FocusEventHandler,
    ) => {
    
    const field = formData.name as keyof InventoryForm

    return (
        <TextInput
        id={formData.name + "Id"}
        name={formData.name}
        disabled={disabled}
        data-test-id={formData.name}
        labelText={formData.title}
        placeholder={formData.title}
        display="primary"
        onValueChange={(event) => {
            if (formData.type === 'number' && event?.target?.value && !/^\d+$/.test(event.target.value)){
                return;
            }

            handleChange(event)
            setFieldValue(field, event?.target?.value);
        }}
        onBlur={handleBlur}
        fieldError={touched[formData.name] && errors[formData.name]}
        value={values[field] as string}
        />
    );
  }

  renderImageInput = (
    formData: FormCreateData,
    handleChange: (event: React.ChangeEvent<unknown>) => void,
    handleBlur: React.FocusEventHandler,
    values: InventoryForm,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
    ) => {
    
    return (
      <Box className="upload-image-field">
        <label className="label-text">{formData.title}</label>
        <Box
          className="image-container"
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          gridGap={10}
        >
          <Uploadmedia3
            id="file-upload"
            navigation={this.props.navigation}
            onFileUpload={(files) =>
              this.setState({
                images: files.map((file) => ({
                  preview: URL.createObjectURL(file),
                  file: file,
                })),
              })
            }
            imageUrl={imageUploadIcon}
            textColor="primary"
            textSize="xs"
            text={formData.title}
          />
          {this.state.images.map((image, imageIndex) => (
            <Box
              key={imageIndex}
              position="relative"
              className="image-thumbnail"
            >
              <img src={image.preview} width={100} height={100} />
              <img
                src={closeIcon}
                data-test-id="delete-icon"
                className="delete-icon"
                onClick={() =>
                  this.setState({
                    images: this.state.images.filter(
                      (img, filterIndex) => filterIndex !== imageIndex
                    ),
                  })
                }
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }  

  renderSelect = (
    formData: FormCreateData,
    values: InventoryForm,
    handleChange: (event: React.ChangeEvent<unknown>) => void,
    handleBlur: React.FocusEventHandler,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
    ) => {
    const field = formData.name as keyof InventoryForm
    
    return (
      <Box mt={"8"} className={`select ${ field === 'refund_status' ? this.state.selectStatus : ''}`}>
        <Select
          data-test-id={formData.name}
          name={formData.name}
          variant="outlined"
          value={values[field] as string}
          onChange={(event) =>{
              setFieldValue(field, event.target.value as string[])
              if(field === 'productName'){
                let productId = event.target.value;
                let product = this.state.products.find((product) => productId == product.id)
                
                if(product){
                  setFieldValue('category',product.categories)
                  setFieldValue('subCategory',product.subCategory)
                  setFieldValue('SKU', product.sku);
                }
              }
              if(field === 'refund_status'){
                let val = event.target.value;
                if(val === 'Yes'){
                  this.setState({
                    selectStatus: 'selectWithYes'
                  })
                }else{
                  this.setState({
                    selectStatus: 'selectWithNo'
                  })
                }
              }
            }
          }
          onBlur={handleBlur}
        >
          {formData.selectItems ? formData.selectItems.map((item, index) =>
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
          ) : ''}
        </Select>
        {
          Boolean(touched[formData.name]) && Boolean(errors[formData.name])
          && <Typography>
            {
              errors[formData.name]
            }
          </Typography>
        }
      </Box>
    );
  }

}

// Customizable Area Start
export const createInventoryStyles: StyleRules = createStyles({
  root: {
    overflow: "auto",
    "& .product-form": {
      "& .label-text": {
        fontSize: "1rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
      },
      "& .drag-drop-box": {
        border: "0",
        background: "#E6EEFF",
        width: "110px",
        minWidth: 0,
      },

      "& .product-form-header, & .product-form-body, & .product-form-footer": {
        "& .MuiGrid-item:nth-child(odd)": {
          border: "1px solid rgb(200,200,200)",
        },
        "& .css-text-1rynq56": {fontWeight:"bold"},
        "& .MuiGrid-item:nth-child(even)": {
          "& label, .label-text": {
            display: "block",
            textAlign: "right",
          },
          "& input": {
            textAlign: "right",
          },
          "& .customBtn span": {
            flexDirection: "row-reverse",
          },

          "& .upload-image-field": {
            "&>div": {
              display: "flex",
              justifyContent: "end",
              flexDirection: "row-reverse",
            },
          },
        },
        "& .category-select": {
          "& > div:last-of-type": {
            color: "#DC2626 !important",
            marginTop: "8px"
          },
          "& .multi-select": {
            width: "100%",
            "& .MuiSelect-root": {
              padding: "18px 14px",
            },
          },

        },
        "& .select": {
          "& > div:last-of-type:not(.MuiInputBase-root)": {
            color: "#DC2626 !important",
            marginTop: "8px"
          },
          "& .MuiInputBase-root": {
            width: "100%",
          },
        },
        
        "& .image-thumbnail": {
          "& .delete-icon": {
            cursor: "pointer",
            position: "absolute",
            top: -10,
            right: -10,
          },
        },
        "& #uploadFileSection": {
          height: 100,
        },
      },
      "& .product-form-header": {
        "& .header-arabic-text": {
          "&>div>div": {
            alignItems: "flex-end",
            flexDirection: "column",
          },
        },
        "& .MuiGrid-item": {
          borderBottom: '0 !important'
        },
      },

      "& .product-form-footer": {
        textAlign: "right",
        marginBottom: "20px",
        "& button": {
          marginRight: "24px",
        },
        "& .MuiGrid-item": {
          padding: "24px 0 24px 0",
          borderTop: '0 !important'
        },
      },
      "& .input-wrap": {
        display: 'flex',
        gap: '15px',
      },
      "& .mb-30": {
        marginBottom: 30
      },
      "& .product-form-body": {
        "& .MuiGrid-item": {
          padding: "24px",
        },
      },
      "& .selectWithYes": {
        "& .MuiSelect-select": {
          color: 'green'
        },
      },
     
      "& .selectWithNo": {
        "& .MuiSelect-select": {
          color: 'red'
        },
      },
    },
  },
});
export default withStyles(createInventoryStyles)(CreateInventory);
// Customizable Area End
