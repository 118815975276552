import React, { Component } from "react";
import { Box, Modal, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "./design-system/Typography.web";
import CustomButton from "./design-system/CustomButton.web";

type Props = {
    children: any;
    title?: string;
    saveBtnTxt?: string;
    cancelBtnTxt?: string;
    isVisible: boolean;
    onCloseModal: () => void;
    onSaveOrConfirmModal?: () => void
};


const ModalBox = styled(Box)({
    "&.innerBody": {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        overflowY: "scroll",
        "@media (max-width: 500px)": {
            minWidth: "19rem"
        },
    },
    "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 24px",
        marginBottom: "0.7rem",
        "@media (max-width: 420px)": {
            padding: "24px 16px",
        },
    },
    "& .child": {
    },
    "& .footer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "1rem",
        padding: "20px 24px 0px 20px",
        borderTop: "1px solid #E2E8F0",
        marginTop: "0.5rem"
    },
    "& .errors": {
        color: '#DC2626',
        fontSize: '0.875rem',
        fontWeight: 400,
        marginTop: '0.5rem',
        marginLeft: '0',
    },
    "& .label": {
        fontSize: '1rem',
        color: '#000000',
        fontWeight: "bold",
        marginBottom:"0.5rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    "& .form-body": {
        maxHeight: "65vh",
        overflow: "scroll",
        marginBottom: "1rem",
        padding: "20px 20px 0 20px",
        maxWidth: "600px",
        "@media (max-width: 420px)": {
            padding: "0 16px",
        },
    },
    "& .MuiGrid-spacing-xs-3": {
        margin:0,
        width:"100%"
    }
});


export default class CustomModal extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        const { isVisible, title, cancelBtnTxt = "Cancel", saveBtnTxt = "Save", children, onSaveOrConfirmModal, onCloseModal } = this.props;

        return (
            <Modal
                open={isVisible}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBox className="innerBody">
                    <Box className="header">
                        <Typography size="x" weight="semiBold">
                            {title}
                        </Typography>
                        <Box onClick={onCloseModal} id="close-modal">
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box className="child">
                        {children}
                    </Box>
                    {
                        onSaveOrConfirmModal && <Box className="footer">
                            <CustomButton
                                styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                width="132px"
                                themes="dark"
                                display="secondaryBorderless"
                                onClickHandle={onCloseModal}
                                dataTestId="cancel">
                                {cancelBtnTxt}
                            </CustomButton>
                            <CustomButton
                                width="132px"
                                styles={{ background: "#2563EB" }}
                                themes="dark"
                                display="primary"
                                onClickHandle={onSaveOrConfirmModal}
                                data-testid="save">
                                {saveBtnTxt}
                            </CustomButton>
                        </Box>
                    }
                </ModalBox>
            </Modal>
        );
    }
}


