import React from "react";

// Customizable Area Start
import {
  Box,
  Popover,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import UserSidebar from "../../navigationmenu/src/UserSidebar.web";
import i18n from "i18next";
import {NotificationInterface} from "./NotificationsController";
import CreateAvatar from "../../../components/src/design-system/CreateAvatar.web";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";
import Loader from "../../../components/src/design-system/Loader.web";
import Typography from "../../../components/src/design-system/Typography.web";
import TimeFormat from "../../../components/src/design-system/TimeFormat.web";
import { Avatar } from "@builder/component-library";
// Customizable Area End

import CustomerNotificationController, {
  Props
} from "./CustomerNotificationController.web";

export default class CustomerNotification extends CustomerNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <>
            <Loader loading={this.state.loader} />
           {!this.props.isFromHeader && <FrontHeader navigation={this.props.navigation} customStyle={{ background: "#fff" }} customStyleBox={{ background: "#fff", boxShadow: "0px 1px 5px 5px rgba(0,0,0,0.2)" }} />}
            <NotificationWrap>
                <Box className="main_wrapper">
                {!this.props.isFromHeader && <UserSidebar navigation={this.props.navigation} activeScreenId="CustomerNotification" />}
                    <Box className="wrapper">
                        <Box className="markAllWrap" id="markAllWrap">
                            <Typography>
                                <Box className="title">
                                    {i18n.t("ALLNOTIFICATIONTXT")}
                                </Box>
                            </Typography>
                            <CustomButton dataTestId="markAllRead" display="textButton" styles={{padding: "0px"}} themes="dark" onClickHandle={this.markAllAsRead}>
                                <CheckIcon />
                                {i18n.t("MARKALLASREADTXT")}
                            </CustomButton>
                        </Box>
                        <Box className="inner_wrapper">
                        {(this.state.notifications && this.state.notifications.length > 0) ? this.state.notifications.map((notification_item: NotificationInterface) => {
                            return (
                            <Box
                                className={this.isReadNotification(notification_item)}
                                key={notification_item.attributes.id}
                            >
                                <Box className="notificationLeftBox">
                                <Avatar image={notification_item.attributes.images}/>
                                <Typography>
                                    <Box className="notificationContent">
                                        {notification_item.attributes.contents}
                                    </Box>
                                </Typography>
                                </Box>

                                <Box className="notificationRightBox">
                                <IconButton className={`iconOpenPopover ${notification_item.attributes.is_read && "read"}`} aria-controls="open-menu" id={notification_item.id} onClick={(event) => this.openPopover(event, notification_item)}>
                                    <MoreVertSharpIcon />
                                </IconButton>
                                <StyledActionPopper
                                    id={notification_item.id}
                                    open={Boolean(this.state.anchorEl)}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.closePopover}
                                    anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                    }}
                                >
                                    <CustomButton themes="dark" dataTestId="actionRead" display="textButton" className="actionIconWrap actionRead" onClickHandle={this.notificationRead}>
                                        <Box sx={{ mr: 1 }}>
                                            <DraftsIcon />
                                        </Box>
                                        {i18n.t("READTXT")}
                                    </CustomButton>
                                    <CustomButton themes="dark" dataTestId="actionDelete" display="textButton" className="actionIconWrap actionDelete" onClickHandle={this.deleteNotification}>
                                        <Box sx={{ mr: 1 }}>
                                            <DeleteIcon />
                                        </Box>
                                        {i18n.t("DELETETXT")}
                                    </CustomButton>
                                </StyledActionPopper>
                                <Typography>
                                    <Box className={`textNoWrap ${notification_item.attributes.is_read && "read"}`}>
                                        <TimeFormat date={notification_item.attributes.created_at} />
                                    </Box>
                                </Typography>
                                </Box>
                            </Box>
                            );
                        }) : <Typography size="xl"><Box className="no-record">{i18n.t("NONOTIFICATIONTXT")}</Box></Typography>}
                        </Box>
                    </Box>
                </Box>
            </NotificationWrap>
            <CustomSnackBar
              data-test-id="CustomSnackBarHeader"
              open={this.state.showToast}
              errorMessage={this.state.toastMessage}
              onClose={this.handleCloseSnackBars}
              severity={this.state.toastSuccess }
            />
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NotificationWrap = styled("div")({
    background: "white",
  "& .main_wrapper":{
    "display": "flex",
    "maxWidth": "1440px",
    "margin": "0px auto 200px",
    "padding": "16px 40px",
    "boxSizing": "border-box",
    "gap": "16px"
  },
  "& .wrapper":{
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px",
    flex:1
  },
  "& .title": {
    fontWeight: 'bold',
    color: "#334155",
    fontSIze: "14px"
  },
  "& .inner_wrapper":{
    padding: "14px 24px",
    display: "block"
  },
  "& .notificationBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    marginBottom: "16px",
    padding: "10px 34px" 
  },
  "& .no-record":{
    paddingLeft: "28px"
  },
  "& .notificationRightBox": {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "end",
    "justifyContent": "end",
    "textAlign": "right"
  },
  "& .notificationContent": {
    color: "#333333"
  },
  "& .notificationBox.isRead": {
    "& .notificationContent": {
      color: "gray"
    },
  },
  "& .notificationBox img": {
    width: 48,
    height: 48,
    borderRadius: '50%',
    marginRight: 10
  },
  "& .markAllWrap": {
    padding: "19px 50px",
    borderBottom: '1px solid #E2E8F0',
    marginTop: '19px',
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      textTransform: 'none',
      color: "#334155",
      fontSize: "14px"
    },
    "& svg": {
      marginRight: 8,
      fill: "#0F172A"
    }
  },
  "& .notificationLeftBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    maxWidth: "70%"
  },
  "& .textNoWrap": {
    whiteSpace: 'nowrap',
    color:"black"
  },
  "& .iconOpenPopover":{
    color:"black"
  },
  "& .read": {
    color:"#475569"
  }
});

const StyledActionPopper = styled(Popover)({
  '& .MuiPopover-paper': {
    padding: '0px',
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid #E2E8F0',
    '& .actionIconWrap': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 60,
      paddingTop: 10,
      paddingBottom: 10,
      cursor: 'pointer',
      borderRadius: "0",
      width: "100% !important",
      color: "#333333",
      "&:hover":{
        background: "#eee"
      }
    },
  },
});
// Customizable Area End
