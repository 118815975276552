import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import {  OrderApiLatest, OrderDetail   } from "./types";
import {languageConvertor} from "../../languageoptions/src/LanguageSelectorController.web";
export const configJSON = require("./config");
import i18n from "i18next";
export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    id: string;
}

interface S {
    token: string;
    orderId?: number;
    orderDetail: Partial<OrderDetail>;
    language: string;
    order:Partial<OrderApiLatest>
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderDetailManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    orderId = this.getOrderId("navigationBarTitleText");
    orderDetailApiId = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            orderId: this.orderId,
            orderDetail: {},
            language: "en",
            order: {}
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if(lang!= undefined && lang != this.state.language){
              this.setState({language: lang}, ()=>{
                languageConvertor(lang);
                this.getOrderDetailApi()
              });
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && apiRequestCallId === this.orderDetailApiId) {
                if (responseJson) {
                    this.setState({ order: responseJson.data })
                } else {
                    this.parseApiErrorResponse(errorReponse);
                }

            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        
        const lang = await getStorageData("language") || "en";
        if (lang) {
            languageConvertor(lang);
            this.setState({ language: lang }, async() =>{
                await this.getTokenFn();
                this.getOrderDetailApi()
            });
        }
    }

    getTokenFn = async () => {
        let token = await getStorageData("authToken")
        this.setState({ token: token });
    };

    getOrderDetailApi() {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
            language: this.state.language
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderDetailApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getOrderDetailEndPoint}${this.state.orderId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOrderListMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getOrderId(param: "navigationBarTitleText" | "orderId") {
        const parsedParam = this.props.navigation.getParam(param);
        return /^\d+$/.test(parsedParam) ? Number(parsedParam) : undefined;
    }
    navigateToPage = (target: string, targetId?: string) => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), target);
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        targetId && request.addData(getName(MessageEnum.NavigationScreenNameMessage), targetId);
        this.send(request);
    }
    formatDatePlaced = (datePlaced: string) => moment(datePlaced).format("MMM DD, YYYY")
    // Customizable Area End
}
