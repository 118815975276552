import React, { Component } from "react";
import { Box, styled } from "@material-ui/core";
const assets = require("./assets");
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";


type Props = {
    onCloseAction: () => void;
    onAccessAction: () => void;
    children: any;
    title?: string;
    saveBtnTxt: string;
    cancelBtnTxt: string;
    disableSaveButton?: boolean
};


const PopupBox = styled(Box)({
    "&.innerBody":{
        maxWidth: "600px",
        backgroundColor: "#fff",
        margin: "auto",
        boxShadow: "0px 0px 48px 4px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
        "& .checkbox-wrapper":{
            marginTop: "32px"
        },
        "@media (max-width: 420px)": {
            width: "50%",
            minWidth: "19rem",
        },
    },
    "& .header":{
        padding: "24px 16px 24px 40px",
        borderBottom: "1px solid #E2E8F0",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "@media (max-width: 420px)": {
            padding:"16px",
        },
    },
      
    "& .middle":{
        padding: "32px 40px",
        "& .pera":{
            height: '202',
            overflow:'auto',
            overscrollBehavior: 'contain',
            boxSizing: "border-box",
            overflowX:'hidden',
            width: 'calc(100%)',
            paddingRight: "10px",
            marginBottom:"16px",
            '&::-webkit-scrollbar': {
            width: '6px',
            height:'45px',
            },
            '&::-webkit-scrollbar-thumb': {
            background: '#D9D9D9',
            borderRadius: '4px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
            background: 'darkgrey',
            },
            
        },
        "& *":{
            color: "#333",
            padding: "0px"
        },
        "& .error":{
            marginTop: "6px",
            marginLeft: "30px",
            color: "#DC2626"
        },
        "@media (max-width: 420px)": {
            padding: "12px 16px"
        },
      },
    "& .footer": {
        padding: "24px",
        display: "flex",
        justifyContent: "end",
        borderTop: "1px solid #E2E8F0",
        gap: "16px"
      },
    "& .titleText" : {
        fontSize:"24px",
        fontWeight:"700",
        "@media (max-width: 420px)": {
            fontSize:"1.3rem"
        },
    }
});

export default class Popup extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        const {title, cancelBtnTxt, saveBtnTxt, children, onCloseAction, onAccessAction, disableSaveButton} = this.props;
        return (
            <PopupBox className="innerBody">
              <Box className="header">
                <Typography size="xl">
                    <Box className="titleText">{title}</Box>
                    </Typography>
                <div>
                <CustomButton 
                    data-testid="cross-btn"
                    display="textButton"
                    themes="dark"
                    onClickHandle={onCloseAction}
                    type="button"
                >
                    <img src={assets.cross} alt="" />
                </CustomButton>
                </div>
              </Box>
              <Box className="middle">
                    {children}
              </Box>
              <Box className="footer">
                  <CustomButton 
                      themes="dark"
                      display="primary"
                      type="button"
                      onClickHandle={onCloseAction}
                      dataTestId="cancel">
                      {cancelBtnTxt}
                  </CustomButton>
                    <CustomButton
                        themes="dark"
                        type="button"
                        display="secondaryBorderless"
                        onClickHandle={onAccessAction}
                        isDisable={!!disableSaveButton}
                        styles={saveBtnTxt === "Agree" ? { color: "#64748B" } : ""}
                        dataTestId="save">
                        {saveBtnTxt}
                    </CustomButton>
              </Box>
            </PopupBox>
        );
    }
}

