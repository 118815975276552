import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
const Ulti = require("./../utility");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};
export interface VendorListingFromApi {
  id: string;
  attributes:{
    created_at: string;
    id: string;
    company_name: string;
    email: string;
    phone_number: string;
    location_string: string;
    activated: boolean;
    image: string;
    full_name:string
  }
}
// Latest one
export interface VendorOrderTableData {
  id: string;
  name: string;
  status: string;
  total: number;
  address: string;
  date: string;
  image: string;
  payment:string
}
export interface OrdeListingVendorInterface{
  id: string;
  type: string;
  attributes: {
      id: number;
      placed_at: string | null;
      product_name: string;
      product_image: string;
      order_id: string;
      price: number;
      status: string;
      item_status: string;
      date: string;
      delivery_address: string;
  };
}
export type VendorTableData = {
  id: string;
  name: string;
  address: string;
  date: string;
  payment: string;
  status: string;
  total: number;
};

interface OrderItemAttributesVendor {
  id: number;
  placed_at: string | null;
  transaction_id: string | null;
  product_name: string;
  product_image: string;
  order_id: string;
  price: number;
  status: string;
  item_status: string;
  date: string;
  delivery_address: string;
}

interface OrderItemupdateVendor{
  id: string;
  type: string;
  attributes: OrderItemAttributesVendor;
}

interface DateObje {
  [date: string]: {
    startingDay?: boolean;
    endingDay?: boolean;
      color: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vendorRowsPerPage: number;
  vendorCurrentTab: number;
  vendorRowEdit: object | null;
  vendorDateRange: DateRange;
  vendorTableData: VendorOrderTableData[];
  apiToken: string | null;
  isOpenEdit: boolean,
  textSearch: string,
  customerListArray:OrdeListingVendorInterface[];
  showToast:boolean
  toastMessage:string
  vendorDetail: VendorTableData | null,
  toastSuccess:string,
  language:string,
  vendorTabsData:{}[],
  vendorHeaderColumns: {}[],
  sortType: string
    // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrderListVendorController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  vendorData: {}[] = [];
  vendorTabsData: string[] = [];
  vendorColumns: string[] = [];
  vendorMapDataTab: string[] = [];
  vendorPagination: object = {};
  vendorDateFormat: string;
  getVendorListCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.vendorDateFormat = "YYYY-MM-DD";

    this.state = {
      vendorCurrentTab: 0,
      vendorRowsPerPage: 10,
      vendorDateRange: {
        fromDate: moment().startOf("month").format(this.vendorDateFormat),
        toDate: moment().endOf("month").format(this.vendorDateFormat),
      },
      vendorRowEdit: null,
      vendorTableData: [],
      apiToken: "",
      textSearch: "",
      customerListArray: [],
      isOpenEdit: false,
      vendorDetail: null,
      toastSuccess:"",
      showToast: false,
      toastMessage:"",
      language:"en",
      vendorTabsData:[],
      vendorHeaderColumns:[],
      sortType:""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getTokenfun();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageCustomer(landing_lang)
    // Customizable Area End
  }
  componentDidUpdate(prevProps: Readonly<Props>, prev: Readonly<S>): void {
    if(this.state.sortType !== prev.sortType ){
      this.getVendorList()
    }
  }
  getTokenfun = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateListArray = [];
    if (!toDate) {
      dateListArray = [fromDate];
    } else {
      let itemDate = fromDate;
      const endDate = moment(toDate).add(1, "day").format(this.vendorDateFormat);
      while (itemDate !== endDate) {
        dateListArray.push(itemDate);
        itemDate = moment(itemDate).add(1, "day").format(this.vendorDateFormat);
      }
    }
    let result: DateObje = {};
    dateListArray.forEach((item, index) => {
      result[item] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateListArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ vendorCurrentTab: value });
  };

  handleDateRangePickerPressDay = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.vendorDateRange;
    let newDateRangePick = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRangePick = { fromDate: dateString };
    } else {
      newDateRangePick = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.vendorDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.vendorDateFormat),
        };
    }
    this.setState({ vendorDateRange: newDateRangePick });
  };

  getTableData = (currentTab: {label: string}) => {
    let tableData = this.state.vendorTableData;
    
    if (currentTab.label.toLowerCase() !== "all order") {
      tableData = this.state.vendorTableData.filter((data) => {
        return data.status == currentTab.label;
      });
    }

    if (
      this.state.vendorDateRange.fromDate &&
      this.state.vendorDateRange.toDate
    ) {
      tableData = this.filterTableDataByDate(tableData);
    }
    return tableData;
  };
  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      vendorDateRange: data,
    });
  };
  getTabStatus = () => {
    return Ulti.tabsData[this.state.vendorCurrentTab];
  };

  filterTableDataByDate = (tableData: VendorOrderTableData[]) => {
    let dateRange = this.state.vendorDateRange;
    const strFromDate = dateRange.fromDate;
    const strToDate = dateRange.toDate;

    const newTableData = tableData.filter((data) => {
      let compareDate = moment(data.date,'DD-MM-YY');
      let fromDate = moment(strFromDate, this.vendorDateFormat);
      let toDate = moment(strToDate, this.vendorDateFormat);
      return compareDate.isBetween(fromDate, toDate, null, "[]")|| compareDate.isSame(fromDate) || compareDate.isSame(toDate)
    });
    return newTableData;
  };

  handleVendortableData(CustomerArrayData:OrdeListingVendorInterface[]){
    const newData = CustomerArrayData.map((item: OrdeListingVendorInterface) => {
     return {
        id: item.id,
        name: item.attributes.product_name,
        address:item.attributes.delivery_address,
        image:item.attributes.product_image,
        total:item.attributes.price,
        date: item.attributes.date,
        status: item.attributes.status,
        payment:"Paid"
      }
    })
    this.setState({vendorTableData: newData})
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguageAdminOrder(message)
    this.handleReceiveResponse(from, message)
    
    // Customizable Area End
  }
  getVendorList = () => {
    const header = {
      language:this.state.language,
      token: this.state.apiToken,
      "Content-Type": configJSON.OrderListVendorContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorListCallId = requestMessage.messageId;
    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.OrderListVendorApiEndPoint}${searchQuery}` + this.state.sortType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.OrderListVendorMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openEditVendor = (vendor: VendorTableData) => {
    this.setState({ isOpenEdit: true, vendorDetail: vendor })
  }
  closeEditVendor = () => {
    this.setState({
      isOpenEdit: false,
   
    })
  }
  handleCloseSnackBars() {
    this.setState({showToast:false})
     }

  handleChangeLanguageCustomer = (lang: string) => {
    languageConvertor(lang);
    this.setState({
      language: lang,
      vendorTabsData: [

        { label: i18n.t('ALLORDERTXT') },
        { label: i18n.t('DASHBOARD.STATUS.INPROGRESSTXT') },
        { label: i18n.t('DASHBOARD.STATUS.DELIVEREDTXT') },
        { label: i18n.t('DASHBOARD.STATUS.CANCELLEDTXT') }
      ],
      vendorHeaderColumns: [
        { id: 'orderid', label: i18n.t('DASHBOARD.TABLEHEADER.ORDERID'), renderFunction: 'renderRowId' },
        { id: 'name', label: i18n.t('DASHBOARD.TABLEHEADER.PRODUCTNAME'), renderFunction: "renderRowHaveImage" },
        { label: i18n.t('ADDRESSTXT') ,id: 'address'},
        { id: 'date', label: i18n.t('DASHBOARD.TABLEHEADER.DATE') },
        {renderFunction: 'renderPaymentStatus', id: 'payment',label: i18n.t('DASHBOARD.TABLEHEADER.PAYMENTS')},
        { id: 'price', label: i18n.t('DASHBOARD.TABLEHEADER.TOTAL') ,renderFunction:"renderRowTotal" },
        { id: 'status', label: i18n.t('DASHBOARD.TABLEHEADER.STATUS'), renderFunction: 'renderRowStatus' },
      ]
    })
  }
  recieveLanguageAdminOrder = (customerMessage: Message) => {
    if (customerMessage.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let currentLang = customerMessage.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (currentLang != this.state.language) {
        this.handleChangeLanguageCustomer(currentLang)
        this.setState({
          language: currentLang
        })
      }
    }
  };
  async handleReceiveResponse(from: string, message: Message){
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken")
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getVendorList();
        });
      }
    }
    const editOrderVendorResponse= message?.properties?.RestAPIResponceSuccessMessage?.data
    if(editOrderVendorResponse && editOrderVendorResponse.id) {
      this.updateTableData(editOrderVendorResponse)
     this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: "Updated Succeffully",
     })
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVendorListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responsedataJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      if (responsedataJson?.errors) this.parseApiErrorResponse(responsedataJson);
      if(responsedataJson){
        this.setState({
          customerListArray: responsedataJson.data,
        });
      }
      this.handleVendortableData(this.state.customerListArray)
    }
  }

  updateTableData(editOrderVendorResponseData:OrderItemupdateVendor){
    const newUpdatedTable=this.state.vendorTableData.map((item:VendorOrderTableData)=>{
      const updatedVendorAttributes=editOrderVendorResponseData?.attributes;
      return item.id===editOrderVendorResponseData?.id?{
        id: item.id,
        name: updatedVendorAttributes.product_name,
        address:updatedVendorAttributes.delivery_address,
        image:updatedVendorAttributes.product_image,
        total:updatedVendorAttributes.price,
        date: updatedVendorAttributes.date,
        status: updatedVendorAttributes.status,
        payment:"Paid"
      }:item
    })
    this.setState({vendorTableData:newUpdatedTable})
  }

  handleSearch = (text?: string) => this.setState({textSearch: text || ""}, () => this.getVendorList())
  getSortValueFn = (fieldName:string, sortType: string) => {
    this.setState({sortType: sortType })
  }
  // Customizable Area End
}
