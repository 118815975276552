import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { FormikValues } from 'formik';
import React from "react";
import { removeStorageData } from "framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from "i18next";

export interface SignUpForm {
  companyName: string;
  email: string;
  companyRegNo: string;
  vatId: string;
  phoneNumber: string;
  password: string;
  agreedWithTerms: boolean;
  doc1Url: string;
  doc2Url: string;
 
}

export interface SignUpFormErrors {
  companyName?: string;
  email?: string;
  companyRegNo?: string;
  vatId?: string;
  phoneNumber?: string;
  password?: string;
  agreedWithTerms?: string;
  doc1Url?: string;
  doc2Url?: string;
  isUpperError?:boolean;
  isLowerError?:boolean;
  isDigitError?:boolean;
  isLengthError?:boolean;
}

export const initialSignupFormValues: SignUpForm = {
  companyName: '',
  email: '',
  companyRegNo: '',
  vatId: '',
  phoneNumber: '',
  password: '',
  agreedWithTerms: false,
  doc1Url: '',
  doc2Url: '',

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  submittingSignupDetails: boolean;
  doc1File: File | undefined;
  doc2File: File | undefined;
  isVisble: boolean
  showToast:boolean
  toastMessage:string
  toastSuccess:string
  language: string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  formikRef: any = React.createRef();
  signupApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      submittingSignupDetails: false,
      doc1File: undefined,
      doc2File: undefined,
      isVisble: false,
      showToast:false,
      toastMessage:"",
      toastSuccess:"error",
      language: "en",
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguage(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.handleValidationApiResponse(responseJson);
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          this.handleCreateAccountResponse(responseJson, errorReponse);
        } else  if (apiRequestCallId === this.signupApiCallId) {
          this.onSignupResponse(responseJson)
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationMessage(message);
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCodeMessage(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  recieveLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(lang != this.state.language){
        this.setState({language: lang}, ()=>{
          this.handleCloseSnackBar()
          languageConvertor(lang)
        });
      }
    }
  }
  handleNavigationMessage = (message: Message) => {
    const otpAuthTkn = message.getData(
      getName(MessageEnum.AuthTokenDataMessage)
    );
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  };

  handleCountryCodeMessage = (message: Message) => {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  };

  handleValidationApiResponse = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(
          regexData.password_validation_regexp
        );
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  };

  onSignup = (values: FormikValues) => {
    if (!this.state.doc1File || !this.state.doc2File) return;
    this.setState({submittingSignupDetails: true});
    const header = {
      redirect: 'follow'
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.signupApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.signupApiEndpoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    const httpBody = new FormData();
    httpBody.append('data[attributes][full_phone_number]', values.phoneNumber);
    httpBody.append('data[attributes][email]',values.email);
    httpBody.append('data[attributes][company_name]',values.companyName);
    httpBody.append('data[attributes][company_registration_no]',values.companyRegNo);
    httpBody.append('data[attributes][password]',values.password);
    httpBody.append('data[attributes][user_type]','customer');
    httpBody.append('data[type]', 'email_account');
    httpBody.append('data[documents][]', this.state.doc1File);
    httpBody.append('data[documents][]', this.state.doc2File);
    httpBody.append('data[attributes][vat_id]', values.vatId);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeAddDetail);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  handleCreateAccountResponse = (responseJson: any, errorReponse: any) => {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  };

  async componentDidMount() {
    super.componentDidMount();
    const loglang = await getStorageData('language') || "en";
    this.setState({language: loglang}, ()=>{
      languageConvertor(loglang);
    });
    const signupFormValues = await getStorageData('signupFormValues');
    if (signupFormValues) {
      const formValues = JSON.parse(signupFormValues);
      await removeStorageData('signupFormValues');
      this.formikRef?.current?.setValues(formValues);
    }
    const isTermAccepted = await getStorageData('termsAccepted');
    await removeStorageData('termsAccepted');
    this.formikRef?.current?.setFieldValue('agreedWithTerms', isTermAccepted === 'true');
    const doc1File = await getStorageData('doc1File');
      if (doc1File) {
        const parsedDoc1 = JSON.parse(doc1File);
        fetch(parsedDoc1.data)
          .then(res => res.blob())
          .then(async (blob) => {
            this.setState({doc1File: new File([blob], parsedDoc1.name,{ type: parsedDoc1.type })});
            this.formikRef?.current?.setFieldValue('doc1Url', parsedDoc1.name);
            await removeStorageData('doc1File');
          });
      }
      const doc2File = await getStorageData('doc2File');
      if (doc2File) {
        const parsedDoc2 = JSON.parse(doc2File);
        fetch(parsedDoc2.data)
          .then(res => res.blob())
          .then(async (blob) => {
            this.setState({doc2File: new File([blob], parsedDoc2.name,{ type: parsedDoc2.type })});
            this.formikRef?.current?.setFieldValue('doc2Url', parsedDoc2.name);
            await removeStorageData('doc2File');
          });
      }
   
  }


  onSignupResponse = async (responseJson: any) => {
    if (responseJson?.errors){
      this.setState({
        showToast: true,
        toastSuccess: "error",
        toastMessage: JSON.stringify(Object.values(responseJson.errors[0])[0]),
      });
    }
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      this.setState({
        showToast: true,
        toastMessage: "SignUp Successfully",
        toastSuccess: "success",
      });
      setTimeout(async () => {
        await setStorageData('accessToken', responseJson.meta.token);
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage),
        );
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "ProfileReview"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage),
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      }, 2000);
    }
   
    
  };
  validateSignupForm = (values: SignUpForm) => {
    const errors: SignUpFormErrors = {};

    if (!values.companyName) {
      errors.companyName = i18n.t("COMPANYNAMEERROR");
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!values.email || !emailRegex.test(values.email)) {
      errors.email = i18n.t("EMAILERROR");
    }

    if (!values.companyRegNo) {
      errors.companyRegNo = i18n.t("COMPANYERROR");
    }

   this.handleVatIdError(values.vatId,errors)
    const phoneRegex = /^966\d{9,}$/;
    if (!values.phoneNumber || !phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = i18n.t("MOBILENOERROR");
    }

    this.handlePasswordValidation(values.password,errors)
  

    if (!this.state.doc1File) {
      errors.doc1Url = i18n.t("DOC1ERROR");
    }
    if(this.state.doc1File && this.state.doc1File?.size>500000){
      errors.doc1Url = "File size is more than 5mb"
    }

    if (!this.state.doc2File) {
      errors.doc2Url = i18n.t("DOC2ERROR");
    }
  

    if (!values.agreedWithTerms) {
      errors.agreedWithTerms = i18n.t("PRIVACYERROR");
    }
    return errors;
  };

  onDocumentUpload  = (fieldName: string, files: File[], updateValue: any) => {
    if (files && files.length) {
      updateValue(fieldName, files[0].name);
      if (fieldName === 'doc1Url') {
        if (files[0].size > 5242880) {
          this.setState({
            showToast: true,
            toastSuccess: "error",
            toastMessage: i18n.t('VENDORFORMVALIDATION.DOCUMENT') ,
          });
        }
        else {
          this.setState({ doc1File: files[0] });
        }
      } 
      else if (fieldName === 'doc2Url') {
        if (files[0].size > 5242880) {
          this.setState({
            showToast: true,
            toastSuccess: "error",
            toastMessage: i18n.t('VENDORFORMVALIDATION.DOCUMENT') ,
          });
        }
        else {
          this.setState({ doc2File: files[0] });
        }
      }
    }
  };

  storeSignupFormData = async (signupFormValues: FormikValues) => {
    await setStorageData('signupFormValues', JSON.stringify(signupFormValues));
    if (this.state.doc1File) {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const base64String = event.target.result;
        await setStorageData('doc1File', JSON.stringify({ name: this.state.doc1File?.name, type: this.state.doc1File?.type, data: base64String }))
      };
      reader.readAsDataURL(this.state.doc1File);
    }

    if (this.state.doc2File) {
      const reader = new FileReader();
      reader.onload = async (event: any) => {
        const base64String = event.target.result;
        await setStorageData('doc2File', JSON.stringify({ name: this.state.doc2File?.name, type: this.state.doc2File?.type, data: base64String }));
      };
      reader.readAsDataURL(this.state.doc2File);
    }
    this.goToTermsAndConditionPage();
  };

  goToTermsAndConditionPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "TermsConditionsUsers"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goToLoginPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "CustomerEmailAccountLogin"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  handleCloseSnackBar() {
   this.setState({showToast:false})
    }
  getpwdstyle = () => {
    let space_pw = this.state.language === "ar" ? "40px" : "36px";
    return { paddingRight: space_pw }
  } 
  getInputErrorStyles(
    touched: { [fieldName: string]: boolean | undefined },
    errors: { [fieldName: string]: string | undefined },
    fieldName: string
  ) {
    return {
      border: touched[fieldName] && errors[fieldName] ? "solid red" : "",
      borderRadius: "6px"
    };
  }
  handleVatIdError(vatId: string, errors: SignUpFormErrors) {
    if (!vatId) {
      errors.vatId = i18n.t("VATIDERROR");
    }
  }
  handlePasswordValidation(password: string, errors: SignUpFormErrors) {
    const passwordValidator = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
    if (!password && !passwordValidator.test(password)) {

      errors.password = i18n.t("PASSWORDERROR");
    }
    if (password.length < 8) {
      errors.password = i18n.t("ISLENGTHERROR");
      errors.isLengthError = true
    }

    const uppercaseValidator = /^(?=.*[A-Z])/;
    if (!uppercaseValidator.test(password)) {
      errors.password = i18n.t("ISCAPITALERROR");
      errors.isUpperError = true

    }

    const numberValidator = /^(?=.*\d)/;
    if (!numberValidator.test(password)) {
      errors.password = i18n.t("ISNUMBERERROR");
      errors.isDigitError = true
    }

    const lowercaseValidator = /^(?=.*[a-z])/;
    if (!lowercaseValidator.test(password)) {
      errors.password = i18n.t("ISLOWERERROR");
      errors.isLowerError = true
    }
  }
  // Customizable Area End
}
