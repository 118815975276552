import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import createRequestMessage from "../../utilities/src/create-request-message";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
// Customizable Area End
export interface FaqsTypes {
    id: string,
    type: string,
    "attributes": {
        "question": string,
        "answer": string
    }
}
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  limit?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: string;
  loading: boolean;
  faq_record: Array<FaqsTypes>;
  language: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class HelpCenterQAController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
        getName(MessageEnum.NavigationPayLoadMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
        activeTab: "panel1",
        loading: true,
        faq_record: [],
        language: "en"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let value = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(value != this.state.language){
        this.setState({language: value}, ()=>{
          this.getFaqs(value)
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        this.setState({loading: false});
        const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
        this.recieveFaq(message, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  faqCallBackID:string="";

  async componentDidMount() {
    super.componentDidMount();
    const lang = await getStorageData("language") || "en";
    this.getFaqs(lang)
  }

  handleChange = (index: string) => {
    this.setState({activeTab: index});
  }

  recieveFaq = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(messageID === this.faqCallBackID){
        const toActiveID = `faq-${responseJson.data[0].id}`;
        this.setState({faq_record: responseJson.data, activeTab: toActiveID});
    }
  }

  getFaqs = (lang: string) => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.faqCallBackID = requestMessage.messageId;
    this.setState({loading: true});

    const endPoint = this.props.limit != undefined ? `${configJSON.faqEndPoint}?language=${lang}&limit=${this.props.limit}` : `${configJSON.faqEndPoint}?language=${lang}`;
    
    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: endPoint,
      method: configJSON.httpGetType,
      header: {language: lang}
    });
  }
  // Customizable Area End
}
