import React from "react";

// Customizable Area Start
import {
  Box,
  Popover,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import MoreVertSharpIcon from '@material-ui/icons/MoreVertSharp';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import i18n from "i18next";
import CustomSnackBar from '../../../components/src/design-system/CustomSnackBar.web';
import Loader from "../../../components/src/design-system/Loader.web";
import CreateAvatar from "../../../components/src/design-system/CreateAvatar.web";
import {NotificationInterface} from "./NotificationsController";
import Typography from "../../../components/src/design-system/Typography.web";
import TimeFormat from "../../../components/src/design-system/TimeFormat.web";
import { Avatar } from "@builder/component-library";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu pageBackgroundMode="light" navigation={this.props.navigation} id="">
          <Loader loading={this.state.loader} />
          <NotificationWrap>
            <Box>
              <Typography>
                <Box className="title">
                  {i18n.t("USERSIDEBAR.NOTIFICATIONS")}
                </Box>
              </Typography>
            </Box>
            <Box className="wrapper">
            <Box className="markAllWrap" id="markAllWrap">
              <CustomButton dataTestId="VendorAllRead" display="textButton" styles={{padding: "0px"}} themes="dark" onClickHandle={this.markAllAsRead}>
                <CheckIcon />
                {i18n.t("MARKALLASREADTXT")}
              </CustomButton>
            </Box>

            {(this.state.data && this.state.data.length>0) ? this.state.data.map((item: NotificationInterface) => {
                return (
                  <Box
                    className={this.isReadNotification(item)}
                    key={item.attributes.id}
                  >
                    <Box className="notificationLeftBox">
                      <Avatar image={item.attributes.images}/>
                      <Typography>
                        <Box className="notificationContent">
                          {item.attributes.contents}
                        </Box>
                      </Typography>
                    </Box>

                    <Box className="notificationRightBox">
                      <IconButton className={`iconOpenPopover ${item.attributes.is_read && "read"}`}  aria-controls="open-menu" id={item.id} onClick={(event) => this.openPopover(event, item)}>
                        <MoreVertSharpIcon />
                      </IconButton>
                      <StyledPopover
                        id={item.id}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.closePopover}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'right',
                        }}
                      >
                        <CustomButton themes="dark" dataTestId="actionRead" display="textButton" className="actionIcon actionRead" onClickHandle={this.markAsRead}>
                            <Box sx={{ mr: 1 }}>
                                <DraftsIcon />
                            </Box>
                            {i18n.t("READTXT")}
                        </CustomButton>
                        <CustomButton themes="dark" dataTestId="actionDelete" display="textButton" className="actionIcon actionDelete" onClickHandle={this.deleteNotifications}>
                            <Box sx={{ mr: 1 }}>
                                <DeleteIcon />
                            </Box>
                            {i18n.t("DELETETXT")}
                        </CustomButton>
                      </StyledPopover>
                      <Typography>
                        <Box className={`textNoWrap ${item.attributes.is_read && "read"}`}>
                          <TimeFormat date={item.attributes.created_at} />
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                );
              }) : <Typography size="xl"><Box className="no-record">{i18n.t("NONOTIFICATIONTXT")}</Box></Typography>}
              </Box>
          </NotificationWrap>
          <CustomSnackBar
              data-test-id="CustomSnackBarHeader"
              open={this.state.showToast}
              errorMessage={this.state.toastMessage}
              onClose={this.handleCloseSnackBars}
              severity={this.state.toastSuccess }
          />
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NotificationWrap = styled("div")({
  marginLeft: "-5px",
  marginRight: "-5px",
  "& .wrapper":{
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    borderRadius: "4px"
  },
  "& .title": {
    fontSize: '26px',
    fontWeight: 'bold',
    margin: "14px 0px"
  },
  "& .notificationRightBox": {
    "display": "flex",
    "alignItems": "end",
    "justifyContent": "end",
    "flexDirection": "column",
    "textAlign": "right"
  },
  "& .notificationBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    marginBottom: "16px",
    padding: "10px 34px" 
  },
  "& .notificationContent": {
    color: "#333333"
  },
  "& .notificationBox.isRead": {
    "& .notificationContent": {
      color: "gray"
    },
  },
  "& .notificationBox img": {
    width: 48,
    height: 48,
    borderRadius: '50%',
    marginRight: 10
  },
  "& .markAllWrap": {
    textAlign: 'right',
    padding: "16px 8px",
    borderBottom: '1px solid #E2E8F0',
    marginTop: '24px',
    "& .MuiButton-root": {
      textTransform: 'none',
      color: "#334155"
    },
    "& svg": {
      marginRight: 8,
      fill: "#0F172A"
    }
  },
  "& .notificationLeftBox": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    maxWidth: "70%"
  },
  "& .textNoWrap": {
    whiteSpace: 'nowrap',
    color:"black"
  },
  "& .iconOpenPopover":{
    color:"black"
  },
  "& .read": {
    color:"#475569"
  }
});

const StyledPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    padding: '0px',
    backgroundColor: 'white',
    boxShadow: 'none',
    border: '1px solid #E2E8F0',
    '& .actionIcon': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 20,
      paddingRight: 60,
      paddingTop: 10,
      paddingBottom: 10,
      width: "100% !important",
      borderRadius: "0",
      cursor: 'pointer',
      color: "#333333",
      justifyContent: "start",
      "&:hover":{
        background: "#eee"
      }
    },
  },
});
// Customizable Area End
