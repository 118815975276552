import React, { Component } from "react";
import {Cards} from "@builder/component-library";
import CustomButton from "./CustomButton.web";

type MyProps = {
    color?: 'primary' | 'black' | 'white'| 'dark' | 'red' | 'gray';
    size?: 's' | 'xs' | 'sm' | 'base' | 'x' | 'xl';
    weight?: 'normal' | 'semiBold' | 'bold';
    transform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
    Image?: string;
    title: string;
    titleStyle?: any;
    cardStyles?:any;
    ImgStyle? :any;
    ItemId?: number;
    description?: string;
    descriptionStyle?: any;
    onClickHandle: (event: Event, itemId?: number) => void;
};

export default class Card extends Component<MyProps> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const {description, Image, title, cardStyles, titleStyle, ImgStyle, descriptionStyle, onClickHandle, ItemId} = this.props;

        const propCardStyles = cardStyles || {};
        const propTitleStyle = titleStyle || {};
        const propImgStyle = ImgStyle || {};
        const propDescStyle = descriptionStyle || {};
        
        const styles = {
            color: this.props.color ? colors[this.props.color] : 'inherit',
            fontSize: this.props.size ? fontSize[this.props.size] : 'inherit',
            fontWeight: this.props.weight ? fontWeights[this.props.weight] : 400,
            textTransform: this.props.transform ? textTransformations[this.props.transform] : 'none',
            fontFamily: "'Roboto', sans-serif",
            margin: "0"
        };

        const desStyle = description!= "" ? {display: "none"} : {}
        
        
        return (
            <CustomButton themes="dark" display="textButton" styles={{padding: "0px"}} onClickHandle={(event: Event)=> onClickHandle(event, ItemId)}>
            <Cards
                containerStyle={{...containerStyle, ...propCardStyles}}
                descTxtStyle={{...styles, ...desStyle, ...propDescStyle}}
                description={description}
                iconContainer={{display: "none"}}
                iconStyle={{display: "none"}}
                iconUrl=""
                imageUrl={Image}
                imgStyle={{...imgStyle, ...propImgStyle}}
                size="medium"
                title={title}
                titleTxtStyle={{...textStyle, ...styles, ...propTitleStyle}}
                />
            </CustomButton>
        );
    }
}

const colors: any = {
    primary: '#2563EB',
    black: '#000000',
    white: '#ffffff',
    dark: '#0F172A',
    red: '#DC2626',
    gray: '#A6A6A6',
};


const fontSize: any = {
    xl: '1.375rem',
    x: '1.25rem',
    base: '1.125rem',
    sm: '1rem',
    xs: '0.875rem',
    s: '0.625rem'
};

const fontWeights: any = {
    normal: 400,
    medium: 500,
    semiBold: 600,
};

const textTransformations: any = {
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    none: 'none',
};

const containerStyle = {
    "borderRadius": 4,
    "border": "1px solid #DFE0E0",
    "background": "var(--Accent, #FFF)",
    "boxShadow": "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
    "height": "auto",
    "minWidth": "220px",
    "gap": "8px",
    "textAlign": "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "25px",
    paddingRight: "25px"
}

const imgStyle = {
    minHeight: 90,
    margin: "0px",
    minWidth: "138px"
}

const textStyle = {
    "color": "var(--Text_01, #000)",
    "fontWeight": 500,
    "whiteSpace": "nowrap",
    "textOverflow": "ellipsis",
    "overflow": "hidden"
}