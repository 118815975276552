
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { getStorageData } from "../../../framework/src/Utilities";

interface FrequentlyAskedQuestion {
    id: string,
    type: string,
    "attributes": {
        "question": string,
        "answer": string
    }
}

export const configJSON = require("./config");

export interface Props {
    navigation: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative?: string) => string;
        goBack: () => void;
    };
}

interface S {
    helpCentreQA: Array<FrequentlyAskedQuestion>;
    searchInput: string
    lang: string
    token: string
}

interface SS {
    id: any;
}

export default class HelpCentreController extends BlockComponent<
    Props,
    S,
    SS
> {
    getHelpCentreApiCallId = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage)];
        this.state = {
            helpCentreQA: [],
            searchInput: "",
            lang: "en",
            token: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        const [token, language] = await Promise.all([getStorageData('authToken'), getStorageData('language')]);
        const lang = language || "en"
        this.setState({ lang, token: token || "" }, () => this.getHelpCentreQA(lang))
    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let value = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if (value != this.state.lang) {
                languageConvertor(value);
                this.setState({ lang: value }, () => {
                    this.getHelpCentreQA(value)
                });
            }
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getHelpCentreApiCallId) {
                this.setState({ helpCentreQA: responseJson?.data || [] })
            }
        }

    }

    getHelpCentreQA = (lang?: string) => {

        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getHelpCentreApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.faqEndPoint}?language=${lang || this.state.lang}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
}
// Customizable Area End
