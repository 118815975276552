export const TITLE_MAP = {
  "PrivacyPolicy": "PRIVACYPOLICYTXT",
  "FeesPayments": "FEETXT",
  "ShippingPolicy": "SHIPPINGPOLICYTXT",
  "ReturnPolicy": "RETURNPOLICYTXT",
  "OrderMedicine": "OURMEDICINETXT",
  "CustomersSpeak": "CUSTOMERSPEAKTXT",
  "BusinessPartnership": "BPARTNERTXT",
  "Careers": "CAREERTXT",
  "HealthCareProducts": "HEALTHCAREPRODUCTTXT",
  "HospitalsProduct": "HOSPITALPRODUCTTXT",
  "BrowseAZ": "BROWSEAZTXT",
  "BrowseManufacturers": "BROWSEMANUFACTURETXT",
  "HealthArticles": "HEALTHARTICLESTXT",
  "OffersCoupons": "OFFERTXT"
}

export const API_CONTENT_TYPE_MAP = {
  "PrivacyPolicy": "privacy_policy",
  "FeesPayments": "fees_payments_policy",
  "ShippingPolicy": "shipping_delivery_policy",
  "ReturnPolicy": "return_refund_cancel_policy",
  "OrderMedicine": "order_medicine",
  "CustomersSpeak": "customers_speak",
  "BusinessPartnership": "business_partnership",
  "Careers": "careers",
  "HealthCareProducts": "healthcare_products",
  "HospitalsProduct": "hospitals_product",
  "BrowseAZ": "browse_by_a_z",
  "BrowseManufacturers": "browse_by_manufacturers",
  "HealthArticles": "health_articles",
  "OffersCoupons": "offers_coupons"
}

const utilities = {
    TITLE_MAP
}

export default utilities