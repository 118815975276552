// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import resources from "./utilities";
import { WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

type GetCategoriesApiResponse = Array<{
  id: string
  attributes: {
    name: string;
    ar_category_name: string;
    sub_categories: {
      data: Array<{
        attributes: {
          id: number
          name: string
          ar_sub_categories_name: string
        }
      }>
    }
  }
}>

type CreateProductApiResponse = {
  data: {
    type: string;
    attributes: {
      errors?: {
        [key: string]: string[]
      }
    }
  }
}

type GetProductDetailApiResponse = {
    images: [],
    categories: [],
    sub_categories: [],
    name: string,
    second_name: string,
    manufacturer_name: string,
    manufacturer_second_name: string,
    stock_qty: string,
    price: string,
    keywords: [],
    second_keywords: [],
    specifications: string,
    description: string,
    second_description: string,
    warranty: string,
    ar_warranty: string,
    sale_price: string,
    discount: string,
}

type Category = {
  id: string
  name: string
  arabicName: string
  subCategories: Array<{
    id: string
    name: string
    arabicName: string
  }>
}

interface FormInputs {
  type: "number" | "text" | "dropdown" | "file-uploader";
  name: string;
  title: string;
  placeHolder: string;
  desc: string;
  value: string | number | string[];
}
export interface ProductFormErrors {
  [key: string]: string;
}

export interface Image {
  id?: number;
  preview: string;
  file: Blob | null;
}

export interface ProductFormData {
  productName: string;
  arabicProductName: string;
  manufacturerName: string;
  arabicManufacturerName: string;
  category: string[];
  subCategory: string[];
  price: string;
  keywords: string;
  arabicKeywords: string;
  stockQuantity: string;
  descriptions: string;
  arabicDescriptions: string;
  specification: string;
  arabicSpecification: string;
  warranty: string;
  arabicWarranty: string;
  images?: { id: number, url: string }[]
  salePrice: string,
  salePriceArabic: string
  discount: string
  discountArabic: string
}

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  classes: WithStyles["classes"];
}

interface S {
  productData?: ProductFormData;
  loading: boolean;
  token: string;
  language: string;
  categories: Category[];
  images: Image[];
  formTitleInputs: {
    productName: string,
    category: string,
    price: string,
    salePrice: string,
    keywords: string,
    stockQuantity: string,
    descriptions: string,
    specification: string,
    image: string,
    warranty: string,
    discount: string,
  };
  formInputs: FormInputs[][]
}

interface SS {
  id: string;
}

export default class CreateProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  isAdd = this.props.navigation.getParam("navigationBarTitleText") === "Add";
  productId = this.getProductId();
  formikRef = React.createRef<FormikProps<ProductFormData>>();
  getCategoriesApiCallId = ""
  getProductDetailApiCallId = ""
  updateProductDetailApiCallId = ""
  createProductApiCallId = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: false,
      token: "",
      language: "en",
      categories: [],
      images: [],
      formTitleInputs: {
        productName: "",
        category: "",
        price: "",
        salePrice: "",
        keywords: "",
        stockQuantity: "",
        descriptions: "",
        specification: "",
        image: "",
        warranty: "",
        discount: "",
      },
      formInputs: [[{
        type: 'text',
        name: "",
        title: '',
        placeHolder: '',
        value: '',
        desc: '',
      }]],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    const token = (await getStorageData("authToken")) as string;
    if (token) {
      this.setState({ 
        token: token,
      });
      
      this.callGetCategoriesApi(token)
      if (this.productId !== undefined) this.callGetProductDetailApi(token)
    }
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageCreateProduct(landing_lang)

    //after translation
  }

  componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): void {
    if(oldState.language !== this.state.language){
      this.handleChangeLanguageCreateProduct(this.state.language)
    }
  }

  validateProductForm = (values: ProductFormData) => {
    const errors: ProductFormErrors = {};

    const printError = (
      value: string | undefined,
      name: string,
      msg: string
    ) => {
      if (!value?.trim() && name) {
        errors[name] = msg;
      }
    };

    printError(
      values.productName,
      "productName",
      i18n.t('PRODCUT_NAME_ERROR')
    );

    printError(
      values.arabicProductName,
      "arabicProductName",
      resources.productName.error.ar
    );

    printError(
      values.manufacturerName,
      "manufacturerName",
      i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('MANUFACTURERNAMETXT')
    );

    printError(
      values.arabicManufacturerName,
      "arabicManufacturerName",
      resources.manufacturerName.error.ar
    );

    printError(
      values.specification,
      "specification",
      i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('SPECIFICATIONTXT')
    );

    printError(
      values.arabicSpecification,
      "arabicSpecification",
      resources.specification.error.ar
    );

    printError(
      values.descriptions,
      "descriptions",
      i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('DESCRIPTIONSTXT')
    );

    printError(
      values.arabicDescriptions,
      "arabicDescriptions",
      resources.descriptions.error.ar
    );

    if (!values.price.trim()) {
      errors.price = i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('PRICETXT')
      errors.arabicPrice = resources.price.error.ar
    }

    if (!values.stockQuantity.trim()) {
      errors.stockQuantity = i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('STOCKQUANTITYTXT')
      errors.arabicStockQuantity = resources.stockQuantity.error.ar
    }

    if (!values.category.length) {
      errors.category = i18n.t('PLEASE_ENTER_YOUR') + ' ' + i18n.t('CATEGORYTXT')
      errors.arabicCategory = resources.category.error.ar
    }

    return errors;
  };

  getFieldObject = (detail: {
    type: "number" | "text" | "dropdown" | "file-uploader";
    name: string;
    arabicName: string;
    title: string;
    arabicTitle: string;
    placeHolder: string;
    arabicPlaceHolder: string;
    descEn: string;
    descAr: string;
    value: string | number | string[];
  }) => {

    return [{
        type: detail.type,
        name: detail.name,
        title: detail.title,
        placeHolder: detail.placeHolder,
        value: detail.value,
        desc: detail.descEn,
      },{
        type: detail.type,
        name: detail.arabicName,
        title: detail.arabicTitle,
        placeHolder: detail.arabicPlaceHolder,
        value: detail.value,
        desc: detail.descAr,
      }]
  };

  getProductId() {
    const parsedProductId = this.props.navigation.getParam("navigationBarTitleText");
    return /^\d+$/.test(parsedProductId) ? Number(parsedProductId) : undefined;
  }

  getCategoryOption = (fieldName: "category" | "arabicCategory") =>
    this.state.categories.map(category => ({ key: category.id, name: fieldName === "category" ? category.name : category.arabicName }))

  getFilteredSubCategories = (selectedCategoryIds: string[]) =>
    this.state.categories
      .filter(category => selectedCategoryIds.includes(category.id))
      .map(category => category.subCategories).flat()


  handleCancel = () => {
    this.props.navigation.navigate("ProductList", {})
  }

  callGetApi = (
    apiIdField:
      | "getProductDetailApiCallId"
      | "getCategoriesApiCallId",
    apiEndPoint: string,
    token?: string
  ) => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: token || this.state.token,
    };

    const apiGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this[apiIdField] = apiGetMessage.messageId;

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
  };

  callGetProductDetailApi = (token: string) => {
    this.callGetApi("getProductDetailApiCallId", `${configJSON.productApiEndPoint}/${this.productId}`, token)
  }

  callGetCategoriesApi = (token?: string) => {
    this.callGetApi("getCategoriesApiCallId", configJSON.getCategoriesApiEndpoint, token);
  };

  callProductApi = (formData: ProductFormData) => {
    if (!this.isAdd && !this.state.productData) return;
    this.setState({ loading: true });
    const requestBody = this.convertToFormData(formData);

    const headers = {
      token: this.state.token,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProductApiCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.productId ? `${configJSON.productApiEndPoint}/${this.productId}` : configJSON.productApiEndpoint
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.productData ? configJSON.updateApiMethodType : configJSON.createProductApiMethodType
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );
    runEngine.sendMessage(message.id, message);
  }

  convertToFormData = (productData: ProductFormData) => {
    const formData = new FormData();
    formData.append("name", productData.productName);
    formData.append("second_name", productData.arabicProductName);
    formData.append("manufacturer_name", productData.manufacturerName);
    formData.append("manufacturer_second_name", productData.arabicManufacturerName);
    formData.append("price", productData.price);
    formData.append("sale_price", productData.salePrice);
    formData.append("discount", productData.discount);
    formData.append("description", productData.descriptions);
    formData.append("second_description", productData.arabicDescriptions);
    formData.append("specifications", productData.specification);
    formData.append("second_specifications", productData.arabicSpecification);
    formData.append("warranty", productData.warranty)
    formData.append("ar_warranty", productData.arabicWarranty)
    formData.append("stock_qty", productData.stockQuantity)
    formData.append("keywords[]", productData.keywords)
    formData.append("second_keywords[]", productData.arabicKeywords)
    formData.append("availability", Number(productData.stockQuantity) ? "in_stock" : "out_of_stock")
    productData.category.forEach(category => {
      formData.append("category_ids[]", category)
    })
    if (productData.subCategory.length) {
      productData.subCategory.forEach(subCategory => {
        formData.append("sub_category_ids[]", subCategory)
      })
    } else {
      formData.append("sub_category_ids[]", "")
    }
    const productOriginalImages = this.state.productData?.images
    if (productOriginalImages?.length) {
      const deletedImages = productOriginalImages.filter(originalImage => this.state.images.findIndex(image => image.id === originalImage.id) === -1)
      deletedImages.forEach((deletedImage, index) => {
        formData.append(`catalogue_images_attributes[0${index}][id]`, deletedImage.id.toString())
        formData.append(`catalogue_images_attributes[0${index}][_destroy]`, "1")
      })
    }
    this.state.images.forEach((image, index) => {
      if (image.file) {
        formData.append(`catalogue_images_attributes[${index}][image]`, image.file)
      }
    })
    return formData;
  };

  async receive(from: string, message: Message) {
    this.recieveLanguageCreateProduct(message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.receiveApiResponse(message)
    }
  }

  receiveApiResponse = async (message: Message) => {
    let updateStateData: Partial<S> = {
      loading: false,
    };
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.parseApiErrorResponse(responseJson);
    this.parseApiCatchErrorResponse(errorReponse);

    if (apiRequestCallId === this.getProductDetailApiCallId && responseJson?.data?.type === "catalogue") {
      const { attributes } = responseJson.data
      this.handleGetProductDetail(attributes)

    }
    if (apiRequestCallId === this.getCategoriesApiCallId) {
      updateStateData.categories = responseJson?.data
        ? (responseJson.data as GetCategoriesApiResponse).map((record) => ({
          id: record.id,
          name: record.attributes.name,
          arabicName: record.attributes.ar_category_name,
          subCategories: record.attributes.sub_categories.data.map(sub_category => ({
            id: sub_category.attributes.id.toString(),
            name: sub_category.attributes.name,
            arabicName: sub_category.attributes.ar_sub_categories_name
          }))
        }))
        : [];
    }
    if ([this.createProductApiCallId, this.updateProductDetailApiCallId].includes(apiRequestCallId) && responseJson?.data) {
      if (responseJson.data.type === "catalogue") {
        this.handleSuccessFormSubmit()
      }
      if (responseJson.data.type === "error") {
        this.parseProductApiErrorResponse(responseJson)
      }
    }
    this.setState(updateStateData as Pick<S, keyof S>);
  }

  handleGetProductDetail(attributes: GetProductDetailApiResponse) {
    const images = attributes.images || []
    const category = attributes.categories.map((category: { id: number }) => category.id.toString())
    const subCategory = attributes.sub_categories.map((sub_category: { id: number }) => sub_category.id.toString())
    this.setState({
      productData: {
        productName: attributes.name,
        arabicProductName: attributes.second_name,
        manufacturerName: attributes.manufacturer_name,
        arabicManufacturerName: attributes.manufacturer_second_name,
        category,
        subCategory,
        stockQuantity: attributes.stock_qty.toString(),
        price: attributes.price.toString(),
        keywords: attributes.keywords.join(", "),
        arabicKeywords: attributes.second_keywords.join(", "),
        specification: attributes.specifications,
        arabicSpecification: attributes.specifications,
        descriptions: attributes.description,
        arabicDescriptions: attributes.second_description,
        warranty: attributes.warranty.toString(),
        arabicWarranty: attributes.ar_warranty ? attributes.ar_warranty.toString() : attributes.warranty.toString(),
        salePrice: attributes.sale_price,
        salePriceArabic: attributes.sale_price,
        discount: attributes.discount ? attributes.discount.toString() : '0',
        discountArabic:  attributes.discount,
        images
      },
      images: images.map((image: { id: number, url: string }) => ({ id: image.id, preview: image.url, file: null }))
    })
  }

  parseProductApiErrorResponse(responseJson: CreateProductApiResponse) {
    if (!responseJson.data.attributes.errors || !Object.keys(responseJson.data.attributes.errors).length) {
      return;
    }
    const { errors } = responseJson.data.attributes;

    let allerrors = '';
    Object.keys(errors).forEach((key) => {
      const value = errors[key]
      if (value.length > 0) {
        const msg = `${key}: ${value}`
        if (allerrors.length <= 0) {
          allerrors = msg;
        } else {
          allerrors = `${allerrors}\n${msg}`;
        }
      }
    });

    this.showAlert('Error', allerrors);
  }

  handleSuccessFormSubmit = () => {
    if (this.isAdd) {
      this.formikRef.current?.resetForm()
      this.setState({ images: [] })
      this.showAlert("Success", "Product Created")
    } else {
      this.props.navigation.navigate("ProductList", {})
    }
  }

  handleChangeLanguageCreateProduct = (lang: string) => {
    languageConvertor(lang);
      this.setState({
        language: lang,
        formTitleInputs: {
          productName: i18n.t('PRODUCTNAMETXT'),
          category: i18n.t('CATEGORYTXT'),
          price: i18n.t('PRICETXT'),
          salePrice: i18n.t('SALEPRICETXT'),
          keywords: i18n.t('KEYWORDSTXT'),
          stockQuantity: i18n.t('STOCKQUANTITYTXT'),
          descriptions: i18n.t('DESCRIPTIONSTXT'),
          specification: i18n.t('SPECIFICATIONTXT'),
          image: i18n.t('IMAGETXT'),
          warranty: i18n.t('WARRANTYTXT'),
          discount: i18n.t('DISCOUNTTYPETXT'),
        },
        formInputs: [
          this.getFieldObject({
          type: "text",
          name: "productName",
          arabicName: "arabicProductName",
          title: i18n.t('PRODUCTNAMETXT'),
          arabicTitle: resources.productName.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "text",
          name: "manufacturerName",
          arabicName: "arabicManufacturerName",
          title: i18n.t('MANUFACTURERNAMETXT'),
          arabicTitle: resources.manufacturerName.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "dropdown",
          name: "category",
          arabicName: "arabicCategory",
          title: i18n.t('CATEGORYTXT'),
          arabicTitle: resources.category.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: [],
        }),
        this.getFieldObject({
          type: "number",
          name: "price",
          arabicName: "arabicPrice",
          title: i18n.t('PRICETXT'),
          arabicTitle: resources.price.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "text",
          name: "keywords",
          arabicName: "arabicKeywords",
          title: i18n.t('KEYWORDTXT'),
          arabicTitle: resources.keywords.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "number",
          name: "stockQuantity",
          arabicName: "arabicStockQuantity",
          title: i18n.t('STOCKQUANTITYTXT'),
          arabicTitle: resources.stockQuantity.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "text",
          name: "descriptions",
          arabicName: "arabicDescriptions",
          title: i18n.t('DESCRIPTIONSTXT'),
          arabicTitle: resources.descriptions.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "text",
          name: "specification",
          arabicName: "arabicSpecification",
          title: i18n.t('SPECIFICATIONTXT'),
          arabicTitle: resources.specification.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),    
        this.getFieldObject({
          type: "number",
          name: "warranty",
          arabicName: "ar_warranty",
          title: i18n.t('WARRANTYTXT'),
          arabicTitle: resources.warranty.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),    
        this.getFieldObject({
          type: "text",
          name: "salePrice",
          arabicName: "arabicSalePrice",
          title: i18n.t('SALEPRICETXT'),
          arabicTitle: resources.salePrice.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),    
        this.getFieldObject({
          type: "text",
          name: "discount",
          arabicName: "discountArabic",
          title: i18n.t('DISCOUNTTYPETXT'),
          arabicTitle: resources.discountType.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: "",
          descAr: "",
          value: "",
        }),
        this.getFieldObject({
          type: "file-uploader",
          name: "image",
          arabicName: "image",
          title: i18n.t('IMAGETXT'),
          arabicTitle: resources.image.label.ar,
          placeHolder: "",
          arabicPlaceHolder: "",
          descEn: i18n.t('IMAGETXT'),
          descAr: resources.addImage.label.ar,
          value: "",
        }),]
    })
  }
  recieveLanguageCreateProduct = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
}
// Customizable Area End
