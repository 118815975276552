import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { DateRange } from "../../../../packages/blocks/email-account-registration/src/CustomerListController";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { tabsEnum } from "./utility";
const Ulti = require("./../../email-account-registration/utility");
export const configJSON = require("./config");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
// Customizable Area End

export interface Props {
  navigation?: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative: string) => string | Object;
    goBack: () => void;
  };
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  productsRowsPerPage: number;
  productsCurrentTab: number;
  productsDateRange: DateRange;
  productData: Product[];
  token: string;
  isOpenEdit: boolean,
  inventoryDetail: Product,
  textSearch?: string
  inventoryTabsData: {}[],
  inventoryColumns: {}[],
  language: string,
  sortType: string

}
interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface Product {
  id: string,
  catalogue_id: string,
  name: string,
  category: string,
  categoryIds: string[],
  subCategoryIds: string[],
  sku: string,
  incomming: string,
  commited: string,
  numberAvailable: string,
  refund: boolean,
  refun_string: string,
  date: string;
  images?: { id: string, preview: string }[];

}
export interface ProductDetail {
  data: {
    attributes: {
      name: string;
      sku: string;
      categories: CategoriesProduct[];
      sub_categories: CategoriesProduct[];
      images?: { id: number, preview: string, url: string }[]
    };
  };
}
export interface CategoriesProduct {
  id: string;
  name: string;
}
export interface ProductType {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    catalogue_id: string;
    incoming: number;
    committted: number;
    available: number;
    stock: number;
    price: string;
    category_name: string;
    refund_status: boolean;
    product_details: ProductDetail;
    created_at: string,
  };
}
type MarkedDate = {
  startingDay?: boolean;
  endingDay?: boolean;
  color: string;
};

type MarkedDatesResult = {
  [date: string]: MarkedDate;
};
type DateRangePickerDay = {
  dateString: string;
};
// Customizable Area End

export default class InventoryListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductsApiCallId = "";
  productsDateFormat: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.productsDateFormat = "YYYY-MM-DD";
    this.state = {
      productData: [],
      // Customizable Area Start
      productsRowsPerPage: 10,
      productsCurrentTab: 0,
      productsDateRange: {
        fromDate: moment().startOf('week').format(this.productsDateFormat),
        toDate: moment().endOf('week').format(this.productsDateFormat),
      },
      token: "",
      isOpenEdit: false,
      inventoryDetail: {
        id: "",
        name: "",
        category: "",
        categoryIds: [""],
        subCategoryIds: [""],
        sku: "",
        incomming: "0",
        commited: "0",
        numberAvailable: "0",
        refund: false,
        refun_string: "",
        catalogue_id: "",
        date: "",
      },
      textSearch:"",
      inventoryTabsData: [],
      inventoryColumns: [],
      language: "en",
      sortType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    await this.getTokenFn()
    // Customizable Area Start
    this.productsAPiCall()
    super.componentDidMount();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguage(landing_lang)
    // Customizable Area End
  }
  componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): void {
    if (this.state.textSearch !== "" && this.state.textSearch !== oldState.textSearch) {
      this.productsAPiCall()
    }
    if(oldState.language !== this.state.language){
      this.handleChangeLanguage(this.state.language)
    }
    if(this.state.sortType !== oldState.sortType ){
      this.productsAPiCall()
    }
  }

  getTokenFn = async () => {
    const token = (await getStorageData("authToken")) as string;
    this.setState({ token: token });
    return token;
  };  
  handleChangeLanguage = (lang: string) => {
    languageConvertor(lang);

      this.setState({
        language: lang,
        inventoryTabsData: [
          { label: i18n.t('ALLPRODUCTSTXT') },
          { label: i18n.t('REFUNDABLETXT') },
          { label: i18n.t('NONREFUNDABLETXT') },
        ],
        inventoryColumns: [
          { id: 'name', label: i18n.t('PRODUCTNAMETXT') },
          { id: 'category', label: i18n.t('CATEGORYTXT') },
          { id: 'sku', label: i18n.t('SKU') },
          { id: 'incomming', label: i18n.t('INCOMMINGTXT') },
          { id: 'commited', label: i18n.t('COMMITTEDTXT') },
          { id: 'numberAvailable', label: i18n.t('AVAILABLETXT') },
          { id: 'refun_string', label: i18n.t('REFUNDTXT')},
      ]
    })
  };
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguage(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const editInventoryResponse: ProductType= message?.properties?.RestAPIResponceSuccessMessage?.data
      
      if(editInventoryResponse && editInventoryResponse.id && editInventoryResponse.attributes) {
        this.productsAPiCall()
      }
    
      if (apiRequestCallId === this.getProductsApiCallId) {
        let data = responseJson.data.map((res: ProductType) => {
          const createdAtDate = res.attributes?.created_at ? new Date(res.attributes?.created_at) : "";
          return {
            id: res.id,
            catalogue_id: res.attributes.catalogue_id,
            category: res.attributes.product_details.data.attributes.categories.map((cate: CategoriesProduct) => cate.name).join(', '),
            categoryIds: res.attributes.product_details.data.attributes.categories.map((cate: CategoriesProduct) => cate.id.toString()),
            subCategoryIds: res.attributes.product_details.data.attributes.sub_categories.map((cate: CategoriesProduct) => cate.id.toString()),
            commited: res.attributes.committted,
            incomming: res.attributes.incoming,
            name: res.attributes.product_details.data.attributes.name,
            numberAvailable: res.attributes.available,
            refund: res.attributes.refund_status,
            refun_string: res.attributes.refund_status ? "Yes" : "No",
            date: createdAtDate,
            sku: res.attributes.product_details.data.attributes.sku,
            images: res.attributes.product_details.data.attributes.images?.map((image) => ({
              ...image,
              preview: image.url
            })),
          }
        })
        this.setState({
          productData: data
        });

      }
    }

    // Customizable Area End
  }
  productsAPiCall = (dateRange?: DateRange) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = requestMessage.messageId;
    let fromDate = this.state.productsDateRange.fromDate;
    let toDate = this.state.productsDateRange.toDate;
    if (dateRange && (dateRange as {}).hasOwnProperty('toDate') && (dateRange as {}).hasOwnProperty('toDate')) {
      fromDate = dateRange?.fromDate;
      toDate = dateRange?.toDate;
    }
    const invalidDate = !fromDate && !toDate
    const endpoit = invalidDate ?`${configJSON.InventoryEndPoint}?query=${this.state.textSearch}`: `${configJSON.InventoryEndPoint}?query=${this.state.textSearch}&start_date="${fromDate}"&end_date="${toDate}"` + this.state.sortType

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoit
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTableData = (currentTab: string) => {
    let tableData = this.state.productData;
    if (currentTab === tabsEnum[1].label) {
      tableData = this.state.productData.filter((data) => data.refund === true);
    }
    if (currentTab === tabsEnum[2].label) {
      tableData = this.state.productData.filter((data) => data.refund === false);
    }
    if (
      this.state.productsDateRange.fromDate &&
      this.state.productsDateRange.toDate
    ) {
      tableData = this.filterTableDataByDate(tableData);
    }
    return tableData;
  };

  filterTableDataByDate = (tableData: Product[]) => {
    const newTableData = tableData.filter((data) => {
      let compareDate = moment(data.date, this.productsDateFormat);
      let fromDate = moment(this.state.productsDateRange.fromDate, this.productsDateFormat);
      let toDate = moment(this.state.productsDateRange.toDate, this.productsDateFormat);
      if (compareDate.isBetween(fromDate, toDate, null, "[]") || data.date === "" ) {
        return data;
      }
    });
    return newTableData;
  };
  getTabStatus = () => {
    return tabsEnum[this.state.productsCurrentTab].label
  };
  handleTabChange = (value: number) => {
    this.setState({ productsCurrentTab: value });
  };
  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArray = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let idate = fromDate;
      const end = moment(toDate).add(1, "day").format(this.productsDateFormat);
      while (idate !== end) {
        dateArray.push(idate);
        idate = moment(idate).add(1, "day").format(this.productsDateFormat);
      }
    }
    let result: MarkedDatesResult = {};
    dateArray.forEach((e, index) => {
      result[e] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };
  handleDateRangePickerDayPress = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.productsDateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.productsDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.productsDateFormat),
        };
    }
    this.setState({productsDateRange: newDateRange });
    if (!newDateRange.hasOwnProperty("toDate")) {
      newDateRange = {
        ...newDateRange,
        toDate: dateString
      }
    }
    this.productsAPiCall(newDateRange);
  };
  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      productsDateRange: data,
    });
  };
  openEditInventory = (inventory: Product) => {
    this.setState({ isOpenEdit: true, inventoryDetail: inventory })
  }
  closeEditInventory = () => {
    this.setState({
      isOpenEdit: false,
    })
  }
  handleSearchHeader = (value?: string) => {
    this.setState({ textSearch:  value })
  }
  navigateToAddInventory = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'CreateInventory');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message)
  }
  recieveLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        })
      }
    }
  };
  getSortValueFn = (fieldName:string, sortType: string) => {
    this.setState({sortType: sortType })
  }
  // Customizable Area End
}
