import React from "react";
import {Box, styled} from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CatalogueCardController from "./CatalogueCardController.web";
import i18n from "i18next";
import AddShoppingCartOrderItem from "../../../blocks/shoppingcart/src/AddShoppingCartOrderItem.web";
export default class CatalogueCard extends CatalogueCardController {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {description, image, title,  price, onClickImage, id} = this.props;
        return (<ProductWrap className={`cardContainer ${this.props.widthStyle === "285" ? 'containerWidthsm' : 'containerWidthlg'}`}>
                <img src={image} alt="" onClick={onClickImage || (() => this.goToProductDetail(id))} />
                <Typography><Box className="titleStyle">{title}</Box></Typography>
                <Typography><Box className="qtyStyle">{description}</Box></Typography>
                <Box className="cardFooter">
                    <Typography><Box className="titleStyle">{price}</Box></Typography>
                    <AddShoppingCartOrderItem
                        navigation={this.props.navigation}
                        id=""
                        productId={this.props.id.toString()}
                        addButtonLabel={i18n.t("ADDTXT")}
                        addButtonProps={{
                            display: "light",
                            themes: "dark",
                            className: "add-btn"
                        }}
                    /> 
                    </Box>
            </ProductWrap>)
    }
}
const ProductWrap = styled(Box)({
    "&.containerWidthlg":{
        width:"210px"
    },
    "&.containerWidthsm":{
        width:"285px"
    },

    "&.cardContainer":{
        "borderRadius": 4,
        "border": "1px solid #DFE0E0",
        "background": "var(--Accent, #FFF)",
        "boxShadow": "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
        "height": "auto",
        // "width": "285px",
        "gap": "4px",
        "textAlign": "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "25px",
        paddingRight: "25px",
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "space-between",
        "& img": {
            height: "111px",
            width: "100%",
            objectFit: "contain",
            cursor: "pointer"
        },
        "@media(max-width: 560px)":{
            width: "100%"
        }
    },
    "& .cardFooter":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .price": {
        "color": "#000",
        "fontFamily": "Roboto",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "lineHeight": "normal",
        textAlign: "left"
    },
    "& .qtyStyle": {
        "color": "#666",
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": 400,
        "lineHeight": "normal",
        textAlign: "left"
    },
    "& .titleStyle": {
        "color": "#000",
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": 400,
        "lineHeight": "124.219%",
        textAlign: "left"
    },
    "&.cardContainer2":{
        padding: "0px",
        "& .titleStyle": {
            "padding": "22px",
            "textAlign": "center",
            "fontFamily": "Roboto",
            "fontSize": "24px",
            "fontWeight": "600",
            "color": "#000"
        },
        "& img":{
            height: "auto !important",
            cursor: "pointer"
        }
    },
    "& .add-btn": {
        padding: "8px 21px"
    }
});
