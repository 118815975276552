import React, { Component } from "react";

import { Button , styled} from "@material-ui/core";

type MyProps = {
    onClickHandle: (event?: any, index?: number | string) => void;
    styles?: any;
    textStyles?:any;
    size?: string;
    dataTestId?: string;
    width?: string;
    type?: 'button' | 'submit';
    className?: string;
    children?: any;
    itemId?: number | string;
    isDisable?: boolean;
    themes: 'dark' | 'primary' | 'secondary';
    weight?: 'normal' | 'semiBold' | 'bold';
    transform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
    display?: 'primary' | 'primaryWithBorder' | 'lightWhite' | 'light' | 'secondary' | "textButton" | 'successBorderless' | 'successWithBorder' | 'secondaryBorderless' | 'secondaryWithBorder' | 'error';
};

const ButtonBox = styled(Button)({
    "&.customeButton": {
        "padding": '9px 18px',
        "borderRadius": '4px',
        "fontFamily": "Roboto",
        "fontSize": '16px',
        "backgroundImage": "none",
        "& span":{
            "gap": "8px",
            display: "flex",
            "@media (max-width: 420px)": {
                gap: "2px",  
            },
        },
        "& svg": {
            width: "24px",
            height: "24px"
        },
        "@media (max-width: 768px)": {
            width: '100% !important',
            padding: '9px 13px',
        },
    },
    "&.primaryBtn":{
        "border": '2px solid #2563EB',
        "color": '#fff',
        "background": "#2563EB",
    },
    "&.primaryWithBorderBtn": {
        "border": '2px solid #2563EB',
        "color": '#2563EB',
    },
    "&.errorBtn" : {
        "border": '1px solid #FEE3E3',
        "background": "#FEE3E3",
        "color": '#DC2626',
    },
    "&.successBorderlessBtn":{
        "background": "#77B300",
        "border": '1px solid #77B300',
        "color": "#fff"
    },
    "&.successWithBorderBtn": {
        "border": '1px solid #77B300',
        "color": '#77B300',
    },
    "&.secondaryBorderlessBtn": {
        "border": '1px solid #EEE',
        "background": "#EEE",
        "color": '#000000',
    },
    "&.secondaryWithBorderBtn": {
        "border": '1px solid #A6A6A6',
        "background": "#EEE",
        "color": '#333',
    },
    "&.secondaryBtn":{
        "border": '1px solid #EEE',
        "background": "#EEE",
        "color": '#2563EB',
    },
    "&.lightBtn":{
        "background": "rgba(37, 99, 235, 0.08)",
        "color": '#2563EB',
    },
    "&.lightWhiteBtn": {
        "background": "rgba(255, 255, 255, 0.31)",
        "color": "#fff"
    },
    "&.textButtonBtn":{
        "background": "transparent",
        "color": "#2563EB",
        "border": "0px"
    },
});

export default class CustomButton extends Component<MyProps> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const propStyles = this.props.styles || {};
        const styles = {
            "textTransform": this.props.transform ? textTransformations[this.props.transform] : 'none',
            "width": this.props.width || 'auto',
            "fontSize": this.props.size && fontSize[this.props.size],
            "padding": this.props.size && padding[this.props.size],
            "fontWeights": fontWeights[this.props.weight || 'semiBold']
        }

        const {dataTestId, isDisable, onClickHandle, children, type, itemId} = this.props;
        return (
            <ButtonBox 
                data-testid={dataTestId}
                type={type || "button"}
                className={`${this.props.display || 'primary'}Btn customeButton ${this.props.className}`}
                style={{...styles, ...propStyles}} 
                onClick={(event) => onClickHandle(event, itemId)}
                disabled={isDisable}
            >
                {children}
            </ButtonBox>
        );
    }
}



const fontWeights: any = {
    "normal": 400,
    "medium": 500,
    "semiBold": 600,
};

const fontSize: any = {
    xl: '16px',
    x: '14px',
    base: '12px'
};

const padding: any = {
    xl: '19px 36px 18px',
    x: '9px 18px',
    base: '8px 17px'
};

const textTransformations: any = {
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    none: 'none',
};