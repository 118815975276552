import React from "react";

// Customizable Area Start
import {
    Grid,
    Box,
} from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { Formik, FormikValues } from "formik";
import EditVendorController, { Props } from "./EditVendorController";
export const configJSON = require("./config");
import Select from "react-select";
import CustomModal from "../../../components/src/CustomModal.web";
import i18n from "i18next";

// Customizable Area End
export const initialFormValues = {
    vendorId: '',
    vendorName: '',
    contactPerson: "",
    email: '',
    phoneNumber: '',
    status: 'active'
}

export const statusOptions = [{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }]

export default class EditVendor extends EditVendorController {
    constructor(props: Props) {
        super(props);
                // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                <CustomModal
                    isVisible={this.props.isOpenModal}
                    title={i18n.t('VENDORDETAILSTXT')}
                    onCloseModal={this.props.onCloseModal}
                    children={
                        <Formik
                            initialValues={this.state}
                            validate={this.validateEditVendor}
                            onSubmit={this.onEdit}
                            innerRef={this.formikRef}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }: FormikValues) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3} className="form-body">
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="vendorId"
                                                    labelText={i18n.t('VENDORIDTXT')}
                                                    display="primary"
                                                    placeholder="ID"
                                                    value={values.vendorId}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fieldError={touched.vendorId && errors.vendorId} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="vendorName"
                                                    labelText={i18n.t('VENDORNAMETXT')}
                                                    display="primary"
                                                    placeholder={i18n.t('VENDORNAMETXT')}
                                                    value={values.vendorName}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fieldError={touched.vendorName && errors.vendorName} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="contactPerson"
                                                    labelText={i18n.t('CONTACTPERSONTXT')}
                                                    display="primary"
                                                    placeholder={i18n.t('CONTACTPERSONTXT')}
                                                    value={values.contactPerson}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fieldError={touched.contactPerson && errors.contactPerson} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="email"
                                                    labelText={i18n.t('EMAILTXT')}
                                                    display="primary"
                                                    placeholder={i18n.t('EMAILTXT')}
                                                    value={values.email}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fieldError={touched.email && errors.email} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="phoneNumber"
                                                    labelText={i18n.t('PHONENUMBERTXT')}
                                                    display="primary"
                                                    placeholder={i18n.t('PHONENUMBERTXT')}
                                                    value={values.phoneNumber}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fieldError={touched.phoneNumber && errors.phoneNumber} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className="label">{i18n.t('STATUSTXT')}</Box>
                                                <Select
                                                    name="status"
                                                    id="status-vendor"
                                                    options={statusOptions}
                                                    value={statusOptions.find((option) => option.value === values.status)}
                                                    onChange={option => setFieldValue("status", option?.value)}
                                                    onBlur={handleBlur}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            padding: "10px",
                                                        }),
                                                    }}
                                                    components={{ IndicatorSeparator: null }}
                                                    menuPlacement="top"
                                                />
                                                <Box className="errors">{touched.status && errors.status}</Box>
                                            </Grid>
                                        </Grid>
                                        <Box className="footer">
                                            <CustomButton
                                                styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                                width="132px"
                                                themes="dark"
                                                display="secondaryBorderless"
                                                onClickHandle={this.props.onCloseModal}
                                                dataTestId="cancel-vendor">
                                                {i18n.t("CANCELTXT")}
                                            </CustomButton>
                                            <CustomButton
                                                width="132px"
                                                styles={{ background: "#2563EB" }}
                                                themes="dark"
                                                display="primary"
                                                onClickHandle={handleSubmit}
                                                data-testid="save-vendor">
                                                {i18n.t("SAVETXT")}
                                            </CustomButton>
                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    }
                />
            </>
            // Customizable Area End
        );
    }
}
