// Customizable Area Start
import React from "react";
import VendorProfileController, {
  Props
} from "./VendorProfileController.web";
import { Box, styled, Grid, Container } from "@material-ui/core";
import { Formik, FormikValues } from "formik";
import Typography from "../../../components/src/design-system/Typography.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Loader from "../../../components/src/design-system/Loader.web";
import { checkIcon, avatar, background } from "./assets";
import Uploadmedia4 from "../../uploadmedia3/src/Uploadmedia4";
import Uploadmedia3 from "../../uploadmedia3/src/Uploadmedia3.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomSnackBar from '../../../components/src/design-system/CustomSnackBar.web';
import i18n from "i18next";
export default class VendorProfile extends VendorProfileController {
  constructor(props: Props) {
    super(props);
  }


  render(): React.ReactNode {
    const { loading, popOpen } = this.state;
    return (
      <NavigationMenu pageBackgroundMode="light" navigation={this.props.navigation} id="">
        <VendorProfileContainer maxWidth="lg">
        <Loader loading={loading} />
        <Formik
            enableReinitialize
            initialValues={this.state.initialFormValue}
            validate={this.validateForm}
            onSubmit={this.handleSubmitForm.bind(this)}
            data-test-id="addAddressForm"
            innerRef={this.formikRef}
        >
            {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleReset,
            handleSubmit,
            setFieldValue,
            setErrors
            }: FormikValues) => (
            <form onSubmit={handleSubmit}>
              <Box className="form-container">
                
                <img className="formHeading" src={background} alt="" />
                <Grid container spacing={3} className="form-header">
                    <Grid item xs={12} md={6}>
                    <Uploadmedia4 
                      image_preview={this.state.image_preview || avatar} 
                      isEdit={true} 
                      removeProfile={this.removeProfile} 
                      showEditProfile={popOpen} 
                      imageSrc={this.state.image_preview || avatar} alt={""} 
                      handleFileChnage={this.handleFileChnage} 
                      onMouseEnter={this.popHandler} 
                      onMouseLeave={this.popHandler} />

                    </Grid>
                    <Grid item xs={12} md={6} className="action-btn">
                      <CustomButton
                        dataTestId="btn-cancel"
                        styles={webStyles.formActionButtonStyle}
                        display="secondary"
                        onClickHandle={handleReset}
                        themes="primary"
                      >
                        {i18n.t("CANCELTXT")}
                      </CustomButton>
                      <CustomButton
                        dataTestId="btn-save"
                        display="primary"
                        styles={webStyles.formActionButtonStyle}
                        onClickHandle={handleSubmit}
                        themes="primary"
                        type="submit"
                      >
                        <img src={checkIcon} alt="" />
                        {i18n.t("SAVETXT")}
                      </CustomButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="form-wrapper">
                    <Grid item xs={12} md={6}>
                        <Typography size="x" weight="semiBold">{i18n.t("PERSONALINFOTXT")}</Typography>
                    </Grid>
                    <Grid className="form-inner" item xs={12} md={6}>
                      <Grid item>
                          <TextInput
                              name="name"
                              labelText={i18n.t("NAMETXT")}
                              display="primary"
                              placeholder=""
                              value={values.name}
                              onValueChange={handleChange}
                              onBlur={handleBlur}
                              fieldError={touched.name && errors.name}
                              id="nameField"
                          />
                      </Grid>
                      {this.state.userRole === 'vendor' &&
                        <Grid item>
                        <TextInput
                            name="company_name"
                            labelText={i18n.t("COMPANYNAMETXT")}
                            display="primary"
                            placeholder=""
                            value={values.company_name}
                            onValueChange={handleChange}
                            onBlur={handleBlur}
                            fieldError={touched.company_name && errors.company_name}
                            id="company_nameField"
                        />
                        </Grid>
                      }
                      <Grid item>
                      <TextInput
                          name="email"
                          labelText={i18n.t("EMAILLABEL")}
                          display="primary"
                          placeholder=""
                          value={values.email}
                          onValueChange={handleChange}
                          onBlur={handleBlur}
                          fieldError={touched.email && errors.email}
                          id="emailField"
                      />
                      </Grid>
                      <Grid item>
                      <TextInput
                          name="phoneNumber"
                          labelText={i18n.t("PHONENUMBERLABEL")}
                          display="primary"
                          placeholder=""
                          value={values.phoneNumber}
                          onValueChange={handleChange}
                          onBlur={handleBlur}
                          fieldError={touched.phoneNumber && errors.phoneNumber}
                          id="phoneField"
                      />
                      </Grid>
                      <Grid item>
                      <TextInput
                          name="location"
                          labelText={i18n.t("LOCATIONTXT")}
                          display="primary"
                          placeholder=""
                          value={values.location}
                          onValueChange={handleChange}
                          onBlur={handleBlur}
                          fieldError={touched.location && errors.location}
                          id="locationField"
                      />
                      </Grid>
                      <Grid item>
                      <TextInput
                          name="bio"
                          labelText={i18n.t("BIOTXT")}
                          display="primary"
                          placeholder=""
                          value={values.bio}
                          onValueChange={handleChange}
                          onBlur={handleBlur}
                          fieldError={touched.bio && errors.bio}
                          id="bioField"
                          multiline={true}
                      />
                      </Grid>
                      {this.state.userRole === 'vendor' ?
                      <Grid item>
                        <div>
                            <Typography color="black" size="sm" weight="semiBold">{i18n.t("DOCUMENTTXT")}</Typography>
                        </div>
                        <Uploadmedia3
                            navigation={this.props.navigation}
                            id="signupUploadDoc1"
                            types="vendor"
                            fieldError={touched.document || errors.document}
                            fileName={values.document}
                            onFileUpload={(files) => this.onDocumentUpload('document', files, setFieldValue, setErrors)} />
                        <Typography color="red" size="xs">{touched.document || errors.document}</Typography>
                      </Grid> : ''}
                    </Grid>
                </Grid>
              </Box>
            </form> )}
        </Formik>
        <CustomSnackBar
              data-test-id="CustomSnackBarHeader"
              open={this.state.showToast}
              errorMessage={this.state.toastMessage}
              onClose={this.handleCloseSnackBars}
              severity={this.state.toastSuccess }
          />
        </VendorProfileContainer>
      </NavigationMenu>
    );
  }
}

const VendorProfileContainer = styled(Container)({
  padding: 20,
  width: "100%",
  margin: 0,
  paddingBottom: "155px",
    "& .header":{
        padding: "24px 48px",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .form-container":{
        background: "#fff",
        borderRadius: "8px"
    },
    "& .formHeading": {
        borderRadius: "4px 4px 32px 0px",
        width: "100%"
    },
    "& .form-wrapper":{
        display: "flex",
        padding: "39px 40px",
        "@media (max-width: 420px)":{
          padding:"24px 16px",
        },
        "& .form-inner":{
            display: "flex",
            flexDirection: "column",
            gap: "16px"
        }
    },
    "& .form-header":{
        alignItems: "baseline",
        padding: "0px 40px",
        marginTop: "-80px",
        "@media (max-width: 420px)":{
          padding:"0 16px",
        }
    },
    "& .action-btn": {
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        minWidth: 70,
        "& .edit-btn": {
          background: "#EEE",
          color: "#2563EB",
          padding: "5px 17px",
          textTransform: "uppercase",
        },
        "& > img": {
          alignSelf: "center",
          cursor: "pointer",
        },
      },
      "@media(max-width: 800px)":{
        width: "100%",
        padding: "0px",
        "& .profilePicWrapper, .profilePicDiv, .blurBg":{
            width: "80px",
            height: "80px"
        },
        "& .cameraImage":{
          width: "20px"
        },
        "& .blurBg":{
          top: "-100%"
        },
        "& .form-header":{
          padding: "0px 20px",
          marginTop: "-40px"
        },
        "& .editText p":{
          fontSize: "10px !important"
        },
        "& .form-wrapper":{
          padding: "20px 0px"
        }
      }
});


const webStyles = {
    formActionButtonStyle: {
      width: "10.5rem",
      height: "3.5rem",
    }
  };
// Customizable Area End
