// Customizable Area Start
import React from "react";
import UserAddressesController, {
  Props,
  AddressForm,
} from "./UserAddressesController.web";
import { Box, styled, Paper, Button, Grid } from "@material-ui/core";
import { Formik, FormikValues } from "formik";
import AddIcon from "@material-ui/icons/Add";
import Typography from "../../../components/src/design-system/Typography.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomCheckbox from "../../../components/src/design-system/CustomCheckbox.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import StyledRadio from "../../../components/src/design-system/StyledRadio.web";
import Loader from "../../../components/src/design-system/Loader.web";
import { deleteIcon } from "./assets";
import FrontHeader from "../../../blocks/navigationmenu/src/FrontHeader.web";
import UserSidebar from "../../../blocks/navigationmenu/src/UserSidebar.web";
import i18n from "i18next";
import StyledTextArea from "../../../components/src/design-system/StyledTextArea.web";

export default class UserAddresses extends UserAddressesController {
  constructor(props: Props) {
    super(props);
  }

  formRenderer = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  }: FormikValues) => {
    const { t } = i18n
    return (
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} className="form-container">
          <Grid item xs={12} md={10}>
            <TextInput
              name="name"
              labelText={t("FULLNAME")}
              display="primary"
              placeholder=""
              value={values.name}
              onValueChange={this.handleChangeName}
              onBlur={handleBlur}
              fieldError={touched.name && errors.name}
              id="nameField"
            />
          </Grid>

          <Grid item xs={12} md={10}>
            <TextInput
              name="address"
              labelText={t("STREETADDRESS")}
              display="primary"
              placeholder=""
              value={values.address}
              onValueChange={handleChange}
              onBlur={handleBlur}
              fieldError={touched.address && errors.address}
              id="addressField"
            />
          </Grid>

          <Grid item xs={12} md={10}>
            <TextInput
              name="flatNumber"
              labelText={t("FLATNUMBER")}
              display="primary"
              placeholder=""
              value={values.flatNumber}
              onValueChange={handleChange}
              onBlur={handleBlur}
              id="flatNumberField"
            />
          </Grid>

          <Grid item xs={12} md={10}>
            <TextInput
              name="city"
              labelText={t("CITYTXT")}
              display="primary"
              placeholder=""
              value={values.city}
              onValueChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={6} md={5}>
            <TextInput
              name="state"
              labelText={t("STATE")}
              display="primary"
              placeholder={t("STATE")}
              value={values.state}
              onValueChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={6} md={5}>
            <TextInput
              name="zipCode"
              labelText={t("ZIPCODE")}
              display="primary"
              placeholder=""
              value={values.zipCode}
              onValueChange={handleChange}
              onBlur={handleBlur}
              fieldError={touched.zipCode && errors.zipCode}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <TextInput
              name="phoneNumber"
              labelText={t("PHONENUMBERTXT")}
              display="primary"
              placeholder=""
              value={values.phoneNumber}
              onValueChange={handleChange}
              onBlur={handleBlur}
              fieldError={
                touched.phoneNumber && errors.phoneNumber
              }
            />
            <Box paddingY="0.5rem">
              <Typography font="Roboto">
                {t("CONTACTTXT")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography weight="medium">
              {t("DELIVERYNOTES")}
            </Typography>
            <StyledTextArea
              className="delivery-notes"
              name="deliveryNotes"
              value={values.deliveryNotes}
              placeholder={t("ADDPLACEHOLDER")}
              onChange={handleChange}
              aria-multiline
              minRows={3}
              maxLength={250}
            />
            <Box textAlign='right' color='#666'>
              <Typography size="xs">{values.deliveryNotes.length}/250</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <CustomCheckbox
              name="isDefault"
              label={t("SETADDRESS")}
              checked={values.isDefault}
              onValueChange={(event) =>
                setFieldValue("isDefault", event.target.checked)
              }
              onLabelClick={() => { }}
              id="isDefaultCheckbox"
            />
          </Grid>

          <Grid item xs={12} md={10} className="action-btn">
            <CustomButton
              dataTestId="btn-cancel"
              styles={webStyles.formActionButtonStyle}
              onClickHandle={this.handleCancel}
              themes="primary"
            >
              {t("CANCELTXT")}
            </CustomButton>
            <CustomButton
              dataTestId="btn-save"
              styles={webStyles.formActionButtonStyle}
              onClickHandle={handleSubmit}
              themes="primary"
            >
              {t("SAVETXT")}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    )
  }

  render(): React.ReactNode {
    const { t } = i18n
    const { addresses, initialFormValue, loading } = this.state;
    return (
      <UserAddressWrapper maxWidth="lg" className={this.props.isSelectAddressScreen ? ' select-address-wrapper' : ''}>
        <Loader loading={loading} />
        {
          !this.props.isSelectAddressScreen && <FrontHeader navigation={this.props.navigation} isCategory={false} customStyle={{ backgroundColor: "white" }} />
        }
        <Box className="body-wrapper">
          {
            !this.props.isSelectAddressScreen && <UserSidebar navigation={this.props.navigation} />
          }
          <Box
            className="main-wrapper"
            style={this.getpwdstyle()}
          >
            <Box className={`breadcrumbs${this.state.language === "ar" ? " arabic-breadcrumbs" : ""}`}>
              <CustomButton
                onClickHandle={() => this.goToHome()}
                themes="primary"
              >
                {t("HomeTxt")}
              </CustomButton>
              <Typography>&gt;</Typography>
              <Typography>{t("ACCOUNTTXT")}</Typography>
              <Typography>&gt;</Typography>
              <Typography>{t("ADDRESS")}</Typography>
            </Box>
            <Typography size="xl" weight="bold">
              {t("MYADDRESSTXT")}
            </Typography>
            <Box className="main-content-wrapper">
              <Box className="addresses-content-wrapper">
                {!initialFormValue && (
                  <Paper className="add-btn-wrapper">
                    <CustomButton
                      themes="primary"
                      dataTestId="add-address-btn"
                      className="add-btn"
                      onClickHandle={this.openAddAddressForm}
                    >
                      <AddIcon />
                      {t("ADDNEWADDRESS")}
                    </CustomButton>
                  </Paper>
                )}
                <Paper className="addresses-list-wrapper">
                  <Typography weight="medium" size="x" transform="uppercase">
                    {t("DELIVERYADDRESS")}
                  </Typography>
                  <Box className="addresses-list">
                    {addresses.map((addressItem, addressItemIndex) => (
                      <Box className="address-item" key={addressItem.id}>
                        <StyledRadio
                          value={addressItem.id}
                          checked={addressItem.isDefault && !initialFormValue}
                          disabled={Boolean(initialFormValue)}
                          onChange={(event, checked) => {
                            if (checked) {
                              this.callUpdateAddressApi({
                                ...addressItem,
                                isDefault: true,
                              });
                            }
                          }}
                        />
                        <Box className="address-info">
                          <Typography weight="medium">
                            {addressItem.name}
                          </Typography>
                          <Box>
                            <Typography>
                              {[
                                addressItem.flatNumber,
                                addressItem.address,
                                addressItem.city,
                                addressItem.state,
                              ]
                                .filter((item) => item)
                                .join(", ")}
                            </Typography>
                            {addressItem.zipCode && (
                              <Typography weight="semiBold">
                                {` - ${addressItem.zipCode}`}
                              </Typography>
                            )}
                          </Box>
                          <Typography>{addressItem.phoneNumber}</Typography>
                          {addressItem.deliveryNotes && (
                            <Typography>
                              {t("NOTES")} {addressItem.deliveryNotes}
                            </Typography>
                          )}
                        </Box>

                        <Box className="address-action-btn">
                          {initialFormValue?.id !== addressItem.id && (
                            <>
                              <Button
                                onClick={() =>
                                  this.openEditAddressForm(addressItem)
                                }
                                className="edit-btn"
                              >
                                {t("EDITBTN")}
                              </Button>

                              <img
                                src={deleteIcon}
                                data-test-id={`delete-address-${addressItemIndex}`}
                                alt="remove-address"
                                width={24}
                                height={24}
                                onClick={() =>
                                  this.handleDeleteAddress(addressItem.id)
                                }
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Paper>
                {initialFormValue && (
                  <Paper className="add-form-wrapper">
                    <Box display="flex" alignItems="center" gridGap={16}>
                      <StyledRadio checked />
                      <Typography weight="medium" size="x" transform="uppercase">
                        {initialFormValue.id
                          ? t("EDITADDRESS")
                          : t("ADDNEWADDRESS")}
                      </Typography>
                    </Box>
                    <Formik
                      enableReinitialize
                      initialValues={this.state.initialFormValue as AddressForm}
                      validate={this.validateAddressForm}
                      onSubmit={this.handleSubmitAddressForm.bind(this)}
                      data-test-id="addAddressForm"
                      innerRef={this.formikRef}
                    >
                      {
                        this.formRenderer
                      }
                    </Formik>
                  </Paper>
                )}
              </Box>

            </Box>
          </Box>
        </Box>
      </UserAddressWrapper>
    );
  }
}

const UserAddressWrapper = styled(Box)({
  background: "white",
  "&:not(.select-address-wrapper)": {
    height: "100vh",
    overflow: "auto",
  },
  "&.select-address-wrapper": {
    flex: 1,
    "& .breadcrumbs": {
      position: "absolute",
      left: 40,
      top: 15,
      "&.arabic-breadcrumbs": {
        left: "unset",
        right: 40
      }
    },
    "& .body-wrapper": {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  "& .body-wrapper": {
    display: "flex",
    padding: "20px 40px 20px 50px",
    maxWidth: 1440,
    margin: "auto",
    gap: 20,
    boxSizing: "border-box",
    "& .main-wrapper": {
      flex: 1,
      maxWidth: 785,
    }
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
  },
  "& .breadcrumbs": {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: 20,
    
    "& button.MuiButtonBase-root": {
      padding: 0,
      background: "none",
      border: 0,
      color: "#333",
      fontWeight: "normal",
      minWidth: "fit-content",
      maxWidth: "fit-content"
    },
    "& > div:last-of-type": {
      color: "#666 !important",
    },
  },
  "& .main-content-wrapper": {
    display: "flex",
    gap: 16,
    marginTop: 32,
    justifyContent: "center",
    flexWrap: "wrap",
    
    
  },
  "& .addresses-content-wrapper": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 32,
  },
  "& .add-btn-wrapper": {
    minWidth: 320,
    padding: 24,
    "@media (max-width: 420px)": {
      padding: "16px 0",
    },
    "& > .add-btn": {
      textTransform: "none",
      fontWeight: 600,
      fontSize: 20,
      color: "#2563EB",
      background: "transparent",
      border: "none",
      "& svg": {
        marginRight: 24,
      },
    },
  },
  "& .addresses-list-wrapper": {
    padding: "12px 16px 16px 24px",
    "@media (max-width: 420px)": {
      padding: "20px 16px 16px 24px",
    },
    "& .addresses-list": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    "& .address-item": {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      alignItems: "flex-start",
      "& .MuiIconButton-root.Mui-disabled": {
        color: "#A6A6A6",
      },
      "& > .address-info": {
        maxWidth: 600,
        display: "flex",
        flexDirection: "column",
        padding: "10px 30% 10px 16px",
        "@media (max-width: 420px)": {
          padding: "10px 16px",
        },
        "& > .MuiBox-root": {
          "& > div": {
            display: "inline !important",
            
          },
        },
        "& > div:nth-of-type(4)": {
          fontStyle: "italic",
        },
      },
      "& .address-action-btn": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "center",
        minWidth: 70,
        "& .edit-btn": {
          background: "#EEE",
          color: "#2563EB",
          padding: "5px 17px",
          textTransform: "uppercase",
        },
        "& > img": {
          alignSelf: "center",
          cursor: "pointer",
        },
      },
    },
  },
  "& .add-form-wrapper": {
    padding: "29px 28px",
    "@media (max-width: 420px)": {
      padding: "0 16px",
    },
    "& .form-container": {
      padding: 38,
      paddingLeft: 55,
      "@media (max-width: 420px)": {
        padding: "0",
        paddingTop: "12px",
      },
      "& .MuiInputBase-root.Mui-error": {
        "& fieldset": {
          borderColor: "red"
        }
      }
    },
    "& .delivery-notes": {
      resize: 'none',
      borderRadius: 4,
      fontFamily: "Roboto",
      marginBottom: "0.5rem",
      "&::placeholder": {
        fontFamily: "Roboto"
      }
    },
    "& .action-btn": {
      display: "flex",
      gap: "1rem",
      justifyContent: "flex-end",
      "& > button:first-of-type": {
        border: "1px solid #A6A6A6",
        background: "#EEE",
        color: "black",
      },
    },
  },
});

const webStyles = {
  formActionButtonStyle: {
    width: "10.5rem",
    height: "3.5rem",
  },
  payButtonStyle: {
    width: "100%",
    height: "3.5rem",
    marginTop: 24,
  },
};
// Customizable Area End
