export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const deleteIcon = require("../assets/delete.png");
export const AccountInfo = require("../assets/AccountInfo.svg");
export const callIcon = require("../assets/call.svg");
export const editIcon = require("../assets/editIcon.svg");
export const checkIcon = require("../assets/check.png");
export const avatar = require("../assets/avatar.png");
export const background = require("../assets/bg-1.png");
export const HelpIcon = require("../assets/help.svg");
export const locationIcon = require("../assets/image_location_on.svg");
export const logoutIcon = require("../assets/logout.svg");
export const myAddressIcon = require("../assets/myAddress.svg");
export const notificationIcon = require("../assets/notification.svg");
export const passIcon = require("../assets/passIcon.svg");
export const paymentsIcon = require("../assets/payments.svg");
export const profileImage = require("../assets/profileImage.png");
export const arrowBack = require("../assets/arrow_back.svg");
export const cameraIcon = require("../assets/cameraIcon.svg");
export const deleteIconSvg = require("../assets/delete_icon.svg");
export const uploadIcon = require("../assets/upload_icon.svg");
export const passwordIcon = require("../assets/view_vector.png");
export const userInfo = require("../assets/userInfo.svg");
export const location = require("../assets/location.svg");
export const envelope = require("../assets/envelope.svg");
export const copyIcon = require("../assets/copy.png");
export const buildingIcon = require("../assets/building.svg");
export const docIcon = require("../assets/file.svg");
export const closeIcon = require("../assets/close.svg");
export const bg2 = require("../assets/bg-2.png");
