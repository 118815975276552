const image = require("./assets");
export const CountryList = [
    {
      id: 1,
      title: "EN",
      icon: image.en_flag,
      url: "en"
    },
    {
      id: 2,
      title: "AR",
      icon: image.ar_flag,
      url: "ar"
    }
]