import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
const Ulti = require("./../utility");
export const configJSON = require("./config");
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import createRequestMessage from "../../utilities/src/create-request-message";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};
export interface TransactionListingInterface {
  id: string;
  transactionId: string;
  orderId: string;
  avatar?: string;
  contact: string;
  transactionType: string;
  amount: string;
  date: string;
  status: string;
}

export interface AttributesInterface {
  id: number
  order_management_order_id: number
  payment_status: string
  transaction_id: string
  total_price: string
  payment_method: string | null
  order_number: string
  payment_date: string
}

export interface RootDataInterface {
  id: string;
  attributes: AttributesInterface;
}
// Customizable Area End

export interface Props {
  navigation: object;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  transactionRowsPerPage: number;
  transactionCurrentTab: number;
  transactionDateRange: DateRange;
  transactionTableData: TransactionListingInterface[];
  transactionRowEdit: object | null;
  apiToken: string | null;
  transactionListArray: [];
  textSearch: string;
  language: string;
  transactionTabsData: {}[],
  transactionTableColumns: {}[],
  sortType: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class TransactionListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  transactionData: {}[] = [];
  transactionTabsData: string[] = [];
  transactionColumns: string[] = [];
  transactionMapDataTab: string[] = [];
  transactionPagination: object = {};
  transactionDateFormat: string;
  getTransactionListCallId: string = "";
  deleteTransactionApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.transactionDateFormat = "YYYY-MM-DD";

    this.state = {
      transactionRowsPerPage: 10,
      transactionCurrentTab: 0,
      transactionDateRange: {
        fromDate: moment().startOf("week").format(this.transactionDateFormat),
        toDate: moment().endOf("week").format(this.transactionDateFormat),
      },
      transactionRowEdit: null,
      language: 'en',
      transactionTableData: [],
      apiToken: "",
      transactionListArray: [],
      textSearch:"",
      transactionTabsData: [
        { label: 'All' },
        { label: 'Completed' },
        { label: 'Pending' },
        { label: 'Failed' }
      ],
      transactionTableColumns: [
          { id: 'transactionId', label: 'Transaction Id', renderFunction: 'renderRowWithCheckbox' },
          { id: 'orderId', label: 'Order Id' },
          { id: 'date', label: 'Date' },
          { id: 'amount', label: 'Amount', renderFunction: 'renderRowPrice'},
          { id: 'status', label: 'Status', renderFunction: 'renderRowStatus', mapStatusColumn: Ulti.transactionMapStatusColumn },
      ],
      sortType: ""
    };



    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArray = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let date = fromDate;
      const endDate = moment(toDate).add(1, "day").format(this.transactionDateFormat);
      while (date !== endDate) {
        dateArray.push(date);
        date = moment(date).add(1, "day").format(this.transactionDateFormat);
      }
    }
    let result: {}[] = [];
    dateArray.forEach((dateArrayItem, index) => {
      result[dateArrayItem as keyof object] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ transactionCurrentTab: value }, () => this.getTransactionList());
  };

  handleDateRangePickerDayPress = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.transactionDateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.transactionDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.transactionDateFormat),
        };
    }
    this.setState({transactionDateRange: newDateRange }, () => this.getTransactionList());
  };

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      transactionDateRange: data,
    }, () => this.getTransactionList());
  };


  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageTransaction(landing_lang)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prev: Readonly<S>): void {
    if(prev.language !== this.state.language){
      this.handleChangeLanguageTransaction(this.state.language)
    }
    if(this.state.sortType !== prev.sortType ){
      this.getTransactionList()
    }
  }

  getToken = async () => {
    const token = await getStorageData("authToken")
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getTransactionList()
        });
      }
  };

  async receive(from: string, message: Message) {
    this.recieveLanguageTransaction(message)
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getTransactionListCallId) {
        this.setState({
          transactionTableData: responseJson?.data?.map((item: RootDataInterface) => ({
            id: item.attributes.id,
            transactionId: item.attributes.transaction_id,
            orderId: item.attributes.order_number,
            paymentMethod: item.attributes.payment_method || "",
            amount: item.attributes.total_price,
            date: item.attributes.payment_date ? moment(item.attributes.payment_date).format("DD/MM/YY") : "",
            status: this.capitalizeFirstLetter(item.attributes.payment_status)
          })) || []
        })
      }
    }
  }

  getTransactionList = () => {
    const header = {
      token: this.state.apiToken,
      "Content-Type": configJSON.transactionApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTransactionListCallId = requestMessage.messageId;

    const { fromDate, toDate } = this.state.transactionDateRange
    const currentTab = Ulti.transactionTabsMap[this.state.transactionCurrentTab]

    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}` : ""
    const tabQuery = currentTab !== "all" ? `&status=${currentTab}` : ""

    const endPoint = `${configJSON.transactionListEndPoint}?start_date=${fromDate}&end_date=${toDate || fromDate}${searchQuery}${tabQuery}` + this.state.sortType

    createRequestMessage({requestMessage, header, endPoint, method: configJSON.apiMethodTypeGet})
  }

  handleChangeLanguageTransaction = (lang: string) => {
    languageConvertor(lang);

    this.setState({
      language: lang,
      transactionTabsData: [
        { label: i18n.t('ALLTXT') },
        { label: i18n.t('COMPLETEDTXT') },
        { label: i18n.t('PENDINGTXT') },
        { label: i18n.t('FAILEDTXT') },
      ],
      transactionTableColumns: [
          { id: 'transactionId', label: i18n.t('TRANSACTIONIDTXT')},
          { id: 'orderId', label: i18n.t('DASHBOARD.TABLEHEADER.ORDERID') },
          { id: 'date', label: i18n.t('DASHBOARD.TABLEHEADER.DATE') },
          { id: 'amount', label: i18n.t('AMOUNTTXT'), renderFunction: 'renderRowPrice'},
          { id: 'status', label: i18n.t('STATUSTXT'), renderFunction: 'renderRowStatus', mapStatusColumn: Ulti.transactionMapStatusColumn },
      ],
    })
  }

  recieveLanguageTransaction = (transactionMessage: Message) => {
    if (transactionMessage.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = transactionMessage.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({
          language: lang
        }, () => this.handleChangeLanguageTransaction(lang))
      }
    }
  };

  capitalizeFirstLetter = (inputString: string) =>  inputString ? inputString.charAt(0).toUpperCase() + inputString.slice(1) : "";
  
  handleSearch = (text?: string) => this.setState({textSearch: text || ""}, () => this.getTransactionList())
  getSortValueFn = (fieldName:string, sortType: string) => {
    this.setState({sortType: sortType })
  }
  // Customizable Area End
}
