import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import {
  createStyles,
  createTheme,
  StyleRules,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import { FormikValues } from "formik/dist/types";
import TextInput from "../../../components/src/design-system/TextInput.web";
import { Formik } from "formik";
import resources from "./utilities";
import { closeIcon, imageUploadIcon, cancelIcon } from "./assets";
import { FormHeader } from "@builder/component-library";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomSelect from "../../../components/src/design-system/CustomSelect.web";
import Typography from "../../../components/src/design-system/Typography.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Uploadmedia3 from "../../../blocks/uploadmedia3/src/Uploadmedia3.web";
import Loader from "../../../components/src/Loader.web";
import i18n from "i18next";

export const initialProductFormValues: ProductFormData = {
  productName: "",
  arabicProductName: "",
  manufacturerName: "",
  arabicManufacturerName: "",
  category: [],
  subCategory: [],
  price: "0",
  keywords: "",
  arabicKeywords: "",
  stockQuantity: "",
  descriptions: "",
  arabicDescriptions: "",
  specification: "",
  arabicSpecification: "",
  warranty: "",
  arabicWarranty: "",
  salePrice: "",
  discount: "0",
  salePriceArabic: "",
  discountArabic: "0",
};

interface FormData {
  type: "number" | "text" | "dropdown" | "file-uploader";
  name: string;
  title: string;
  placeHolder: string;
  value: string | number | string[];
  desc: string;
  options?: { key: string; name: string }[]
}

// Customizable Area End

import CreateProductController, {
  ProductFormData,
  Props,
} from "./CreateProductController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200EA",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiCheckbox: {
      input: {
        borderRaduis: "50px",
      },
    },
  },
});


export class CreateProduct extends CreateProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  handleChangePrice = (event: { target: { value: string } }, prefix: string, setFieldValue: (field: keyof ProductFormData, value: string) => void) => {
    const value = event.target.value;
    const valueWithoutPrefix = value.replace(prefix, "");
    if (valueWithoutPrefix && (Number.isNaN(parseFloat(valueWithoutPrefix)) || value.length > 20)) return;
    setFieldValue("price", valueWithoutPrefix.replace(/^0(?=\d)/, ""));
  }

  handleChangeSalePrice = (event: { target: { value: string } }, prefix: string, setFieldValue: (field: keyof ProductFormData, value: string) => void) => {
    const value = event.target.value;
    const valueWithoutPrefix = value.replace(prefix, "");
    if (valueWithoutPrefix && (Number.isNaN(parseFloat(valueWithoutPrefix)) || value.length > 20)) return;
    setFieldValue("salePrice", valueWithoutPrefix.replace(/^0(?=\d)/, ""));
    setFieldValue("salePriceArabic", valueWithoutPrefix.replace(/^0(?=\d)/, ""));
  }

  handleChangeDiscount = (event: { target: { value: string } }, prefix: string, setFieldValue: (field: keyof ProductFormData, value: string) => void) => {
    const value = event.target.value;
    const valueWithoutPrefix = value.replace(prefix, "");
    if (valueWithoutPrefix && (Number.isNaN(parseFloat(valueWithoutPrefix)) || value.length > 20 || parseFloat(valueWithoutPrefix) > 100 || parseFloat(valueWithoutPrefix) < 0)) return;
    setFieldValue("discount", valueWithoutPrefix.replace(/^0(?=\d)/, ""));
    setFieldValue("discountArabic", valueWithoutPrefix.replace(/^0(?=\d)/, ""));
  }

  getRenderCategoryDropdown = (
    formData: FormData,
    handleBlur: React.FocusEventHandler,
    values: ProductFormData,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof ProductFormData, value: string[] | string, shouldValidate? :boolean) => void,
  ) => {
    const fieldName = formData.name as "category" | "arabicCategory";
    const subCategories = this.getFilteredSubCategories(values.category);
    const subCategoryOption =
      subCategories.map(subCategory => ({ key: subCategory.id, name: fieldName === "category" ? subCategory.name : subCategory.arabicName }))

    return (
      <>
        <label className="label-text">{formData.title}</label>
        <Box mt={"8"} className="category-select">
          <CustomSelect
            name={fieldName}
            variant="outlined"
            className="multi-select"
            multiple
            options={this.getCategoryOption(fieldName)}
            value={values.category}
            onChange={(event) => {
              const newSelectedCategories = event.target.value as string[]
              setFieldValue("category", newSelectedCategories)
              const filteredSubCategories = this.getFilteredSubCategories(newSelectedCategories)
              setFieldValue("subCategory", values.subCategory
                .filter(subCategoryId => filteredSubCategories.findIndex(filteredSubCategory => filteredSubCategory.id === subCategoryId) !== -1), false)
            }}
            onBlur={handleBlur}
          />
          {
            Boolean(touched[fieldName]) && Boolean(errors[fieldName])
            && <Typography>
              {
                errors[fieldName]
              }
            </Typography>
          }
        </Box>
        {
          subCategoryOption.length > 0
          &&
          <Box pt="48">
            <label className="label-text">
              {
                fieldName === "category"
                  ? i18n.t('SUBCATEGORYTXT')
                  : resources.subCategory.label.ar
              }
            </label>
            <Box mt="8" className="category-select">
              <CustomSelect
                name="subCategory"
                variant="outlined"
                className="multi-select"
                multiple
                options={subCategoryOption}
                value={values.subCategory}
                onChange={(event) =>
                  setFieldValue("subCategory", event.target.value as string[])
                }
                onBlur={handleBlur}
              />
            </Box>
          </Box>
        }
      </>
    );
  }

  getRenderPriceInput = (
    formData: FormData,
    handleBlur: React.FocusEventHandler,
    values: ProductFormData,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof ProductFormData, value: string[] | string, shouldValidate? :boolean) => void,
  ) => {
      const prefix = formData.name === "price" ? resources.currency.label.en : resources.currency.label.ar;
      return (
        <TextInput
          id={formData.name + "Id"}
          name={formData.name}
          data-test-id={formData.name}
          labelText={formData.title}
          display="primary"
          placeholder={formData.placeHolder}
          onValueChange={(event) => this.handleChangePrice(event, prefix, setFieldValue)}
          value={prefix + values.price}
          onBlur={handleBlur}
          fieldError={touched[formData.name] && errors[formData.name]}
        />
      );
  }

  getRenderSalePriceInput = (
    formData: FormData,
    values: ProductFormData,
    handleBlur: React.FocusEventHandler,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof ProductFormData, value: string[] | string, shouldValidate? :boolean) => void,
  ) => {
    const salePricePrefix = formData.name === "salePrice" ? resources.currency.label.en : resources.currency.label.ar;
    return (
      <TextInput
        id={formData.name + "Id"}
        name={formData.name}
        data-test-id={formData.name}
        display="primary"
        labelText={formData.title}
        placeholder={formData.placeHolder}
        onValueChange={(event) => this.handleChangeSalePrice(event, salePricePrefix, setFieldValue)}
        value={salePricePrefix + values.salePrice}
        onBlur={handleBlur}
        fieldError={touched[formData.name] && errors[formData.name]}
      />
    );
  }

  getRender = (
    formData: FormData,
    handleChange: (event: React.ChangeEvent<unknown>) => void,
    handleBlur: React.FocusEventHandler,
    values: ProductFormData,
    touched: { [key: string]: string },
    errors: { [key: string]: string | undefined },
    setFieldValue: (field: keyof ProductFormData, value: string[] | string, shouldValidate?: boolean) => void,
  ) => {
    if (formData.type === "dropdown") {
      return this.getRenderCategoryDropdown(
        formData,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue
      )
    }
    if (formData.type === "file-uploader") {
      return (
        <Box className="upload-image-field">
          <label className="label-text">{formData.title}</label>
          <Box
            className="image-container"
            display="flex"
            flexWrap="wrap"
            gridGap={10}
            alignItems="center"
          >
            <Uploadmedia3
              id="file-upload"
              navigation={this.props.navigation}
              onFileUpload={(files) =>
                this.setState({
                  images: [...this.state.images, ...files.map((file) => ({
                    preview: URL.createObjectURL(file),
                    file: file,
                  })),]
                })
              }
              text={formData.desc}
              imageUrl={imageUploadIcon}
              textColor="primary"
              textSize="xs"
            />
            {this.state.images.map((image, imageIndex) => (
              <Box
                key={imageIndex}
                className="image-thumbnail"
                position="relative"
              >
                <img
                  className="product-image"
                  src={image.preview}
                  width={100}
                  height={100}
                />
                <img
                  src={closeIcon}
                  data-test-id="delete-icon"
                  className="delete-icon"
                  onClick={() =>
                    this.setState({
                      images: this.state.images.filter(
                        (img, index) => index !== imageIndex
                      ),
                    })
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>
      );
    }

    if (["price", "arabicPrice"].includes(formData.name)) {
      return this.getRenderPriceInput(
        formData,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue
      )
    }

    if (["salePrice", "arabicSalePrice"].includes(formData.name)) {
      return this.getRenderSalePriceInput(
        formData,
        values,
        handleBlur,
        touched,
        errors,
        setFieldValue
      )
    }

    if (["discount", "arabicDiscount"].includes(formData.name)) {
      const prefix = '%';
      return (
        <TextInput
          id={formData.name + "Id"}
          name={formData.name}
          data-test-id={formData.name}
          labelText={formData.title}
          display="primary"
          placeholder={formData.placeHolder}
          onValueChange={(event) => this.handleChangeDiscount(event, prefix, setFieldValue)}
          value={values.discount + prefix}
          onBlur={handleBlur}
          fieldError={touched[formData.name] && errors[formData.name]}
        />
      );
    }

    const field = formData.name === "arabicStockQuantity" ? "stockQuantity" : formData.name as keyof ProductFormData

    return (
      <TextInput
        id={formData.name + "Id"}
        name={formData.name}
        data-test-id={formData.name}
        labelText={formData.title}
        display="primary"
        placeholder={formData.placeHolder}
        onValueChange={(event) => {
          if (event?.target?.value
            && (["stockQuantity", "arabicStockQuantity"].includes(formData.name)
              && !/^\d+$/.test(event.target.value))
          ) return;

          if (["arabicStockQuantity", "stockQuantity"].includes(formData.name)) {
            setFieldValue("stockQuantity", event.target.value)
            return;
          }
          handleChange(event)
        }}
        onBlur={handleBlur}
        value={values[field] as string}
        fieldError={touched[formData.name] && errors[formData.name]}
      />
    );
  };
  // Customizable Area End

  render() {
    const { classes } = this.props;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <NavigationMenu id="product" navigation={this.props.navigation}>
        <Loader loading={this.state.loading} />
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <Formik
              initialValues={this.state.productData || initialProductFormValues}
              validate={this.validateProductForm}
              data-test-id="formik"
              onSubmit={this.callProductApi}
              innerRef={this.formikRef}
              enableReinitialize
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
              }): FormikValues => (
                <form onSubmit={handleSubmit} className="product-form">
                  {/* START FORM HEADER */}
                  <Grid container className="product-form-header">
                    <Grid item xs={6} className="form-title">
                      <FormHeader
                        containerStyle={{ border: "none" }}
                        iconRight={cancelIcon}
                        titleText={this.isAdd ? i18n.t('ADDNEWITEMTXT') : (i18n.t('EDITTXT') + ' ' + i18n.t('ITEMTXT'))}
                        iconPress={this.handleCancel}
                      />
                    </Grid>
                    <Grid item xs={6} className="form-title header-arabic-text">
                      <FormHeader
                        containerStyle={{ border: "none", textAlign: "right" }}
                        titleText={resources.addNewItem.ar}
                      />
                    </Grid>
                  </Grid>
                  {/* END FORM HEADER */}

                  {/* START FORM BODY */}
                  <Grid container className="product-form-body">
                    {this.state.formInputs.map((formInput, index) =>
                      formInput.map((formData, subIndex) => (
                        <Grid key={formData.name + index + subIndex} item xs={6}>
                          {this.getRender(
                            formData,
                            handleChange,
                            handleBlur,
                            values,
                            touched as { [key: string]: string },
                            errors as { [key: string]: string | undefined },
                            setFieldValue,
                          )}
                        </Grid>
                      ))
                    )}
                  </Grid>
                  {/* START FORM BODY */}

                  {/* START FORM FOOTER */}
                  <Grid container className="product-form-footer">
                    <Grid item xs={6}>
                      <CustomButton
                        dataTestId="productFormCancel"
                        onClickHandle={this.handleCancel}
                        themes="dark"
                        display="secondary"
                        styles={{ background: "#E6EEFF" }}
                      >
                        {i18n.t('CANCELTXT')}
                      </CustomButton>
                      <CustomButton
                        dataTestId="productFormSubmit"
                        onClickHandle={() => handleSubmit()}
                        themes="dark"
                        display="primary"
                      >
                        {this.isAdd ? i18n.t('ADDTXT') : i18n.t('EDITTXT')}
                      </CustomButton>
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                  {/* START FORM FOOTER */}
                </form>
              )}
            </Formik>
          </div>
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export const styles: StyleRules = createStyles({
  root: {
    overflow: "auto",
    "& .product-form": {
      minWidth: 700,
      border: "solid 1px #0000003d",
      "& .drag-drop-box": {
        border: "0",
        background: "#E6EEFF",
        width: "110px",
        minWidth: 0,
      },
      "& .label-text": {
        fontSize: "1rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
      },
      "& .product-form-header, & .product-form-body, & .product-form-footer": {
        "& .MuiGrid-item:nth-child(odd)": {
          borderRight: "1px solid rgb(200,200,200)",
        },

        "& .MuiGrid-item:nth-child(even)": {
          "& label, .label-text": {
            display: "block",
            textAlign: "right",
          },
          "& .customBtn span": {
            flexDirection: "row-reverse",
          },
          "& input": {
            textAlign: "right",
          },
          "& .upload-image-field": {
            "&>div": {
              display: "flex",
              justifyContent: "end",
              flexDirection: "row-reverse",
            },
          },
        },
        "& .category-select": {
          "& .multi-select": {
            "& .MuiSelect-root": {
              padding: "18px 14px",
            },
            width: "100%",
          },
          "& > div:last-of-type": {
            color: "#DC2626 !important",
            marginTop: "8px"
          }
        },
        "& #uploadFileSection": {
          height: 100,
        },
        "& .image-thumbnail": {
          "& .product-image": {
            objectFit: "cover"
          },
          "& .delete-icon": {
            cursor: "pointer",
            position: "absolute",
            top: -10,
            right: -10,
          },
        },
      },
      "& .product-form-header": {
        alignItems: "center",
        borderBottom: "1px solid rgb(200,200,200)",
        "& .form-title": {
          "& > div": {
            padding: 0,
            marginTop: 25,
            "& > div": {
              padding: "0px 24px",
              "& > div:first-of-type": {
                fontSize: 20,
                lineHeight: "28px",
                fontFamily: "Roboto",
                fontWeight: 600
              },
              "& > div:last-of-type > div": {
                width: "32px !important",
                height: "32px !important",
              }
            }
          }
        },
        "& .header-arabic-text": {
          "&>div>div": {
            flexDirection: "row-reverse",
          },
        },
      },
      "& .product-form-body": {
        borderTop: "1px solid rgb(200,200,200)",
        "& .MuiGrid-item": {
          padding: "24px",
        },
      },
      "& .product-form-footer": {
        textAlign: "right",
        marginBottom: "20px",
        "& button": {
          marginRight: "24px",
          minWidth: 130,
          padding: "13px 18px"
        },
      },
    },
  },
});
export default withStyles(styles)(CreateProduct);
// Customizable Area End
