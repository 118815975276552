Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.putApiMethod = 'PUT'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.getInCartOrderListEndPoint = "bx_block_order_management/orders/get_customer_in_cart_order_list"
exports.ordersApiEndPoint = "bx_block_order_management/orders"
exports.addProductSuccessMessage = "Product Added Successfully"
exports.deliveryAddressErrorMessage = "You don't have any address"

exports.getAccountInfo = "account_block/accounts/specific_account"

exports.similarProductsApi = "/bx_block_catalogue/catalogues/similar_product_list?id="
// Customizable Area End
