import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, { Props, SignUpFormErrors, initialSignupFormValues } from "./EmailAccountRegistrationController";
import {
    styled,
    Grid,
    Box,
    Tooltip,
    createTheme,
    ThemeProvider,
} from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomCheckbox from "../../../components/src/design-system/CustomCheckbox.web";
import CustomSnackBar  from "../../../components/src/design-system/CustomSnackBar.web";
import { Formik, FormikValues } from "formik";
import { signupBackground,InfoIcon,redCheck,tickCheck} from "./assets";
import Uploadmedia3 from "../../uploadmedia3/src/Uploadmedia3.web";
import EditVendor from "./EditVendor.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import i18n from "i18next";

// Customizable Area End

const SignupWrapper = styled('div')({
    '&.signup-page': {
        width: '100%',
        height: 'calc(100vh)',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        background: `url(${signupBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'auto',
        flexDirection: "column",
    },
    '& .signup-container': {
        width: '56%',
        minWidth: '50.5rem',
        maxWidth: '50.5rem',
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        padding: '0 2rem',
        margin: '7rem 0 2.5rem 0',
        "@media (max-width: 960px)": {
            width: '50%',
            minWidth: '15rem',
        },
        "@media (max-width: 420px)": {
            margin: '4rem 0 2.5rem 0',
            overflow: 'auto',
        },
    },
    '& .signup-header': {
        textAlign: 'center',
        margin: '3.94rem 0 2.5rem 0',
        "@media (max-width: 420px)": {
            margin: "2rem 0 2rem 0",
        },
    },
    '& .drag-drop-box': {
        border: '1px dashed #A6A6A6',
        borderRadius: '0.25rem',
        minWidth: '14rem',
        height: '5.25rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#EEE',
        margin: '0.5rem 0',
        cursor: 'pointer',
    },
    '& .label-txt': {
        fontSize: '1rem',
        fontWeight: 500,
        color: '#000'
    },
    '& .submit': {
        display: 'flex',
        justifyContent: 'center',
        margin: '1.94rem 0 1.25rem 0',
    },
    '& .login-link': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '3rem',
        gap: '0.5rem',
        '& .go-to-login': {
            cursor: 'pointer',
        },
        "@media (max-width: 960px)": {
            flexDirection: 'column',
            alignItems: "center",
        },
    },
    '& .terms-err': {
        margin: '0.4rem 0 0 3.18rem',
    },
    '& .signupheader-text': {
        "@media (max-width: 420px)": {
            fontSize: "1.5rem"
        },
    },
    "& .passwordBox":{
      position:"relative"  
    },
    "& .infoIcon":{
        position:"absolute",
        top:"9%",
        left:"24%",
        "@media (max-width: 768px)": {
            left:"30%",
        },
        "@media (max-width: 420px)": {
            left:"40%",
        },
    },
    "& .check":{
        width:"50px"
    },

});
const theme=createTheme({
    overrides:{
        MuiTooltip:{
            tooltip: {
                backgroundColor: "white",
                border:"1px solid gray",
                color:"black",
                padding:"14px 7px",
                width:"80%",
                boxShadow: "0px 4px 8px 4px rgba(0,0,0,25%)",
                "@media (max-width: 768px)": {
                    width: "60%",
                   
                },
                "@media (max-width: 420px)": {
                    width: "70%",
                    position: "absolute",
                    left: "20%",
                    top: "50px"
                },

              }
        }
    }
})
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        
        const BoxWrapper = (errors: SignUpFormErrors) => (
                    <Grid container alignItems="center" spacing={2} justifyContent="center">
                        <Grid item xs={12} spacing={2}>
                            <Box display="flex" gridColumnGap={3}>
                                <img width={"12px"} src={errors.isUpperError ? redCheck : tickCheck} />
                                <Typography size="xs">{i18n.t("ISCAPITALERROR")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" gridColumnGap={3}>
                                <img width={"12px"} src={errors.isLowerError ? redCheck : tickCheck} />
                                <Typography size="xs">{i18n.t("ISLOWERERROR")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" gridColumnGap={3}>
                                <img width={"12px"} src={errors.isDigitError? redCheck : tickCheck} />
                                <Typography size="xs">{i18n.t("ISNUMBERERROR")}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" gridColumnGap={3}>
                                <img width={"12px"} src={errors.isLengthError?redCheck  :  tickCheck} />
                                <Typography size="xs">{i18n.t("ISLENGTHERROR")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )
  
        return (
            // Customizable Area Start
            <SignupWrapper className="signup-page">
                <FrontHeader navigation={this.props.navigation} headerType="signin" signUpData={{ target: "CustomerEmailAccountLogin", isSignIn: false, userType: "customer", isFromRegistrationPage: true }} />
                <div className="signup-container">
                    <div className="signup-header">
                        <Typography color="black" size="xxl" weight="bold" >
                            <Box className="signupheader-text">
                               { i18n.t("SIGNUPTITLE")}
                            </Box>
                        </Typography>
                    </div>
                    <Formik
                        initialValues={initialSignupFormValues}
                        validate={this.validateSignupForm}
                        onSubmit={this.onSignup}
                        data-test-id="signupForm"
                        innerRef={this.formikRef}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }: FormikValues) => (
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            name="companyName"
                                            labelText= { i18n.t("COMPANYNAME")}
                                            display="primary"
                                            placeholder={ i18n.t("COMPANYNAMEPLACEHOLDER")}
                                            value={values.companyName}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.companyName && errors.companyName}
                                            inputStyles={this.getInputErrorStyles(touched, errors, 'companyName')}
                                            id="companyNameField"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            name="email"
                                            labelText={ i18n.t("EMAIL")}
                                            display="primary"
                                            placeholder={ i18n.t("EMAILPLACEHOLDER")}
                                            value={values.email}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.email && errors.email}
                                            id="emailField"
                                            inputStyles={this.getInputErrorStyles(touched, errors, 'email')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            name="companyRegNo"
                                            labelText={ i18n.t("COMPANYREGNO")}
                                            display="primary"
                                            placeholder={ i18n.t("REGNOPLACEHOLDER")}
                                            value={values.companyRegNo}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.companyRegNo && errors.companyRegNo}
                                            id="companyRegNoField"
                                            inputStyles={this.getInputErrorStyles(touched, errors, 'companyRegNo')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            name="vatId"
                                            labelText={ i18n.t("VATID")}
                                            display="primary"
                                            placeholder={ i18n.t("VATIDPLACEHOLDER")}
                                            value={values.vatId}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.vatId && errors.vatId}
                                            inputStyles={this.getInputErrorStyles(touched, errors, 'vatId')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            name="phoneNumber"
                                            labelText={ i18n.t("PHONENUMBER")} 
                                            display="primary"
                                            placeholder={ i18n.t("PHONENOPLACEHOLDER")}
                                            value={values.phoneNumber}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            fieldError={touched.phoneNumber && errors.phoneNumber}
                                            inputStyles={this.getInputErrorStyles(touched, errors, 'phoneNumber')}
                                        />
                                    </Grid>

                                    <Grid className="passwordBox" item xs={12} md={6}>
                                        <TextInput
                                            name="password"
                                            labelText={ i18n.t("PASSWORD")}
                                            display="primary"
                                            placeholder={ i18n.t("PASSWORDPLACEHOLDER")}
                                            value={values.password}
                                            onValueChange={handleChange}
                                            onBlur={handleBlur}
                                            inputStyles={{...this.getpwdstyle(),
                                                ...this.getInputErrorStyles(touched, errors, 'password')}}
                                            fieldError={touched.password && !values.password && i18n.t("PASSWORDERROR") }
                                            inputType="password"
                                            
                                        />
                                        <ThemeProvider theme={theme}>
                                            <Tooltip
                                                title={BoxWrapper(errors)}
                                                arrow
                                                placement={window.innerWidth<768 ?"top":"right-start"}
                                            >
                                                <img width={20} height={20} className="infoIcon" src={InfoIcon} alt="Information Icon" />
                                            </Tooltip>
                                        </ThemeProvider>

                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <div>
                                            <Typography color="black" size="sm" weight="semiBold">{ i18n.t("DOC1")}</Typography>
                                        </div>

                                        <Uploadmedia3
                                            navigation={this.props.navigation}
                                            id="signupUploadDoc1"
                                            fieldError={touched.doc1Url && errors.doc1Url}
                                            fileName={values.doc1Url}
                                            onFileUpload={(files) => this.onDocumentUpload('doc1Url', files, setFieldValue)} />

                                        <Typography color="red" size="xs">{touched.doc1Url && errors.doc1Url}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <div>
                                            <Typography color="black" size="sm" weight="semiBold">{ i18n.t("DOC2")}</Typography>
                                        </div>

                                        <Uploadmedia3
                                            navigation={this.props.navigation}
                                            id="signupUploadDoc2"
                                            fieldError={touched.doc2Url && errors.doc2Url}
                                            fileName={values.doc2Url}
                                            onFileUpload={(files) => this.onDocumentUpload('doc2Url', files, setFieldValue)} />

                                        <Typography color="red" size="xs">{touched.doc2Url && errors.doc2Url}</Typography>

                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CustomCheckbox
                                            name="agreedWithTerms"
                                            label= { i18n.t("TERMS")}
                                            checked={values.agreedWithTerms}
                                            onValueChange={handleChange}
                                            onLabelClick={() => this.storeSignupFormData(values)}
                                            onAlert={!!errors.agreedWithTerms && touched.agreedWithTerms}
                                            id="agreeTermsCheckbox"
                                        />
                                        {(touched.agreedWithTerms && errors.agreedWithTerms) && (
                                            <div className="terms-err">
                                                <Typography color="red" size="xs">{touched.agreedWithTerms && errors.agreedWithTerms}</Typography>
                                            </div>
                                        )}
                                    </Grid>

                                </Grid>

                                <div className="submit">
                                    <CustomButton
                                        dataTestId="signupSubmit"
                                        onClickHandle={() => handleSubmit()}
                                        themes="primary"
                                        styles={{
                                            height: '3.5rem',
                                            width: '22.5rem',
                                        }}
                                    > { i18n.t("SIGNUP")}</CustomButton>
                                </div>
                                <div className="login-link">
                                    <Typography font="Inter" size="sm" color="dark">{ i18n.t("ALREADYACCOUNT")}</Typography>
                                    <div className="go-to-login" onClick={(e) => this.goToLoginPage()}>
                                        <Typography size="sm" font="Inter" color="primary" weight="bold" >{ i18n.t("LOGIN")}</Typography>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
                <CustomSnackBar
                    data-test-id="CustomSnackBar"
                    open={this.state.showToast}
                    onClose={() => this.handleCloseSnackBar()}
                    errorMessage={this.state.toastMessage}
                    severity={this.state.toastSuccess as "error" | "warning" | "info" | "success"}
                />
            </SignupWrapper>
            // Customizable Area End
        );
    }
}
