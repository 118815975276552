import React from "react"
import { Box, Divider, styled } from "@material-ui/core"
import Typography from "./design-system/Typography.web"
import StyledRadio from "./design-system/StyledRadio.web"
import CustomCheckbox from "./design-system/CustomCheckbox.web"
import TextInput from "./design-system/TextInput.web"
import CustomButton from "./design-system/CustomButton.web"
import i18n from "i18next";
type Option = {
    id: number
    name: string
}
type BrandOption = Option & {
    productCount: number
}
type ProductFormOption = Omit<BrandOption, "id">
type Props = {
    subCategoryOptions: Option[]
    brandOptions: BrandOption[]
    productFormOptions: ProductFormOption[]
    categoryName: string;
    selectedSubCategoryId?: number
    selectedBrandIds: number[]
    selectedProductForms: string[]
    onChangeSubCategory: (newSubCategoryId: number) => void
    onChangeBrands: (newBrands: number[]) => void
    onChangeProductForms: (newProductForms: string[]) => void
}

type S = {
    brandSearchInput: string
    productSearchInput: string
    subCategoryShowCount: number
    brandShowCount: number
    productShowCount: number
}

export default class ProductFilter extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props)
        this.state = {
            brandSearchInput: "",
            productSearchInput: "",
            subCategoryShowCount: 5,
            brandShowCount: 5,
            productShowCount: 5,
        }
    }

    componentDidUpdate(): void {
        const currentSubCategoryIndex = this.props.subCategoryOptions.findIndex(subCatOption => subCatOption.id === this.props.selectedSubCategoryId)
        if (currentSubCategoryIndex > this.state.subCategoryShowCount) this.setState({ subCategoryShowCount: currentSubCategoryIndex + 1 })
    }

    render(): React.ReactNode {
        const { subCategoryOptions, brandOptions, productFormOptions, categoryName, selectedSubCategoryId, selectedBrandIds, selectedProductForms, onChangeSubCategory, onChangeBrands, onChangeProductForms } = this.props
        const { brandSearchInput, productSearchInput, subCategoryShowCount, brandShowCount, productShowCount } = this.state
        const filteredBrandOptions = brandOptions.filter(brandOption => brandOption.name.toLowerCase().includes(brandSearchInput.toLowerCase()))
        const filteredProductOptions = productFormOptions.filter(productFormOption => productFormOption.name.toLowerCase().includes(productSearchInput.toLowerCase()))

        return (
            <ProductFilterWrapper>
                <Typography weight="medium" font="Roboto" size="xl">
                    {i18n.t("FILTERTXT")}
                </Typography>
                <Box className="filter-type filter-category">
                    <Typography weight="medium" size="sm">
                    {i18n.t("CATEGORYTXT")}
                    </Typography>
                    {
                        categoryName
                        &&
                        <Box className="option-item" >
                            <Typography>
                                {categoryName}
                            </Typography>
                            <StyledRadio checked />
                        </Box>
                    }

                </Box>
                <Divider className="divider" />

                <Box className="filter-type">
                    <Typography size="xs" weight="medium">
                    {i18n.t("SUBCATEGORYTXT")}
                    </Typography>
                    {
                        subCategoryOptions.slice(0, subCategoryShowCount).map(subCategory => (
                            <Box className="option-item" key={subCategory.id}>
                                <Typography>
                                    {subCategory.name}
                                </Typography>
                                <StyledRadio
                                    checked={subCategory.id === selectedSubCategoryId}
                                    onChange={(e, checked) => {
                                        if (checked) onChangeSubCategory(subCategory.id)
                                    }}
                                />
                            </Box>
                        ))
                    }
                    {
                        subCategoryShowCount < subCategoryOptions.length
                        &&
                        <CustomButton
                            className="view-more-btn"
                            themes="primary"
                            onClickHandle={() => this.setState({ subCategoryShowCount: subCategoryShowCount + 3 })}
                        >{i18n.t("VIEWMORETXT")}</CustomButton>
                    }
                </Box>
                <Divider className="divider" />

                <Box className="filter-type">
                    <Typography weight="medium">
                    {i18n.t("BRANDS")}
                    </Typography>
                    <TextInput
                        placeholder={i18n.t("SEARCHBRANDTXT")}
                        value={this.state.brandSearchInput}
                        inputStyles={inputStyles}
                        onValueChange={(event) => this.setState({ brandSearchInput: event.target.value.trim() })}
                    />
                    {
                        filteredBrandOptions
                            .slice(0, brandShowCount)
                            .map(brandOption => (
                                <Box className="option-item" key={brandOption.id}>
                                    <Box display="flex" alignItems="center">
                                        <CustomCheckbox
                                            checked={selectedBrandIds.includes(brandOption.id)}
                                            onValueChange={(event) => onChangeBrands(event.target.checked ? [...selectedBrandIds, brandOption.id] : selectedBrandIds.filter(selectedBrandId => selectedBrandId !== brandOption.id))}
                                        />
                                        <Typography>
                                            {brandOption.name}
                                        </Typography>
                                    </Box>

                                    <Typography>
                                        {brandOption.productCount}
                                    </Typography>
                                </Box>
                            ))
                    }
                    {
                        brandShowCount < filteredBrandOptions.length
                        &&
                        <CustomButton
                            className="view-more-btn"
                            themes="primary"
                            onClickHandle={() => this.setState({ brandShowCount: brandShowCount + 3 })}
                        >{i18n.t("VIEWMORETXT")}</CustomButton>
                    }
                </Box>
                <Divider className="divider" />

                <Box className="filter-type">
                    <Typography weight="medium">
                    {i18n.t("PRODUCTFORMTEXT")}
                    </Typography>
                    <TextInput
                        placeholder={i18n.t("SEARCHPRODUCTTXT")}
                        value={this.state.productSearchInput}
                        inputStyles={inputStyles}
                        onValueChange={(event) => this.setState({ productSearchInput: event.target.value.trim() })}
                    />
                    {
                        filteredProductOptions
                            .slice(0, productShowCount)
                            .map((productOption, productOptionIndex) => (
                                <Box className="option-item" key={productOption.name + productOptionIndex}>
                                    <Box display="flex" alignItems="center">
                                        <CustomCheckbox
                                            checked={selectedProductForms.includes(productOption.name)}
                                            onValueChange={(event) =>
                                                onChangeProductForms(event.target.checked
                                                    ? [...selectedProductForms, productOption.name]
                                                    : selectedProductForms.filter(selectedProductForm => selectedProductForm !== productOption.name)
                                                )}
                                        />
                                        <Typography>
                                            {productOption.name}
                                        </Typography>
                                    </Box>

                                    <Typography>
                                        {productOption.productCount}
                                    </Typography>
                                </Box>
                            ))
                    }
                    {
                        productShowCount < filteredProductOptions.length
                        &&
                        <CustomButton
                            className="view-more-btn"
                            themes="primary"
                            onClickHandle={() => this.setState({ productShowCount: productShowCount + 3 })}
                        >{i18n.t("VIEWMORETXT")}</CustomButton>
                    }
                </Box>
            </ProductFilterWrapper>
        )
    }
}

const ProductFilterWrapper = styled(Box)({
    background: "white",
    minWidth: 210,
    maxWidth: 260,
    height: "fit-content",
    padding: "8.5px 0",
    borderRight:"1px solid rgba(0,0,0,0.3)",
    "& > div:first-of-type": {
        padding: "10px 8.5px"
    },
    "& .divider": {
        height: 2
    },
    "& > .filter-type": {
        padding: "16px 8.5px",
        "&.filter-category": {
            paddingBottom: 8
        },
        "& .option-item": {
            fontFamily: "Roboto",
            fontSize: 14,
            display: "flex",
            padding: "8px 0",
            justifyContent: "space-between",
            alignItems: "center",
            "& span.MuiCheckbox-root": {
                paddingLeft: 0,
                "& .custom-checkbox": {
                    width: 20,
                    height: 20
                }
            },
            "& svg": {
                width: 16,
                height: 16
            }
        },
        "& > .view-more-btn": {
            color: "#2563EB",
            background: "transparent",
            height: 20,
            border: "none",
            paddingLeft: 0,
            margin: "20px 0"
        }
    }
})

const inputStyles = {
    borderRadius: 2,
    border: "1px solid #A6A6A6",
    background: "#EEE",
    padding: 9
}