import React from "react";

// Customizable Area Start
import {
    Grid,
    Box,
    styled,
} from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { Formik, FormikValues } from "formik";
import EditOrderAdminController, { Props } from "./EditOrderAdminController";
export const configJSON = require("./config");
import Select from "react-select";
import CustomModal from "../../../components/src/CustomModal.web";
import i18n from "i18next";
// Customizable Area End
export const initialFormValues = {
    vendorId: '',
    vendorName: '',
    contactPerson: "",
    email: '',
    phoneNumber: '',
    status: ""
}
export const statusOptions = [{ label: "In Progress", value: "placed", color: "#F59E0B" }, { label: "Delivered", value: "delivered", color: "#059669" }, { label: "Cancelled", value: "cancelled", color: "#DC2626" }]
export const paymentStatusMethos = [{ label: "paid", value: "paid" }, { label: "unpaid", value: "unpaid" }]

export default class EditOrderAdmin extends EditOrderAdminController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const { id, name, address, date, price } = this.props.defaultOrder;
        return (
            // Customizable Area Start
            <>
                <CustomModal
                    data-testId="form-wrapper"
                    title="Order Details"
                    onCloseModal={this.props.onCloseModal}
                    isVisible={this.props.isOpenModal}

                    children={
                        <Formik
                            initialValues={{
                                ...initialFormValues,
                                status: this.props.defaultOrder.status
                            }}
                            onSubmit={this.onEdit}
                            innerRef={this.formikRef}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                errors,
                                setFieldValue,
                                handleSubmit,
                                touched,
                            }: FormikValues) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container spacing={3} className="form-body">

                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.ORDERID")}
                                                    display="primary"
                                                    placeholder="ID"
                                                    name="orderId"
                                                    value={id}
                                                    onBlur={handleBlur}
                                                    onValueChange={handleChange}

                                                    disabled={true} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.PRODUCTNAME")}
                                                    name="productName"
                                                    placeholder="Name"
                                                    display="primary"
                                                    value={name}
                                                    onValueChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={true} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    name="Address"
                                                    labelText={i18n.t("ADDRESS")}
                                                    display="primary"
                                                    placeholder={address}
                                                    value={address}
                                                    onBlur={handleBlur}
                                                    onValueChange={handleChange}
                                                    disabled={true} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("DASHBOARD.TABLEHEADER.DATE")}
                                                    display="primary"
                                                    name="date"
                                                    onBlur={handleBlur}
                                                    placeholder="Email"
                                                    value={date}
                                                    onValueChange={handleChange}
                                                    disabled={true} />
                                            </Grid>


                                            <Grid item xs={12}>
                                                <TextInput
                                                    labelText={i18n.t("PRICETXT")}
                                                    name="Price"
                                                    placeholder="Phone Number"
                                                    display="primary"
                                                    onBlur={handleBlur}
                                                    value={price}
                                                    onValueChange={handleChange}
                                                    disabled={true} />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Box className={`textColor${values.status?.value}`}>{i18n.t("STATUSTXT")}</Box>

                                                <Select
                                                    name="status"
                                                    id="status-vendor"
                                                    onBlur={handleBlur}
                                                    options={statusOptions}
                                                    onChange={option => setFieldValue("status", option?.value)}
                                                    value={statusOptions.find((option) => option.label=== values.status)}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            padding: "10px",
                                                        }),
                                                        singleValue: (provided, state) => ({
                                                            ...provided,
                                                            color: state.data.color,
                                                        }),
                                                    }}
                                                    components={{ IndicatorSeparator: null }}
                                                    menuPlacement="top"
                                                />
                                            </Grid>

                                        </Grid>



                                        <Box className="footer">
                                            <CustomButton
                                                width="132px"
                                                styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                                onClickHandle={this.props.onCloseModal}
                                                display="secondaryBorderless"
                                                themes="dark"
                                                dataTestId="cancel-vendor">
                                                {i18n.t("CANCELTXT")}
                                            </CustomButton>
                                            <CustomButton
                                                styles={{ background: "#2563EB" }}
                                                width="132px"
                                                themes="dark"
                                                onClickHandle={handleSubmit}
                                                display="primary"
                                                data-testid="save-vendor">
                                                {i18n.t("SAVETXT")}
                                            </CustomButton>
                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    }
                />
            </>
            // Customizable Area End
        );
    }
}
