export const VectorIcon = require("../src/icons/VectorIcon.svg")
export const CartIcon = require("../src/icons/cartIcon.svg")
export const SearchIcon = require("../src/icons/group_icon.svg")
export const LangIcon = require("../src/icons/group_lang.svg")
export const OrderIcon = require("../src/icons/group_order_icon.svg")
export const UserIcon = require("../src/icons/group_user.svg")
export const TextLogo = require("../src/icons/textlabel_logo.svg")
export const CloseIcon = require("../src/icons/close.svg")
export const SearchGrayIcon = require("../src/icons/searchGray.svg")
export const imgInCome = require("../src/icons/income.svg")
export const imgOutCome = require("../src/icons/outcome.svg")
export const sortIcon = require("../src/icons/sortIcon.svg")
export const upIcon =  require("../src/icons/up-arrow.png")
export const downIcon =  require("../src/icons/down-arrow.png")


