// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export interface Props {
    navigation?: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    image?: string;
    title: string;
    ImgStyle? :string;
    display: string;
    id: number;
    description?: string;
    descriptionStyle?: any;
    onClickHandle: () => void;
    price: string;
    onClickImage?: () => void
    widthStyle?:string
}

interface S {
  loading: boolean;
  token: string;
}

interface SS {
  id: string;
}

export default class CatalogueCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      loading: true,
      token: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    const token = (await getStorageData("authToken")) as string;
    this.setState({ token: token });
  }

  
  goToProductDetail = (id: string | number) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    request.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(request);
  }
}
// Customizable Area End
