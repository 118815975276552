import React, { Component } from 'react';
import MenuList from '@material-ui/core/MenuList';
import { Box, styled, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import CustomButton from './CustomButton.web';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '../../../components/src/design-system/Typography.web';


type SimpleMenuState = {
  anchorEl: null | HTMLElement;
  selectedList: number | null;
}

type DropDownProp = {
  label: string,
  index?: number,
  listData: any[],
  children?: any,
  downArrowIcon: React.ReactNode,
  upArrowIcon: React.ReactNode,
  rightIcon?:  React.ReactNode,
  btnStyle?: any,
  customeStyle?: any,
  handleTarget: (item:string) => void;
  btnDisplay?: 'primary' | 'primaryWithBorder' | 'lightWhite' | 'light' | 'secondary' | "textButton" | 'successBorderless' | 'successWithBorder' | 'secondaryBorderless' | 'secondaryWithBorder' | 'error';
  custompropDropdown?:boolean,
  handleRaiseMessage?: (btnText?: string) => void
}

const DropDownStyling = styled(Box)({
  position: "relative",
  "& .customBtn":{
    textTransform: "capitalize"
  },
  "& .dropdownList:hover":{
    background: "#fff",
    color: "#2563EB",
  },
  "& .dropdownList:hover > .imgcolor":{
    filter: "brightness(0) saturate(100%) invert(27%) sepia(93%) saturate(2476%) hue-rotate(216deg) brightness(96%) contrast(92%)",
  },
  "& .menuContainer":{
    position: "absolute !important" as "absolute",
    top: "100% !important",
    width: "100%",
    minWidth: "fit-content",
    zIndex:99
  },
  "& .all": {
    fontWeight:"bold"
  },
  "& .active": {
    color:"#2563EB"
  },
  "& .borderTop":{
    borderTop:"1px solid rgba(0,0,0,0.4)",
  }
})

export default class DropDownMenu extends Component<DropDownProp, SimpleMenuState>{
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
      selectedList: null,
    };
  }

  private handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedList: index + 1 });
  };

  private handleClose = () => {
    this.setState({ anchorEl: null, selectedList: null });
  };
  private handleRaiseMessage = (btnText?: string) => {
    this.props.handleRaiseMessage && this.props.handleRaiseMessage(btnText)
  };

  render(): React.ReactNode {
    const { anchorEl } = this.state;
    return (
      <DropDownStyling>
        <CustomButton styles={this.props.btnStyle} display={this.props.btnDisplay} themes='primary' onClickHandle={(event) => this.handleClick(event, this.props.index || 0)}
          className={`customBtn`}
        >
          {this.props.rightIcon}
          {this.props?.children}
          <div className={this.state.selectedList ? "active" : ""}>{this.props.label}</div>
          {this.props.index === this.state.selectedList ? this.props.upArrowIcon : this.props.downArrowIcon}
        </CustomButton>
        <Popper
            open={Boolean(anchorEl)} 
            transition
            placement="top-start"
            disablePortal
            className='menuContainer'
            >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
              
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList autoFocusItem={Boolean(anchorEl)}>
                      {this.props.listData.map((item: any) => {
                        return (
                            <MenuItem onClick={() => {this.props.handleTarget(item?.url); this.handleClose(); this.handleRaiseMessage(item.title)}} className={item.title=="Logout"?"dropdownList borderTop":"dropdownList"}>
                              {item?.icon ? <ListItemIcon className={this.props.custompropDropdown?'imgcolor':""}><img src={item.icon}/> </ListItemIcon>: ""}
                              <ListItemText>{item?.title ? <div className={item.title.includes("All") && "all"}>{item.title}</div> : item}</ListItemText>
                            </MenuItem>
                        )
                      })
                      }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </DropDownStyling>
    )
  }
}