import * as React from "react";
// Customizable Area Start
import {
  Box,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import UserAddresses from "../../user-profile-basic/src/UserAddresses.web";
import PaymentDetail from "../../stripepayments/src/PaymentDetail.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import { configJSON, ViewProps } from "./SelectAddress";

const SelectAddressView: React.FC<ViewProps> = ({
  // Customizable Area Start
  navigation,
  addressId,
  selectAddress,
  loading,
  setLoading
  // Customizable Area End
}) => {
  // Customizable Area Start
  
  // Customizable Area End

  return (
    // Customizable Area Start
    <ThemeProvider theme={theme}>
      <SelectAddressWrapper>
        <Loader loading={loading} />
        <FrontHeader navigation={navigation} isCategory={false} customStyle={{ backgroundColor: "white" }} />
        <Box maxWidth={1440} margin="auto" position="relative">
          <Box className="main-container">
            <UserAddresses
              navigation={navigation}
              isSelectAddressScreen
              updateSelectedAddress={selectAddress}
            />
            <Box className="payment-detail">
              <PaymentDetail
                navigation={navigation}
                deliveryAddressId={addressId}
                isOrderApiRequesting={loading}
                setIsOrderApiRequesting={setLoading}
              />
            </Box>
          </Box>
        </Box>
      </SelectAddressWrapper>
    </ThemeProvider>
    // Customizable Area End
  );
};

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
});

const SelectAddressWrapper = styled(Box)({
  background: "#eee",
  height: '100vh',
  overflowY: 'auto',
  boxSizing: "border-box",
  "& .main-container": {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 1140,
    padding: "40px",
    margin: "auto",
    gap: 15,
    justifyContent: "center",
    "& .payment-detail": {
      marginTop: "80px",
      "@media (max-width: 820px)": {
        marginTop: 0
      }
    }
  }
})
// Customizable Area End

export default SelectAddressView;
