//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Select from "react-select";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomAccordion from "../../../components/src/CustomAccordion.web";
import TypographyCustom from "../../../components/src/design-system/Typography.web";
import Footer from "../../navigationmenu/src/Footer.web";
import AddShoppingCartOrderItem from "../../../blocks/shoppingcart/src/AddShoppingCartOrderItem.web";
import CatalogueCard from "../../../blocks/catalogue/src/CatalogueCard.web";
import Markup from "../../../components/src/design-system/Markup.web";
import i18n from "i18next";
export const unitoptions = [{ label: "5 box", value: "5" }, { label: "1 box", value: "1" }]
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FrontHeader
          navigation={this.props.navigation}
          customStyle={{ background: "#fff", borderBottom: "1px solid #A6A6A6" }}
          customStyleBox={{ background: "#fff", boxShadow: "0px 1px 5px 5px rgba(0,0,0,0.2)" }}
        />
        <Wrapper>
          <Box className="breadcrumbs">
            <Box className="breadcrumItem" onClick={() => this.navigateTo("LandingPage")}>
              <TypographyCustom>{i18n.t("HomeTxt")}</TypographyCustom>
            </Box>
            <>
              <TypographyCustom>&gt;</TypographyCustom>
              <Box className="breadcrumItem" onClick={() => this.navigateTo("Categoriessubcategories",  this.state.categoryId || this.state.productDetail.categories[0].id)}>
                <TypographyCustom>{this.state.categoryId ? this.state.productDetail.categories.find(cate => cate.id === this.state.categoryId)?.name_locale : this.state.productDetail.categories[0].name_locale}</TypographyCustom>
              </Box>
            </>
            <>
              <TypographyCustom>&gt;</TypographyCustom>
              <Box className="breadcrumItem" onClick={() => this.navigateTo("Categoriessubcategories",  `${this.state.categoryId || this.state.productDetail.categories[0].id}/${this.state.productDetail.sub_categories[0].id}`)}>
                <TypographyCustom>{this.state.productDetail.sub_categories[0]?.name}</TypographyCustom>
              </Box>
            </>
          </Box>
          <Box className="productWrapper">
            <Box className="imageView">
              <Box className="subImageWrapper">
                {
                  this.state.productDetail?.images && this.state.productDetail?.images?.map((image, index) => {
                    return (
                      <Box key={index} className={`subImage ${index === this.state.focusedImageIndex && "active"}`} onClick={() => this.clickImage(index)}>
                        <img src={image.url} className="item" width={95} height={95} key={index} />
                      </Box>
                    )
                  })
                }
              </Box>
              <Box className="mainImage">
                {this.state.productDetail?.images ? <img src={this.state.productDetail?.images[this.state.focusedImageIndex].url} width={500} height={400} /> : <></>}
              </Box>
            </Box>
            <Box className="infoView">
              <TypographyCustom size="xl" weight="bold" >{this.state.productDetail.name_locale}</TypographyCustom>
              <Box className="priceWrapper">
                <Typography className="salePrice">{i18n.t("SARTXT")}{this.state.productDetail.sale_price}</Typography>
                <Typography className="price">{i18n.t("SARTXT")}{this.state.productDetail.price}</Typography>
              </Box>
              <Box className="hightlightWrrapper">
                <TypographyCustom size="base" weight="bold" >{i18n.t("PRODUCTHIGHLIGHTSTXT")}</TypographyCustom>
                <Markup>{this.state.productDetail.highlights_locale || ""}</Markup>
              </Box>
              <Box className="line" />
              <Box className="unitText">
                <TypographyCustom size="base" weight="bold" >{i18n.t("UNITTXT")}</TypographyCustom>
              </Box>
              <Box className="units">
                <Select
                  className="selectBox"
                  id="selectbox"
                  options={unitoptions}
                  value={unitoptions.find((option) => option.value === "1")}
                  components={{ IndicatorSeparator: null }}
                />
                <TypographyCustom>{i18n.t("OF1UNITTXT")}</TypographyCustom>
              </Box>
              <Box className="buttonBox">
                {
                  this.state.productDetail.stock_qty === 0 ? <CustomButton
                  onClickHandle={() => {}}
                  themes="primary"
                  display="secondaryBorderless"
                  styles={{background:"#C8D5F2", cursor:"none"}}
                >
                  {i18n.t("ADDTOCARTTXT")}
                </CustomButton> : <AddShoppingCartOrderItem
                  productId={this.productId}
                  addButtonClassName="add-to-cart-btn"
                  addButtonLabel={i18n.t("ADDTOCARTTXT")}
                />
                }
                 
                
                <CustomButton
                  onClickHandle={this.buyNow}
                  themes="primary"
                  display="primaryWithBorder"
                  
                >
                  {i18n.t("BUYNOWTXT")}
                </CustomButton>
              </Box>
              {
                this.state.address.length > 0 && <Box className="deliveryBox">
                  <Box className="deliveryText">
                    <TypographyCustom size="xl" weight="bold">{i18n.t("EARLIESTTXT")}</TypographyCustom>
                    <Typography className="titleColor">{i18n.t("TOMORROWTXT")}</Typography>
                  </Box>
                  <Box className="deliveryText">
                    <TypographyCustom>{i18n.t("DELIVERYTOTXT")}</TypographyCustom>
                    <Select
                      className="selectAddress"
                      id="selectaddress"
                      options={this.state.address}
                      value={this.state.address.find((option) => option.value === this.state.adressId)}
                      components={{ IndicatorSeparator: null }}
                      onChange={option => this.handleSelectAddress(option?.value)}
                    />
                  </Box>
                </Box>
              }

              <CustomAccordion title={i18n.t("DESCRIPTIONTXT")} content={this.state?.productDetail.description_locale} />
              <CustomAccordion title={i18n.t("SPECIFICATIONTXT")} content={this.state?.productDetail.specifications_locale} />
              <CustomAccordion title={i18n.t("WARRANTYTXT")} content={this.state?.productDetail.warranty_locale} />
            </Box>
          </Box>
        </Wrapper>
        <SimilarProductWrapper>
          <Box className="simlarBg"><TypographyCustom color="white" size="xl" weight="bold">{i18n.t("SIMILARPRODUCTSTXT")}</TypographyCustom></Box>
          <Box className="similar">
            {
              this.state.similarProduct.slice(0,6).map((product, index) => {
                return <CatalogueCard
                  id={product.id}
                  key={index}
                  title={product.title}
                  price={product.price}
                  description={product.description}
                  image={product.image}
                  display="default"
                  widthStyle="285"
                />
              })
            }
          </Box>
        </SimilarProductWrapper>
        <FrequentlyProductWrapper>
          <Box className="simlarBg"><TypographyCustom color="white" size="xl" weight="bold">{i18n.t("FBTTXT")}</TypographyCustom></Box>
          <Box className="similar">
            {
              this.state.similarProduct.slice(0,6).map((product, index) => {
                return <CatalogueCard
                  key={index}
                  id={product.id}
                  title={product.title}
                  price={product.price}
                  description={product.description}
                  image={product.image}
                  display="default"
                  widthStyle="285"
                />
              })
            }
          </Box>
        </FrequentlyProductWrapper>
        <Footer navigation={this.props.navigation}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)({
  padding: "14px 50px",
  "width":"100%",
  boxSizing: "border-box", 
  maxWidth: "1980px",
  margin:"auto",
  "& .breadcrumbs": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "1rem",
    textColor: "#333333",
    padding:"0.5rem 0"
  },
  "& .breadcrumItem": {
    cursor: "pointer",
  },
  "& .productWrapper": {
    display: "flex",
    gap: 20,
    width:"100%",
    "@media(max-width: 768px)":{
      display:"grid"
  },
  },
  "& .imageView": {
    display: "flex",
    width:"50%",
    flexDirection: "row",
    gap: 20,
    "@media(max-width: 768px)":{
      justifyContent: "center",
      flexWrap:"wrap-reverse",
      width:"100%"
  },
    
  },
  "& .subImageWrapper": {
    display: "flex",
    flexDirection: "column",
    maxWidth: "95px",
    gap: 10,
    maxHeight: "428px",
    overflowY: "auto !important",
    "@media(max-width: 768px)":{
      display:"flex",
      flexDirection: "row",
      maxWidth: "100%"
  },
  },
  "& .subImage": {
    border: "1px solid #DBDBDB",
    borderRadius: "4px",
    cursor: "pointer",
    overflow: "hidden",
    "&.active": {
      border: "1px solid #2563EB",
    }
  },
  "& .mainImage": {
    width: "500px",
    height: "428px",
    border: "1px solid #DBDBDB",
    borderRadius: "4px",
    overflow: "hidden",
    "@media(max-width: 420px)":{
      height:"auto"
  },
  },
  "& .infoView": {
    flex:1
  },
  "& .title": {
    maxWidth: "full",
    display: "-webkit-box",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "41px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    lineClamp: "2",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    wordWrap: "break-word"
  },
  "& .subTitle": {
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: "1.5rem",
    marginBottom: "0.5rem"
  },
  "& .titleColor": {
    fontWeight: "bold",
    fontSize: "1.375rem",
    color: "#77B300",
  },
  "& .priceWrapper": {
    display: "flex",
    gap: 10,
    "& .salePrice": {
      fontWeight: "bold",
      fontSize: "1.5rem",
    },
    "& .price": {
      textDecorationLine: 'line-through',
      color: "#666666",
      fontSize: "1.5rem"
    }
  },
  "& .line": {
    width: "full",
    height: "2px",
    backgroundColor: "#0000001F",
    margin: "1.5rem 0"
  },
  "& .units": {
    display: "flex",
    gap: 20,
    alignItems: "center",
    "& .selectBox": {
      width: "100px",
      "& div": {
        border: "none",
        background: "#EEEEEE"
      }
    }
  },
  "& .buttonBox": {
    display: "grid",
    gridAutoFlow:"column",
    maxWidth: "480px",
    margin: "1.5rem 0",
    gap: 10,
    "& .add-to-cart-btn": {
      minWidth: 230
    }
  },
  "& .deliveryText": {
    display: "flex",
    gap: 5,
    alignItems: "center",
    margin: "0.5rem 0"
  },
  "& .deliveryBox": {
    display: "flex",
    flexDirection: "column",
    height: "106px",
    padding: "0 1rem 1rem",
    gap: 10,
    border: "1px solid #DBDBDB",
    borderRadius: "4px",
    marginBottom: "1rem"
  },
  "& .selectAddress": {
    minWidth: "200px",
    "& div": {
      border: "none",
      color: "#000000",
      fontWeight: "500",
      fontSize: "1rem"
    }
  },
  "& .hightlightWrrapper": {
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
    gap: 10
  },
  "& .unitText": {
    margin: "0.5rem 0"
  }
});
const SimilarProductWrapper = styled("div")({
  background:"white",
  paddingBottom:"1rem",
  marginTop:"2rem",
  "width":"100%",
  boxSizing: "border-box", 
  maxWidth: "1980px",
  margin:"auto",
  "& .similar": {
    padding: "0 50px",
    display: "flex",
    gap: "20px",
    marginTop:"-2.5rem",
    "@media(max-width: 768px)":{
      justifyContent: "center",
      flexWrap:"wrap"
  },
  },
  "& .simlarBg": {
    background:"linear-gradient(90deg, #467CF3 0%, #42B8E2 100%)",
    width:"full",
    height:"140px",
    borderBottomRightRadius:"1rem",
    borderBottomLeftRadius:"1rem",
    display:"flex",
    justifyContent:"left",
    alignItems:"center",
    padding: "0 3rem"
  }
})
const FrequentlyProductWrapper = styled("div")({
  background:"white",
  marginTop:"2rem",
  paddingBottom:"1rem",
  "width":"100%",
  boxSizing: "border-box", 
  maxWidth: "1980px",
  margin:"auto",
  "& .similar": {
    display: "flex",
    gap: "20px",
    marginTop:"-2.5rem",
    padding: "0 50px",
    "@media(max-width: 768px)":{
        justifyContent: "center",
        flexWrap:"wrap"
    },
  },
  "& .simlarBg": {
    background:"linear-gradient(90deg, #42B8E2 21.88%, #9DFEE7 100%)",
    width:"full",
    height:"140px",
    borderBottomRightRadius:"1rem",
    borderBottomLeftRadius:"1rem",
    display:"flex",
    justifyContent:"left",
    alignItems:"center",
    padding: "0 3rem"
  }
})
// Customizable Area End
