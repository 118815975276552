// Customizable Area Start
import React from "react";
import moment from "moment";
import {
  Container,
  Box,
  Paper,
  styled,
  createTheme,
  ThemeProvider
} from "@material-ui/core";
import Loader from "../../../components/src/design-system/Loader.web";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomSelect from "../../../components/src/design-system/CustomSelect.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import CatalogueCard from "../../catalogue/src/CatalogueCard.web";
import PaymentDetail from "../../stripepayments/src/PaymentDetail.web";
import AddIcon from "@material-ui/icons/Add";
import { deleteIcon } from "./assets";
import i18n from "i18next";

const theme = createTheme();
import OrderSummaryController, {
  Props
} from "./OrderSummaryController";

export default class OrderSummary extends OrderSummaryController {
  constructor(props: Props) {
    super(props);
  }

  formatCurrency = (value?: number, alwaysShowPrefix?: boolean) => {
    return `${i18n.t("SARTXT")} ${value ? value.toFixed(2) : 0}`;
  };
  formatDeliveryDate = (date: string) => moment(date, "YYYY/MM/DD").format("MMMM DD").toUpperCase()

  render() {
    const { loading, payment, orderItems, similarProducts } = this.state

    return (
      <ThemeProvider theme={theme}>
        <OrderSummaryWrapper>
          <Loader loading={loading} />
          <Box>
            <FrontHeader navigation={this.props.navigation} isCategory={false} customStyle={{ background: "white" }} />
            <Container maxWidth="lg">
              <Box fontSize={24} lineHeight="28px">
                <Typography weight="medium">
                  {i18n.t("ORDERSUMMARYTXT")}
                </Typography>
              </Box>

              <Box className="main-content-wrapper">
                <Paper className="products-box">
                  <Typography size="x" weight="medium" transform="uppercase">
                    {i18n.t("PRODUCTSTXT")}
                  </Typography>
                  <Box className="order-items-list-wrapper">
                    {
                      orderItems.map((orderItem, orderItemIndex) => (
                        <Box className="order-item" key={orderItem.id}>
                          <Box className="product-image">
                            <img src={orderItem.image || ""} />
                          </Box>
                          <Box className="product-info">
                            <Typography weight="medium">
                              {orderItem.name}
                            </Typography>
                            <Typography size="xs" color="limegreen">
                              {i18n.t("ONLYITEMREMAILINGTXT", {qty: orderItem.stockQty})}
                            </Typography>
                            <Box className="product-info-price">
                              <Typography color="primary" size="x" weight="medium">
                                {this.formatCurrency(orderItem.price)}
                              </Typography>
                              {
                                orderItem.saveAmount > 0 && <Typography color="limegreen">
                                  {i18n.t("YOUSAVETXT")} {this.formatCurrency(orderItem.saveAmount)}
                                </Typography>
                              }
                            </Box>
                            <Typography color="charcoal" size="xs">
                              {i18n.t("INCARTTXT")}
                            </Typography>
                          </Box>
                          <Box className={`order-item-actions ${this.state.language}`}>
                            <img
                              src={deleteIcon}
                              data-test-id={`delete-order-item-${orderItemIndex}`}
                              alt="remove-order-item"
                              width={24}
                              height={24}
                              onClick={() => this.callApiUpdateProductQuantity(orderItem.id, orderItem.productId, 0)}
                            />
                            <CustomSelect
                              value={orderItem.orderQty}
                              options={
                                Array(orderItem.stockQty)
                                  .fill(0).map((element, index) => ({ key: index + 1, name: (index + 1).toString() }))
                              }
                              onChange={(event) => this.callApiUpdateProductQuantity(orderItem.id, orderItem.productId, event.target.value as number)}
                              renderValue={(value) => `${i18n.t("QTYTXT")}:${value}`}
                            />
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                  <Paper className="add-btn-wrapper">
                    <CustomButton
                      themes="primary"
                      dataTestId="add-order-item-btn"
                      className="add-btn"
                      onClickHandle={() => this.goToLandingPage()}
                    >
                      {i18n.t("ADDMOREITEMTXT")}
                      <AddIcon />
                    </CustomButton>
                  </Paper>
                </Paper>
                <PaymentDetail
                  navigation={this.props.navigation}
                />
              </Box>
            </Container>
            <Box className="similar-products-section">
              <Box>
                <Box fontSize={24} marginBottom="30px">
                  <Typography color="white" weight="medium">
                    {i18n.t("SIMILARPRODUCTSTXT")}
                  </Typography>
                </Box>

                <Box className="similar-products">
                  {similarProducts.map((item) => (
                    <CatalogueCard
                      key={item.id}
                      id={Number(item.id)}
                      title={item.name}
                      price={i18n.t("SARTXT") + item.price}
                      description={item.blockQty !== null ? `${item.blockQty} unit` : item.description}
                      image={item.image || undefined}
                      display="default"
                      onClickHandle={() => { }}
                      onClickImage={() => this.goToProductDetail(item.id)}
                    />
                  ))}
                </Box>
              </Box>

            </Box>
          </Box>
        </OrderSummaryWrapper>
      </ThemeProvider>
    );
  }
}

const OrderSummaryWrapper = styled(Box)({
  background: "white",
  height: "100vh",
  overflowY: "auto",
  paddingBottom: 60,
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
  },
  "& .MuiContainer-root": {
    padding: "30px 67px 50px"
  },
  "& .main-content-wrapper": {
    display: "flex",
    gap: 16,
    marginTop: 32,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    "& .products-box": {
      flex: 1,
      padding: 24,
      maxWidth: 785,
      boxSizing: "border-box"
    }
  },
  "& .order-items-list-wrapper": {
    "& .order-items-list": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    "& .order-item": {
      padding: "30px 0",
      borderBottom: "solid 1px #0000001f",
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      alignItems: "flex-start",
      "& .product-image": {
        border: "1px solid #DBDBDB",
        width: 100,
        height: 100,
        borderRadius: 4,
        padding: 10,
        "& img": {
          objectFit: "cover",
          width: "100%"
        }
      },
      "& .MuiIconButton-root.Mui-disabled": {
        color: "#A6A6A6",
      },
      "& > .product-info": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "0 15px 10px 16px",
        "& .product-info-qty": {
          color: "#77B300"
        },
        "& .product-info-price": {
          display: "flex",
          gap: 8,
          alignItems: "center",
          fontSize: 12,
          flexWrap: "wrap"
        },
        "& > div:nth-of-type(4)": {
        },
      },
      "& .order-item-action-btn": {
        "& > img": {
          alignSelf: "center",
          cursor: "pointer",
        },
      },
    },
  },
  "& .order-item-actions": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "100%",
    "& > img": {
      cursor: "pointer"
    },
    "& .MuiSelect-root": {
      backgroundColor: "#EEE",
      minWidth: 60,
      padding: "10px 30px 10px 26px",
      fontSize: 14
    },
    "&.ar":{
      "& .MuiSelect-root": {
        padding: "10px 40px 10px 26px",
      }
    },
    "& .MuiSelect-icon": {
      right: 20
    }
  },
  "& .add-btn-wrapper": {
    minWidth: 320,
    paddingTop: 24,
    "@media (max-width: 420px)": {
    },
    "& > .add-btn": {
      width: "100% !important",
      padding: 0,
      fontWeight: 600,
      fontSize: 20,
      color: "#2563EB",
      background: "transparent",
      border: "none",
      "& > .MuiButton-label": {
        justifyContent: "space-between",
      },
      "& svg": {
        width: 32,
        height: 32
      },
    },
  },
  "& .similar-products-section": {
    paddingBottom: 30,
    background: "white",
    position: "relative",
    "& > .MuiBox-root": {
      position: "relative",
      zIndex: 1,
      display: "flex",
      flexDirection: "column",
      maxWidth: 1440,
      margin: "auto",
      padding: "40px 50px",
      boxSizing: "border-box",
      "&:after": {
        content: "''",
        background: 'linear-gradient(90deg, #467CF3 0%, #42B8E2 100%)',
        minHeight: 140,
        borderRadius: "0 0 20px 20px",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1
      },
    },
    "& .similar-products": {
      display: "flex",
      gap: 10,
      flexWrap: "wrap",
      "& > .cardContainer": {
        flexShrink: 0,
        "& .titleStyle": {
          color: "black"
        },
        "& > img": {
          marginBottom: 20,
        },
        "& .cardFooter": {
          marginTop: 10,
          "& > div > .titleStyle": {
            fontWeight: "500 !important",
            fontSize: 16
          },
          "& > button": {
            borderRadius: 0,
            lineHeight: "normal",
            backgroundColor: "#EEEEEE",

            "& > span.MuiButton-label": {
              textTransform: "uppercase"
            }
          },
        },
      },
    }
  }
})

// Customizable Area End
