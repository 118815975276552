// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DateData } from "react-native-calendars";
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export const webConfigJSON = require("./config.js");

const generateColors = <T extends Object>(data: Array<T>) => {
  const hueStep = 360 / data.length;

  return data.map((item, index) => {
    const hueValue = Math.floor(index * hueStep);
    return {
      ...item,
      fill: `hsl(${hueValue}, 80%, 50%)`,
    };
  });
};

type DateRange = {
  fromDate?: string;
  toDate?: string;
};

export interface Props {
  navigation: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
}
interface S {
  token: string;
  loading: boolean;
  language: string;
  dateRange: DateRange;
  dashboardData: {
    sales: string;
    orders: number;
    products: number;
  };
  revenueAndSalesVolume: {
    revenue: number;
    revenueGrowthPercentage: number;
    salesVolume: number;
    salesVolumeGrowthPercentage: number;
    data: Array<{
      weekday: string;
      revenue: number;
      salesVolume: number;
    }>;
  };
  salesByCategory: Array<{
    name: string;
    fill: string;
    value: number;
  }>;
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  dashboardApiCallId: string = "";
  salesVolumeAndRevenueApiCallId: string = "";
  salesByCategoryApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      loading: false,
      language: "en",
      dateRange: {
        fromDate: moment().startOf("month").format("YYYY-MM-DD"),
        toDate: moment().endOf("month").format("YYYY-MM-DD"),
      },
      dashboardData: {
        sales: this.formatArabicCurrency(0, 2),
        orders: 0,
        products: 0,
      },
      revenueAndSalesVolume: {
        revenue: 0,
        revenueGrowthPercentage: 0,
        salesVolume: 0,
        salesVolumeGrowthPercentage: 0,
        data: [],
      },
      salesByCategory: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const [token, storedLanguage] = await Promise.all([
      getStorageData("authToken"),
      getStorageData("language"),
    ]);
    const lang = storedLanguage || "en";
    languageConvertor(lang);
    this.setState({ language: lang, token }, () => {
      if (token) {
        this.fetchData();
      }
    });
  }

  callGetApi = (
    apiIdField:
      | "dashboardApiCallId"
      | "salesVolumeAndRevenueApiCallId"
      | "salesByCategoryApiCallId",
    apiEndPoint: string
  ) => {
    const { dateRange } = this.state;
    this.setState({ loading: true });
    const headers = {
      language: this.state.language,
      "Content-Type": webConfigJSON.dashboarContentType,
      token: this.state.token,
    };

    const apiGetMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this[apiIdField] = apiGetMessage.messageId;
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}?start_date=${dateRange.fromDate}&end_date=${dateRange.toDate || dateRange.fromDate
      }`
    );

    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiGetMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
  };

  fetchData = () => {
    this.callGetApi(
      "dashboardApiCallId",
      webConfigJSON.adminDashboardApiEndPoint
    );
    this.callGetApi(
      "salesVolumeAndRevenueApiCallId",
      webConfigJSON.salesVolumeAndRevenueApiEndPoint
    );
    this.callGetApi(
      "salesByCategoryApiCallId",
      webConfigJSON.salesByCategoryApiEndPoint
    );
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(
        getName(MessageEnum.InfoPageTitleMessage)
      );
      if (selectedLng != undefined && selectedLng != this.state.language) {
        languageConvertor(selectedLng);
        this.setState({ language: selectedLng }, () => this.fetchData());
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.receiveResponse(message)
    }
  }

  receiveResponse = async (message: Message) => {
    const resJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiCallId = await message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiCallId === this.dashboardApiCallId && resJson?.data) {
      this.setState({
        dashboardData: {
          sales: this.formatArabicCurrency(
            resJson.data.total_sales_amount,
            2
          ),
          products: resJson.data.product_count,
          orders: resJson.data.order_count,
        },
      });
    }
    if (apiCallId === this.salesVolumeAndRevenueApiCallId && !resJson.errors?.length) {
      this.setState({
        revenueAndSalesVolume: {
          revenue: parseFloat(resJson.total_sales_revenue) || 0,
          revenueGrowthPercentage: parseFloat(resJson.revenue_growth_percentage) || 0,
          salesVolume: resJson.total_sales_volume,
          salesVolumeGrowthPercentage: parseFloat(resJson.sales_volume_growth_percentage) || 0,
          data: resJson.data.map(
            (item: {
              day_of_month: string;
              total_sales_volume: number;
              total_revenue: string;
            }) => ({
              weekday: item.day_of_month,
              revenue: parseFloat(item.total_revenue),
              salesVolume: item.total_sales_volume,
            })
          ),
        },
      });
    }
    if (apiCallId === this.salesByCategoryApiCallId) {
      const salesByCategoryArray = resJson?.data?.map(
        (category: { category: string; total_sales: string }) => ({
          name: category.category,
          value: parseFloat(category.total_sales),
        })
      ) || [];
      this.setState({
        salesByCategory: generateColors(salesByCategoryArray),
      });
    }
  }

  getMarkedDates = () => {
    const { fromDate, toDate } = this.state.dateRange;
    if (!fromDate) {
      return {};
    }
    let dateArray = [];
    if (!toDate) {
      dateArray = [fromDate];
    } else {
      let iterator = fromDate;
      const endDate = moment(toDate).add(1, "day").format("YYYY-MM-DD");
      while (iterator !== endDate) {
        dateArray.push(iterator);
        iterator = moment(iterator).add(1, "day").format("YYYY-MM-DD");
      }
    }
    let result: {
      [key: string]: {
        startingDay?: boolean;
        endingDay?: boolean;
        color: string;
      };
    } = {};
    dateArray.forEach((date, index) => {
      result[date] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArray.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleChangeDate = (data: DateRange) =>
    this.setState({ dateRange: data }, () => this.fetchData());

  handleDayPress = ({ dateString }: DateData) => {
    const { fromDate, toDate } = this.state.dateRange;
    let newDateRange = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRange = { fromDate: dateString };
    } else {
      newDateRange = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format("YYYY-MM-DD"),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format("YYYY-MM-DD"),
        };
    }
    this.setState({ dateRange: newDateRange }, () => this.fetchData());
  };

  formatArabicCurrency = (inputNumber: number, fractionDigits?: number) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      maximumFractionDigits: fractionDigits || 2,
      minimumFractionDigits: fractionDigits || 0,
      currency: "SAR",
    }).format(inputNumber);

  formatPercentage = (percentage: number) => {
    const prefix = percentage > 0 ? "+" : ""
    let formatted = percentage.toFixed(2)
    formatted = parseFloat(formatted).toString()
    const [integer, decimal] = formatted.split(".")
    return `${prefix}${decimal ? formatted : integer}%`;
  }
}
// Customizable Area End
