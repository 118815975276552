// Customizable Area Start
import React, {RefObject} from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { FormikProps, FormikHelpers } from "formik";
export const configJSON = require("./config.js");
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import  i18n from "i18next";
type VendorType = {
    id: string;
    name: string,
    phoneNumber: string,
    email: string,
    location: string,
    bio: string,
    company_name: string,
    document: File | null;
};

type VendorFormErrors = {
    name: string,
    phoneNumber: string,
    email: string,
    location: string,
    bio: string,
    document: string
};

export type VendorTypeForm = Omit<VendorType, "id"> & {
  id?: string;
};

export const initialVendorFormValues: VendorTypeForm = {
  name: "",
  phoneNumber: "",
  email: "",
  location: "",
  bio: "",
  company_name: "",
  document: null
};

export interface Props {
  navigation:  {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
}
interface S {
  initialFormValue: VendorTypeForm;
  token: string;
  errorMsg: string;
  loading: boolean;
  userId: number;
  image_preview: string | ArrayBuffer;
  image?: Blob | null;
  popOpen: boolean;
  isRemoved: string;
  document?: File | null,
  showToast: boolean,
  toastMessage: string,
  userRole: string;
  toastSuccess: "error" | "warning" | "info" | "success",
  language: string;
}
interface SS {
  id: string;
}

export default class VendorProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  formikRef: RefObject<FormikProps<VendorTypeForm>>
  getVendorApiCallId: string = "";
  updateVendorApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [];
    this.formikRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationTargetMessage),
    ];

    this.state = {
      initialFormValue: initialVendorFormValues,
      token: "",
      loading: false,
      errorMsg: "",
      userId: 0,
      popOpen: false,
      image_preview: "",
      isRemoved: "no",
      showToast: false,
      toastMessage: "",
      toastSuccess: "success",
      userRole: "vendor",
      language: "en"
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const lang = await getStorageData('language') || "en";
    this.setState({language: lang}, ()=>{
      this.getToken();
      languageConvertor(lang);
    });
  }

  validateForm = (values: VendorTypeForm) => {
    const errors: Partial<VendorFormErrors> = {};
    if (!values.name || !configJSON.nameRegex.test(values.name)) { errors.name = i18n.t("VENDORFORMVALIDATION.NAME"); }
    if (!values.email || !configJSON.emailRegex.test(values.email)) { errors.email = i18n.t("VENDORFORMVALIDATION.EMAIL"); }
    if (!values.phoneNumber || values.phoneNumber.length < 7 || !configJSON.phoneRegex.test(values.phoneNumber)) { errors.phoneNumber = i18n.t("VENDORFORMVALIDATION.PHONE"); }
    if (!values.location) { errors.location = i18n.t("VENDORFORMVALIDATION.LOCATION"); }
    return errors;
  };

  handleSubmitForm(formData: VendorTypeForm) {
    this.UpdateVendore(formData as VendorType);
  }

  onDocumentUpload  = (fieldName: string, files: File[], updateValue: FormikHelpers<VendorTypeForm>["setFieldValue"], setErrors: FormikHelpers<VendorTypeForm>['setErrors']) => {
    if (files && files.length) {
      if(files[0].size > 5000000){
        setErrors({ document: i18n.t('VENDORFORMVALIDATION.DOCUMENT') });
        return;
      }
      updateValue(fieldName, files[0].name);
      if (fieldName === 'document') {
        this.setState({document: files[0]});
      }
    }
  };

  handleCloseSnackBars = () => {
    this.setState({showToast: false})
  }
  getVendoreDetails(token?: string) {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.addressesApiContentType,
      token: token || this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVendorApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getVendorEndpoint}?id=${this.state.userId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }


  UpdateVendore(updateData: VendorType) {
    this.setState({ loading: true });
    const requestBody = this.convertToFormData(updateData);

    const headers = {token: this.state.token};
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateVendorApiCallId = requestMsg.messageId;

    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.updateVendorEndpoint}?id=${this.state.userId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchMethod);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),requestBody);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }
  handleSelector = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selected = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selected != this.state.language){
        this.setState({language: selected}, ()=>{
          this.getToken();
          languageConvertor(selected);
        });
      }
    }
  }
  handleFileChnage = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (input: Event): void => {
      let target = input.target as HTMLInputElement;
      let files = target.files;
       if (files!= null && files.length > 0) {
        const file = files[0];
        const extension = file.type.split("/")[1];
        if(configJSON.fileExtension.includes(extension)){
          const reader = new FileReader();
          reader.onload = (event) => {
            if(event.target?.result){
              this.setState({
                  image_preview: event.target?.result,
                  image: file as Blob,
                  isRemoved: "no"
              });
            }
          };
          reader.readAsDataURL(file as Blob);
        }else{
          this.setState({
            showToast: true,
            toastMessage: i18n.t("VENDORFORMVALIDATION.IMAGE"),
            toastSuccess: "error"
          })
        }
      } else {
        this.setState({ image: null,  image_preview: "", isRemoved: "yes" });
      } 
    };
    input.click();
    this.popHandler();
  };

  popHandler = () => {
    this.setState({ popOpen: !this.state.popOpen });
  };

  removeProfile = () => {
    this.setState({
      image_preview: "", image: null, isRemoved: "yes"
    });
  };
  

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    this.handleSelector(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken");
      let roleInfoData = await getStorageData("roleInfoData");
      let userData = JSON.parse(roleInfoData);
      this.setState({ token: token, userId: userData.data.id, userRole: userData.data.attributes.user_type});
      this.getVendoreDetails(token);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let updateStateData = {
        loading: false,
        initialFormValue: initialVendorFormValues,
        image_preview: ""
      };
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(errorReponse || responseJson.errors){
        this.setState({
            showToast: true,
            toastMessage: errorReponse || JSON.stringify(responseJson.errors),
            toastSuccess: "error"
        });
      }
      switch (apiRequestCallId) {
        case this.getVendorApiCallId: {
          updateStateData.initialFormValue = responseJson.data
            ? {
                id: responseJson.data.id,
                name: responseJson.data.attributes.full_name,
                email: responseJson.data.attributes.email,
                company_name: responseJson.data.attributes.company_name,
                phoneNumber: responseJson.data.attributes.full_phone_number,
                location: responseJson.data.attributes.location_string,
                bio: responseJson.data.attributes.bio,
                document: responseJson.data.attributes.document
             }
            : this.state.initialFormValue;
            updateStateData.image_preview = responseJson.data.attributes.image;
          break;
        }
        case this.updateVendorApiCallId: {
          if (responseJson) {
            this.setState({
              showToast: true,
              toastMessage: i18n.t("VENDORFORMVALIDATION.SUCCESS"),
              toastSuccess: "success"
            });
            this.getVendoreDetails();
            setTimeout(() => {
              this.navigateToProfile();
            },2000)
          }
          break;
        }
        default:
          break;
      }
      this.setState(updateStateData);
    }
  }

  navigateToProfile = () => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    request.addData(getName(MessageEnum.NavigationTargetMessage), "VendorProfile");
    this.send(request);
  }

  convertToFormData = (addressData: VendorTypeForm) => {
    const formData = new FormData();
    formData.append("data[attributes][full_name]", addressData.name);
    formData.append("data[attributes][full_phone_number]", addressData.phoneNumber);
    formData.append("data[attributes][email]", addressData.email);
    formData.append("data[attributes][company_name]", addressData.company_name);
    formData.append("data[attributes][location_string]", addressData.location);
    formData.append("data[attributes][bio]", addressData.bio);
    if(this.state.document){
      formData.append("data[attributes][documents][]", this.state.document);
    }
    if(this.state.isRemoved === "yes"){
      formData.append("data[remove_photo]", this.state.isRemoved);
    }else if(this.state.image!=null){
      formData.append("data[attributes][image]", this.state.image);
    }
    return formData;
  };
}
// Customizable Area End
