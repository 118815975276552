import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";


export interface Props {
  filterStatus: (value: string) => void;
  filterTime: (value: number | string) => void;
}

interface S {
  orderStatus: string;
  orderTime: number | string;
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilterOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.InfoPageBodyMessage),
    ];
    this.state = {
      orderStatus: "",
      orderTime: 30,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let btnText = message.getData(getName(MessageEnum.InfoPageBodyMessage));
      if (btnText === "Reorder List") {
        this.setState({orderStatus:"reorder"})
      }
    }
    // Customizable Area End
  }
  handleCheckSatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ orderStatus: event.target.value });
    this.props.filterStatus(event.target.value);
  };
  handleCheckTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ orderTime: +event.target.value });
    this.props.filterTime(event.target.value);
  };
  // Customizable Area End
}
