import React from "react";
import { View } from "react-native";
import Select from "react-select";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

const configJSON = require("./config");

interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  disable: boolean;
  allowPropChange: boolean;
  value: string;
  handleChangeValue: (newCountry: {countryId: string, countryCode: string}) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dataSource: {value: string; label: string; countryCode: string}[];
  countryCodeSelected: string;
  mobileNo: string;
  token: string;
  placeHolder: string;
  disable: boolean;
  // Customizable Area End
}

interface SS {}

export default class CountryCodeSelector extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  countryCodeApiCallId: null | string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      dataSource: [],
      countryCodeSelected: "",
      mobileNo: "",
      token: "",
      placeHolder: configJSON.countryPlaceHolder,
      disable: this.props.disable,
    };
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
        <Select
        styles={{
          menuList: (base) => ({
            ...base,
            overflowX: "hidden",
            "::-webkit-scrollbar": {
              width: "2px",
            },
            "::-webkit-scrollbar-track": {
              background: "#f1f1f1"
            },
            "::-webkit-scrollbar-thumb": {
              background: "#888"
            },
          }),
        }}
          options={this.state.dataSource}
          placeholder=""
          onChange={(item) => {
            if(!item) return;
            this.props.handleChangeValue({countryId: item.value, countryCode: item.countryCode})
          }}
          value={this.state.dataSource.find(item => item.value === this.props.value)}
          isDisabled={this.state.disable}
          data-testid="countryCode"
          isSearchable={false}
          components={{
            IndicatorSeparator: this.IndicatorSeparator,
            Control: this.Control,
            IndicatorsContainer: this.IndicatorsContainer,
          }}
        />
    );
    // Customizable Area End
  }

  // Customizable Area Start
  IndicatorSeparator = () => null
  Control = (props: {children: React.ReactNode}) => <div className="control">{props.children}</div>
  IndicatorsContainer = (props: {children: React.ReactNode}) => <div className="indicator-container">{props.children}</div>

  async componentDidMount() {
    this.makeRemoteRequest();
  }

  countryCodesToDropDown = (data: {id: string; attributes: {emoji_flag: string; country_code: string}}[]) => {
    return data.map((item) => ({
      label: item.attributes.emoji_flag,
      value: item.id,
      countryCode: `+${item.attributes.country_code}`
    }));
  };

  async receive(from: String, message: Message) {
    runEngine.debugLog("Country Code", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeApiCallId != null &&
      this.countryCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      //
      if (responseJson && !responseJson.errors) {
        this.setState({
          dataSource: this.countryCodesToDropDown(responseJson.data),
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
