// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Search from "../../blocks/search/src/Search";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ChangePasswordWeb from "../../blocks/forgot-password/src/ChangePassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import DeliveryEstimator from "../../blocks/deliveryestimator/src/DeliveryEstimator";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers.web";
import Sorting from "../../blocks/sorting/src/Sorting";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import ProductList from "../../blocks/catalogue/src/ProductList.web";
import CreateProduct from "../../blocks/catalogue/src/CreateProduct.web";
import TransactionList from "../../blocks/collecttransactionfees/src/TransactionList.web";
import PayoutsList from "../../blocks/collecttransactionfees/src/PayoutsList.web";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import ContentFlag from "../../blocks/contentflag/src/ContentFlag";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TalkToUs from "../../blocks/contactus/src/TalkToUs.web";
import AddContactus from "../../blocks/contactus/src/AddContactus.web";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import CustomerNotification from "../../blocks/notifications/src/CustomerNotification.web";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories.web";
import Cfapiintegrationerp1 from "../../blocks/cfapiintegrationerp1/src/Cfapiintegrationerp1";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Analytics from "../../blocks/analytics/src/Analytics";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Cfdeliveryintegration6 from "../../blocks/cfdeliveryintegration6/src/Cfdeliveryintegration6";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OrderListManagement from "../../blocks/ordermanagement/src/OrderListManagement.web"
import OrderDetailManagement from "../../blocks/ordermanagement/src/OrderDetailManagement.web"
import TrackOrder from "../../blocks/ordermanagement/src/TrackOrder.web"
import ReturnOrders from "../../blocks/ordermanagement/src/ReturnOrder.web"
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import InventoryList from "../../blocks/inventorymanagement/src/InventoryList";
import CreateInventory from "../../blocks/inventorymanagement/src/CreateInventory";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"
import AdminDashboard from "../../blocks/dashboard/src/AdminDashboard.web"
import EmailAccountRegistration  from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import ProfileReview  from "../../blocks/email-account-registration/src/ProfileReview.web";
import CustomerList from "../../blocks/email-account-registration/src/CustomerList.web";
import VendorList from "../../blocks/email-account-registration/src/VendorList.web";
import MainLanding from "../../blocks/landingpage/src/MainLanding.web";
import UserAddress from "../../blocks/user-profile-basic/src/UserAddresses.web";
import Vendordashboard from "../../blocks/dashboard/src/VendorDashboard.web"
import VendorProfile from "../../blocks/user-profile-basic/src/VendorProfile.web";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web"
import OrderListVendor from "../../blocks/ordermanagement/src/OrderListVendor.web"
import OrderListAdmin from "../../blocks/ordermanagement/src/OrderListAdmin.web"
import ViewProfile from "../../blocks/user-profile-basic/src/ViewProfile.web";
import OrderSummary from "../../blocks/shoppingcart/src/OrderSummary.web";
import SelectAddress from "../../blocks/ordermanagement/src/SelectAddress";
import AboutUsManagement from "../../blocks/contentmanagement/src/AboutUsManagement.web"
import ContentManagement from "../../blocks/contentmanagement/src/ContentManagement.web"
import ReturnOrderTsx from "../../blocks/ordermanagement/src/ReturnOrder";
import TrackOrderTsx from "../../blocks/ordermanagement/src/TrackOrder"

const routeMap = {
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
UserAddress:{
  component:UserAddress,
 path:"/UserAddress"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Search:{
 component:Search,
path:"/Search"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ForgotPasswordWeb:{
  component:ForgotPasswordWeb,
 path:"/ForgotPassword"},
 ChangePasswordWeb:{
  component:ChangePasswordWeb,
 path:"/ChangePassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
DeliveryEstimator:{
 component:DeliveryEstimator,
path:"/DeliveryEstimator"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
ProductList:{
 component:ProductList,
path:"/ProductList"},
Product: {
  component:CreateProduct,
  path: "/Product/:navigationBarTitleText"
},
TransactionList:{
  component:TransactionList,
 path:"/TransactionList"},
 Payouts: {
  component: PayoutsList,
  path: "/Payouts"
 },
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
  component:UserProfileBasicBlock,
 path:"/UserProfileBasicBlock"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
ContentFlag:{
 component:ContentFlag,
path:"/ContentFlag"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:TalkToUs,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/notifications"},
CustomerNotification:{
 component:CustomerNotification,
path:"/notification"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
CustomerList:{
 component:CustomerList,
path:"/CustomerList"},
VendorList:{
 component:VendorList,
path:"/VendorList"},
Categoriessubcategories:{
 component:Categoriessubcategories,
 path:"/Category/:navigationBarTitleText/:subCategoryId?"},
ProductDescription:{
  component:ProductDescription,
  path:"/ProductInfo/:navigationBarTitleText/:productId?"},
Cfapiintegrationerp1:{
 component:Cfapiintegrationerp1,
path:"/Cfapiintegrationerp1"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
OrderSummary:{
  component: OrderSummary,
 path:"/OrderSummary"},
SelectAddress: {
  component: SelectAddress,
  path: "/SelectAddress"
},
ReturnItemsOrder: {
  component: ReturnOrderTsx,
  path: "/OrderItemReturn2"
},
TrackOrder: {
  component: TrackOrderTsx,
  path: "/TrackOrderItemReturn2"
},
AboutUs: {
  component: AboutUsManagement,
  path: "/AboutUs"
},
ContentManagement: {
  component: ContentManagement,
  path: "/Content/:navigationBarTitleText"
},
Analytics:{
 component:Analytics,
path:"/Analytics"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
  CustomerEmailAccountLogin: {
    component: EmailAccountLoginBlock,
    path: "/CustomerEmailAccountLogin"
  },
  AdminEmailAccountLogin: {
    component: EmailAccountLoginBlock,
    path: "/AdminEmailAccountLogin"
  },
  VendorEmailAccountLogin: {
    component: EmailAccountLoginBlock,
    path: "/VendorEmailAccountLogin"
  },
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
ProfileReview:{
  component:ProfileReview,
 path:"/ProfileReview"},
Cfdeliveryintegration6:{
 component:Cfdeliveryintegration6,
path:"/Cfdeliveryintegration6"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OrderManagement:{
 component:OrderListManagement,
path:"/OrderManagement"},
OrderDetailManagement:{
  component:OrderDetailManagement,
 path:"/OrderDetail/:navigationBarTitleText/:orderId?"},
ReturnOrders:{
  component:ReturnOrders,
 path:"/ReturnOrder/:navigationBarTitleText"},
TrackOrder:{
  component:TrackOrder,
 path:"/TrackOrder/:navigationBarTitleText"},
InventoryManagement:{
 component:InventoryList,
path:"/InventoryManagement"},
CreateInventory: {
  component:CreateInventory,
  path: "/CreateInventory"
},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
VendorDashboard:{
  component:Vendordashboard,
  path:"/vendordashboard"
},
OrderListVendor:{
component:OrderListVendor,
path:"/OrderListVendor"
},
OrderListAdmin:{
  component:OrderListAdmin,
  path:"/OrderListAdmin"
  },

Dashboard:{
    component: AdminDashboard,
    path: '/dashboard',
},
VendorProfile:{
    component: ViewProfile,
    path: '/Profile',
},
UpdateProfile:{
    component: VendorProfile,
    path: '/update-profile',
},
  Home: {
    component: MainLanding,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;