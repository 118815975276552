Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getContentEndPoint = "bx_block_content_management/footer_page_contents";
exports.aboutUsApiEndPoint = "bx_block_landingpage2/landingpage_contant?page_type=about_page&language="
exports.aboutUsApiMethod = "GET"
exports.aboutUsContentType = "application/json";
exports.productList="Product List"
exports.cancel="cancel"
exports.approved="Approved"
exports.pending="Pending"
exports.addProduct='Add Your Product'
exports.title='enter the Title'
exports.description='enter the Description'
exports.price='enter the Price'
exports.quantity='enter the Quantity'
exports.productImages='add Product Images'
exports.btnExampleTitle = "Submit";
exports.users="User's";
exports.addNewButton='Add New Product'
exports.deleteText="Are you sure you want to delete product?"
exports.delete="delete"
exports.validationsMessage="Please enter all fields"
exports.numberRegex =/^\d+$/
exports.upload ="Upload"
exports.validationApiContentType = "application/json";
exports.formData='multipart/form-data'
exports.userListUrl="https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod='GET'
exports.postMethod='POST'
exports.addDataUrl ='https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status='Staus :'
exports.success='Product Added Successfully'
exports.bannerTitle = "Discover Our Commitment to Excellence"
exports.bannerSub = "in B2B Healthcare"
exports.about = "About B2B Healthcare"
exports.aboutDes = "Your One-Stop Solution for Bulk Healthcare Procurement. From pharmaceuticals to medical equipment, find everything you need in one place with B2BHealthcare."
exports.collabTitle = "Collaborative Solutions"
exports.collabInfo = "B2B healthcare fosters collaboration among providers, insurers, and technology firms to innovate care delivery and enhance patient outcomes"
exports.dataTitle = "Data-driven Decision Making"
exports.dataInfo = "B2B healthcare relies on data analytics to drive informed decisions, optimize operations, and predict patient needs for better care delivery."
exports.regularTitle = "Regulatory Compliance"
exports.regularInfo = "B2B healthcare prioritizes adherence to strict regulations like HIPAA and GDPR to safeguard patient privacy and ensure trust in healthcare systems."
exports.qualityTitle = "1 Lakh+ Products"
exports.qualityInfo = "We maintain strict quality controls on all our partner retailers, so that you always get standard quality products."
exports.paymentTitle = "Secure Payment"
exports.paymentInfo = "100% secure and trusted payment protection"
exports.returnTitle = "Easy Return"
exports.returnInfo = "We have a new and dynamic return window policy for medicines and healthcare items. Refer FAQs section for more details."
exports.reviewName1 = "Darlene Robertson"
exports.reviewContent1 = "B2B healthcare fosters collaboration among providers, insurers, and technology firms to innovate care delivery and enhance patient outcomes"
// Customizable Area End
