import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Dropzone from 'react-dropzone';
import { fileUploadIcon } from "./assets";
import i18n from "i18next";
import Text from "../../../components/src/design-system/Typography.web";

const UploadMediaContainer = styled('div')({
  "& .drag-drop-box": {
    border: '1px dashed #A6A6A6',
    borderRadius: '0.25rem',
    minWidth: '14rem',
    height: '5.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EEE',
    margin: '0.5rem 0',
    cursor: 'pointer',
    "&.error": {
      borderColor: '#DC2626'
    }
  },
});
// Customizable Area End

import Uploadmedia3Controller, {
  Props,
  configJSON,
} from "./Uploadmedia3Controller";

export default class Uploadmedia3 extends Uploadmedia3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <UploadMediaContainer>
        <Dropzone onDrop={(acceptedFiles) => this.props.onFileUpload(acceptedFiles)}>
          {({ getRootProps, getInputProps }) => (
            <div id="uploadFileSection" className={`drag-drop-box ${this.props.fieldError ? 'error' : ''}`} {...getRootProps()}>
              {this.props.types === "vendor" ?
                <>
                  <input id="fileUploadInput" {...getInputProps()} />
                  <Text color="black" size="xs">{this.props.fileName || ''}</Text>
                  <Text color={this.props.textColor || "black"} size={this.props.textSize || "sm"} weight="bold">{this.props.text || i18n.t("DRAGDROPUPLOADFILETXT")}</Text>
                  <Text color="black" size="xs">{i18n.t("FILESIZELIMTTXT")}</Text>
                </>
              : 
              <><img src={this.props.imageUrl || fileUploadIcon} alt="Upload file" />
              <input id="fileUploadInput" {...getInputProps()} />
              <Text color="black" size="xs">{this.props.fileName || ''}</Text>
              <Text color={this.props.textColor || "black"} size={this.props.textSize || "sm"} weight="bold">{this.props.text || i18n.t("DRAGDROPUPLOADFILETXT2")}</Text>
              </>}
            </div>
          )}
        </Dropzone>
      </UploadMediaContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
