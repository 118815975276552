// Customizable Area Start
import React from "react";
import { Box, styled } from '@material-ui/core';
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import PaymentDetailController, { Props } from "./PaymentDetailController";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";
import i18n from "i18next";
export default class PaymentDetail extends PaymentDetailController {
    constructor(props: Props) {
        super(props)
    }

    render() {
        const { t } = i18n
        const { deliveryAddressId, isOrderApiRequesting } = this.props
        const { paymentDetailData, showSuccessToast, showWarningToast, orderItems } = this.state

        return (
            <PaymentDetailWrapper>
                <Typography
                    transform="uppercase"
                    font="Roboto"
                    weight="medium"
                    size="x"
                >
                    {t("PAYMENTDETAIL")}
                </Typography>
                <Box className="pending-payment-details">
                    <div>
                        <Typography>{t("ITEMSSUBTOTAL")}</Typography>
                        <Typography>
                            {this.formatCurrency(paymentDetailData.subTotal)}
                        </Typography>
                    </div>
                    <div>
                        <Typography>{t("SHIPPINGHANDLING")}</Typography>
                        <Typography>
                            {this.formatCurrency(paymentDetailData.shipping)}
                        </Typography>
                    </div>
                    <div>
                        <Typography>{t("TOTALBEFOREVAT")}</Typography>
                        <Typography>
                            {this.formatCurrency(
                                paymentDetailData.subTotal + paymentDetailData.shipping
                            )}
                        </Typography>
                    </div>
                    <div>
                        <Typography>{t("ESTIMATEDVAT")}</Typography>
                        <Typography>
                            {this.formatCurrency(paymentDetailData.vat)}
                        </Typography>
                    </div>
                    <div>
                        <Typography>{t("TOTAL")}</Typography>
                        <Typography>
                            {this.formatCurrency(paymentDetailData.total)}
                        </Typography>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography color="primary" weight="medium">
                        {t("GRANDTOTAL")}
                    </Typography>
                    <Typography color="primary" weight="medium">
                        {this.formatCurrency(paymentDetailData.total)}
                    </Typography>
                </Box>
                <CustomButton
                    dataTestId="btn-pay"
                    styles={payButtonStyle}
                    onClickHandle={() => this.handleSubmit()}
                    themes="primary"
                    isDisable={!orderItems.length || isOrderApiRequesting}
                >
                    {
                        deliveryAddressId === undefined ? t("PROCEEDTXT") : t("PAY")
                    }
                </CustomButton>
                <CustomSnackBar
                    data-test-id="CustomSnackBar"
                    open={showSuccessToast || showWarningToast}
                    onClose={this.handleCloseToast}
                    errorMessage={showSuccessToast ? t("ORDERSUCCESSTXT") : t("ADDRESSREQUIREDTXT")}
                    severity={showSuccessToast ? "success" : "error"}
                    autoHideDuration={800}
                />
            </PaymentDetailWrapper>
        )
    }
}

const PaymentDetailWrapper = styled(Box)(
    {
        background: "white",
        padding: "1rem 1rem 1.75rem",
        minWidth: 325,
        maxWidth: 400,
        height: "fit-content",
        borderRadius: 4,
        "& .pending-payment-details": {
            padding: "1.75rem 0",
            display: "flex",
            flexDirection: "column",
            gap: 12,
            "& > div": {
                display: "flex",
                justifyContent: "space-between",
            },
        },
    },
)
const payButtonStyle = {
    width: "100%",
    height: "3.5rem",
    marginTop: 24,
}
// Customizable Area End