import React, { Component } from "react";
import { Box, styled } from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";

type Props = {
    subTitle: string;
    title: string;
    details: string;
    theme: 'light' | 'dark';
    align?: 'left' | 'right' | 'center';
    classProp?: string;
    itemAlign?: 'end' | 'start' | 'center';
    children?: any;
};


const TitleBox = styled(Box)({
    "&.sectionTitle":{
        display: "flex",
        justifyContent: "center",
        maxWidth: "660px",
        margin: "0 auto 50px",
        flexDirection: "column",
        gap: "16px",
        "& > div:last-child":{
            width: "80%"
        },
        "& .subTitle":{
            fontSize: "24px",
            fontWeight: "500",
            textTransform: "capitalize"
        },
        "@media(min-width: 1024px)":{
            maxWidth: "910px",
            "& > div:nth-child(2)":{
                fontSize: "40px !important"
            }
        },
        "&.dark": {
            "& .details":{
                fontSize: "20px"
            }
        },
        "@media(max-width: 560px)":{
            "& .title":{
                fontSize: "18px"
            },
            "& .subTitle":{
                fontSize: "14px"
            },
            "& .details":{
                fontSize: "14px"
            },
            "&.dark":{
                "& .details":{
                    fontSize: "14px !important"
                }
            } 
        }
    }
});

export default class sectionTitle extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        const {subTitle, title, details, align, classProp, itemAlign, children, theme} = this.props;
        return (
            <TitleBox className={`sectionTitle ${classProp}`} style={{ textAlign: (align ? align : 'left'), alignItems: (itemAlign ? itemAlign : 'start')}}>
                    <Typography color={theme  === 'dark' ? 'white' : 'primary'} weight="semiBold" size="x"><Box className="subTitle">{subTitle}</Box></Typography>
                    <Typography color={theme  === 'dark' ? 'white' : 'black'} size="xxl" weight="bold"><Box className="title">{title}</Box></Typography>
                    <Typography color={theme  === 'dark' ? 'white' : 'charcoal'} size="sm"><Box className="details">{details}</Box></Typography>
                    {children ? children : ""}
            </TitleBox>
        );
    }
}

