Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.apiFormContentType = 'multipart/form-data';

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.signupApiEndpoint = "account_block/accounts";

exports.cancelBtnLabel = "Cancel"
exports.saveBtnLabel = "Save"
exports.customerDetails = "Customer Details"
exports.vendorDetails = "Vendor Details"
exports.status = "Status"

exports.editCustomerApiContentType = "application/json";
exports.editCustomerValidationApiMethodType = "PATCH";
exports.editCustomerAPiEndPoint = "account_block/accounts/update_profile?id=";
exports.editCustomerAPiMethod = "PATCH";
exports.editVendorParams = "?user_type=vendor"

exports.deleteCustomerAPIContentType = "application/json";
exports.deleteCustomerAPIEndPoint = "account_block/accounts/destroy_account?id=";
exports.deleteCustomerAPIMethod = "DELETE";

exports.CustomerListApiContentType = "application/json";
exports.CustomerListGetMethod = "GET";
exports.CustomerListApiEndPoint = "/account_block/accounts/users_list?user_type=customer";

exports.getVendorlistApiContentType = "application/json";
exports.getVendorListMethod = "GET";
exports.getVendorListEndPoint = "/account_block/accounts/users_list?user_type=vendor";

exports.deleteVendorAPIContentType = "application/json";
exports.deleteVendorAPIEndPoint = "account_block/accounts/destroy_account?id=";
exports.deleteVendorAPIMethod = "DELETE";
// Customizable Area End
