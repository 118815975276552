// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from 'i18next'
export const configJSON = require("./config");

type PaymentDetailData = {
    orderId: string;
    subTotal: number;
    shipping: number;
    vat: number;
    total: number;
};

export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    deliveryAddressId?: number | null;
    isOrderApiRequesting?: boolean;
    setIsOrderApiRequesting?: (value: boolean) => void
}

interface S {
    token: string;
    language: string;
    paymentDetailData: PaymentDetailData;
    orderItems: { id: number; productId: number; }[]
    showSuccessToast: boolean;
    showWarningToast: boolean;
}

interface SS {
    id: string;
}

export default class PaymentDetailController extends BlockComponent<
    Props,
    S,
    SS
> {
    orderApiCallId = ''
    paymentApiCallId = ''
    isBuyNow = false

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            token: "",
            language: "en",
            paymentDetailData: {
                orderId: "",
                subTotal: 0,
                shipping: 0,
                vat: 0,
                total: 0
            },
            orderItems: [],
            showSuccessToast: false,
            showWarningToast: false,
        }
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.InfoPageTitleMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        const token = await getStorageData("authToken");
        const lang = await getStorageData("language") || "en";
        if (token && lang) {
            languageConvertor(lang);
            this.setState({ token: token, language: lang });
        }
    }

    callOrderApi = () => {
        this.props.setIsOrderApiRequesting?.(true)
        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.apiContentType,
        };
        const requestBody = {
            data:
            {
                delivery_address_id: this.props.deliveryAddressId,
            }
        }
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.orderApiCallId = requestMsg.messageId;
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.ordersApiEndPoint}/${this.state.paymentDetailData.orderId}`);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    callAddPaymentApi = (orderId: number) => {
        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.apiContentType,
        };
        const body = { order_id: orderId }
        const paymentMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.paymentApiCallId = paymentMessage.messageId;
        paymentMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addPaymentApiEndPoint);
        paymentMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        paymentMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchApiMethod);
        paymentMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(paymentMessage.id, paymentMessage);
    }

    receive = async (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.InfoPageTitleMessage)) {
            const payload = message.getData(getName(MessageEnum.InfoPageDataMessage))
            if (payload?.type === "order") {
                this.setState({
                    paymentDetailData: {
                        orderId: payload.data.attributes.id,
                        subTotal: Number(payload.data.attributes.sub_total),
                        shipping: Number(payload.data.attributes.shipping_total),
                        vat: Number(payload.data.attributes.total_tax),
                        total: Number(payload.data.attributes.total),
                    },
                    orderItems: payload.data.attributes.order_items
                        .map((order_item: { attributes: { id: number; catalogue_id: number } }) => ({
                            id: order_item.attributes.id,
                            productId: order_item.attributes.catalogue_id
                        })),
                })
                this.isBuyNow = true
            }
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
            if (apiRequestCallId === this.orderApiCallId) {
                if (responseJson?.data?.type === 'order') this.callAddPaymentApi(responseJson.data.attributes?.id);
                else this.props.setIsOrderApiRequesting?.(false)
                return;
            }
            if (apiRequestCallId === this.paymentApiCallId) {
                this.receivePaymentApiResponse(responseJson)
                return;
            }
            if (responseJson?.data?.type === 'order' && responseJson.data.attributes?.status === "in_cart" && !this.isBuyNow) {
                this.setState({
                    paymentDetailData: {
                        orderId: responseJson.data.attributes.id,
                        subTotal: Number(responseJson.data.attributes.sub_total),
                        shipping: Number(responseJson.data.attributes.shipping_total),
                        vat: Number(responseJson.data.attributes.total_tax),
                        total: Number(responseJson.data.attributes.total),
                    },
                    orderItems: responseJson.data.attributes.order_items
                        .map((order_item: { attributes: { id: number; catalogue_id: number } }) => ({
                            id: order_item.attributes.id,
                            productId: order_item.attributes.catalogue_id
                        })),
                })
            }
        }
    }

    receivePaymentApiResponse = (responseJson?: { message?: string }) => {
        if (responseJson?.message === configJSON.addPaymentSuccessMessage) this.setState({ showSuccessToast: true });
        else this.props.setIsOrderApiRequesting?.(false)
    }

    handleSubmit = () => {
        if (this.props.deliveryAddressId === undefined) {
            this.send(this.getNavigationMessage("SelectAddress"));
            return;
        }
        if (this.props.deliveryAddressId === null) {
            this.setState({ showWarningToast: true })
        } else {
            this.callOrderApi()
        }
    }

    handleCloseToast = () => {
        if (this.state.showSuccessToast) this.navigateToOrderManagement()
        this.setState({ showSuccessToast: false, showWarningToast: false })
    }

    navigateToOrderManagement = () => {
        this.send(this.getNavigationMessage("OrderManagement"))
    }

    getNavigationMessage = (target: string) => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), target);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        return message
    }

    formatCurrency = (value?: number) => {
        const { t } = i18n
        return `${t("SARTXT")} ${value ? value.toFixed(2) : 0}`;
    };
}
// Customizable Area End