import React from "react";
import moment from "moment";
import { Box, Chip, styled } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { DateRangeCalender } from "@builder/component-library";

const getRange = (markedDates: any = {}) => {
  const dates = Object.keys(markedDates)
  const startingDay = dates.find(d => markedDates[d].startingDay)
  const endingDay = dates.find(d => markedDates[d].endingDay)
  return { startingDay, endingDay }
}

class ControlledDateRangeCalendar extends DateRangeCalender {
  constructor(props: DateRangeCalender['props']) {
    super(props)
    const { startingDay, endingDay } = getRange(props.markedDates)
    if (startingDay) this.state = { fromDate: startingDay, toDate: endingDay }

  }
}

type Props = DateRangeCalender['props'] & {
  onChange: (updateData: { fromDate?: string, toDate?: string }) => void
}
type State = {
  selected: "Day" | "Week" | "Month"
}

export default class CustomDateRangePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selected: "Day"
    }
  }

  onChangeRange = (range: "Day" | "Week" | "Month") => {
    this.setState({ selected: range as "Day" | "Week" | "Month" })
    switch (range) {
      case "Week":
        this.props.onChange({
          fromDate: moment().startOf("week").format("YYYY-MM-DD"),
          toDate: moment().endOf("week").format("YYYY-MM-DD")
        })
        break;
      case "Month":
        this.props.onChange({
          fromDate: moment().startOf("month").format("YYYY-MM-DD"),
          toDate: moment().endOf("month").format("YYYY-MM-DD")
        })
        break;
      default:
        this.props.onChange({
          fromDate: moment().format("YYYY-MM-DD"),
        })
    }
  }

  render(): React.ReactNode {
    const { startingDay, endingDay } = getRange(this.props.markedDates)
    const calenderContainerStyle = (this.props.calenderContainer || {}) as Object  
    return (
      <StyledWrapper>
        <Box display="flex" className="label">
          {`${startingDay ? moment(startingDay).format("MMM DD") : "mm/dd"} - ${endingDay ? moment(endingDay).format("MMM DD") : "mm/dd"}`}
          <KeyboardArrowDownIcon />
        </Box>
        <ControlledDateRangeCalendar
          {...this.props}
          containerStyle={{
            opacity: 0,
            overflow: "hidden"
          }}
          calenderContainer={{
            paddingTop: 40,
            left: "-35px",
            ...calenderContainerStyle,
          }}
          renderHeader={(props: any) => {

            return (
              <Box>
                <Box position="relative">
                  <Box className="header">
                    {["Day", "Week", "Month"].map((i) => (
                      <Chip
                        key={i}
                        style={{
                          flexGrow: 1,
                          background: i === this.state.selected ? "#E6EEFF" : "#F8FAFC",
                          color: i === this.state.selected ? "#2563EB" : "#64748B",
                        }}
                        label={i}
                        onClick={() => this.onChangeRange(i as "Day" | "Week" | "Month")}
                      />
                    ))}
                  </Box>
                  <Box className="current-month">
                    {moment(props[0]).format("MMM")}
                  </Box>
                </Box>
              </Box>
            )
          }}
        />
      </StyledWrapper>
    )
  }
}

const StyledWrapper = styled(Box)({
  position: "relative",
  "& > div:last-child": {
    zIndex: 1,
    "& > div:first-child":{
        "@media (max-width: 440px)": {
         left:"-100px !important",
         width:"345px"
        },
    }

  },
  "& .label": {
    display:"flex",
    position:"absolute",
    alignItems:"center",
    inset: 0, 
    fontFamily: "Roboto"
  },
  "& .header": {
    background: "#F8FAFC",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "50px",
    position: "absolute",
    width: 340,
    left: -155,
    bottom: "150%"
  },
  "& .current-month": {
    fontFamily:"Inter",
    fontSize: 14, 
    fontWeight: "bold"
  },
})

