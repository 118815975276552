// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
export const configJSON = require("./config");
import i18n from "i18next";
export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  customStyle?: {},
}

type UserData = {
  id?: string,
  company_name: string,
  vat_id: string,
  phone_number: string,
  password: string,
  company_registration_no: string,
  full_phone_number: string,
  image_preview: string | ArrayBuffer,
  image?: string,
}

export interface S {
  firstName: string;
  isEdit: boolean;
  showPassword: boolean;
  showEditProfile: boolean;
  getUserProfile: {
    id?: string
    attributes: {
      [key: string]: string,
    }
  };
  userId?: number;
  userData: UserData;
  token: string | null;
  image: Blob | null;
  language: string;
  loading: boolean;
  showToast: boolean,
  toastMessage: string,
  toastSuccess: "error" | "warning" | "info" | "success",
}

export interface SS {
  id: string;
}

export default class UserProfileBasicControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserProfileApiCallId: string = "";
  updateUserProfileApiCalled: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      language: "en",
      firstName: "",
      isEdit: false,
      showPassword: false,
      showEditProfile: false,
      getUserProfile: {
        attributes: {}
      },
      userData: {
        company_registration_no: "",
        company_name: "",
        vat_id: "",
        phone_number: "",
        password: "",
        full_phone_number: "",
        image_preview: "",
        image: "",
      },
      token: '',
      image: null,
      loading: false,
      toastMessage: "",
      toastSuccess: "success",
      showToast: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    let token = await getStorageData('authToken');
    let roleInfoData = await getStorageData("roleInfoData");
    const lang = await getStorageData('language') || "en";
    let userData = JSON.parse(roleInfoData);
    this.setState({ token: token, userId: userData.data.id,language: lang },  ()=>{
      this.handleUserProfile(token, userData.data.id);
      languageConvertor(lang);
    });
  }

  handleSelector = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selected = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selected != this.state.language){
        this.setState({language: selected, loading: true}, ()=>{
          languageConvertor(selected);
          this.setState({loading: false})
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const { getUserProfile } = this.state;
    const prevUserData = prevState?.userData;
    const userProfileAttributes = getUserProfile?.attributes;

    if (
      prevUserData &&
      prevUserData.vat_id !== userProfileAttributes?.vat_id &&
      prevUserData.company_name !== userProfileAttributes?.company_name &&
      prevUserData.phone_number !== userProfileAttributes?.phone_number &&
      prevUserData.password !== userProfileAttributes?.password &&
      prevUserData.vat_id !== userProfileAttributes?.vat_id &&
      prevUserData.full_phone_number !== userProfileAttributes?.full_phone_number &&
      prevUserData.image !== userProfileAttributes?.image &&
      prevUserData.company_registration_no !== userProfileAttributes?.company_registration_no
    ) {
      this.setState({
        userData: {
          company_name: userProfileAttributes?.company_name || '',
          phone_number: userProfileAttributes?.phone_number,
          password: userProfileAttributes?.password,
          vat_id: userProfileAttributes?.vat_id || '',
          full_phone_number: userProfileAttributes?.full_phone_number,
          image: userProfileAttributes?.image,
          image_preview: userProfileAttributes?.image,
          company_registration_no: userProfileAttributes?.company_registration_no
        },
      });
    }
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    this.handleSelector(message);
    if (
      this.getUserProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson) {
        this.setState({
          ...this.state,
          getUserProfile: responseJson.data,
        },()=>{
          this.resetData()
        });
      }
    }
    if (
      this.updateUserProfileApiCalled ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorReponse);

      if (responseJson) {
        this.setState({
          ...this.state,
          getUserProfile: responseJson.data,
          showToast: true,
          toastMessage: i18n.t("CUSTOMERSUCCESSTXT"),
          toastSuccess: "success"
        })
        this.handleUserProfile(this.state.token);
      }
    }
  };

  handleUserProfile = (token: string | null, userId?: number) => {
    if (!userId && !this.state.userId) return;
    const headers = {
      token: token || this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountsAPiEndPointUserProfile}?id=${userId || this.state.userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleEditUserProfile = (data: unknown) => {
    const { userData, token, image } = this.state;
    const headers = {
      token
    };

    const formData = new FormData();
    if (data === null) {
      formData.append("data[remove_photo]", "yes");
    } else {
      if (userData.image != userData.image_preview) {
        formData.append("data[attributes][image]", image || "");
      }
      formData.append("data[attributes][company_name]", userData?.company_name);
      formData.append(
        "data[attributes][vat_id]",
        userData.vat_id
      );
      formData.append(
        "data[attributes][company_registration_no]",
        userData.company_registration_no
      );
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserProfileApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountsAPiEndPointUpdateUserProfile}?id=${this.state.userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  setIsEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  setShowProfileEdit = () => {
    this.setState({ showEditProfile: true });
  };

  setHideProfileEdit = () => {
    this.setState({ showEditProfile: false });
  };

  setUserData = (event: { target?: { name: keyof UserData; value: UserData[keyof UserData] } }) => {
    if (event.target) this.setState({
      userData: { ...this.state.userData, [event.target.name]: event.target.value },
    });
  };

  handleFileChnage = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (input: Event) => {
      let target = input.target as HTMLInputElement
      let files = Array.from(target.files || []);
      if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          this.setState({
            userData: {
              ...this.state.userData,
              image_preview: event.target?.result || "",
            },
            image: file
          });
        };
        reader.readAsDataURL(file);
      } else {
        this.setState({ userData: { ...this.state.userData, image_preview: "", image: "" } });
      }
    };
    input.click();
    this.setShowProfileEdit();
  };

  removeProfile = () => {
    this.setState({
      userData: { ...this.state.userData, image_preview: "", image: "" },
    });
    this.handleEditUserProfile(null);
  };

  getStyle = () => {
    let pading_right = this.state.language === "ar" ? "40px" : "18px";
    return {paddingRight: pading_right}
  }
  handleCloseSnackBars = () => {
    this.setState({showToast: false});
  }
  resetData = () => {
    const { getUserProfile } = this.state;
    this.setState({
      userData: {
        id: getUserProfile?.id,
        company_name: getUserProfile?.attributes?.company_name,
        phone_number: getUserProfile?.attributes?.phone_number,
        full_phone_number: getUserProfile?.attributes?.full_phone_number,
        password: getUserProfile?.attributes?.password,
        vat_id: getUserProfile?.attributes?.vat_id,
        image: getUserProfile?.attributes?.image,
        image_preview: getUserProfile?.attributes?.image,
        company_registration_no: getUserProfile?.attributes?.company_registration_no
      },
    });
  };
}
// Customizable Area End