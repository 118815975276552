import React from "react";
// Customizable Area Start
import HelpCenterQAController, {Props, FaqsTypes} from "./HelpCenterQAController.web";
import {Accordion,AccordionSummary, AccordionDetails, Box, styled } from '@material-ui/core';
import Typography from "../../../components/src/design-system/Typography.web";
import Loader from "../../../components/src/design-system/Loader.web";
import Markup from "../../../components/src/design-system/Markup.web";
const FaqPage = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    "& *":{
        boxSizing: "border-box"
    },
    "& .question_title":{
        fontSize: "20px"
    },
    "& .ans_details":{
        fontSize: "16px",
        color: "#666",
        fontFamily: "Roboto"
    },
    "& .accordien-container": {
        boxShadow: "none",
        borderBottom: "1px solid #eee",
    },
    "& .Mui-expanded":{
        margin: "0px"
    },
    "& ..MuiAccordionSummary-root":{
        padding: "0px 24px"
    },
    "& .MuiAccordionSummary-content":{
        margin: "24px 0px",
        display: "flex",
        gap: "24px",
        "& svg":{
            width: "26px"
        }
    },
    "& .MuiAccordion-root::before":{
        display: "none"
    },
    "& .MuiAccordionDetails-root":{
        padding: "0px 24px 24px 65px"
    }
})
// Customizable Area End

export default class HelpCenterQA extends HelpCenterQAController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <FaqPage>
        {this.state.loading ? <Loader loading={this.state.loading} /> : 
        [
            this.state.faq_record.length > 0 ? this.state.faq_record.map((item: FaqsTypes) => {
                return <Accordion className="accordien-container" square expanded={this.state.activeTab === `faq-${item.id}`} onChange={() => this.handleChange(`faq-${item.id}`)}>
                    <AccordionSummary aria-controls={`panel${item.id}d-content`} id={`panel${item.id}d-header`}>
                        {this.state.activeTab === `faq-${item.id}` ? 
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 14L12 9L7 14" stroke="#333333" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> : 
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 10L12.5 15L7.5 10" stroke="#333333" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> }
                        <Typography weight="bold"><Box className="question_title">{item.attributes.question}</Box></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Markup classProps="ans_details">{item.attributes.answer}</Markup>
                    </AccordionDetails>
                </Accordion>
            }) : 'No Faqs available'
        ]
        }
    </FaqPage>
    // Customizable Area End
  }
}