import React from "react";
import {
    Box,
    styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import TrackOrderController, { Props } from "./TrackOrderController";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
const { successIcon } = require("./assets");
import i18n from "i18next";
import CustomStepper from "../../../components/src/CustomStepper";

export default class TrackOrder extends TrackOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff", borderBottom: "1px solid #A6A6A6" }}
                />
                <Wrapper>
                    <Box className="breadcrumb">
                        <Box className="breadcrumItem" onClick={() => this.navigateToPage("LandingPage")}>
                            <Typography>{i18n.t("HomeTxt")}</Typography>
                        </Box>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItem">
                                <Typography>{i18n.t("ORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItem" onClick={() => this.navigateToPage("OrderManagement")}>
                                <Typography>{i18n.t("MYORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Typography>{i18n.t("TRACKPACKAGETXT")}</Typography>
                        </>
                    </Box>
                    <Typography size="xl" weight="bold">{i18n.t("TRACKPACKAGETXT")}</Typography>
                    <Box className="step-box">
                        <Typography weight="bold" size="xl">{i18n.t("ORDEREDTEXT")}</Typography>
                        <CustomStepper steps={[{
                            label: "Order",
                            percentage: 100,
                            isCompleted: false,
                            step: 1,
                        },
                        {
                            label: "Shipped",
                            percentage: 100,
                            isCompleted: false,
                            step: 2
                        },
                        {
                            label: "Out of delivery",
                            percentage: 100,
                            isCompleted: false,
                            step: 3
                        },
                        {
                            label: "Delivered",
                            percentage: 100,
                            isCompleted: false,
                            step: 4
                        }]} />
                    </Box>
                    <Box className="item">
                        <Box className="leftPart">
                            <Box className="itemInfo">
                                <Box className="imgBox">
                                    <img src={""} alt="product-image" width={80} />
                                </Box>
                                <Box className="itemText">
                                    <Typography size="base" weight="bold">OrderName</Typography>
                                    <Typography>{i18n.t("QTYTXT")} - Order quantity</Typography>
                                    <Typography>{i18n.t("DASHBOARD.TABLEHEADER.ORDERID")} - Order Id</Typography>
                                </Box>
                            </Box>
                            <Box className="delivery">
                                <img src={successIcon} alt="success" />
                                <Typography>{i18n.t("DELIVEREDONTXTs")} Dielivery at</Typography>
                            </Box>
                        </Box>
                        <Box className="address">
                            <Typography size="sm" weight="bold">{i18n.t("SHIPPINGORDERSTXT")}</Typography>
                            <Typography>
                                {[this.state.orderDetail.deliveryAddress?.name,
                                this.state.orderDetail.deliveryAddress?.flatNumber,
                                this.state.orderDetail.deliveryAddress?.address,
                                this.state.orderDetail.deliveryAddress?.city,
                                this.state.orderDetail.deliveryAddress?.state,
                                ]
                                    .filter((address) => address)
                                    .join(", ")}
                            </Typography>
                        </Box>
                        <Box className="itemRight">
                            <CustomButton
                                onClickHandle={() => { }}
                                display={'successWithBorder'}
                                styles={{ height: "32px" }}
                                themes="dark">{i18n.t("VIEWORDERTXT")}</CustomButton>
                            <CustomButton
                                onClickHandle={() => { }}
                                display={'successWithBorder'}
                                styles={{ height: "32px" }}
                                themes="dark">{i18n.t("ARCHIVEORDERTXT")}</CustomButton>
                        </Box>
                    </Box>
                </Wrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled("div")({
    padding: "14px 50px",
    "width": "100%",
    boxSizing: "border-box",
    maxWidth: "1440px",
    margin: "auto",
    "& .breadcrumb": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem",
        textColor: "#333333",
        padding: "0.5rem 0"
    },
    "& .breadcrumItem": {
        cursor: "pointer",
    },
    "& .step-box": {
        margin: "5rem 0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection:"column",
        gap:30
    },
    "& .item": {
        display: "flex",
        justifyContent: "space-between",
        borderTop: "1px solid #00000029",
        margin: "1rem 0",
        padding: "1rem",
        "& .itemInfo": {
            display: "flex",
            gap: 10,
            "& .imgBox": {
                maxWidth: "80px",
                border: "1px solid #DBDBDB",
                overflow: "hidden",
                borderRadius: "4px",
            }
        },
        "& .itemText": {
            display: "flex",
            flexDirection: "column",
            gap: 5,
        },
        "& .itemRight": {
            display: "flex",
            flexDirection: "column",
            gap: 10,
        }
    },
    "& .leftPart": {
        display: 'flex',
        gap: 20,
        flexDirection: "column",
        "& .delivery": {
            display: "flex",
            alignItems: "center",
            gap: 10
        }
    },
    "& .address": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
    },
})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
