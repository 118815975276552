Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.apiContentType = "application/json";
exports.filterProductsApiEndpoint = "bx_block_catalogue/catalogues/filter_products";
exports.filterBrandsApiEndpoint = "bx_block_catalogue/brands/filter";
exports.searchManufacturerApiEndpoint = "bx_block_catalogue/catalogues/search_manufacturer_list";


// Customizable Area End