import React, { Component } from "react";

import {
  Box,
  AppBar,
  Tabs,
  Tab,
  styled,
} from '@material-ui/core';

interface Props {
  tabsData: any[];
  currentTab: any;
  onTabChange: any;
}


interface S {
  value: 0
}

interface SS {
}


export default class GenericTab extends Component<Props, S, SS> {

  constructor(props: Props) {
    super(props);
  }

  render() {

    const { currentTab, tabsData, onTabChange } = this.props;

    return (
      <Box sx={{ width: "100%" }}>
        <AppBarStyled position="static" color="inherit">
          <Tabs
          data-testId="tab"
            value={currentTab} onChange={(e, value) => { onTabChange(value) }} aria-label="tabs" >
            {tabsData.map((tab, index) => (
              <Tab label={tab.label} key={index} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} />
            ))}
          </Tabs>
        </AppBarStyled>
        {tabsData.map((tab, index) => (
          (tab.content && <Box
            component="div"
            role="tabpanel"
            hidden={currentTab !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}>
            {tab.content}
          </Box>)
        ))}
      </Box>
    );
  }
}

const AppBarStyled = styled(AppBar)({
  borderColor: '#fff',
  backgroundColor: '#fff',
  padding: "14px 0px",
  boxShadow: 'none',
  "& .MuiTabs-root": {
    minHeight: 'auto',
  },
  "& .MuiTab-root": {
    border: 0,
    padding: "8px 26px",
    minWidth: 'auto',
    marginRight: "15px",
    textTransform: 'inherit',
    minHeight: 'auto',
  },
  "& .MuiTabs-flexContainer":{
    display:"flex",
    "@media (max-width: 420px)": {
      justifyContent:"center"
    },
},
  "& .Mui-selected": {
    color: '#2563EB',
    backgroundColor: '#C1D3F9',
    borderRadius: '6px'
  },
  "& .MuiTabs-indicator": {
    display: 'none',
  }
});

