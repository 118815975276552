import React from "react";
// Customizable Area Start
import { Box, Grid, ThemeProvider, Tooltip, createTheme, styled } from "@material-ui/core";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
const configJSON = require("./config");
import i18n from "i18next";
import Loader from "../../../components/src/design-system/Loader.web";
// Customizable Area End
import UserProfileBasicControllerWeb, {
  Props,
} from "./UserProfileBasicController.web";
import {
  arrowBack,
  profileImage,
  editIcon,
  locationIcon,
  passIcon
} from "./assets";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Typography from "../../../components/src/design-system/Typography.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import Uploadmedia4 from "../../../blocks/uploadmedia3/src/Uploadmedia4";
import UserSidebar from "../../../blocks/navigationmenu/src/UserSidebar.web";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";
const UserProfileComponent = styled("div")({
  backgroundColor: "white",
  "& .profileMainWrapper": {
    width: "100%",
    height: "98vh",
    padding: "1vh 40px",
    display: "flex",
    gap: "1vw",
    maxWidth: "1440px",
    margin: "16px auto 200px",
    boxSizing: "border-box",
    backgroundColor: "white",
    transition: "all 0.5s"
  },
  "& .image": {
    width: "1rem",
    color: "black",
    marginLeft: "8px"
  },
  "& .arrow": {
    width: "1rem",
  },
  "& .profileWrapper": {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  "& .profileMainBox": {
    width: '100%',
    display: 'flex',
    justifyContent: "space-between",
    "@media (max-width: 420px)": {
      flexDirection: "column",
      gap: "12px"
    },
  },
  "& .profileTopWrapper": {
    zIndex: 1,
    height: "fit-content",
    maxWidth: "100%",
    backgroundColor: "white",
    borderRadius: "0.4rem",
    padding: "1rem",
    display: "flex",
    alignItems: "flex-start",
    "@media (max-width: 420px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .profileBottomWrapper": {
    height: "25rem",
    maxWidth: "100%",
    gap: "inherit",
    backgroundColor: "white",
    borderRadius: "0.4rem",
    padding: "1rem",
    display: "grid",
    gridTemplateColumns: " repeat(2, 1fr)",
    "@media (max-width: 420px)": {
      gridTemplateColumns: "none",
      height: "auto",
    },

  },
  "& .profileDesc": {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.5rem",
    marginLeft: "2rem",
    "@media (max-width: 420px)": {
      alignItems: "center",
      marginLeft: "0",
    },
  },
  "& .companyName": {
    color: "#000000",
    fontSize: "2rem",
    fontWeight: "600",
  },
  "& .text": {
    color: "#333333",
    fontSize: "1rem",
    fontWeight: "400",
  },
  "& .location": {
    display: "flex",
    gap: "1rem",
  },
  "& .editButton": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: "#2563EB14",
    textTransform: "capitalize" as "capitalize",
    position: "relative" as "relative",
    color: "#2563EB",
    whiteSpace: 'nowrap',
    textWrap: 'nowrap'
  },
  "& .textInputWrapper": {
    maxWidth: "100%",
  },
  "& .inputText": {
    color: "#000000",
    fontWeight: "500",
    fontSize: "1rem",
    display: "flex",
    gap: "0.5rem",
  },
  "& .inputIconWrapper": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
    height: "3.7rem",
    width: "88%",
    borderRadius: "0.4rem",
    marginTop: "0.5rem",
  },
  "& .passInput": {
    padding: "1.2rem",
    fontSize: "1rem",
    width: "83%",
    backgroundColor: "#EEEEEE",
    outline: "none",
    border: "none",
    borderRadius: "0.4rem",
  },
  "& .inputField": {
    fontSize: "1rem",
    width: "100%",
    backgroundColor: "#EEEEEE",
    outline: "none",
    border: "none",
    borderRadius: "0.4rem",
  },
  "& .eyeIcon": {
    width: "2rem",
  },
  "& .buttonWrapper": {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "32rem",
    gap: "2rem",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  "& .saveButton": {
    backgroundColor: "#2563EB",
    color: "#fff",
    whitespace: "nowarp",
  },
  "& .cancelButton": {
    color: "#000000",
    whitespace: "nowarp",
    height: "3rem",
    border: " 2px solid black",
    backgroundColor: "#EEEEEE"
  },
});

export default class UserProfileBasicBlock extends UserProfileBasicControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      isEdit,
      showEditProfile,
      getUserProfile,
      userData,
    } = this.state;
    const { company_name, full_phone_number, password, image, image_preview, company_registration_no } = userData;
    const pwdstyle1 = this.getStyle();
    const pwdstyle2 = webStyle.inputField;
    const pwdstyle = { ...pwdstyle1, ...pwdstyle2 }
    const BoxWrapperTooltip = () => (
      <Grid container alignItems="center" spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Box display="flex" gridColumnGap={3}>
            <Typography size="xs">{i18n.t("ISLENGTHERROR")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} spacing={2}>
          <Box display="flex" gridColumnGap={3}>
            <Typography size="xs">{i18n.t("ISCAPITALERROR")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gridColumnGap={3}>
            <Typography size="xs">{i18n.t("ISNUMBERERROR")}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" gridColumnGap={3}>
            <Typography size="xs">{i18n.t("ISLOWERERROR")}</Typography>
          </Box>
        </Grid>

      </Grid>
    )
    return (
      <>
        <UserProfileComponent>
          <Loader loading={this.state.loading} />
          <FrontHeader navigation={this.props.navigation} customStyle={{ background: "#fff" }} customStyleBox={{ background: "#fff", boxShadow: "0px 1px 5px 5px rgba(0,0,0,0.2)" }} />
          <Box className="profileMainWrapper">
            <UserSidebar navigation={this.props.navigation} />
            <Box className="profileWrapper">
              <Box className="profileTopWrapper">
                <Uploadmedia4 image_preview={image || image_preview || profileImage} isEdit={isEdit} removeProfile={this.removeProfile} showEditProfile={showEditProfile} imageSrc={""} alt={""} handleFileChnage={this.handleFileChnage} onMouseEnter={this.setShowProfileEdit} onMouseLeave={this.setHideProfileEdit} />
                <Box className="profileMainBox">
                  <Box className="profileDesc">
                    <Box className="companyName">
                      <Typography>
                        {getUserProfile?.attributes?.company_name}
                      </Typography>
                    </Box>
                    <Box className="text">
                      <Typography >
                        {getUserProfile?.attributes?.email}
                      </Typography>
                    </Box>
                    <Box className="text">
                      <Typography >
                        +{getUserProfile?.attributes?.country_code}-
                        {getUserProfile?.attributes?.phone_number}
                      </Typography>
                    </Box>
                    <Box className="location">
                      <img
                        className="image"
                        src={locationIcon}
                        alt="Location"
                      />
                      <Box className="text">
                        <Typography >{getUserProfile?.attributes?.location_string}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    {!isEdit && (
                      <CustomButton data-testid="editButton" styles={webStyle.editButton} onClickHandle={this.setIsEdit} themes="primary">
                        <img alt="" src={editIcon} />
                        <Box >
                          <Typography weight="bold">
                            {i18n.t("EDITPROFILETXT")}
                          </Typography>
                        </Box>
                      </CustomButton>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box className="profileBottomWrapper">
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium">{i18n.t("COMPANYNAMETXT")}</Typography>
                  </Box>
                  <TextInput
                    dataTestId="company_name"
                    value={company_name}
                    name="company_name"
                    disabled={!isEdit}
                    inputStyles={webStyle.inputField}
                    onValueChange={this.setUserData}
                    display="secondaryBorderless"
                  />
                </Box>
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium" >{i18n.t("VATIDTXT")}</Typography>
                  </Box>
                  <TextInput
                    dataTestId="vat_id"
                    value={userData.vat_id || ""}
                    name="vat_id"
                    disabled={!isEdit}
                    inputStyles={webStyle.inputField}
                    onValueChange={this.setUserData}
                    display="secondaryBorderless"

                  />
                </Box>
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium">
                      {i18n.t("COMPANYREGNOLABEL")}
                    </Typography>
                  </Box>
                  <TextInput
                    dataTestId="companyRegistration"
                    value={(company_registration_no || "").toString()}
                    disabled={!isEdit}
                    name="company_registration_no"
                    inputStyles={webStyle.inputField}
                    display="secondaryBorderless"
                    onValueChange={(event) => {
                      if (event?.target?.value && !/^\d+$/.test(event.target.value)) return;
                      this.setUserData({
                        target: {
                          name: "company_registration_no",
                          value: event?.target?.value || ""
                        }
                      })
                    }}
                  />
                </Box>
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium">{i18n.t("EMAILLABEL")}</Typography>
                  </Box>
                  <TextInput
                    dataTestId="Email"
                    value={getUserProfile?.attributes?.email}
                    name="email"
                    disabled
                    inputStyles={webStyle.inputField}
                    display="secondaryBorderless"
                    onValueChange={() => { }}

                  />
                </Box>
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium">{i18n.t("PHONENUMBERLABEL")}</Typography>
                  </Box>
                  <TextInput
                    dataTestId="phoneNumber"
                    name="full_phone_number"
                    value={full_phone_number}
                    disabled
                    inputStyles={webStyle.inputField}
                    onValueChange={() => { }}
                    display="secondaryBorderless"
                  />

                </Box>
                <Box className="textInputWrapper">
                  <Box className="inputText">
                    <Typography weight="medium">
                      {i18n.t("PASSWORDLABEL")}
                      <ThemeProvider theme={theme}><Tooltip
                        arrow
                        placement={window.innerWidth < 768 ? "top" : "right-start"}
                        title={BoxWrapperTooltip()}
                      >
                        <img src={passIcon} className="image" />
                      </Tooltip></ThemeProvider>

                    </Typography>
                  </Box>
                  <TextInput
                    dataTestId="password"
                    name="password"
                    display="secondaryBorderless"
                    value={password}
                    onValueChange={() => { }}
                    inputType="password"
                    inputStyles={pwdstyle}
                    disabled
                  />
                </Box>
                <Box className="textInputWrapper" />
                {isEdit && (
                  <Box className="buttonWrapper">
                    <CustomButton
                      display="secondaryWithBorder"
                      dataTestId="cancelUserProfileId"
                      onClickHandle={() => {
                        this.setIsEdit();
                        this.resetData();
                      }}
                      styles={webStyle.cancelButton}
                      themes="dark"
                    >
                      {i18n.t("CANCELTXT")}
                    </CustomButton>
                    <CustomButton themes="primary" dataTestId="editUserProfileId" onClickHandle={() => { this.setIsEdit(); this.handleEditUserProfile('') }} styles={webStyle.saveButton}>
                      {i18n.t("SAVECHANGESTXT")}
                    </CustomButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box >
          {
            this.state.showToast && <CustomSnackBar
              data-test-id="CustomSnackBarHeader"
              open
              errorMessage={this.state.toastMessage}
              onClose={this.handleCloseSnackBars}
              severity={this.state.toastSuccess}
            />
          }
        </UserProfileComponent>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: "white",
        border: "1px solid gray",
        color: "black",
        padding: "14px 7px",
        width: "80%",
        boxShadow: "0px 4px 8px 4px rgba(0,0,0,25%)",
        "@media (max-width: 768px)": {
          width: "60%",

        },
        "@media (max-width: 420px)": {
          width: "70%",
          position: "absolute",
          left: "20%",
          top: "50px"
        },

      }
    }
  }
})
const webStyle = {
  linkWrapper: {
    padding: "1vw",
    display: "flex",
    justifyContent: "space-between",
    gap: "0.6vh",
    cursor: "pointer",
    fontFamily: "Roboto",
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    backgroundColor: "#2563EB14",
    textTransform: "capitalize" as "capitalize",
    position: "relative" as "relative",
    color: "#2563EB",
    whiteSpace: 'nowrap',
    textWrap: 'nowrap'
  },
  inputField: {
    fontSize: "1rem",
    width: "100%",
    backgroundColor: "#EEEEEE",
    outline: "none",
    border: "none",
    borderRadius: "0.4rem",
  },
  cancelButton: {
    whitespace: "nowarp",
    height: "3rem",
  },
  saveButton: {
    backgroundColor: "#2563EB",
    color: "#fff",
    whitespace: "nowarp",
  },
}
// Customizable Area End
