import React, { Component } from "react";
import { tickMark } from "./assets";
import { Checkbox, styled } from '@material-ui/core';


type CheckboxProps = {
    name?: string;
    label?: string;
    checked: boolean;
    onValueChange: (event: any) => void;
    onLabelClick?: () => void;
    disabled?: boolean;
    id?: string;
    onAlert?: boolean;
};

const CheckBoxWrapper = styled('div')({
    '&.checkbox-wrapper': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.55rem',
    },
    '& .custom-checkbox': {
        width: '1.37613rem',
        height: '1.25rem',
        borderRadius: '0.375rem',
        border: '1px solid transparent',
        '&.unchecked': {
            borderColor: '#64748B',
            backgroundColor: '#fff',
            '&.error': {
                borderColor: '#DC2626',
            }
        },
        '&.checked': {
            backgroundColor: '#2563EB',
            '& img': {
                height: '18px',
                width: '100%',
            }
        }
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: '#2563EB',
    },
    '& .checkbox-label': {
        color: '#0F172A',
        fontFamily: 'Inter',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
        '&.pointer': {
            cursor: 'pointer',
        }
    }
});

export default class CustomCheckbox extends Component<CheckboxProps> {

    constructor(props: any) {
        super(props);
    }

    labelClickHandler = () => {
        if (typeof this.props.onLabelClick === 'function') {
            this.props.onLabelClick();
        } 
    };

    render(): React.ReactNode {
        const isCheckboxDisabled = this.props.disabled || false;
        
        return (
            <CheckBoxWrapper className="checkbox-wrapper">
                
                <Checkbox 
                    id={this.props.id || ''}
                    name={this.props.name || ''}
                    checked={this.props.checked}
                    onChange={(event) => this.props.onValueChange(event)}
                    icon={<span className={`custom-checkbox unchecked ${this.props.onAlert ? 'error' : ''}`}></span>}
                    checkedIcon={
                        <div className="custom-checkbox checked">
                            <img src={tickMark} alt="tick" />
                        </div>
                    }
                    disabled={isCheckboxDisabled}
                />

                {this.props.label ? <div onClick={(event) => this.labelClickHandler()} className={`checkbox-label ${this.props.onLabelClick ? 'pointer' : ''}`}>{this.props.label}</div> : ''}
                
            </CheckBoxWrapper>
        );
    }
}

