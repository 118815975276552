import React from "react";
import {
    Box,
    styled,
    Grid,
} from "@material-ui/core";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import Typography from "../../../components/src/design-system/Typography.web";
import Footer from "../../navigationmenu/src/Footer.web";
import { Rating } from '@material-ui/lab'
import AboutUsManagementController, { Props, configJSON } from "./AboutUsManagementController";
import Carousel from "react-material-ui-carousel";
const { aboutusbanner, collabImg, dataImg, regularImg, packageImg, pricingImg, calendarImg, qouteImg } = require("./assets");

export default class AboutUsManagement extends AboutUsManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <AboutUsWrapper >
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff" }}
                />
                <Box className="banner">
                    <img src={aboutusbanner} />
                    <Box className="bannerContent">
                        <Typography color="white" size="xl"><Box className="title">{this.state.aboutUsData.find(about => about.attributes.position === 1)?.attributes.sub_title}</Box></Typography>
                    </Box>
                </Box>
                <Box className="about">
                    <Box className="aboutHeader">
                        <Typography color="black" size="xxl" weight="bold" >{this.state.aboutUsData.find(about => about.attributes.position === 2)?.attributes.sub_title}</Typography>
                        <Typography color="charcoal" >{this.state.aboutUsData.find(about => about.attributes.position === 2)?.attributes.description}</Typography>
                    </Box>
                    <Grid container className="aboutBox">
                        {
                            this.state.aboutUsData.find(about => about.attributes.position === 2)?.attributes.landingpage_sub_contants.map((data, index) => {
                                return <Grid item xs={4} key={index}>
                                    <Box className="item">
                                        <img src={data.attributes.image} alt="" />
                                        <Typography color="black" size="base" weight="bold">{data.attributes.sub_title}</Typography>
                                        <Typography color="black" >{data.attributes.description}</Typography>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                </Box>
                <Box className="review">
                    <Box className="itemBox">
                        <img src={qouteImg} width={60} height={60} />
                        <Box>
                            <Carousel
                                index={this.state.indexCarousel}
                                interval={5000}
                                animation="slide"
                                indicators={false}
                                stopAutoPlayOnHover
                                swipe
                                onChange={this.handleChangeCarousel}
                            >
                                {
                                    this.state.aboutUsData.find(about => about.attributes.position === 3)?.attributes.landingpage_sub_contants.map((review, index) => {
                                        return <Box className="reviewItem" key={index}>
                                            <Typography color="white" size="base" weight="bold">{review.attributes.sub_title}</Typography>
                                            <Rating name="aaa" defaultValue={5} precision={0.5} readOnly />
                                            <Typography color="white">{review.attributes.description}</Typography>
                                        </Box>
                                    })
                                }
                            </Carousel>
                            <Box className="stick">
                                {
                                    this.state.aboutUsData.find(about => about.attributes.position === 3)?.attributes.landingpage_sub_contants.map((stick, index) => {
                                        return <Box key={index} className={`stickItem ${index === this.state.indexCarousel && "active"}`} />
                                    })
                                }</Box>
                        </Box>

                    </Box>

                </Box>
                <Box className="advantage">
                    <Grid container className="advantageBox">
                        {
                            this.state.aboutUsData.find(about => about.attributes.position === 4)?.attributes.landingpage_sub_contants.map((advantage, index) => {
                                return <Grid item xs={4} key={index} >
                                    <Box className="item">
                                        <img src={advantage.attributes.image} alt="" />
                                        <Typography color="black" size="base" weight="bold">{advantage.attributes.sub_title}</Typography>
                                        <Typography color="charcoal" >{advantage.attributes.description}</Typography>
                                    </Box>
                                </Grid>
                            })
                        }
                    </Grid>
                </Box>
                <Footer navigation={this.props.navigation}/>
            </AboutUsWrapper>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const AboutUsWrapper = styled(Box)({
    display: "flex",
    background: "white",
    flexDirection: "column",
    "& .banner": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "48px",
        "& img": {
            width: "100%",
        },
        "& .bannerContent": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            position: "absolute",
            flexDirection: "column"
        },
        "& .title": {
            "color": "#FFF",
            "textAlign": "center",
            "fontFamily": "Roboto",
            "fontSize": "48px",
            "fontStyle": "normal",
            "fontWeight": 600,
            "lineHeight": "normal",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "65vw"
        },
    },
    "& .review": {
        background: "#021131",
        "& .itemBox": {
            maxWidth: "1440px",
            margin: "auto",
            display: "flex",
            padding: "2rem 0.5rem",
            gap: 20,
            "& .reviewItem": {
                display: "flex",
                flexDirection: "column",
                gap: 10
            },
            "& .stick": {
                display: "flex",
                gap: 5,
                margin: "1rem 0",
                "& .stickItem": {
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: "#FFFFFF33"
                },
                "& .active": {
                    background: "#5BC0DE",
                    width: "20px",
                    borderRadius: "11px"
                }
            }
        }
    },
    "& .about": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .aboutHeader": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            textAlign: "center",
            gap: 20
        },
        "& .aboutBox": {
            maxWidth: "1440px",
            margin: "2rem 0",
            "& .item": {
                background: "#EEEEEE",
                borderRadius: "4px",
                padding: "4rem 1rem",
                margin: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: 20,
            }
        }
    },
    "& .advantage": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .advantageBox": {
            maxWidth: "1440px",
            margin: "2rem 0",
            "& .item": {
                borderRadius: "4px",
                border: "1px solid gray",
                padding: "2rem 1rem",
                margin: "1rem 0.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 20,
                minHeight: "200px"
            }
        }
    }
});
// Customizable Area End
