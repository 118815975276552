//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { ProductType, SimilarProductType, UserAddress } from "./ProductTypes";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import createRequestMessage from "../../utilities/src/create-request-message";


// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  productData: { name: string; price: number; description: string; images: { url: string }[] };
  isloading: boolean;
  focusedImageIndex: number;
  product_id: number;
  token: string;
  productDetail: ProductType;
  address: UserAddress[];
  adressId: string | number;
  similarProduct: SimilarProductType[];
  language: string;
  categoryId: number | string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductDescriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  createOrderApiCallId: string = '';
  productId = this.getParam("navigationBarTitleText");
  getAddressesApiCallId: string = "";
  similarProductId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.InfoPageBodyMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      isloading: false,
      productData: { name: "", price: 1, description: "", images: [{ url: "" }] },
      product_id: this.productId,
      focusedImageIndex: 0,
      token: "",
      productDetail: { name: "", id: "", images: [{ id: "", url: "" }], price: 1, sale_price: 1, highlights: "", specifications: "", warranty: "", description: "", categories: [{ id: "", name: "" }], manufacturer_name: "", sub_categories: [{ id: "", name: "", ar_category_name: "" }], stock_qty: 0 },
      address: [{ value: "", label: "" }],
      adressId: 0,
      similarProduct: [],
      language: "en",
      categoryId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    this.handleLanguage(message);
    this.handleCheckCategory(message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.createOrderApiCallId) {
        this.receiveNewOrder(message)
      }

      if (apiRequestCallId && apiRequestCallId === this.productApiItemCallId) {
        if (responseJson) {
          this.setState({ productData: responseJson.data?.attributes, productDetail: responseJson.data?.attributes });
        } else {
          this.parseApiErrorResponse(errorReponse);
        }

      }
      if (apiRequestCallId && apiRequestCallId === this.getAddressesApiCallId) {
        const address: UserAddress[] = responseJson?.data?.map((address) => {
          return {
            value: address.attributes.id,
            label: address.attributes.address,
          }
        })
        this.setState({ address: address ?? [], adressId: +responseJson?.data?.find((response) => response?.attributes.is_default)?.id });
      }
      this.transformDataSimilarProduct(apiRequestCallId, responseJson.data)
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({ language: lang }, () => {
          languageConvertor(lang);
          this.getTokenFn();
        });
      }
    }
  }
  handleCheckCategory = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let categoryId = message.getData(getName(MessageEnum.InfoPageBodyMessage));
      if (categoryId) {
        this.setState({ categoryId })
      }

    }
  }
  async componentDidMount() {
    super.componentDidMount();
    const language = await getStorageData('language') || "en";
    this.setState({ language: language }, () => {
      this.getTokenFn();
      languageConvertor(language);
    });
  }
  componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): void {
    if (oldState.product_id !== this.getParam("navigationBarTitleText")) {
      this.setState({ product_id: this.getParam("navigationBarTitleText") })
      this.getTokenFn()
    }
  }
  getTokenFn = async () => {
    let token = await getStorageData("authToken")
    this.setState({ token: token });
    this.getProductAPI()
    this.callGetAddressesApi()
    this.getSimilarProductAPI()
  };

  onGoBack = () => {
    this.props.navigation.navigate("BuildingBlocks");
  };
  getProductAPI(): boolean {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
      language: this.state.language
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.productApiItemCallId = requestMessage.messageId;
    let api_url = `${configJSON.productAPiEndPoint}${this.state.product_id}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      api_url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  callGetAddressesApi() {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
      language: this.state.language
    };

    const getAddressesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressesApiCallId = getAddressesMsg.messageId;

    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressesApiEndpoint
    );

    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(getAddressesMsg.id, getAddressesMsg);
  }
  getParam(param: "navigationBarTitleText" | "productId") {
    const parsedParam = this.props.navigation.getParam(param, "");
    return /^\d+$/.test(parsedParam) ? Number(parsedParam) : undefined;
  }
  clickImage(index: number) {
    this.setState({ focusedImageIndex: index })
  }
  handleSelectAddress(value: number | string) {
    this.setState({ adressId: value })
  }
  navigateTo = (target: string, targetId?: string) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), target);
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    targetId && request.addData(getName(MessageEnum.NavigationScreenNameMessage), targetId);
    this.send(request);
  }
  getSimilarProductAPI(): boolean {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
      language: this.state.language
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.similarProductId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.similarProductApi}${this.state.product_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  transformDataSimilarProduct = (apiId: string, data: SimilarProductType = []) => {
    if (apiId === this.similarProductId) {
      const similarProduct: SimilarProductType[] = data.map((prod) => ({
        id: parseInt(prod.id),
        title: prod.attributes.name_locale,
        image: prod.attributes.images?.[0].url,
        price: prod.attributes.price,
        description: prod.attributes.description_locale
      }))
      this.setState({ similarProduct: similarProduct });
    }
  }
  receiveNewOrder = async (message: Message) => {
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response?.data?.type === "order") {
      const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
      navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), 'SelectAddress');
      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.InfoPageDataMessage),response.data);
      navigationMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(navigationMessage)
    }
  }
  buyNow = () => {
    if (!this.state.product_id) return;
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
      language: this.state.language
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOrderApiCallId = requestMessage.messageId
    const requestBody = JSON.stringify({
      data: {
        order_items_attributes: [{
          catalogue_id: this.state.product_id,
          quantity: 1
        }]
      }
    })
    createRequestMessage({ 
      requestMessage, 
      header, 
      endPoint: configJSON.newOrderApiEndPoint, 
      method: configJSON.exampleAPiMethod, 
      body: requestBody 
    })
  }
  // Customizable Area End
}
