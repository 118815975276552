import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import React from "react";
import { sidebardata } from "./utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
interface ModSubCat { id: number, title: string, url: string }
export interface CategoryType {
  id: number,
  title: string,
  subCategory: Array<ModSubCat>
}

interface SearchHistoryType {
  id: string;
  search_word: string
}
export interface SearchResultsApi {
  id: string;
  type: string;
  attributes: {
    brand_id: number,
    name: string,
    second_name: string,
    product_name: string,
    categories: {
      id: number,
      name: string,
      ar_category_name: string
    }[]
  }
}
export interface DataSearch {
  id: string,
  name: string,
  second_name: string,
  type: string,
  isHistory: boolean
}
export type SidebarItem = {
  id: number;
  key: string;
  value: string;
  icon: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  customStyle?: {},
  headerType?: string;
  signUpData?: { isSignIn: boolean, target: string, userType: string, isFromRegistrationPage?: boolean }
  isCategory?: boolean;
  // Customizable Area Start
  customStyleBox?: {}
  allowGuestAccess?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  categories: Array<CategoryType>;
  showToast: boolean
  toastSuccess: string
  toastMessage: string
  searchHistory: Array<DataSearch>;
  searchResults: Array<DataSearch>;
  textSearch: string;
  isFocusSearch: boolean;
  anchorEl: HTMLElement | null;
  activeClass?: string;
  role?: string;
  searchBoxClass?: string
  menuOpen: boolean,
  activeIndex: number,
  userProfileSideBar?: SidebarItem[];
  language: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class FrontHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoryApiCallId: string = "";
  getSearchHistoryApiCallId: string = ""
  getSearchResultApiCallId: string = ""
  anchorRef = React.createRef()
  searchWrapperRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      categories: [],
      showToast: false,
      toastMessage: "",
      toastSuccess: "error",
      searchHistory: [],
      searchResults: [],
      textSearch: "",
      isFocusSearch: false,
      activeClass: "",
      role: "",
      searchBoxClass: "",
      menuOpen: false,
      activeIndex: 0,
      userProfileSideBar: sidebardata,
      language: "en",
      anchorEl: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.navigateToTarget = this.navigateToTarget.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.searchWrapperRef = React.createRef<HTMLDivElement>();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != undefined && lang != this.state.language) {
        this.setState({ language: lang }, () => {
          this.getToken();
          languageConvertor(lang);
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      if (responseJson?.errors) {
        this.setState({
          showToast: true,
          toastSuccess: "error",
          toastMessage: Array.isArray(responseJson.errors) ? JSON.stringify(Object.values(responseJson.errors[0])[0]) : responseJson.errors,
        });
      }
      this.setState({ loading: false });
      this.recieveCategory(message, apiRequestCallId);
      this.recieveSearchHistory(message, apiRequestCallId)
      this.receiveSearchResults(message, apiRequestCallId)
    }
    // Customizable Area End
  }

  // Customizable Area Start  

  recieveCategory = async (message: Message, apiCallId: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let category: Array<CategoryType> = [];
    if (apiCallId === this.getCategoryApiCallId) {
      for (const item of responseJson.data) {
        if (item.attributes.is_show_header) {
          let inner_item = ({ id: item.attributes.id, title: item.attributes.name_locale, subCategory: [] as Array<ModSubCat> });
          if (item.attributes.sub_categories?.data?.length > 0) {
            let subCat: Array<ModSubCat> = [];
            for (const inner_item_data of item.attributes.sub_categories.data) {
              let itemUrl = `Categoriessubcategories/${item.id}/${inner_item_data.id}`;
              subCat.push({ id: inner_item_data.attributes.id, title: inner_item_data.attributes.name_locale, url: itemUrl });
            }
            subCat.unshift({ id: inner_item.id, title: `All ${inner_item.title} Products`, url: `Categoriessubcategories/${item.id}` })
            inner_item.subCategory = subCat
          }
          category.push(inner_item);
        }
      }
      this.setState({ categories: category })
    }
  }
  recieveSearchHistory = async (message: Message, apiCallId: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiCallId === this.getSearchHistoryApiCallId) {
      const transformData = responseJson?.data?.map((search: SearchHistoryType) => {
        return {
          name: search.search_word,
          isHistory: true
        }
      })
      this.setState({ searchHistory: transformData })
    }
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  receiveSearchResults = async (message: Message, apiCallId: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiCallId === this.getSearchResultApiCallId) {
      let transformData = responseJson?.data?.map((response: SearchResultsApi) => {
        return {
          id: `ProductDescription/${response.id}`,
          name: response.attributes.product_name,
          type: response.type
        }
      })
      const emptyResult = [{ id: "", name: "No results found! Please retry", isHistory: false }]
      this.setState({
        searchResults: transformData || emptyResult,
        activeClass: "active"
      })
    }
  }

  isGuest = () => {
    return this.state.token === "" || this.state.token === null;
  }

  isSignScreens = () => {
    return this.props?.headerType && this.props?.headerType == "signin";
  }

  async componentDidMount() {
    super.componentDidMount();
    const language = await getStorageData('language') || "en";
    this.setState({ language: language }, () => {
      this.getToken();
      languageConvertor(language);
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if (token) {
      this.setState({ token: token }, () => {
        if (this.props.isCategory === undefined) {
          this.getCategories(token)
        }
      });
      const role = await getStorageData("userType")
      this.setState({ role })
    } else {
      if (!this.props.allowGuestAccess) this.navigateToTarget("EmailAccountRegistration")
    }

  };

  isValidUrl = (target: string) => {
    if (target === "") return false;
    if (target === "logout") {
      this.handleLogout()
      return false;
    }
    let tarea_regex = /^(http|https)/;
    if (tarea_regex.test(String(target).toLowerCase())) {
      if (this.isPlatformWeb()) {
        window.open(target);
      }
      return false;
    }
    return true
  }

  navigateToTarget = (target: string) => {
    if (this.isValidUrl(target)) {
      const request: Message = new Message(getName(MessageEnum.NavigationMessage));
      request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      if (target && target.indexOf("/") > 0) {
        let urlBreak = target.split("/");
        request.addData(getName(MessageEnum.NavigationTargetMessage), urlBreak[0]);
        request.addData(getName(MessageEnum.NavigationScreenNameMessage), urlBreak.length > 2 ? `${urlBreak[1]}/${urlBreak[2]}` : urlBreak[1]);
      } else {
        request.addData(getName(MessageEnum.NavigationTargetMessage), target);
      }
      this.send(request);
    }
  }

  handleLogout = () => {
    removeStorageData("authToken");
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }

  handleSignUrl = () => {
    const target = this.props.signUpData?.target || "";
    this.navigateToTarget(target);
  }


  getCategories = (token: string) => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.addressesApiContentType,
      token: token || this.state.token,
      language: this.state.language
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCategoryApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCategoryEndPoint);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }
  handleCloseSnackBars() {
    this.setState({ showToast: false })
  }

  getSearchHistory = async (token: string) => {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.addressesApiContentType,
      token: this.state.token,
    };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getSearchHistoryApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getSearchHistoryEndPoint + this.state.role);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  handleTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ textSearch: event.target.value })
  }
  handleFocusSearch = () => {
    this.setState({
      isFocusSearch: true,
      searchBoxClass: "active"
    })
    this.getSearchHistory(this.state.token)
  }
  handleClickOutside = (event: MouseEvent) => {
    if (this.searchWrapperRef.current && !this.searchWrapperRef.current.contains(event.target as Node)) {
      this.setState({ isFocusSearch: false, searchHistory: [], activeClass: "", searchBoxClass: "" });
    }
  };
  handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault()
    if (
      this.state.textSearch.length === 0
    ) {
      return;
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      language: this.state.language
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSearchResultApiCallId = requestMessage.messageId;
    let urlParams = `${this.state.textSearch}&page=1&limit=5&dropdown=true`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchResulEndPoint}${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleClickHistory = (name: string) => {
    this.setState({
      textSearch: name
    })
  }
  handleMenuOpen = () => {
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));
  }
  handleActive = (navigation: string, index: number) => {
    this.setState({
      ...this.state,
      activeIndex: index
    })
    navigation === "Logout" && this.navigateToTarget(navigation.toLowerCase())
    navigation === "Account Information" && this.navigateToTarget("UserProfileBasicBlock")
  }
  handleResize = () => {
    return { width: '100%' }
  }
  handleRaiseMessageBtn = (textBtn?: string) => {
    const request: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.InfoPageBodyMessage),
      textBtn
    );
    request.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(request);
  }
  // Customizable Area End
}
