import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import {getStorageData} from "../../../framework/src/Utilities";
import { imgBell } from "./assets";
import i18n from "i18next";

interface UserTypes {
  "id": number,
  "first_name": string | null,
  "last_name": string | null,
  "full_phone_number": string,
  "country_code": number,
  "phone_number": number,
  "email": string,
  "activated": boolean,
  "device_id": string,
  "unique_auth_id": string,
  "password_digest": string,
  "created_at": string,
  "updated_at": string,
  "user_name": string | null,
  "platform": string | null,
  "user_type": string,
  "app_language_id": string | null,
  "last_visit_at": string | null,
  "is_blacklisted": boolean,
  "suspend_until": string | null,
  "status": string | null,
  "role_id": number,
  "stripe_id":string | null,
  "stripe_subscription_id":string | null,
  "stripe_subscription_date": string | null,
  "full_name": string | null,
  "gender": string | null,
  "date_of_birth": string | null,
  "age": string | null,
  "company_name": string | null,
  "company_registration_no": number,
  "vat_id": string | null,
  "language": string | null,
  "reset_password_token": string |  null,
  "reset_password_sent_at": string | null,
  "location_string": string | null,
  "bio": string | null,
  "new_password": string
}

interface ImageTypes {
  id: number,
  url: string
}
export interface NotificationInterface {
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "notification_type": string,
      "app_url": string | null,
      "is_read": boolean,
      "read_at": string | null,
      "created_at": string,
      "updated_at": string,
      "account": UserTypes,
      "images": string,
      "created_by": UserTypes,
      "headings": string,
      "contents": string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: Array<NotificationInterface>;
  selectedData: NotificationInterface | null;
  token: string;
  anchorEl: null | Element;
  language: string;
  showToast: boolean,
  toastMessage: string,
  toastSuccess: "error" | "warning" | "info" | "success";
  loader: boolean;
  userId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getUserProfileApiCallId: string = "";
  markAllNotiAsReadCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,
      token: "",
      anchorEl: null,
      language: "en",
      showToast: false,
      toastMessage: "",
      toastSuccess: "success",
      loader: false,
      userId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.markAsRead = this.markAsRead.bind(this);
    this.deleteNotifications = this.deleteNotifications.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this)
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const lang = await getStorageData('language') || "en";
    const token = await getStorageData('authToken') || "";
    const userData = await getStorageData('roleInfoData') || "";
    const userInfo = JSON.parse(userData);
    this.setState({language: lang, token: token, userId: userInfo.data.id}, ()=>{
      this.updateDetails();
      languageConvertor(lang);
    });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.langSelect(message);
    this.receiveUserProfile(message);
    if (this.getDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.closePopover();
      this.receiveNotification(message);
    } else if (this.markAsReadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    } else if (this.deleteCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.setState({
          showToast: true,
          toastMessage: i18n.t("DELETED_SUCCESSFULLY_TXT"),
          toastSuccess: "success"
      });
      this.getNotifications();
    }
    if (this.markAllNotiAsReadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  receiveNotification = (message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.setState({data: responseJson.data})
  }

  receiveUserProfile = (message: Message) => {
    if (this.getUserProfileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    }
  }

  langSelect = (message: Message) => {
    this.setState({loader: false});
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selectedLng && selectedLng != this.state.language){
        this.setState({language: selectedLng}, ()=>{
          this.updateDetails();
          languageConvertor(selectedLng);
        });
      }
    }
  }

  updateDetails() {
    this.setState({ loader: true });

    const requestBody = new FormData();
    requestBody.append("data[attributes][language]", this.state.language);

    const headers = {token: this.state.token};
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.userEndpoint}?id=${this.state.userId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchMethod);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), requestBody);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  isReadNotification = (item: NotificationInterface) => {
    return item.attributes.is_read ? "notificationBox isRead" : "notificationBox"
  }

  handleCloseSnackBars = () => {
    this.setState({showToast: false})
  }

  iconBellProps = {
    source: imgBell,
  };

  openPopover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: NotificationInterface) => {
    this.setState({ anchorEl: event?.currentTarget, selectedData: item });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  getNotifications() {
    this.setState({loader: true, selectedData: null});
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDataCallId = getDataMsg.messageId;
    getDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.endPoint);
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      language: this.state.language
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getDataMethod);
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  markAsRead(id?:string) {
    this.setState({loader: true});
    let endpoint = configJSON.endPoint;

    if(this.state.selectedData){
      const {id} = this.state.selectedData;
      endpoint = `${configJSON.endPoint}/${id}`
    }

    const markAsReadMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markAsReadCallId = markAsReadMsg.messageId;
    markAsReadMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    markAsReadMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      language: this.state.language
      })
    );
    markAsReadMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.markAsReadMethod);
    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id?:number) 
  {
    this.setState({loader: true});
    const requested_id = this.state.selectedData?.id || id;
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteCallId = deletedMsg.messageId;
    deletedMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.endPoint}/${requested_id}`);
    deletedMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );
    deletedMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.deleteMethod);
    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat?: string) {
    function pad(s: number) {
      return s < 10 ? "0" + s : s;
    }
    if(inputFormat){
      var d =  new Date(inputFormat);
      return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
    }
  }
  markAllAsRead () {
    this.setState({loader: true});
    const markAllNotiRead = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markAllNotiAsReadCallId = markAllNotiRead.messageId;
    markAllNotiRead.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.markAllAsReadEndPoint + `${this.state.language}`);
    markAllNotiRead.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token
      })
    );
    markAllNotiRead.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchMethod);
    runEngine.sendMessage(markAllNotiRead.id, markAllNotiRead);
  }
  // Customizable Area End
}
