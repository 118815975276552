import React from "react";
import {
    Box,
    styled,
    Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import OrderListManagementController, { Props, configJSON } from "./OrderListManagementController";
import Typography from "../../../components/src/design-system/Typography.web";
import FilterOrder from "../../../blocks/filteritems/src/FilterOrder.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
const { successIcon } = require("./assets");
import TextInput from '../../../components/src/design-system/TextInput.web';
import { SearchIcon } from '../../../components/src/assets'
import CatalogueCard from "../../../blocks/catalogue/src/CatalogueCard.web";


import i18n from "i18next";

export default class OrderListManagement extends OrderListManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff"}}
                    customStyleBox={{ background: "#fff", boxShadow: "0px 1px 5px 5px rgba(0,0,0,0.2)" }}
                />
                <Wrapper>
                    <Box className="breadcrumbs">
                        <Box className="breadcrumItem" onClick={() => this.navigateTo("LandingPage")}>
                            <Typography>{i18n.t("HomeTxt")}</Typography>
                        </Box>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItem">
                                <Typography>{i18n.t("ORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Typography>{i18n.t("MYORDERSTXT")}</Typography>
                        </>
                    </Box>
                    <Box className="orderHeader">
                        <Typography size="xl" weight="bold">{i18n.t("YOURORDERSTXT")}</Typography>
                        <form action="" onSubmit={this.handleSearch}>
                            <Box className='inputContainer'>
                                <TextInput
                                    placeholder={i18n.t("SEARCHYOURORDERHERETXT")}
                                    value={this.state.textSearch}
                                    onValueChange={this.handleTextSearch}
                                    dataTestId='search'
                                    id="search"
                                />
                                <Box className='searchBox' >
                                    <Box className='searchIcon' >
                                        <CustomButton styles={{ height: "56px" }} themes='secondary' type='submit' className='searchButton' onClickHandle={this.handleSearch}>
                                            <img className='searchImg' src={SearchIcon} alt="" />
                                            <Typography>{i18n.t("SEARCHORDERSTXT")}</Typography>
                                        </CustomButton>
                                    </Box>
                                </Box>

                            </Box>
                        </form>
                    </Box>
                    <Box className="orderWrapper">
                        <FilterOrder filterStatus={this.filterStatus} filterTime={this.filterTime} />
                        {
                            this.state.orderStatus === "reorder" && <Grid container spacing={5} className="re-order">
                                {
                                    this.state.reOderList.map((order, index) => {
                                        return <Grid item key={index}>
                                            <CatalogueCard
                                                id={order.id}
                                                key={index}
                                                title={order.title}
                                                price={order.price.toString()}
                                                description={order.description}
                                                image={order.image}
                                                display="default"
                                                onClickHandle={() => {}}
                                            />
                                        </Grid>
                                    })
                                }
                            </Grid>
                        }
                        {this.state.orderStatus !== "reorder" && <Box className="orderList">
                            {
                                this.state.orders.length === 0 && <Box className="emptyOrder"><Typography>{i18n.t("NOORDERSTXT")}</Typography></Box>
                            }
                            {
                                this.state.orders.map((order, index) => {
                                    return (
                                        <Box className="item" key={index}>
                                            <Box className="itemHeader">
                                                <Box className="headerLeft">
                                                    <Box className="leftItem">
                                                        <Typography>{i18n.t("ORDERNOTXT")}</Typography>
                                                        <Typography color="gray">{order.attributes.order_number}</Typography>
                                                    </Box>
                                                    <Box className="leftItem">
                                                        <Typography>{i18n.t("DATEPLACEDTXT")}</Typography>
                                                        <Typography color="gray">{this.formatDatePlaced(order.attributes.placed_at)}</Typography>
                                                    </Box>
                                                    <Box className="leftItem">
                                                        <Typography>{i18n.t("TOTALAMOUNTTXT")}</Typography>
                                                        <Typography weight="semiBold">{i18n.t("SARTXT")} {order.attributes.total_price}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="headerBtn">
                                                    <CustomButton
                                                        onClickHandle={() => { this.navigateTo("OrderDetailManagement", order.id) }}
                                                        display={'secondaryWithBorder'}
                                                        styles={{ height: "32px" }}
                                                        themes="dark">{i18n.t("VIEWORDERTXT")}</CustomButton>
                                                    <CustomButton
                                                        onClickHandle={() => { }}
                                                        display={'secondaryWithBorder'}
                                                        styles={{ height: "32px" }}
                                                        themes="dark">{i18n.t("VIEWINVOICETXT")}</CustomButton>
                                                </Box>
                                            </Box>
                                            <Box className="itemContent">
                                                <Box>
                                                    <Box key={index} className="orderContent">
                                                        <Box className="orderInfo">
                                                            <Box className="orderImg">
                                                                <img src={order.attributes.catalogue.attributes.images?.[0].url} alt="product-image" width={80} />
                                                            </Box>
                                                            <Box className="orderText">
                                                                <Typography size="base" weight="bold">{order.attributes.catalogue.attributes.name_locale}</Typography>
                                                                <Typography>{i18n.t("QTYTXT")} - {order.attributes.quantity}</Typography>
                                                                <Typography>{i18n.t("DASHBOARD.TABLEHEADER.ORDERID")} - {order.attributes.order_number}</Typography>
                                                                {
                                                                    order.attributes.status === "cancelled" && <Box className="placed">
                                                                        <Typography color="red">{i18n.t("DASHBOARD.STATUS.CANCELLEDTXT")}</Typography>
                                                                    </Box>
                                                                }
                                                            </Box>
                                                        </Box>
                                                        <Box className="orderPrice">
                                                            <Typography color="primary" weight="bold" size="base">{i18n.t("SARTXT")}{order.attributes.total_price}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="orderStatus">
                                                        <Box className="statusBox">
                                                            {order.attributes.status === "delivered" && <Box className="deliverySuccess">
                                                                <img src={successIcon} alt="success" />
                                                                <Typography>{i18n.t("DELIVEREDONTXT")} {this.formatDatePlaced(order.attributes.delivered_at)}</Typography>
                                                            </Box>}
                                                            {order.attributes.status === "cancelled" && <Box className="deliveryCancelled">
                                                                <CustomButton
                                                                    onClickHandle={() => { }}
                                                                    display={'error'}
                                                                    styles={{ height: "32px" }}
                                                                    themes="dark">{i18n.t("ARCHIVEORDERTXT")}</CustomButton>
                                                            </Box>}
                                                        </Box>
                                                        {
                                                            order.attributes.status !== "cancelled" && <Box className="actionBox">
                                                                <Typography><p className={`actionText ${order.attributes.status !== "delivered" ? "last" : ""}`} onClick={() => this.navigateToProductDetail(order.attributes.catalogue.id)}>{i18n.t("VIEWPRODUCTTXT")}</p></Typography>
                                                                <p className="actionText" onClick={() => this.buyAgain(order.attributes)}>{i18n.t("BUYAGAINTXT")}</p>
                                                                {
                                                                    order.attributes.status === "delivered" &&
                                                                        <p className="actionText last" onClick={() => this.navigateTo("ReturnOrders", order.attributes.id.toString())}>{i18n.t("RETURNTXT")}</p>
                                                                }
                                                            </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        }
                    </Box>
                </Wrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled("div")({
    padding: "14px 50px",
    "width": "100%",
    boxSizing: "border-box",
    maxWidth: "1980px",
    margin: "auto",
    "& .breadcrumbs": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem",
        textColor: "#333333",
        padding: "0.5rem 0"
    },
    "& .breadcrumItem": {
        cursor: "pointer",
    },
    "& .orderHeader": {
        display: "flex",
        gap: "12rem",
        margin: "2rem 0",
        alignItems: "center",
        "& .inputContainer": {
            display: "flex",
            gap: 10,
            alignItems: "center",
            "& .searchBox": {
                display: "flex",
                alignSelf: "flex-end"
            },
            "& #search": {
                background: "white",
                width: "30rem"
            }
        }
    },
    "& .emptyOrder": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        color: "#77B300",
        fontSize: "1.5rem",
        fontWeight: "bold"
    },
    "& .orderWrapper": {
        display: "flex",
        gap: 20,
        width: "100%"
    },
    "& .orderList": {
        width: "100%"
    },
    "& .item": {
        width: "full",
        background: "white",
        borderRadius: "4px",
        margin: "0 0 1rem 0"
    },
    "& .itemHeader": {
        display: "flex",
        padding: "1rem",
        justifyContent: "space-between",
        borderBottom: "1px solid gray",
        "& .headerLeft": {
            display: "flex",
            gap: 30,
            "& .leftItem": {
                display: "flex",
                flexDirection: "column",
                gap: 5,
                justifyContent: "space-between"
            }
        },
        "& .headerBtn": {
            display: "flex",
            gap: 10
        }
    },
    "& .itemContent": {
        // borderBottom: "1px solid gray",
    },
    "& .orderContent": {
        padding: "1rem",
        display: "flex",
        justifyContent: "space-between",
        "& .orderInfo": {
            display: "flex",
            gap: 10,
            "& .orderText": {
                display: "flex",
                flexDirection: "column",
                gap: 5,
                "& .placed": {
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    marginTop: "1.5rem"
                }
            },
            "& .orderImg": {
                border: "1px solid #DBDBDB",
                borderRadius: "4px",
                overflow: "hidden"
            }
        }
    },
    "& .orderStatus": {
        padding: "1rem",
        display: "flex",
        justifyContent: "space-between",
        "& .statusBox": {
            "& .deliverySuccess": {
                display: "flex",
                alignItems: "center",
                gap: 5
            },
            "& .deliveryCancelled": {

            }
        },
        "& .actionBox": {
            display: "flex",
            gap: 10,
            alignItems: "center",
            "& .actionText": {
                color: "#77B300",
                padding: "9px 18px",
                cursor: "pointer",
                "&.last": {
                    border: "none"
                }
            }
        }
    },
    "& .orderItem": {
        borderBottom: "1px solid #00000029"
    }

})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
