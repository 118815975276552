import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
const image = require("./assets");
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
export const English = require("./utilities/En.json");
export const Arebic = require("./utilities/Ar.json");
export const languageConvertor = (value: string) => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: English },
      ar: { translation: Arebic }
    },
    lng: value ?? "en",
    fallbackLng: value,
    interpolation: {
      escapeValue: false,
    },
  });
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?:  {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  language: { code: string , flag: string}
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class LanguageSelectorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      language: {code: "en", flag: image.en_flag}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  
  componentDidMount = async() => {
    const getLanguage = await getStorageData("language");
    if(getLanguage){
        this.handleLanguage(getLanguage)
    }
  }
  handleLanguage = (language: string) => {
    const selectedData = {
        code: language,
        flag: image[language+'_flag']
    }
    this.setState({language: selectedData},()=>{
        setStorageData("language", language);
    });
    if(language === "ar"){
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    }else{
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }

    const to = new Message(getName(MessageEnum.NavigationMessage));
    const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.InfoPageTitleMessage),language);
    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(to);
  }
  // Customizable Area End
}
