import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { styled } from '@material-ui/core/styles';
import { Component } from "react";


type ISnackbarData = {
    open: boolean;
    onClose: () => void;
    errorMessage: string;
    severity: "error" | "warning" | "info" | "success";
    hideIcon?: boolean;
    autoHideDuration?: number;
}
const CustomSnack = styled(Snackbar)({
    customIconHidden: {
        '& .MuiAlert-icon': {
            display: 'none',
        },
    },
    addBorderLeft: {
        borderLeft: '3px solid #D97706',
        borderRadius: 5
    }
});
export default class CustomSnackBar extends Component<ISnackbarData>{
    constructor(props: ISnackbarData) {
        super(props)
    }
    render(): React.ReactNode {
        const { open, onClose, errorMessage, severity, hideIcon, autoHideDuration } = this.props;
        return (
            <CustomSnack
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={open}
                onClose={onClose}
                className={hideIcon ? "addBorderLeft" : ''}
                autoHideDuration={autoHideDuration || 2000}
            >
                <Alert severity={severity} className={hideIcon ? "customIconHidden" : ''}>
                    {errorMessage}
                </Alert>
            </CustomSnack>
        );
    }

};