const assets = require("./assets");

export const Ad1 = [
    {
        id: 1,
        title: "Hospitality Equipment",
        image: assets.advertisement1,
        url: ""
    },
    {
        id: 2,
        title: "Hospitality Equipment",
        image: assets.advertisement2,
        url: ""
    },
    {
        id: 3,
        title: "Hospitality Equipment",
        image: assets.advertisement3,
        url: ""
    }
]