import React from "react";

// Customizable Area Start
import {
    styled,
    Grid,
    Box,
    Modal,
} from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { Formik, FormikValues } from "formik";
import EditCustomerController, { Props } from "./EditCustomerController";
import Typography from "../../../components/src/design-system/Typography.web";
export const configJSON = require("./config");
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import i18n from "i18next";

// Customizable Area End

const FormWrapper = styled(Box)({
    '&.form-wrapper': {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        "@media (max-width: 420px)": {
            minWidth: "19rem",
          },
    },
    "& .footer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "1rem",
        padding: "20px 24px 0px 20px",
        borderTop: "1px solid #E2E8F0",
        marginTop: "0.5rem"
    },
    "& .header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #E2E8F0",
        padding: "24px 16px 24px 24px",
        marginBottom: "0.7rem",
        "@media (max-width: 420px)": {
            padding: "24px 16px",
          },
    },
    "& .errors": {
        color: '#DC2626',
        fontSize: '0.875rem',
        fontWeight: 400,
        marginTop: '0.5rem',
        marginLeft: '0',
    },
    "& .label-status": {
        fontSize: '1rem',
        color: '#000000',
        fontWeight: 500,
        marginBottom: "0.5rem"
    },
    "& .formContent": {
        maxHeight: "50vh",
        overflow: "scroll",
        marginBottom: "1rem",
        padding: "20px 20px 0 20px",
        maxWidth: "600px",
        "@media (max-width: 420px)": {
            padding: "0 16px",
          },
    },
    "& .MuiGrid-spacing-xs-3": {
        margin:0,
        width:"100%"
    }

})

export const statusOptions = [{ label: "Active", value: "Active" }, { label: "Inactive", value: "Inactive" }]

export default class EditCustomer extends EditCustomerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                <Modal
                    open={this.props.isOpenModal}
                    onClose={this.props.onCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <FormWrapper className="form-wrapper">
                        <Box className="header">
                            <Typography size="x" weight="semiBold">
                            {i18n.t("CUSTOMERDETAILSTXT")}
                            </Typography>
                            <Box onClick={this.props.onCloseModal} id="close-modal">
                                <CloseIcon />
                            </Box>
                        </Box>
                        <Formik
                            initialValues={this.state}
                            validate={this.validateEditCustomer}
                            onSubmit={this.onEdit}
                            innerRef={this.formikRef}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                            }: FormikValues) => {
                                return (
                                        <form onSubmit={handleSubmit} className="mainForm">
                                            <Grid container spacing={3} className="formContent">
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        name="customerName"
                                                        labelText={i18n.t("CUSTOMERNAMETXT")}
                                                        display="primary"
                                                        placeholder="david"
                                                        value={values.customerName}
                                                        onValueChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fieldError={touched.customerName && errors.customerName} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        name="companyName"
                                                        labelText={i18n.t("COMPANYNAMETXT")}
                                                        display="primary"
                                                        placeholder="builder"
                                                        value={values.companyName}
                                                        onValueChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fieldError={touched.companyName && errors.companyName} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        name="email"
                                                        labelText={i18n.t("EMAILTXT")}
                                                        display="primary"
                                                        placeholder="hello@test.com"
                                                        value={values.email}
                                                        onValueChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fieldError={touched.email && errors.email} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        name="phoneNumber"
                                                        labelText={i18n.t("PHONENUMBERTXT")}
                                                        display="primary"
                                                        placeholder="Enter your phone number"
                                                        value={values.phoneNumber}
                                                        onValueChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fieldError={touched.phoneNumber && errors.phoneNumber} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextInput
                                                        name="city"
                                                        labelText={i18n.t("CITYTXT")}
                                                        display="primary"
                                                        placeholder="London"
                                                        value={values.city}
                                                        onValueChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fieldError={touched.city && errors.city} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="label-status">{i18n.t("STATUSTXT")}</div>
                                                    <Select
                                                        name="status"
                                                        id="selectOption"
                                                        options={statusOptions}
                                                        value={statusOptions.find((option) => option.value === values.status)}
                                                        onChange={option => setFieldValue("status", option?.value)}
                                                        onBlur={handleBlur}
                                                        styles={{
                                                            control: (baseStyles) => ({
                                                                ...baseStyles,
                                                                padding: "10px",
                                                            }),
                                                        }}
                                                        components={{ IndicatorSeparator: null }}
                                                        menuPlacement="top"
                                                    />
                                                    <div className="errors">{touched.status && errors.status}</div>
                                                </Grid>
                                            </Grid>
                                            <Box className="footer">
                                                <CustomButton
                                                    styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                                    width="132px"
                                                    themes="dark"
                                                    display="secondaryBorderless"
                                                    onClickHandle={this.props.onCloseModal}
                                                    dataTestId="cancel">
                                                    {i18n.t("CANCELTXT")}
                                                </CustomButton>
                                                <CustomButton
                                                    width="132px"
                                                    styles={{ background: "#2563EB" }}
                                                    themes="dark"
                                                    display="primary"
                                                    onClickHandle={handleSubmit}
                                                    data-testid="save">
                                                    {i18n.t("SAVETXT")}
                                                </CustomButton>
                                            </Box>
                                        </form>
                                );
                            }}
                        </Formik>
                    </FormWrapper >
                </Modal>
            </>
            // Customizable Area End
        );
    }
}
