// Customizable Area Start
import React from "react";
import CategoriessubcategoriesController, {
  Props,
} from "./CategoriessubcategoriesController.web";
import { Box, styled } from "@material-ui/core";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomSelect from "../../../components/src/design-system/CustomSelect.web";
import ProductFilter from "../../../components/src/ProductFilter.web";
import Loader from "../../../components/src/Loader.web";
import CatalogueCard from "../../catalogue/src/CatalogueCard.web";
import i18n from "i18next";
import Footer from "../../navigationmenu/src/Footer.web";
export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
  }
  render(): React.ReactNode {
    const {
      sortBy,
      categoryName,
      selectedSubCategoryId,
      subCategoriesArray,
      brandsArray,
      selectedBrandIds,
      productFormsArray,
      selectedProducts,
      productItems,
      loading,
    } = this.state;

    return (
      <CategoriessubcategoriesWrapper>
        <FrontHeader
          navigation={this.props.navigation}
          customStyle={{ background: "#fff" }}
          customStyleBox={{ background: "#fff", boxShadow: "0px 1px 5px 5px rgba(0,0,0,0.2)" }}
        />
        <Box className="main-content">
          <Loader loading={loading} />
          <ProductFilter
            categoryName={categoryName}
            subCategoryOptions={subCategoriesArray}
            brandOptions={brandsArray}
            productFormOptions={productFormsArray}
            selectedSubCategoryId={selectedSubCategoryId}
            selectedBrandIds={selectedBrandIds}
            selectedProductForms={selectedProducts}
            onChangeSubCategory={this.handleChangeSubCategory.bind(this)}
            onChangeBrands={this.handleChangeBrands}
            onChangeProductForms={this.handleChangeProducts}
          />
          <Box style={this.getpwdstyle()} flexGrow={1}>
            <Box className="breadcrumbs">
              <CustomButton
                onClickHandle={() => this.goToHome()}
                themes="primary"
              >
                {i18n.t("HomeTxt")}
              </CustomButton>
              {categoryName && (
                <>
                  <Typography>&gt;</Typography>
                  <CustomButton themes='primary' onClickHandle={() => this.goToCategory()}>
                    <Typography>{categoryName}</Typography>
                  </CustomButton>
                </>
              )}
              {this.currentSubCategoryName() && (
                <>
                  <Typography>&gt;</Typography>
                  <Typography>{this.currentSubCategoryName()}</Typography>
                </>
              )}
            </Box>
            <Box className="title-section">
              <Typography weight="medium">{categoryName}</Typography>
              <Box className="sort-by">
                <Typography>{i18n.t("SORTBYTXT")}</Typography>
                <CustomSelect
                  value={sortBy}
                  options={CategoriessubcategoriesController.sortByOptions}
                />
              </Box>
            </Box>
            <Box className="product-items">
              {productItems.map((item) => (
                <CatalogueCard
                  id={Number(item.id)}
                  key={item.id}
                  title={item.title}
                  price={item.price}
                  description={item.qty}
                  image={item.image}
                  display="default"
                  onClickHandle={() => { }}
                  onClickImage={() => this.goToProductDetail(item.id)}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Footer navigation={this.props.navigation} />
      </CategoriessubcategoriesWrapper >
    );
  }
}

const CategoriessubcategoriesWrapper = styled(Box)({
  background: "white",
  height: "100vh",
  overflow: "auto",
  "& .main-content": {
    flexGrow: 1,
    display: "flex",
    padding: "2px 40px 20px",
    overflow: "auto",
    maxWidth: "1980px",
    margin: "0 auto",
    boxSizing: "border-box",
    "& .breadcrumbs": {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      "& button.MuiButtonBase-root": {
        padding: 0,
        background: "none",
        border: 0,
        color: "#333",
        fontWeight: "normal",
        minWidth: "fit-content",
        "&:last-child": {
          pointerEvents: "none",
          color: "#666"
        }
      },
      "& > div:last-of-type": {
        color: "#666 !important",
      },
    },
    "& .title-section": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "10px",
      margin: "40px 0",
      "& > div:first-of-type": {
        fontSize: "1.5rem !important",
      },
      "& > .sort-by": {
        display: "flex",
        gap: "16px",
        alignItems: "center",
        "& > div:first-of-type": {
          minWidth: "fit-content",
        },
      },
    },
    "& .product-items": {
      display: "flex",
      gap: "20px",
      flexWrap: "wrap",
      "& > .cardContainer": {
        flexShrink: 0,
        "& > img": {
          marginBottom: 20,
        },
        "& .cardFooter": {
          "& > div > .titleStyle": {
            fontWeight: "500 !important",
          },
          "& > button": {
            borderRadius: 0,
            lineHeight: "normal",
          },
        },
      },
    },
  },
});

// Customizable Area End
