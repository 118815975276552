export const HeaderBanner = require("../assets/banner.jpg");
export const categoryThumb1 = require("../assets/image_category-1.png");
export const categoryThumb2 = require("../assets/image_category-2.png");
export const categoryThumb3 = require("../assets/image_category-3.png");
export const categoryThumb4 = require("../assets/image_category-4.png");
export const categoryThumb5 = require("../assets/image_category-5.png");
export const categoryThumb6 = require("../assets/image_category-6.png");
export const product1 = require("../assets/image_new-product-1.png");
export const product2 = require("../assets/image_new-product-2.png");
export const product3 = require("../assets/image_new-product-3.png");
export const product4 = require("../assets/image_new-product-4.png");
export const product5 = require("../assets/image_new-product-5.png");
export const product6 = require("../assets/image_new-product-6.png");
export const advertisement1 = require("../assets/advertisement-1.png");
export const advertisement2 = require("../assets/advertisement-2.png");
export const advertisement3 = require("../assets/advertisement-3.png");
export const bg1 = require("../assets/bg-1.png");
export const bg2 = require("../assets/bg-2.png");
export const bg3 = require("../assets/bg-3.png");
export const featureBrand = require("../assets/brandpStyle2.jpg");
export const reliableIcon = require("../assets/info1.svg");
export const secureIcon = require("../assets/info2.svg");
export const affordable = require("../assets/info3.svg");
export const mBg1 = require("../assets/image_bg01.png");
export const mBg2 = require("../assets/image_bg02.png");
export const mSystem = require("../assets/image_desktop.png");
export const offer1 = require("../assets/image_offer-1.png");
export const offer2 = require("../assets/image_offer-2.png");
export const offer3 = require("../assets/image_offer-3.png");
export const info1 = require("../assets/icon-1.svg");
export const info2 = require("../assets/icon-2.svg");
export const info3 = require("../assets/icon-3.svg");
export const info4 = require("../assets/icon-4.svg");
export const info5 = require("../assets/icon-5.svg");
export const info6 = require("../assets/icon-6.svg");
export const quotes = require("../assets/quotes.svg");
