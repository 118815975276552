import React from 'react';
import Link from '@material-ui/core/Link';
import { Box, Drawer, List, styled, Divider, Popper, ClickAwayListener } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Typography from '../../../components/src/design-system/Typography.web';
import CustomButton from '../../../components/src/design-system/CustomButton.web';
import TextInput from '../../../components/src/design-system/TextInput.web';
import DropDownMenu from '../../../components/src/design-system/DropDownMenu.web';
import { TextLogo, OrderIcon, UserIcon, SearchIcon } from '../../../components/src/assets'
import FrontHeaderController, { Props, configJSON, SidebarItem, CategoryType } from './FrontHeaderController';
import { loginBtn, historyIcon, menubar, arrowBack, user2, user3, user4, user5, user6,returnOrderIcon } from "./assets";

import CustomSnackBar from '../../../components/src/design-system/CustomSnackBar.web';
import LanguageSelector from '../../languageoptions/src/LanguageSelector.web';
import i18n from "i18next";
import HeaderShoppingCart from '../../shoppingcart/src/HeaderShoppingCart.web';

const HeaderStyle = styled(Box)({
    position: "relative",
    zIndex: 2,
    width: "100%",
    "& *":{
        boxSizing: "border-box"
    },
    "& .primaryTextInput .MuiOutlinedInput-root": {
        "& fieldset": {
            border: 'none',
        },
    },
    "& .headerContainer": {
        width: '100% !important',
        display: 'flex',
        borderBottom: '1px solid rgb(223, 223, 223)',
        justifyContent: 'center',
        boxSizing: "border-box"
    },
    "& .menuBox": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4vw'
    },
    "& .headerBody": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '14px 40px',
        maxWidth: "1980px",
        position: 'sticky',
        width: '100%',
        flexWrap: 'wrap',
        alignItems: 'center',
        "@media (max-width: 420px)": {
            flexWrap: 'nowrap',
            position: "relative",
            alignItems: "baseline",
            height: "100px",
            justifyContent: "space-between"
        },
        "& .mobView": {
            "@media (max-width: 768px)": {
                display: "none"
            },
        },
    },
    
        "& .searchBar": {
            marginBottom: 0,
            width: "35.07%",
        "@media (max-width: 768px)": {
            marginBottom: 0,
        },
        "@media (max-width: 420px)": {
            
        },
    },
    "& .searchBarinput": {
        "@media (max-width: 420px)": {

            width: "100% !important"
        },
    },
    "& boxItem": {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '3vw',
        "@media (max-width: 768px)": {
            display: "none"
        },

    },
    "& searchIcon": {
        display: 'flex', justifyContent: 'center', marginTop: '1vh',
        alignItems: 'center',
    },
    "& .searchBox": {
        width: '48px',
        backgroundColor: 'rgba(37, 99, 235, 1)',
        borderBottomRightRadius: '4px',
        borderTopRightRadius: '4px',
        justifyContent: 'center',
        display: 'flex',
        "@media (max-width: 520px)": {
            width: "10%",
        },
    },
    "& .orderBoxContainer": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    "& .OrderBox": {
        fontWeight: 400,
        fontSize: '14px',
        color: '#333333',
        fontFamily: 'Roboto',
        marginBottom: '-10px',
        marginLeft: '-25px'
    },
    "& .cartBox": {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    "& .searchButton": {
        marginTop: '0.6rem',
        background: 'none',
        border: 'none',
        padding: "4px 8px",
        "@media (max-width : 420px )": {
            marginTop: "0",
        }
    },
    "& .searchImg": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    "& .cartImg": {
        marginRight: '1vw'
    },
    "& .DropDownContainer": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        zIndex: -1,
        position: "relative",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .inputContainer": {
        display: 'flex',
        "& >div": {
            marginTop: "0px !important",
            "&:first-child":{
                width: "100%"
            }
        },
        position: "relative",
    },
    "& .action-box": {
        display: "flex",
        gap: "20px",
        "@media (max-width: 420px)": {
            gap: "0",
        },
    },
    "& .navigation-box": {
        display: "flex",
        gap: "24px"
    },
    "& .menu-items":{
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    "& .link": {
        "textDecoration": "none",
        "padding": "13px 18px",
        "fontSize": "16px",
        "fontFamily": "Roboto",
        "borderRadius": "4px",
        "color": "#333",
        "fontWeight": 500
    },
    "& .orderBoxContainer button" :{
        padding: "0px !important"
     },
    "& .guest": {
        borderBottom: "0px",
        "& .headerBody": {
            border: "0px",
            flexWrap: "wrap"
        }
    },
    "& .signin": {
        alignItems: "center",
        background: "#fff",
        "& .headerBody": {
            justifyContent: "space-between",
            padding: "14px 40px",
            "@media (max-width: 420px)": {
                padding: "12px 16px",
                height: "100%"
            },
        }
    },
    "& #search": {
        padding: "14px 14px",
        borderRadius: "4px 0px 0px 4px"
    },
    "& .logo-width": {
        "@media (max-width: 420px)": {
            maxWidth: "140px",
        },
    },
    "& .btncheck": {
        "@media (max-width: 420px)": {
            fontSize: "16px"
        },
    }
    ,
    "& .inputContainer .searchWrapper": {
        width: '100%',
        position: "absolute",
        zIndex: 999,
        left: 0,
        top: 60,
        border: "1px solid #00000033",
        background: "#FFFFFF",
        borderRadius: "0 0 4px 4px",
        padding: "0 0 1rem 0 ",
        display: "none"
    },
    "& .searchHistoryContainer": {
        display: "flex",
        gap: 10,
        flexDirection: "column",
        margin: "0 1rem",
        padding: "0.5rem 0"
    },
    "& .history": {
        display: "flex",
        gap: 7,
        alignItems: "center",
        cursor: "pointer"
    },
    "& .subText": {
        marginLeft: "1rem",
        marginRight: "1rem",
        marginTop: "0.5rem"
    },
    "& .categories": {
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
        padding: "1rem",
        "@media (max-width: 420px)": {
            display: "grid",
        },
    },
    "& .category": {
        border: "1px solid #A6A6A6",
        borderRadius: "8px",
        "@media (max-width: 420px)": {
            marginBottom: "10px",
        },
    },
    "& .emptySearch": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "lighter",
        padding: "1rem",
    },
    "& .searchWrapper.active": {
        display: "block",
        "@media (max-width: 420px)": {
            width: "95%",
            maxWidth: "20rem",
        },
    },
    "& .searchImg.nonHistoryClass": {
        display: "none"
    },
    "& .searchResult": {
        gap: 10,
        flexDirection: "column",
        margin: "0 1rem",
        padding: "0.5rem 0",
        display: "none"
    },
    "& .searchResult.active": {
        gap: 10,
        flexDirection: "column",
        margin: "0 1rem",
        padding: "0.5rem 0",
        display: "flex"
    },
    "& .searchHistoryContainer.active": {
        display: "none",
    },
    "& .menuBarIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "inline-block",
            cursor: "pointer"
        },
    },
    "& .listgrid": {
        display: "grid",
        "& .link": {
            "padding": "9px 13px",
            fontWeight: "400"

        },
    },
    "& .sidebarHeading": {
        display: "block",
        padding: "16px",
        background: "#2563EB",
    },
    "& .sidebarCategoryText": {
        paddingInline: "19px",
        paddingTop: "16px",
    },
    "& .headerlogo": {
        "@media(max-width:420px)": {
            marginLeft: "12px"
        }
    },
    "& .imgText": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "8px 0",
        fontWeight: "500"
    },
    "& .linkWrapper": {
        padding: "1vw",
        display: "flex",
        justifyContent: "space-between",
        gap: "0.6vh",
        cursor: "pointer",
        fontFamily: "Roboto",
    },
    "@media(max-width: 1080px)":{
        "& .headerlogo img":{
            maxWidth: "120px"
        },
        "& .searchBar":{
            width: "25.07%"
        }
    },
    "@media(max-width: 800px)":{
        "& .searchBar":{
            width: "60%"
        }
    },
    "@media(max-width: 520px)":{
        "& .headerBody":{
            padding: "14px 20px"
        },
        "& .searchBar":{
            transform: "none",
            position: "absolute",
            left: 0,
            top: "50%",
            width: "calc(100% - 40px)",
            right: 0,
            margin: "auto"
        },
        "& .searchButton":{
            display: "flex",
            height: "100%"
        }
    },
    "& .boxItem": {
        "& .MuiListItemIcon-root": {
            minWidth:"40px"
        }
    }
})

export default class FrontHeader extends FrontHeaderController {
    constructor(props: Props) {
        super(props);
    }

    renderCategories = () => {
        return this.state.categories.map((item: CategoryType, index: number) => {
            return (
                item.subCategory.length > 0 ? (
                    <DropDownMenu
                        btnStyle={customeStyle.langBtn}
                        customeStyle={{ background: "#fff" }}
                        data-test-id="handleTarget"
                        handleTarget={this.navigateToTarget}
                        index={index}
                        label={item.title}
                        listData={item.subCategory}
                        upArrowIcon={<ExpandLess />}
                        downArrowIcon={<ExpandMore />}
                    />
                ) : (
                    <CustomButton styles={customeStyle.navigation} display='textButton' themes='dark' onClickHandle={() => this.navigateToTarget(`Categoriessubcategories/${item.id}`)}><Box className="link">
                        {item.title}
                    </Box></CustomButton>
                )
            );
        })
    };

    render() {
        const inputWidth = this.handleResize();
        const UserRoles = [
            {
                id: 1,
                title: i18n.t("CUSTOMERLOGINTXT"),
                url: "CustomerEmailAccountLogin"
            },
            {
                id: 2,
                title: i18n.t("VENDORLOGINTXT"),
                url: "VendorEmailAccountLogin"
            },
            {
                id: 3,
                title: i18n.t("ADMINLOGINTXT"),
                url: "AdminEmailAccountLogin"
            }
          ];
          const OrdersList = [
            <Typography weight="semiBold">{i18n.t("ORDERSTXT")}</Typography>,
            {
                id: 4,
                title: i18n.t("MYORDERSTXT"),
                url: "OrderManagement",
                icon: OrderIcon
            },
            {
                id: 5,
                title:i18n.t("REORDERLISTTXT"),
                icon: returnOrderIcon,
                url: "OrderManagement",
            }
            ]
  
        
            const accountData = [
                <Typography weight="semiBold">{i18n.t("ACCOUNTTXT")} </Typography>,
                {
                id: 1,
                title: i18n.t("MYPROFILETXT"),
                icon: UserIcon,
                url: "UserProfileBasicBlock"
                },
                {
                id: 2,
                title: i18n.t("MYADDRESSTXT"),
                icon: user2,
                url: "UserAddress"
                },
                {
                id: 5,
                title: i18n.t("NOTIFICATIONTXT"),
                icon: user5,
                url: "CustomerNotification"
                },
                {
                id: 5,
                title: i18n.t("LOGOUTTXT"),
                icon: user6,
                url: "logout"
                } 
            ]
        const DrawerList = (

            <HeaderStyle sx={{ width: 300 }} role="presentation" >
                <Box className='sidebarHeading'>
                    <CustomButton themes="dark"  onClickHandle={this.goToHome} display="textButton"><Box className='headerlogo'> <img src={TextLogo} alt="" /></Box></CustomButton>
                </Box>
                <Box className='sidebarCategoryText'>
                    <Typography weight="semiBold">
                        {i18n.t("CATEGORIESTXT")}
                    </Typography>
                </Box>

                <List className="listgrid">
                    {this.renderCategories()}
                </List>

                <Divider />

                <List>
                    {this.state.userProfileSideBar?.map((data: SidebarItem, index: number) => (
                        <Box className='linkWrapper' data-testId={`mapId${index}`} key={data.key} onClick={() => this.handleActive(data.value, index)}>
                            <Box className="imgText">
                                <img className="image" src={data.icon} alt="" />
                                <Typography weight="medium" color='charcoal'>{data.value}</Typography>
                            </Box>
                            <img className="arrow" src={arrowBack} alt="" />
                        </Box>
                    ))}
                </List>
            </HeaderStyle>
        );

        const signInScreen = [this.isSignScreens() ?
            <Box className={`headerContainer signin`} style={this.props.customStyle}>
                <Box className='headerBody'>
                    <Box className='boxItem'>
                    <CustomButton themes="dark"  onClickHandle={this.goToHome} display="textButton"><img className='logo-width' src={TextLogo} alt="" /></CustomButton>
                    </Box>

                    <Box className='action-box'>
                        <LanguageSelector navigation={this.props.navigation} />

                        {this.props.signUpData?.userType != "" && this.props.signUpData?.userType === "customer"
                            ? [(this.props.signUpData?.isSignIn) ? <CustomButton onClickHandle={this.handleSignUrl} display='primary' themes='primary'>{i18n.t("SIGNUPTXT")}</CustomButton>
                                : <CustomButton onClickHandle={this.handleSignUrl} display='primary' themes='primary' className="btncheck">{i18n.t("SIGNINTXT")}</CustomButton>]
                            : ''}
                    </Box>
                </Box>
            </Box>
            :
            <Box className={`headerContainer guest`} style={this.props.customStyle}>
                <Box className="headerBody">
                    <Box className='boxItem'>
                    <CustomButton themes="dark"  onClickHandle={this.goToHome} display="textButton"><img src={TextLogo} alt="" /></CustomButton>
                    </Box>
                    <Box className='navigation-box'>
                        <CustomButton themes="dark"  onClickHandle={this.goToHome} display="textButton"><Typography color='charcoal' size='base'>{i18n.t("HomeTxt")}</Typography></CustomButton>
                        <CustomButton themes="dark"  onClickHandle={() => this.navigateToTarget("")} display="textButton"><Typography color='charcoal' size='base'>{i18n.t("ContactTxt")}</Typography></CustomButton>
                    </Box>
                    <Box className='action-box'>
                        <DropDownMenu rightIcon={<img src={loginBtn} alt="" />} handleTarget={this.navigateToTarget} label={i18n.t("CUSTOMERLOGINTXT")} btnDisplay="primary" listData={UserRoles} upArrowIcon={<ExpandLess />} downArrowIcon={<ExpandMore />} index={0} />
                        <LanguageSelector navigation={this.props.navigation} />
                    </Box>
                </Box>
            </Box>]

        return (
            <HeaderStyle>
                {this.isGuest()|| this.props.signUpData?.isFromRegistrationPage ?
                    signInScreen
                    :
                    <>
                        <Box className='headerContainer' style={this.props.customStyle}>
                            <Box className='headerBody'>
                                <Box className='boxItem'>
                                    <img data-testId="menuBtn" className='menuBarIcon' src={menubar} onClick={this.handleMenuOpen} />
                                    <CustomButton themes="dark"  onClickHandle={this.goToHome} display="textButton"> <img src={TextLogo} alt="" /></CustomButton>
                                </Box>
                                <form className='searchBar' action="" onSubmit={this.handleSearch}>
                                    <div id='divRef' ref={this.searchWrapperRef} >
                                        <Box className='inputContainer'>
                                            <TextInput
                                                placeholder={i18n.t("CUSTOMERSEARCHPLACEHOLDER")}
                                                value={this.state.textSearch}
                                                onValueChange={this.handleTextSearch}
                                                inputStyles={{ ...inputWidth, ...headerInput }}
                                                onFocusInput={this.handleFocusSearch}
                                                dataTestId='search'
                                                id="search"
                                            />
                                            <Box className='searchBox' >
                                                <Box className='searchIcon' >
                                                    <CustomButton dataTestId='searchBtn' themes='secondary' type='submit' className='searchButton' onClickHandle={this.handleSearch}>
                                                        <img className='searchImg' src={SearchIcon} alt="" />
                                                    </CustomButton>
                                                </Box>
                                            </Box>
                                            <Box className={`searchWrapper ${this.state.searchBoxClass}`}>
                                                <Box className={`searchResult ${this.state.activeClass}`}>
                                                    {
                                                        this.state.searchResults?.map((result, index) => {
                                                            return (
                                                                <CustomButton dataTestId='searchResultBtn' onClickHandle={() => this.navigateToTarget(result.id)} styles={customeStyle.searchResultBtn} themes='dark' display='textButton'>
                                                                    <Box key={result.id} className='history'>
                                                                        <Typography size='sm' weight='normal' color='charcoal'>
                                                                            {result.name}
                                                                        </Typography>
                                                                    </Box>
                                                                </CustomButton>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                                <Box className={`searchHistoryContainer ${this.state.activeClass}`}>
                                                    {
                                                        this.state.searchHistory?.map((result, index) => {
                                                            return (
                                                                <Box key={result.id} id="searchHistory" className='history' onClick={() => this.handleClickHistory(result.name)}>
                                                                    <img className="searchImg" src={historyIcon} alt="" />
                                                                    <Typography size='sm' weight='normal' color='charcoal'>
                                                                        {result.name}
                                                                    </Typography>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                                <Box className='subText'>
                                                    <Typography size='xl'>{i18n.t("DISCOVERMORETXT")}</Typography>
                                                </Box>
                                                <Box className="categories">
                                                    {
                                                        this.state.categories.map((category, index) => {
                                                            return (
                                                                <Box className='category' key={category.id}>
                                                                    <CustomButton styles={customeStyle.navigation} display='textButton' themes='dark' onClickHandle={(event) => this.navigateToTarget(`Categoriessubcategories/${category.id}`)}>
                                                                    <Box className="link">
                                                                        <Typography size="sm" weight='normal' color='charcoal'>{category.title}
                                                                        </Typography>
                                                                    </Box>
                                                                    </CustomButton>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>
                                    </div>
                                </form>
                                <Box className='boxItem mobView'>
                                    <Box className='orderBoxContainer'>
                                        
                                        <DropDownMenu btnDisplay='textButton' btnStyle={customeStyle.secondary} handleTarget={this.navigateToTarget} handleRaiseMessage={this.handleRaiseMessageBtn} label={''} listData={OrdersList} upArrowIcon={<ExpandLess />} downArrowIcon={<ExpandMore />} rightIcon={<img src={OrderIcon} alt="Right Icon" />} index={0}>
                                            <Box className='menu-items'>
                                            <Box className='OrderBox'>{i18n.t("ORDERSTXT")}</Box>
                                            <Box className='label'>{i18n.t("MYITEMSTXT")}</Box>
                                            </Box>
                                        </DropDownMenu>
                                    </Box>
                                </Box>
                                <Box className='boxItem mobView' >
                                    <DropDownMenu btnDisplay='textButton' btnStyle={customeStyle.secondary} handleTarget={this.navigateToTarget} label={i18n.t("ACCOUNTTXT")} listData={accountData} upArrowIcon={<ExpandLess />} downArrowIcon={<ExpandMore />} rightIcon={<img src={UserIcon} alt="Right Icon" />} index={0} custompropDropdown={true} />
                                </Box>
                                <Box className='boxItem mobView' >
                                    <LanguageSelector navigation={this.props.navigation} />
                                </Box>
                                <Box className='cartBox' onClick={(event) => this.setState({ anchorEl: event.currentTarget })}>
                                    <HeaderShoppingCart navigation={this.props.navigation}/>
                                    <Typography size='sm' weight='normal' color='charcoal' children={i18n.t("CARTTXT")} />
                                </Box>
                            </Box>
                        </Box>
                        <Box className='DropDownContainer' style={this.props.customStyleBox?this.props.customStyleBox:this.props.customStyle}>
                            {this.state.categories?.map((item, index) => {
                                if (item.subCategory.length > 0) {
                                    return <DropDownMenu btnDisplay='textButton' customeStyle={customeStyle.dropDown} btnStyle={customeStyle.secondary} handleTarget={this.navigateToTarget} index={index} label={item.title} listData={item.subCategory} upArrowIcon={<ExpandLess />} downArrowIcon={<ExpandMore />} />
                                } else {
                                    return <CustomButton styles={customeStyle.navigation} display='textButton' themes='dark' onClickHandle={(event) => this.navigateToTarget(`Categoriessubcategories/${item.id}`)}><Box className="link">{item.title}</Box></CustomButton>
                                }
                            })}
                        </Box>
                        <Drawer data-testId="drawerSidebar" open={this.state.menuOpen} onClose={() => { this.setState({ menuOpen: false }) }}>
                            {DrawerList}
                        </Drawer>
                    </>}
                <CustomSnackBar
                    data-test-id="CustomSnackBarHeader"
                    open={this.state.showToast}
                    errorMessage={this.state.toastMessage}
                    onClose={() => this.handleCloseSnackBars()}
                    severity={this.state.toastSuccess as "error" | "warning" | "info" | "success"}
                />
            </HeaderStyle>
        );
    }
}
const headerInput = {
    font: 'Roboto',
    background: '#EDF0F7',
    bordarRedius: '4px',
    marginTop: "0px",
    height: "100%",
}

const customeStyle = {
    langBtn: {
        justifyContent: "space-between",
        color: "#333",
        border: "none",
        background: "transparent",
        fontWeight: "400"
    },
    secondary: {
        color: "#333"
    },
    dropDown: {
        marginTop: "132px",
        height: "247px",
        top: "0px !important"
    },
    navigation:{
        padding: 0,
        color: "#333"
    },
    searchResultBtn: {
        justifyContent: "start",
        padding: 0,
        color: "#333"
    }
}