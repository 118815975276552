import React from "react";

// Customizable Area Start
import { Box, withStyles, createStyles } from "@material-ui/core";
const assets = require("./assets");
import Typography from "../../../components/src/design-system/Typography.web";
import CustomCheckbox from "../../../components/src/design-system/CustomCheckbox.web";
import Popup from "../../../components/src/design-system/Popup.web";
import TermsConditionsUsersController, { Props } from "./TermsConditionsUsersController";
import Markup from "../../../components/src/design-system/Markup.web";
import Loader from "../../../components/src/design-system/Loader.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import i18n from "i18next";
// Customizable Area End

export class TermsConditionsUsers extends TermsConditionsUsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <Box className={classes.container} style={this.state.token ? { gap: 30 } : { backgroundImage: `url(${assets.backgroundImage})` }}>
        <FrontHeader
          allowGuestAccess
          navigation={this.props.navigation}
          headerType="signin"
          signUpData={{ target: "CustomerEmailAccountLogin", isSignIn: false, userType: "customer" }} />
        {
          this.state.token ?
            <Box className={classes.content}>
              <Box textAlign="center" marginBottom="40px">
                <Typography weight="bold" size="xl">
                  {i18n.t("TERMSCONDITION")}
                </Typography>
              </Box>
              <Markup classProps="pera">{this.state.termsConds[0].attributes.description}</Markup>
            </Box>
            :
            <>
              <Popup
                title={i18n.t("TERMSCONDITION")}
                onAccessAction={this.handleAccept}
                onCloseAction={this.HandleCancel}
                cancelBtnTxt={i18n.t("CANCEL")}
                saveBtnTxt={i18n.t("AGREE")}
                disableSaveButton={!this.state.isAccept}
            >
              {this.state.isLoading ? <Loader loading={true} /> :
              <Markup classProps="pera">{this.state.termsConds[0].attributes.description}</Markup> }
                <CustomCheckbox 
                  label={i18n.t("AGREETERMSCONDITION")}
                  checked={this.state.isAccept}
                  id="isAccept"
                  onValueChange={this.HandleCheckbox} />
                {!this.state.isAccept && <Typography color="red"><Box className="error">{i18n.t("ERRORTERMSCONDITIONS")}</Box></Typography>}
              </Popup>
            </>
        }
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = () => createStyles({
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: "100%",
    backgroundSize: "cover",
    backgroundColor: "#ffffffff",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "162px",
    "& *": {
      fontFamily: "Roboto !important",
      boxSizing: "border-box"
    },
    "@media (max-width: 420px)": {
      gap: "0px",
    },

  },
  content: {
    maxWidth: 1440,
    padding: "24px 40px",
    flexGrow: 1,
    flexShrink: 0
  },
  innerBody: {
    maxWidth: "600px",
    backgroundColor: "#fff",
    margin: "auto",
    boxShadow: "0px 0px 48px 4px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    "& .checkbox-wrapper": {
      marginTop: "32px"
    }
  },
  middle: {
    padding: "32px 40px",
  }
});
export default withStyles(styles)(TermsConditionsUsers)
// Customizable Area End
