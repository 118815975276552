import React from "react";

// Customizable Area Start
import { Box, Button, Modal } from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DataTable from "../../../components/src/DataTable.web";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
const Ulti = require("./../utility");
import i18n from "i18next";
import SortingTable from "../../../components/src/SortingTable.web";
const ORDER_LIST_SORTED_FIELD = [{ label: "Order Id", field: "order_id" }, { label: "Product Name", field: "product_name" }, { label: "Address", field: "address" },{ label: "Date", field: "date" }, { label: "Price", field: "price"}]

// Customizable Area End

import OrderListAdminController, { Props } from "./OrderListAdminController";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";
import EditOrderAdmin from "./EditOrderAdmin.web";
import Typography from "../../../components/src/design-system/Typography.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class OrderListAdmin extends OrderListAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      // Required for all blocks
      <NavigationMenu id="" navigation={this.props.navigation} pageBackgroundMode="light" handleSearch={this.handleSearch}>
        <ThemeProvider theme={theme}>
          <VendorListWrap>
            <Box className="titleWrap">
              <Box className="title">{i18n.t("ORDERTXT")}</Box>
              <Box id="orderfound">
              {this.getTableData(this.getTabStatus()).length} {i18n.t("ORDERFOUNDTXT")}
              </Box>
            </Box>

            <Box className="tabsWrap">
              <GenericTab
                currentTab={this.state.vendorCurrentTab}
                tabsData={this.state.adminTabsData}
                onTabChange={this.handleTabChange.bind(this)}
              />
                <SortingTable columnSorted={ORDER_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFn} />
              <Box className="dateRangeWrap">
                <Box className="dateRangeSize">
                  <CustomDateRangePicker
                    markingType="period"
                    displayDateFormat="MMM DD"
                    current=""
                    hidePlaceHolder2
                    markedDates={this.getMarkedDates(
                      this.state.vendorDateRange.fromDate,
                      this.state.vendorDateRange.toDate
                    )}
                    calenderContainer={{ left: "-200px"}}
                    onChange={this.handleDateRangePickerOnChange}
                    onDayPress={this.handleDateRangePickerPresDay}
                  />
                </Box>
              </Box>
            </Box>

            <DataTable
              data={this.getTableData(this.getTabStatus())} 
              rowsPerPage={this.state.vendorRowsPerPage}
              columns={this.state.adminHeaderColumns}
              onEditClick={this.openEditVendor}
              adminVendorOrder={true}
            />
            {
              this.state.isOpenEdit && <EditOrderAdmin defaultOrder={this.state.vendorDetail} isOpenModal={this.state.isOpenEdit} onCloseModal={this.closeEditVendor} />
            }
             <CustomSnackBar
            data-test-id="CustomSnackBarHeader"
            onClose={() => this.handleCloseSnackBars()}
            open={this.state.showToast}
            severity={this.state.toastSuccess as "error" | "warning" | "info" | "success"}
            errorMessage={this.state.toastMessage}
          />
          </VendorListWrap>
        </ThemeProvider>
  </NavigationMenu> 
      // Customizable Area End
    );
  }
}

const VendorListWrap = styled('div')({
  paddingTop: '40px',
  backgroundColor: 'white',
  fontFamily: 'Roboto',
  paddingBottom: '30px',
  "& .title": {
    fontWeight: 'bold',
    letterSpacing: '2px',
    fontSize: '24px',
    marginRight: 10,
  },
  "& #customerFound": {
    fontWeight: 400,
    lineHeight: '32px',
    fontSize: '16px',
  },
  "& .tabsWrap": {
    display: 'flex',
    marginBottom: 30,
    justifyContent: 'space-between',
    padding: '0 10px',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '6px',
    "@media (max-width: 420px)": {
      flexDirection:"column",
      padding: "16px 10px",
    },
  },
  "& .titleWrap": {
    display: 'flex',
    marginBottom: 20,
    fontSize: 25,
  },
  "& .dateRangeSize": {
    maxWidth: 150,
    "@media (max-width: 420px)": {
      margin: "0 auto"
    },
  },
  "& .dateRangeWrap": {
    display: 'flex',
    borderRadius: 8,
    padding: "0px 15px 0px 35px",
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    "@media (max-width: 420px)": {
      width:"100%",
      padding: "0",
    },
  },

  "& .MuiPaper-elevation1": {
    boxShadow: 'none'
  },
});
const ModalStyled = styled(Modal)({
  "& .deleteWrap": {
    backgroundColor: '#fff',
    width: '300px',
    padding: '20px 20px 10px',
    textAlign: 'center',
    margin: '200px auto',
    },
  "& .btnCancel": {
    marginRight: '10px',
    color: 'white',
    backgroundColor: '#2563EB',
  },
  "& .btnDelete": {
    color: 'white',
    backgroundColor: 'red',
  },
});