export const tabsEnum = [{label: 'All Products'},{label: 'Refundable'}, {label: 'Non-Refundable'}]

export const productColums = [
    { id: 'name', label: 'Product Name' },
    { id: 'category', label: 'Category' },
    { id: 'sku', label: 'SKU' },
    { id: 'incomming', label: 'Incoming' },
    { id: 'commited', label: 'Commited' },
    { id: 'numberAvailable', label: 'Available' },
    { id: 'refun_string', label: 'Refund'},
];

