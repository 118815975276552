Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.mapDataTab = [
    'All',
    'Active',
    'Inactive',
]

exports.tabsData = [
    { label: 'All' },
    { label: 'Active' },
    { label: 'Inactive' },
];

exports.columns = [
    { id: 'companyName', label: 'Company' },
    { id: 'email', label: 'Email' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'city', label: 'City' },
    { id: 'status', label: 'Status', renderFunction: 'renderRowStatus' },
];


exports.vendorColumns = [
    { id: 'id', label: 'Vendor ID',  renderFunction: 'renderRowId'  },
    { id: 'name', label: 'Vendor Name'},
    { id: 'contact', label: 'Contact Person', renderFunction: 'renderRowHaveImage' },
    { id: 'email', label: 'Email' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'status', label: 'Status', renderFunction: 'renderRowStatus' },
];