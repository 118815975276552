import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { DoReturnResponse, ReasonOptions, ReasonOptionsApiData, ReasonOptionsApiResponse, ReturnItemApiResponse, } from "./types";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
export const configJSON = require("./config");
export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    id: string;
}

interface S {
    token: string;
    returnItem: Partial<ReturnItemApiResponse>;
    language: string;
    orderId?: number;
    reason: ReasonOptions[];
    reasonId?: number;
    showToast: boolean;
    toastType: "success" | "error";
    toastMessage: string;
    errorColor: string;
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReturnOrderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    returnOrderApiId = "";
    reasonApiId = "";
    doReturnApiId = "";
    orderId = this.getOrderId("navigationBarTitleText");
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.doReturnItemApi = this.doReturnItemApi.bind(this)
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            token: "",
            returnItem: {},
            language: "en",
            orderId: this.orderId,
            reason: [],
            showToast: false,
            toastType: "success",
            toastMessage: "",
            errorColor:""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if (lang != undefined && lang != this.state.language) {
                this.setState({ language: lang }, () => {
                    languageConvertor(lang);
                    this.getReturnOrderItemApi()
                    this.getReasonApi()
                });
            }
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (apiRequestCallId && apiRequestCallId === this.returnOrderApiId) {
                if (responseJson) {
                    this.setState({ returnItem: responseJson.data })
                }
                if (errorReponse) {
                    this.parseApiErrorResponse(errorReponse);
                }
            }
            this.processReturnReasonApi(apiRequestCallId, responseJson)
            this.processDoReturnItemApi(apiRequestCallId, responseJson)
        }

        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();

        const lang = await getStorageData("language") || "en";
        if (lang) {
            languageConvertor(lang);
            this.setState({ language: lang }, async () => {
                await this.getTokenFn();
                this.getReturnOrderItemApi()
                this.getReasonApi()
            });
        }
    }

    getTokenFn = async () => {
        let token = await getStorageData("authToken")
        this.setState({ token: token });
    };

    getReturnOrderItemApi() {
        const header = {
            "Content-Type": configJSON.OrderListVendorContentType,
            token: this.state.token,
            language: this.state.language
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.returnOrderApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.showOrderItemEndPoint + this.state.orderId
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOrderListMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getReasonApi() {
        const header = {
            "Content-Type": configJSON.OrderListVendorContentType,
            token: this.state.token,
            language: this.state.language
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.reasonApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.reasonEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOrderListMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    doReturnItemApi() {
        if(!this.state.reasonId) {
            this.setState({errorColor:"red"})
            return
        }
        const header = {
            "Content-Type": configJSON.OrderListVendorContentType,
            token: this.state.token,
            language: this.state.language
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.doReturnApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.returnOrderApiEndPoint + `?id=${this.state.orderId}` + `&return_order_reason_id=${this.state.reasonId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.returnItemMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    getOrderId(param: "navigationBarTitleText" | "orderId") {
        const parsedParam = this.props.navigation.getParam(param);
        return /^\d+$/.test(parsedParam) ? Number(parsedParam) : undefined;
    }
    navigateToPage = (target: string, targetId?: string) => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), target);
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        targetId && request.addData(getName(MessageEnum.NavigationScreenNameMessage), targetId);
        this.send(request);
    }
    handleCloseSnackBar = () => this.setState({ showToast: false })
    handleSelectReason = (reasonId?: number) => {
        this.setState({ reasonId: reasonId })
    }
    processReturnReasonApi = (apiId: string, reasonResponse: ReasonOptionsApiData) => {
        if (apiId === this.reasonApiId) {
            this.setState({ reason: reasonResponse.return_order_reason_list.map((reasonApi: ReasonOptionsApiResponse) => { return { value: reasonApi.id, label: reasonApi.reason_locale } })})
        }
    }
    processDoReturnItemApi = (apiId: string, responseJson: DoReturnResponse) => {
        if (apiId === this.doReturnApiId) {
            if (responseJson.errors) {
                this.setState({ showToast: true, toastMessage: responseJson.errors, toastType: "error" })
            }
            if (responseJson.message) {
                this.setState({ showToast: true, toastMessage: responseJson.message, toastType: "success", errorColor:"" })
            }
        }

    }
    // Customizable Area End
}
