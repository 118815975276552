import React, { Component } from "react";

type Props = {
    children: any;
    classProps: string;
};

export default class Markup extends Component<Props> {
    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const {children, classProps} = this.props;
        const markup_child = { __html: children };
        return <div className={classProps} dangerouslySetInnerHTML={markup_child}></div>
    }
}