Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';

exports.getTermsCondsApiEndPoint = 'terms_and_conditions/latest_record';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';

exports.dateFormat = "MM-DD-YYYY";
exports.getTermsEndPoint = "bx_block_terms_and_conditions/terms_and_conditions/";
exports.termsContent = "Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary. It's also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact - there is no difference. In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first."
  // Customizable Area End