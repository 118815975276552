// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { getStorageData, isEmail, isEmpty } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export type TalkToUsForm = {
    name: string;
    email: string;
    contactNumber: string;
    subject: string;
    details: string;
};

export const initialFormValue: TalkToUsForm = {
    name: "",
    email: "",
    contactNumber: "+966",
    subject: "",
    details: ""
}

export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
}

interface S {
    country: {
        countryId: string;
        countryCode: string;
    }
    token: string
    loading: boolean
    showToast: boolean
    language: string
}

interface SS {
    id: string;
}

export default class TalkToUsController extends BlockComponent<Props, S, SS> {
    contactUsApiCallId = '';
    formikRef = React.createRef<FormikProps<TalkToUsForm>>();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            country: {
                countryId: "SA",
                countryCode: "+966"
            },
            token: "",
            loading: false,
            showToast: false,
            language: "en"
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        const [storedLanguage, storedToken] = await Promise.all([getStorageData("language"), getStorageData("authToken")])
        this.setState({ language: storedLanguage || "en", token: storedToken || "" }, async () => {
            languageConvertor(storedLanguage);
        });
    }

    receive = async (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            const lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if (lang !== this.state.language) {
                languageConvertor(lang);
                this.setState({ language: lang }, () => this.formikRef.current?.validateForm())
            }
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiCallId === this.contactUsApiCallId) {
                const isSuccess = responseJson?.data?.type === "contact"
                this.setState({
                    loading: false,
                    showToast: isSuccess
                })
                if (isSuccess) {
                    this.formikRef.current?.resetForm({values: {...initialFormValue, contactNumber: this.state.country.countryCode}})
                }
            }
        }
    }

    handleCloseSnackBar = () => this.setState({ showToast: false })

    handleChangeCountryCode = (newCountry: { countryId: string; countryCode: string }) => {
        this.formikRef.current?.setFieldValue("contactNumber", newCountry.countryCode)
        this.setState({ country: newCountry })
    }

    handleChangeName = (event: { target: { value: string } }) => {
        if (event?.target?.value && !/^[^\d]+$/.test(event.target.value)) return;
        this.formikRef.current?.setFieldValue("name", event.target.value)
    }

    handleChangeContactNumber = (event: { target: { value: string } }) => {
        const value = event?.target?.value.replace("\u200E", "")
        if (!value.startsWith(this.state.country.countryCode) || !/^\+\d{0,20}$/.test(value)) return;
        this.formikRef.current?.setFieldValue("contactNumber", value)
    }

    handleValidateForm = (values: TalkToUsForm) => {
        const { t } = i18n
        const errors: Partial<TalkToUsForm> = {};
        if (isEmpty(values.name) || !/^[^\d]+$/.test(values.name)) {
            errors.name = t("CONTACTUSFORM.NAMEERROR");
        }
        if (!isEmail("",values.email).status) {
            errors.email = t("CONTACTUSFORM.EMAILERROR")
        }
        if (values.contactNumber === this.state.country.countryCode) {
            errors.contactNumber = t("CONTACTUSFORM.CONTACTNUMBERERROR");
        }
        if (isEmpty(values.subject)) {
            errors.subject = t("CONTACTUSFORM.SUBJECTERROR");
        }
        if (isEmpty(values.details)) {
            errors.details = t("CONTACTUSFORM.DETAILSERROR");
        }
        return errors
    }

    handleSubmitForm = (values: TalkToUsForm) => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.contactUsApiContentType,
            token: this.state.token,
        };
        const body = {
            "data": {
                "name": values.name,
                "email": values.email,
                "phone_number": values.contactNumber,
                "subject": values.subject,
                "description": values.details
            }
        }

        const apiRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.contactUsApiCallId = apiRequestMessage.messageId;

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postContactUsApiEndPoint
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        )
        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    }
}
// Customizable Area End
