import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikValues } from 'formik';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area Start
export interface VendorForm {
    vendorName: string;
    vendorId: string;
    email: string;
    phoneNumber: string;
    contactPerson: string;
    status: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    onCloseModal: () => void
    isOpenModal: boolean
    defaultOrder: any
    navigation?: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative: string) => string | Object;
        goBack: () => void;
      },
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    orderId: string;
    productName: string;
    address:string;
    status: string;
    price:string;
    date:string
    isVisible: boolean;
    token: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditOrderAdminController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef: any = React.createRef();
    emailReg: RegExp;
    apiEditVendorId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.emailReg = /\w+/;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            orderId: this.props.defaultOrder.id,
            productName: this.props.defaultOrder.productName,
            status: this.props.defaultOrder.status,
            address:this.props.defaultOrder.address,
            price:this.props.defaultOrder.total,
            date:this.props.defaultOrder.date,
            isVisible: false,
            token: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken")
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }
    getToken = () => {
        const msgtxt: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msgtxt);
    };
   
    // Customizable Area Start

    onEdit = (values: FormikValues) => {
        this.setState({ orderId: this.props.defaultOrder.id, 
            status: values.status, 
           },() => { this.doEditVendor()});
    };

    // Customizable Area Start
    doEditVendor(): boolean {
        const header = {
            "Content-Type": configJSON.editOrderListAdminContentType,
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEditVendorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editOrderListAdminAPiEndPoint}${this.state.orderId}&status=${this.state.status}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editOrderListAdminMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.props.onCloseModal()

        return true;
    }
   
    // Customizable Area End
}
