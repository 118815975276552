const image = require("./assets");
import {
  callIcon,
  logoutIcon,
  HelpIcon,
  notificationIcon,
  myAddressIcon,
  userInfo,
  paymentsIcon,
} from "./assets";

export const dropDownList = [
    'Hospitality Equipment',
    'Housekeeping & Cleaning',
    'Ameneties', 'Diagnostics',
    'Hospital Supplies',
    'Surgical Instruments']
  
 export const listData = [
    'Blood Pressure Monitors',
    'Thermometers',
    'Medical Gloves',
    'Patient Monitors',
    'Defibrillators',
    'Ventilators',
    'Infusion Pumps',
    'Nebulizers & Vaporizers',
    'Pulse Oximeter',
  ]
  
  
  export const OrdersList = ["Orders", ` My Orders`, "Reorder List"]
  
  export const CountryList = [
    {
      id: 1,
      title: "EN",
      icon: image.en_flag
    },
    {
      id: 2,
      title: "AR",
      icon: image.ar_flag
    }
  ]

  
  
  export const sidebardata = [
    {
      id: 1,
      key: "account_info",
      icon: userInfo,
      value: "Account Information",
    },
    {
      id: 2,
      key: "my_address",
      icon: myAddressIcon,
      value: "My Addresses",
    },
    {
      id: 3,
      key: "payment",
      icon: paymentsIcon,
      value: "Payments",
    },
    {
      id: 4,
      key: "notification",
      icon: notificationIcon,
      value: "Notifications",
    },
    {
      id: 5,
      key: "help",
      icon: HelpIcon,
      value: "Helps",
    },
    {
      id: 6,
      key: "contact_us",
      icon: callIcon,
      value: "Contact US",
    },
    {
      id: 7,
      key: "log_out",
      icon: logoutIcon,
      value: "Logout",
    },
  ]; 