import React from "react";
import { TextareaAutosize, TextareaAutosizeProps, styled } from "@material-ui/core";

export default class StyledTextArea extends React.Component<TextareaAutosizeProps> {
    constructor(props: TextareaAutosizeProps) {
        super(props)
    }
    render(): React.ReactNode {
        return (
            <StyledTextareaAutosize
                {...this.props}
            />
        )
    }
}

const StyledTextareaAutosize = styled(TextareaAutosize)({
    borderRadius: 8,
    border: "solid 1px #A6A6A6",
    marginTop: "0.5rem",
    padding: "12px 14px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    fontFamily: "Inter",
    color: "#333"
})