import React from "react";

//Customizable Area Start

import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { Grid, Box, styled } from "@material-ui/core";
import { Form, Formik, FormikValues } from "formik";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
const assets = require("./assets");
import CustomSnackBar  from "../../../components/src/design-system/CustomSnackBar.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import i18n from "i18next";
const FormWrapperBox = styled(Form)({
    
})

//Customizable Area End

export default class ChangePasswordWeb extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    render() {
        return (
            <ChangepasswordWrapper>
                <FrontHeader navigation={this.props.navigation} headerType="signin" signUpData={{target: "EmailAccountRegistration",
    isSignIn: true, userType: "customer"}}/>
                <Box className="modal">
                    <Formik
                        initialValues={{
                            newPassword: "",
                            confirmNewPassword: "",
                        }}
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleChangePassword}
                        data-testid="Changeform"
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur
                            }: FormikValues) => (
                            <form onSubmit={handleSubmit}>
                                <Box className="formWrap"
                                    id="formikFormCompChange"
                                >
                                    <Typography>
                                        <Box className="heading">
                                        {i18n.t("NEWPASSWORDTXT")}
                                        </Box>
                                    </Typography>
                                    <Typography>
                                        <Box className="subHeading">
                                        {i18n.t("ENTERANEWPWDFORTXT")} {this.state.userEmail}
                                        </Box>
                                    </Typography>
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                        <Grid item xs={12}>
                                            <TextInput
                                                name="newPassword"
                                                labelText={i18n.t("NEWPASSWORDTXT")}
                                                display="primary"
                                                placeholder={i18n.t("YOURNEWPASSWORDTXT")}
                                                value={values.newPassword}
                                                inputStyles={this.getStyle()}
                                                onValueChange={handleChange}
                                                onBlur={handleBlur}
                                                id="input-with-icon-textfield"
                                                inputType="password"
                                                fieldError={i18n.t(touched.newPassword) && i18n.t(errors.newPassword)}

                                            />

                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                name="confirmNewPassword"
                                                labelText={i18n.t("CONFIRMNEWPWDTXT")}
                                                display="primary"
                                                inputStyles={this.getStyle()}
                                                placeholder={i18n.t("CONFIRMNEWPWDTXT")}
                                                value={values.confirmNewPassword}
                                                onValueChange={handleChange}
                                                onBlur={handleBlur}
                                                fieldError={i18n.t(touched.confirmNewPassword) && i18n.t(errors.confirmNewPassword)}
                                                id="input-with-icon-textfield"
                                                inputType="password"

                                            />
                                        </Grid>
                                    </Grid>
                                    <div
                                        className ="div3"
                                    >
                                        <Typography>
                                            <Box className="subHeading2">
                                            {i18n.t("YOURPASSWORDMUSTCONTAINTXT")}
                                            </Box>
                                        </Typography>
                                        <Box className="container1">
                                            <Box className="container1">
                                                {this.state.PasswordConditions.map((condition: any, index: any) => (
                                                    <Typography key={index}>
                                                        {
                                                            this.checkValidation(index, values?.newPassword) ?
                                                                <img  className="checkimg" src={`${assets.successCheck}`} alt="success" /> :
                                                                <img className="checkimg" src={`${assets.unChecked}`}  alt="unCheck" />

                                                        }
                                                        {condition}
                                                    </Typography>
                                                ))}
                                            </Box>
                                        </Box>
                                    </div>
                                    <div
                                        className="row-center"
                                    >
                                        <CustomButton
                                            dataTestId="changePasswordClick"
                                            onClickHandle={handleSubmit}
                                            themes="primary"
                                            type="submit"
                                            styles={webStyle.submitButton}
                                        >{i18n.t("SETNEWPASSWORDTXT")}</CustomButton>

                                        <Typography>
                                            <Box className="para">
                                            {i18n.t("BACKTOTXT")} <span onClick={this.handlelLogin} className="link">{i18n.t("SIGNINTXT")}</span>
                                            </Box>
                                        </Typography>
                                    </div>
                                </Box>
                            </form>
                            )}
                    </Formik>
                </Box>
                <CustomSnackBar
                    data-test-id="CustomSnackBar"
                    open={this.state.showToast}
                    onClose={this.handleCloseSnackBar}
                    errorMessage={this.state.toastMessage}
                    severity={this.state.toastSuccess as "error" | "warning" | "info" | "success"}
                />
            </ChangepasswordWrapper>
        );
    }
}

// Customizable Area Start
const webStyle = {
    submitButton: {
        width: "100%",
        backgroundColor: "rgb(37, 99, 235)",
        color: "white",
        marginTop: "20px",
        minHeight: "56px"
    }
}
const ChangepasswordWrapper = styled(Box)({
        flex: 1,
        backgroundImage: `url(${assets.backgroundImage})`,
        marginLeft: "auto",
        marginRight: "auto",
        width: "100%",
        maxWidth: "100%",
        backgroundSize: "cover",
        backgroundColor: "#ffffffff",
        display: "flex",
        alignItems: "center",
        flexDirection: "column" as "column",
        gap: "170px",
        boxSizing: "border-box",
        backgroundPosition: 'center',
        "& *": {
            fontFamily: "Roboto !important",
            boxSizing: "border-box"
        },
        "@media (max-width: 420px)": {
            gap: "70px",
        },
  

    "& .heading": {
        textAlign: "center" as "center",
        fontWeight: 600,
        fontSize: "32px",
    },
    "& .subHeading": {
        textAlign: "center" as "center",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
    },
    "& .label": {
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18.75px",
    },
    "& .div3": {
        width: "100%",
        display: "flex",
        flexDirection: "column" as "column",
    },
    "& .subHeading2": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "1rem"
    },
    "& .para": {
        marginTop: "24px",
        fontSize: "16px",
        fontWeight: "400",
    },
    "& .link": {
        fontWeight: "700",
        color: "rgb(37, 99, 235)",
        cursor: "pointer",
    },
    "& .passwordConddition": {
        fontFamily: "Roboto",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px",
        marginTop: "8px",
    },
    "& .container1": {
        display: "flex",
        flexDirection: "column" as "column",
        gap: "8px",
    },
    "& .checkimg":{
        paddingRight:"4px"
    },
    "& .modal":{
        "width": "39.19%",
        "padding": "56px 100px",
        "background": "#FFF",
        borderRadius: "4px",
        marginBottom: "100px",
        "boxShadow": "0px 0px 48px 4px rgba(0, 0, 0, 0.16)"
    },
    "& .formWrap": {
        display: 'flex',
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1.47rem",
    },
    "& .MuiOutlinedInput-input":{
        minHeight: "56px",
        borderRadius: "4px"
    },
    "& .row-center":{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    "& label":{
        textTransform: "capitalize"
    },
    "@media(max-width: 1359px)":{
        "& .modal":{
            padding: "56px 75px"
        }
    },
    "@media(max-width: 1080px)":{
        "& .modal":{
            width: "50%"
        }
    },
    "@media(max-width: 768px)":{
        "& .modal":{
            width: "60%",
            padding: "46px 50px"
        }
    },
    "@media(max-width: 767px)":{
        "& .modal":{
            width: "95%"
        }
    }
});
