import React from "react";

// Customizable Area Start
import ProfileReviewController, { Props } from "./ProfileReviewController";
import {
    styled,
} from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { signupBackground , profileReview} from "./assets";

// Customizable Area End

const ProfileReviewWrapper = styled('div')({
    '&.profile_review-page': {
        width: '100%',
        height: 'calc(100vh - 4.75rem)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${signupBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        overflow: 'auto',
        paddingTop: '6.78rem'
    },
    '& .profile_review-container': {
        width: '56%',
        minWidth: '20rem',
        maxWidth: '35rem',
        backgroundColor: '#fff',
        borderRadius: '0.25rem',
        padding: '3rem 0rem',
        overflow: 'auto',
        margin: '7rem 0 2.5rem 0',
        display:"grid",
        justifyContent:"center",
        gap:"28px"
    },
    '& .profile_review-header': {
        textAlign: 'center',
        display:"grid",
        maxWidth:"50%",
        justifySelf:"center",
        gap:"12px"
    },
     
    
    '& .submit': {
        display: 'flex',
        justifyContent: 'center',
        margin: '1.94rem 0 1.25rem 0',
    },
   
   
    '& .profile_review-img , .continue-btn-container': {
        display:"grid",
        justifySelf:"center",
    },
});

export default class ProfileReview extends ProfileReviewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {

        return (
            // Customizable Area Start
            <ProfileReviewWrapper className="profile_review-page">
                <div className="profile_review-container">
                   
                    <img src={profileReview} alt="Profile Review" className="profile_review-img" />
                    <div className="profile_review-header">
                        <Typography color="black" size="xxl" weight="bold" >Your profile is under review</Typography>
                        <Typography color="black" size="sm">Your profile has been submitted & will be  reviewed by our team. you will be notified if any extra information in needed.</Typography>
                    </div>
                   <div className="continue-btn-container">
                   <CustomButton
                        className="landingPageRedirection"
                        dataTestId="custombtn-review-profile"
                        onClickHandle={() => this.handleRedirect()}
                        themes="primary"
                        styles={{
                            height: '3.5rem',
                            width: '22.5rem',
                        }}
                    >Continue
                    </CustomButton>
                   </div>
                </div>
                
            </ProfileReviewWrapper>

            // Customizable Area End
        );
    }
}
