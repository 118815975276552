// Customizable Area Start
import React from "react";
import ViewProfileController, {
  Props
} from "./ViewProfileController.web";
import { Box, styled, Grid, Container } from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Loader from "../../../components/src/design-system/Loader.web";
import { editIcon, avatar, bg2, location, envelope, callIcon, copyIcon , buildingIcon, docIcon, closeIcon} from "./assets";
import Uploadmedia4 from "../../uploadmedia3/src/Uploadmedia4";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomSnackBar from '../../../components/src/design-system/CustomSnackBar.web';
import  i18n from "i18next";
export default class ViewProfile extends ViewProfileController {
  constructor(props: Props) {
    super(props);
  }


  render(): React.ReactNode {
    const { loading } = this.state;
    return (
      <NavigationMenu pageBackgroundMode="light" navigation={this.props.navigation} id="">
        <ViewProfileContainer maxWidth="lg">
        <Loader loading={loading} />
        <Grid item className="action-btn">
            <Typography size="xl" weight="bold"><Box className="page-title">{i18n.t("PROFILETXT")}</Box></Typography>
            <CustomButton
                dataTestId="btn-save"
                display="textButton"
                styles={webStyles.formActionButtonStyle}
                onClickHandle={this.navigateToEdit}
                themes="primary"
                type="submit"
                >
                <img src={editIcon} alt="" />
                {i18n.t("EDITPROFILETXT")}
            </CustomButton>
        </Grid>

        <Box className="form-container">
        
        <img className="formHeading" src={bg2} alt="" />
        <Grid container spacing={3} className="form-header">
            
            <Uploadmedia4 
                image_preview={this.state.image_preview || avatar} 
                isEdit={false} 
                removeProfile={this.handleFileChnage} 
                showEditProfile={false} 
                imageSrc={this.state.image_preview || avatar} alt={""} 
                handleFileChnage={this.handleFileChnage} 
                onMouseEnter={this.handleFileChnage} 
                onMouseLeave={this.handleFileChnage} />

                <Box className="headerInfo">
                    <Box className="TopHead">
                        <Typography size="xxl" weight="bold"><Box className="title">{this.state.initialFormValue.name}</Box></Typography>
                        <Typography size="base" color="charcoal"><Box className="location-grid"><img src={location} alt="" /> {this.state.initialFormValue.location}</Box></Typography>
                    </Box>
                    <Box className="BottomHead">
                        <Typography size="base" color="charcoal"><Box className="small-title">{i18n.t("BIOTXT")}:</Box></Typography>
                        <Typography size="base" color="charcoal"><Box className="small-details">{this.state.initialFormValue.bio}</Box></Typography>
                    </Box>
                </Box>
        </Grid>
        {this.state.userRole==="vendor" &&
        <Grid container className="form-data">
            <Box className="iconGroup">
                <Box className="icon"><img src={buildingIcon} alt="" /></Box>
                <Box className="icon-data">
                    <Typography><Box className="title">{i18n.t("COMPANYTXT")}</Box></Typography>
                    <Typography><Box className="details">{this.state.initialFormValue?.company || ""}</Box></Typography>
                </Box>
            </Box>
            <CustomButton itemId={this.state.initialFormValue.company} onClickHandle={this.copyText} themes="dark" display="textButton"><img src={copyIcon} alt="" /></CustomButton>
        </Grid>
        }
        <Grid container className="form-data">
            <Box className="iconGroup">
                <Box className="icon"><img src={envelope} alt="" /></Box>
                <Box className="icon-data">
                    <Typography><Box className="title">{i18n.t("EMAILLABEL")}</Box></Typography>
                    <Typography><Box className="details">{this.state.initialFormValue.email || ""}</Box></Typography>
                </Box>
            </Box>
            <CustomButton dataTestId="copyBtn" itemId={this.state.initialFormValue.email} onClickHandle={this.copyText} themes="dark" display="textButton"><img src={copyIcon} alt="" /></CustomButton>
        </Grid>
        <Grid container className="form-data">
            <Box className="iconGroup">
                <Box className="icon"><img src={callIcon} alt="" /></Box>
                <Box className="icon-data">
                    <Typography><Box className="title">{i18n.t("PHONENUMBERLABEL")}</Box></Typography>
                    <Typography><Box className="details">{this.state.initialFormValue.phoneNumber || ""}</Box></Typography>
                </Box>
            </Box>
            <CustomButton dataTestId="copyBtn" itemId={this.state.initialFormValue.phoneNumber} onClickHandle={this.copyText} themes="dark" display="textButton"><img src={copyIcon} alt="" /></CustomButton>
        </Grid>
        {(this.state.initialFormValue.document.length > 0 && this.state.userRole==="vendor") &&
        this.state.initialFormValue.document.map((item, index) => {
            return <Grid container className="form-data">
            <Box className="iconGroup">
                <Box className="icon"><img src={docIcon} alt="" /></Box>
                <Box className="icon-data">
                    <Typography><Box className="title">{i18n.t("DOCUMENTTXT")}</Box></Typography>
                    <Typography><Box className="details">{item.file_name} <Box className="small-title">{this.fileSizeByteToKb(item.file_size)}</Box></Box></Typography>
                </Box>
            </Box>
            <CustomButton dataTestId="copyBtn" onClickHandle={this.handleFileChnage} themes="dark" display="textButton"><img src={closeIcon} alt="" /></CustomButton>
        </Grid>
        })
        }
        
        </Box>
            
            
        <CustomSnackBar
              data-test-id="CustomSnackBarHeader"
              open={this.state.showToast}
              errorMessage={this.state.toastMessage}
              onClose={this.handleCloseSnackBars}
              severity={this.state.toastSuccess }
          />
        </ViewProfileContainer>
      </NavigationMenu>
    );
  }
}

const ViewProfileContainer = styled(Container)({
  paddingLeft: "50px",
  width: "86.47%",
  margin: 0,
  paddingBottom: "155px",
  background:"white",
  "& .page-title":{
    fontSize:"24px"
  },
  "& .headerInfo":{
    display: "flex",
    flexDirection: "column",
    gap: "36px",
    paddingLeft: "20px",
    marginTop: "90px",
    "& .TopHead, .BottomHead":{
        display: "flex",
        flexDirection: "column",
        gap: "8px"
    },
    "& .location-grid":{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        color: "#64748B",
        fontSize: "16px",
        textTransform: "capitalize"
    },
    "& .small-title":{
        color: "#64748B",
        fontSize: "12px",
        textTransform: "uppercase"
    },
    "& .small-details":{
        color: "#334155",
        fontSize: "16px",
        lineHeight: "24px"
    }
  },
  "& .iconGroup": {
    "display": "flex",
    "gap": "16px",
    "alignItems": "center",
    "& .icon": {
        "width": "48px",
        "height": "48px",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "center",
        "borderRadius": "8px",
        "background": "#F1F5F9"
      }
  },
  "& .form-data":{
    "display": "flex",
    "alignItems": "center",
    justifyContent: "space-between",
    padding: "24px 16px",
    marginBottom: "8px",
    borderBottom: "1px solid #F1F5F9"
  },
  "& .icon-data": {
    "display": "flex",
    "flexDirection": "column",
    "& .title":{
        "color": "#64748B",
        "fontSize": "14px",
        "lineHeight": "22px",
        "textTransform": "uppercase"
    },
    "& .details":{
        "color": "#334155",
        "fontSize": "16px",
        "fontWeight": "500",
        "lineHeight": "20px",
        "& .small-title":{
            fontSize: "12px",
            color: "#666",
            display: "inline-block",
            verticalAlign: "bottom"
        }
    }
  },
  "& .profilePicWrapper":{
    borderColor: "#F1F5F9"
  },
    "& .header":{
        padding: "24px 48px",
        display: "flex",
        justifyContent: "space-between"
    },
    "& .form-container":{
        background: "#fff",
        borderRadius: "8px"
    },
    "& .formHeading": {
        borderRadius: "4px 4px 32px 0px",
        width: "100%"
    },
    "& .form-wrapper":{
        display: "flex",
        padding: "39px 40px",
        "& .form-inner":{
            display: "flex",
            flexDirection: "column",
            gap: "16px"
        }
    },
    "& .form-header":{
        padding: "0px 40px",
        marginTop: "-50px",
        marginBottom: "30px",
        flexWrap: "nowrap"
    },
    "& .action-btn": {
        display: "flex",
        gap: "10px",
        justifyContent: "space-between",
        minWidth: 70,
        alignItems: "center",
        padding: "24px 0px",
        "& .edit-btn": {
          background: "#EEE",
          color: "#2563EB",
          padding: "5px 17px",
          textTransform: "uppercase",
        },
        "& > img": {
          alignSelf: "center",
          cursor: "pointer",
        },
    },
    "@media(max-width: 800px)":{
        width: "100%",
        padding: "0px",
        "& .profilePicWrapper, .profilePicDiv":{
            width: "80px",
            height: "80px"
        },
        "& .form-header":{
            marginTop: "-20px"
        },
        "& .headerInfo":{
            marginTop: "40px",
            "& .small-details, .location-grid":{
                fontSize: "14px !important"
            },
            "& .title":{
                fontSize: "18px"
            }
        },
        "& .customeButton":{
            width: "auto !important"
        },
        "& .icon-data":{
            "& .title":{
                fontSize: "12px"
            },
            "& .details":{
                fontSize: "14px !important"
            }
        }
    },
    "@media(max-width: 420px)":{
        "& .iconGroup":{
            "& .icon":{
                width: "40px !important",
                height: "40px !important"
            }
        },
        "& .form-data":{
            padding: "24px 0px"
        },
        "& .form-header":{
            flexDirection: "column",
            padding: "0px 20px"
        }
    }
});


const webStyles = {
    formActionButtonStyle: {
        background:"#fff"
    }
  };
// Customizable Area End
