import React from "react";
// Customizable Area Start
import { Box, Button, Typography, LinearProgress } from "@material-ui/core";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Card from "../../../components/src/design-system/Card.web";
import Cards from "../../../components/src/design-system/Cards.web";
import Footer from "../../navigationmenu/src/Footer.web";
import FrontHeader from "../../../blocks/navigationmenu/src/FrontHeader.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <FrontHeader navigation={this.props.navigation} />
        {/* // Plural Cards */}
        <Box style={webStyles.cardContainer}>
          <Cards Records={[{
            title: "Ameneties",
            image:"https://i.imgur.com/K1RcRvt.jpeg"
          },
          {
            title: "Diagnostics",
            image:"https://i.imgur.com/KwV718N.jpeg"
          },
          {
            title: "Housekeeping & Cleaning",
            image:"https://i.imgur.com/K1RcRvt.png"
          },
          {
            title: "Hospital Supplies",
            image:"https://i.imgur.com/K1RcRvt.png"
          }]} clickHandler={this.goToHome} />
        </Box>
        {/* // Single Card */}
        <Box style={webStyles.cardContainer}>
          
          <Card 
            title="Ameneties" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/K1RcRvt.png"
            />

          <Card 
            title="Housekeeping & Cleaning" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />

          <Card 
            title="Hospital Supplies" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />
          
          <Card 
            title="Hospitality Equipment" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />

          <Card 
            title="Ameneties" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />
          <Card 
            title="Diagnostics" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />

          <Card 
            title="Surgical Instruments" 
            size="base"
            onClickHandle={this.goToHome}
            Image="https://i.imgur.com/9hqPxpK.png"
            />
          
        </Box>
     
      <Box>
        
      

        <Box style={webStyles.container}>
          <Box style={webStyles.btnContainer}>
          <CustomButton 
            dataTestId="goToHomeButton-1"
            size="xl"
            themes="dark"
            display="primaryWithBorder"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-2"
            size="xl"
            themes="dark"
            display="primary"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-3"
            size="x"
            themes="dark"
            display="primary"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-4"
            size="xl"
            themes="dark"
            display="secondary"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>
          <CustomButton 
            dataTestId="goToHomeButton-5"
            size="base"
            themes="dark"
            display="secondaryBorderless"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-6"
            size="base"
            themes="dark"
            display="secondaryWithBorder"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-7"
            size="x"
            themes="dark"
            display="successBorderless"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>
           
           
          <CustomButton 
            dataTestId="goToHomeButton-8"
            size="x"
            themes="dark"
            display="successWithBorder"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-8"
            size="x"
            themes="dark"
            display="error"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-9"
            size="x"
            themes="dark"
            display="light"
            onClickHandle={this.goToHome}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.709 5.63127C21.099 6.02127 21.099 6.65127 20.709 7.04127L18.879 8.87127L15.129 5.12127L16.959 3.29127C17.1459 3.10401 17.3995 2.99878 17.664 2.99878C17.9285 2.99878 18.1822 3.10401 18.369 3.29127L20.709 5.63127ZM2.99902 20.5013V17.4613C2.99902 17.3213 3.04902 17.2013 3.14902 17.1013L14.059 6.19126L17.809 9.94127L6.88902 20.8513C6.79902 20.9513 6.66902 21.0013 6.53902 21.0013H3.49902C3.21902 21.0013 2.99902 20.7813 2.99902 20.5013Z" fill="#2563EB"/>
            </svg> 
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-10"
            size="base"
            themes="dark"
            display="textButton"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          <CustomButton 
            dataTestId="goToHomeButton-10"
            size="base"
            themes="dark"
            display="lightWhite"
            onClickHandle={this.goToHome}
          >
            Go Home
          </CustomButton>

          </Box>
          <Box style={webStyles.totalCandidates}>
            <Box style={webStyles.candidatesFontSize}>
              {webConfigJSON.totalCandidateslabelTitleText}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography style={webStyles.mediumFontSizeForType}>
                {this.state.totalCandidates}
              </Typography>
              <br />
              <Typography style={webStyles.fontSizeForType}>
                {webConfigJSON.candidateslabelTitleText}
              </Typography>
            </Box>
          </Box>
          
          

          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              {this.state.dashboardData.length > 0 &&
                this.state.dashboardData.map(
                  (
                    category: { type: string; quantity: string },
                    index: number
                  ) => (
                    <Box key={index} style={webStyles.itemStyle}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography data-testid={"category-type-" + index}>
                          {category.type}
                        </Typography>
                        <Typography style={webStyles.fontSizeForType}>
                          {category.quantity}
                        </Typography>
                      </Box>
                      <LinearProgress
                        className={`progress-${index}`}
                        variant="determinate"
                        value={
                          (Number(category.quantity) /
                            Number(this.state.totalCandidates)) *
                          100
                        }
                      />
                    </Box>
                  )
                )}
            </Box>
            <Button style={webStyles.btn}>
              {webConfigJSON.viewDetailsBtn}
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer navigation={this.props.navigation}/>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  cardContainer:{
    display: "flex",
    gap: "20px",
    marginBottom: "40px",
    marginTop: "40px",
    "justifyContent": "center"
  },
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "100%",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  btnContainer:{
    display: "grid",
    gap: "15px"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
// Customizable Area End
