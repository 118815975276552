Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.orderListVendorApiEndPoint = "bx_block_order_management/orders/order_items_vendor";
exports.vendorDashboardApiEndPoint = "bx_block_dashboard/dashboards/vendor_dashboard"
exports.adminDashboardApiEndPoint = "bx_block_dashboard/dashboards/admin_dashboard"
exports.salesVolumeAndRevenueApiEndPoint = "bx_block_dashboard/dashboards/sales_volumes_and_revenues"
exports.salesByCategoryApiEndPoint = "bx_block_dashboard/dashboards/sales_per_category"
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
// Customizable Area End