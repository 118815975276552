// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import DataTable from "../../../components/src/DataTable.web";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import i18n from "i18next";
const PAYOUT_LIST_SORTED_FIELD = [{ label: "Transaction Id", field: "transaction_id" }, { label: "Id", field: "order_id" }, { label: "Date", field: "date" }, { label: "Amount", field: "amount"}, { label: "Status", field: "status"}]
import SortingTable from "../../../components/src/SortingTable.web";

import PayoutsListController, { Props } from "./PayoutsListController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class PayoutsList extends PayoutsListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (

      <NavigationMenu id="" navigation={this.props.navigation} handleSearch={this.handleSearch} pageBackgroundMode="light">
        <ThemeProvider theme={theme}>
          <PayoutsListWrap>
            <Box className="payoutsHeader">
              <Box className="titleWrap">
                <Box className="title">{i18n.t('TRANSACTIONTXT')}</Box>
                <Box fontSize={16} lineHeight={2}>
                  {this.state.payoutsTableData.length} {i18n.t('TRANSACTIONTXT')} {i18n.t('FOUNDTXT')}
                </Box>
              </Box>
            </Box>

            <Box className="tabsWrap">
              <GenericTab
                currentTab={this.state.payoutsCurrentTab}
                onTabChange={this.handleTabChange}
                tabsData={this.state.payoutsTabsData}
              />
              <SortingTable columnSorted={PAYOUT_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFn} />

              <Box className="dateRangeWrap">
                <Box className="dateRangeSize">
                  <CustomDateRangePicker
                    displayDateFormat="MMM DD"
                    markingType="period"
                    hidePlaceHolder2
                    current=""
                    markedDates={this.getMarkedDates(
                      this.state.payoutsDateRange.fromDate,
                      this.state.payoutsDateRange.toDate
                    )}
                    calenderContainer={{ left: this.state.language === "ar" ? "-40px" : "-200px" }}
                    onDayPress={this.handleDateRangePickerDayPress}
                    onChange={this.handleDateRangePickerOnChange}
                  />
                </Box>
              </Box>
            </Box>

            <DataTable
              data={this.state.payoutsTableData}
              columns={this.state.payoutsTableColumns}
              rowsPerPage={this.state.payoutsRowsPerPage}
              hideContextMenu
            />
          </PayoutsListWrap>
        </ThemeProvider>
      </NavigationMenu>
      
    );
  }
}

const PayoutsListWrap = styled("div")({
  paddingBottom: '30px',
  fontFamily: 'Roboto',
  "& .titleWrap": {
    display: "flex",
    fontSize: 25,
    margin: "20px 0",
  },
  "& .title": {
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: '24px',
    letterSpacing: '2px'
  },
  "& .tabsWrap": {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px',
    "@media (max-width: 420px)": {
      flexDirection:"column",
      padding: "16px 10px",
    },
  },
  "& .dateRangeWrap": {
    borderRadius: 8,
    padding: "0px 15px 0px 35px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
  },
  "& .dateRangeSize": {
    maxWidth: 150,
  },
  "& .payoutsHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
  "& .btnAddPayouts": {
    display: 'flex',
    alignItems: 'center',
    background: '#2563EB',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
  },
  "& .btnAddPayoutsText": {
    marginLeft: '4px',
  },
  "& table": {
    "& tbody .MuiTableCell-root.MuiTableCell-body": {
      height: 80
    }
  }
});
// Customizable Area End