import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { ReceivedOrderItemType } from "./utilities";
import {languageConvertor} from "../../languageoptions/src/LanguageSelectorController.web";
type CartItem = {
    orderItemId: string
    productId: string
    name: string
    quantity: number
    price: number
}

export const configJSON = require("./config");

export interface Props {
    navigation?: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
}

interface S {
    token: string;
    loading: boolean;
    showToast: boolean;
    toastSuccess: "error" | "success";
    toastMessage: string;
    anchorEl: HTMLElement | null;
    orderId: string | null;
    cartItems: CartItem[];
    total: number;
    language: string;
}

interface SS {
    id: string;
}

export default class ShoppingCartController extends BlockComponent<
    Props,
    S,
    SS
> {
    getAccountDataApiCallId: string = "";
    getInCartOrderListApiCallId: string = "";
    addToCartApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.EventsMessageData)
        ];

        this.state = {
            token: "",
            loading: false,
            showToast: false,
            toastMessage: "",
            toastSuccess: "success",
            anchorEl: null,
            orderId: "",
            cartItems: [],
            total: 0,
            language: "en"
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        
        const lang = await getStorageData("language") || "en";
        if (lang) {
            languageConvertor(lang);
            this.setState({language: lang });
            this.getToken();
        }
    }

    getToken = async () => {
        const token = await getStorageData("authToken");
        if (token) {
            this.getAccountInfo(token);
            this.getIncartOrderList(token);
            this.setState({ token: token });
        }
    };

    getAccountInfo = async (token: string) => {
        const userData = await getStorageData("roleInfoData")
        if (!userData) return;
        const userInfoData = JSON.parse(userData)
        if (userInfoData.data.id) {
            this.callGetApi("getAccountDataApiCallId", `${configJSON.getAccountInfo}?id=${userInfoData.data.id}`, token)
        }
    }

    getIncartOrderList = async (token: string) => {
        this.callGetApi("getInCartOrderListApiCallId", configJSON.getInCartOrderListEndPoint, token)
    }

    receive = async (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if(lang!= undefined && lang != this.state.language){
              languageConvertor(lang);
              this.setState({language: lang, loading: false}, ()=>{
                this.getToken();
                languageConvertor(lang);
              });
            }
        }

        if (message.id === getName(MessageEnum.EventsMessageData)) {
            await this.receiveEventsMessageData(message)
        }

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.receiveInCartOrderList(message)
        }
    }

    receiveEventsMessageData = async (message: Message) => {
        const data = await message.getData(getName(MessageEnum.EventsMessageData))
        if (data?.action === "add-to-cart") this.addProductToCart(data.payload.productId)
    }

    receiveInCartOrderList = async (message: Message) => {
        const apiCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiCallId === this.addToCartApiCallId && responseJson.data) {
            this.setState({ showToast: true, toastMessage: configJSON.addProductSuccessMessage })
        }
        if (apiCallId === this.getInCartOrderListApiCallId) {
            if (responseJson.data === null) this.setState({ orderId: null, cartItems: [], total: 0 })
        }
        if (responseJson?.data?.type === 'order' && responseJson.data.attributes?.status === "in_cart")
            this.setState({
                orderId: responseJson.data.id,
                cartItems: responseJson.data.attributes.order_items.map((order_item: ReceivedOrderItemType) => ({
                    orderItemId: order_item.id,
                    productId: order_item.attributes.catalogue_id.toString(),
                    name: order_item.attributes.catalogue.attributes.name_locale,
                    quantity: order_item.attributes.quantity,
                    price: order_item.attributes.unit_price ? parseFloat(order_item.attributes.unit_price) : 0
                })),
                total: responseJson.data.attributes.total ? parseFloat(responseJson.data.attributes.total) : 0
            })
    }

    addProductToCart = (productId: string | number) => {
        const headers = {
            token: this.state.token,
            "Content-Type": configJSON.apiContentType,
            langauge: this.state.language
        };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.addToCartApiCallId = requestMsg.messageId;
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.ordersApiEndPoint}/${this.state.orderId || "new_order"}`);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), this.state.orderId ? configJSON.putApiMethod : configJSON.postApiMethod);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), this.addProductToCartApiRequestBody(productId))
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    addProductToCartApiRequestBody = (productId: string | number) => {
        if (!this.state.orderId) return JSON.stringify({
            data: {
                order_items_attributes: [{
                    catalogue_id: Number(productId),
                    quantity: 1
                }]
            }
        })
        const inCartItem = this.state.cartItems.find(cartItem => cartItem.productId === productId.toString())
        const quantity = inCartItem ? (inCartItem.quantity + 1) : 1
        return JSON.stringify(
            {
                data:
                {
                    order_items_attributes: [
                        {
                            ...inCartItem && {
                                id: inCartItem.orderItemId
                            },
                            catalogue_id: Number(productId),
                            status: "in_cart",
                            quantity
                        }
                    ]
                }
            }
        )
    }

    callGetApi = (
        apiIdField:
            | "getAccountDataApiCallId"
            | "getInCartOrderListApiCallId",
        apiEndPoint: string,
        token: string
    ) => {
        this.setState({ loading: true });
        const headers = {
            "Content-Type": configJSON.apiContentType,
            token,
            language: this.state.language
        };

        const apiGetMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this[apiIdField] = apiGetMessage.messageId;

        apiGetMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );

        apiGetMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        apiGetMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
    };

    goToOrderSummary = () => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), "OrderSummary");
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(request);
    }
}
