import React, { Component } from "react";
import { Box, MenuItem, styled } from '@material-ui/core';
import i18n from "i18next";
import Typography from "./design-system/Typography.web";
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
const { sortIcon, upIcon, downIcon } = require("./assets")

type SortingProps = {
    columnSorted: Array<{ field: string; label: string }>;
    getSortValueFn: (fieldName: string, sortType: string) => void;
};
type SimpleMenuState = {
    anchorEl: null | HTMLElement;
    activeIndex?: number;
    sortType: string;
}

export default class SortingTable extends Component<SortingProps, SimpleMenuState> {
    constructor(props: SortingProps) {
        super(props);
        this.state = {
            anchorEl: null,
            activeIndex: undefined,
            sortType: "",
        };
    }
    private handleClose = () => {
        this.setState({ anchorEl: null });
    };
    private handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClickSort = (field: string, index: number) => {
        let sortType = ""
        if (this.state.activeIndex === index) {
            if (this.state.sortType === "asc") {
                sortType = "desc"
            }
            if (this.state.sortType === "desc") {
                sortType = "asc"
            }
        } else {
            sortType = "asc"
        }

        this.setState({
            activeIndex: index,
            sortType: sortType
        })

        const sortParams = `&sort_by[${field}]=${sortType}`

        this.props.getSortValueFn(field, sortParams)
    };

    render(): React.ReactNode {
        const { anchorEl } = this.state;
        return (
            <Wrapper className="sortingWrapper">
                <div className="sorting" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.handleOpen(e)}>
                    <img src={sortIcon} alt="" />
                    <Typography>{i18n.t('SORTTXT')}</Typography>
                </div>
                <Popper
                    open={Boolean(anchorEl)}
                    transition
                    placement="bottom-start"
                    disablePortal
                    anchorEl={this.state.anchorEl}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList autoFocusItem={Boolean(anchorEl)} className="menuList">
                                        {
                                            this.props.columnSorted.map((sortItem, index) => {
                                                return (
                                                    <MenuItem key={index} className={`menuItem ${this.state.activeIndex === index && "active"}`} onClick={() => this.handleClickSort(sortItem.field, index)}>
                                                        <Box className="imgBox">
                                                            {this.state.activeIndex === index && <img src={this.state.sortType === "asc" ? upIcon : downIcon} width={15} height={15} />}
                                                        </Box>
                                                        <Typography color={this.state.activeIndex === index ? "primary" : "black"}>{sortItem.label}</Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Wrapper>
        );
    }
}

const Wrapper = styled('div')({
    '&.sortingWrapper': {
        marginRight: "2rem",
        cursor: "pointer"
    },
    "& .sorting": {
        display: "flex",
        gap: 7,
        justifyContent: "center",
        alignItems: "center"
    },
    "& .menuList": {
        minWidth: "150px"
    },
    "& .menuItem": {
        display: "flex",
        gap: 5,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    "& .imgBox": {
        width: "15px",
        height: "15px"
    }
});
