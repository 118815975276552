// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Box, Grid, Card, Typography, Tooltip as MuiTooltip } from "@material-ui/core";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DataTable from "../../../components/src/DataTable.web";
import { TextButton } from "@builder/component-library";
import * as assets from "./assets";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import CustomPieChart from "../../../components/src/CustomPieChart.web";
import i18n from "i18next";
import VendorDashboardController, {
  Props
} from "./VendorDashboardController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Loader from "../../../components/src/design-system/Loader.web";
import RevenueChart from "../../../components/src/design-system/RevenueChart.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    success: {
      main: "#059669"
    }
  },
});

export default class VendorDashboard extends VendorDashboardController {
  constructor(props: Props) {
    super(props);
  }

  static orderStatuses = [
    {
      id: 0,
      label: "DASHBOARD.STATUS.INPROGRESSTXT",
      color: "#F59E0B"
    },
    {
      id: 1,
      label: "DASHBOARD.STATUS.DELIVEREDTXT",
      color: theme.palette.success.main
    },
    {
      id: 2,
      label: "DASHBOARD.STATUS.CANCELLEDTXT",
      color: "#DC2626"
    }
  ]

  render() {
    const { t } = i18n
    const { orderData, earningData, revenueData, orders } = this.state

    const cards = [
      {
        label: "DASHBOARD.CARDS.ORDERSTXT",
        className: "orders-card",
        icon: assets.ordersIcon,
        value: orderData.orderCount,
        percentage: orderData.growthPercentage
      },
      {
        label: "DASHBOARD.CARDS.REVENUETXT",
        className: "revenue-card",
        icon: assets.revenueIcon,
        value: revenueData.revenue,
        percentage: revenueData.revenueGrowthPercentage
      },
      {
        label: "DASHBOARD.CARDS.EARNINGTXT",
        className: "earnings-card",
        icon: assets.earningsIcon,
        value: earningData.balance,
        percentage: earningData.growthPercentage
      }
    ];

    return (
      <NavigationMenu navigation={this.props.navigation} id="">
        <ThemeProvider theme={theme}>
          <Loader loading={this.state.loader} />
          <VendorDashboardWrapper>
            <Box
              className="main-content"
            >
              <Grid
                container
                direction="column"
                spacing={2}
              >
                <Grid container item xs={12} justifyContent="space-between" >
                  <Box display="flex" alignItems="center">
                    <Typography className="page-title">
                      {t("DASHBOARD.DASHBOARDTXT")}
                    </Typography>
                    <Box className="date-range-picker">
                      <CustomDateRangePicker
                        displayDateFormat="MMM DD"
                        markingType="period"
                        hidePlaceHolder2
                        markedDates={this.markedDates()}
                        onDayPress={this.handleDateRangePickerDayPress}
                        onChange={this.handleChangeDateRange}
                      />
                    </Box>

                  </Box>
                  <StyledTooltip
                    interactive
                    title={<Box className="menu">
                      <Box alignItems="flex-start">
                        <img src={assets.shareIcon} />
                        {t("DASHBOARD.SHARETXT")}
                      </Box>
                      <Box>
                        <img src={assets.downloadIcon} />
                        {t("DASHBOARD.DOWNLOADTXT")}
                      </Box>
                    </Box>}>
                    <MoreHorizIcon data-test-id="context-menu-button" className="context-menu-button" />
                  </StyledTooltip>

                </Grid>

                <Grid
                  container
                  item
                  spacing={3}
                >
                  {
                    cards.map((card, cardIndex) => {
                      const isDecreased = card.percentage < 0;
                      return (
                        <Grid
                          key={card.label}
                          item
                          lg={4}
                          xs={12}
                          className="overview-section-wrapper"
                        >
                          <Card
                            className={`overview-card ${card.className}`}
                          >
                            <img src={card.icon} alt={card.label} height={64} />
                            <Box>
                              <Typography>
                                {t(card.label)}
                              </Typography>
                              <Box className="card-data">
                                <Typography>
                                  {cardIndex === 1 ? this.formatArabicCurrency(card.value) : card.value}
                                </Typography>
                                <Box>
                                  <img src={isDecreased ? assets.decreasedIcon : assets.increasedIcon} alt="progress" />
                                  <Typography
                                    style={{
                                      color: isDecreased ? "#D97706" : theme.palette.success.main,
                                      lineHeight: "20px"
                                    }}
                                  >
                                    {this.formatPercentage(card.percentage)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Card>
                        </Grid>
                      )
                    }
                    )
                  }
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  className="detail-sections-wrapper"
                >
                  <Grid item lg={8} xs={12}>
                    <Box>
                      <Typography>
                        {t("DASHBOARD.CARDS.REVENUETXT")}
                      </Typography>
                      <Typography
                        className="revenue-value"
                      >
                        {this.formatArabicCurrency(revenueData.revenue)}
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        color={
                          revenueData.revenueGrowthPercentage < 0
                            ? "#D97706"
                            : theme.palette.success.main
                        }
                        marginBottom="3rem"
                        gridGap={5}
                        fontWeight="bold"
                        fontFamily="Inter"
                        fontSize={14}
                      >
                        <img
                          src={
                            revenueData.revenueGrowthPercentage < 0
                              ? assets.decreasedIcon
                              : assets.revenueIncreasedIcon
                          }
                        />
                        {this.formatPercentage(revenueData.revenueGrowthPercentage, true)}
                      </Box>
                      <RevenueChart
                        data={revenueData.data}
                        items={[
                          {
                            label: t("DASHBOARD.CARDS.REVENUETXT"),
                            color: "#2563EB",
                            dataKey: "revenue",
                          },
                        ]} />
                    </Box>
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <Box>
                      <Typography>
                        {t("DASHBOARD.CARDS.EARNINGTXT")}
                      </Typography>
                      <Box>
                        <CustomPieChart
                          items={[
                            {
                              value: 2124,
                              color: "#34D399",
                              label: t("DASHBOARD.INCOMELABELS.FEES")
                            },
                            {
                              value: 2000,
                              color: "#FC5F5F",
                              label: t("DASHBOARD.INCOMELABELS.TAXES")
                            },
                            {
                              value: 2400,
                              color: "#F59E0B",
                              label: t("DASHBOARD.INCOMELABELS.INCOME")
                            }
                          ]}
                          total={8000}
                          width={200}
                          strokeWidth={10}
                          formatLegendValue={(value) => this.formatArabicCurrency(value)}
                          chartCenter={
                            <Box className="earning-pie-chart-center">
                              <img src={assets.balanceIcon} />
                              <Typography>
                                {this.formatArabicCurrency(earningData.balance)}
                              </Typography>
                              {t("DASHBOARD.BALANCETXT")}
                            </Box>
                          }
                        />

                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box className="order-table-wrapper">
                      <DataTable
                        data={orders}
                        columns={this.state.headerColumns}
                        rowsPerPage={orders.length}
                        onEditClick={() => { }}
                      />

                      <TextButton
                        testID="view-full-orders-btn"
                        icon={assets.arrowRightIcon}
                        style={webStyles.tableStyle.textButtonStyle}
                        textStyle={webStyles.tableStyle.textButtonTextStyle}
                        iconPosition="right"
                        text={t("DASHBOARD.VIEWFULLORDERS")}
                        onPress={this.goToOrderList}
                      />
                    </Box>
                  </Grid>
                </Grid>

              </Grid>
            </Box>
          </VendorDashboardWrapper>
        </ThemeProvider >
      </NavigationMenu >
    );
  }
}

const VendorDashboardWrapper = styled(Box)({
  display: "flex",
  "& .main-content": {
    width: "100%",
    paddingRight: 5
  },
  "& .page-title": {
    color: "0F172A",
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
    marginRight: "16px",
  },
  "& .date-range-picker .label": {
    justifyContent: "space-evenly",
  },
  "& .context-menu-button": {
    alignSelf: "end"
  },
  "& .overview-section-wrapper": {
    display: "flex",
    flexDirection: "column",
  },
  "& .overview-card": {
    padding: "38px 10px 38px 30px",
    borderRadius: 8,
    boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.08)",
    borderStyle: "solid",
    borderWidth: "2px",
    display: "flex",
    gap: 18,
    flexGrow: 1,
    "&.orders-card": {
      borderColor: "#A1B8FC",
      backgroundColor: "#EDF2FF",
    },
    "&.revenue-card": {
      borderColor: "#FFCA95",
      backgroundColor: "#FFF1E2",
    },
    "&.earnings-card": {
      borderColor: "#95F0D4",
      backgroundColor: "#E5FFF6",
    },
    "& .card-data": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      "& > .MuiTypography-root:nth-child(1)": {
        fontSize: 24,
        fontWeight: 600,
        marginRight: 23,
        wordBreak: "break-word",
      },
      "& > .MuiBox-root": {
        display: "flex",
        gap: "2px",
        marginBottom: "-5px",

        "& > img": {
          width: 20,
          height: 20
        }
      }
    },
  },
  "& .detail-sections-wrapper": {
    "& .MuiGrid-item": {
      display: "flex",
      "& > .MuiBox-root": {
        borderRadius: 4,
        border: "1px solid #EEE",
        padding: "24px 33px 32px 27px",
        flexGrow: 1
      }
    },

  },
  "& .revenue-value": {
    fontSize: 30,
    fontWeight: 600
  },
  "& .revenue-progress": {
    gap: "4px",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 700,
    marginBottom: "20px"
  },
  "& .earning-pie-chart-center": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "& > .MuiTypography-root": {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: "2.5rem"

    }
  },
  "& .order-table-wrapper": {
    overflowX: "auto",
    justifyContent: "flex-start",
    width: "100%",
    padding: "0px !important",
    "& thead": {
      "& th:last-of-type": {
        width: 30
      }
    },
    "& > div": {
      padding: 0,
    },
    " & > div > div": {
      "& > div": {
        padding: 0,
        width: "100%",
        "&:nth-child(2)": {
          flex: 2,
          flexDirection: "row",
          gap: 4,
          justifyContent: "flex-start",
          alignItems: "center"
        },

      }
    },
    "& > div:last-of-type img": {
      opacity: 1
    },
    "& .order-product-name": {
      marginLeft: 3,
      fontSize: 14,
      color: "#333"
    },
    "& .order-status": {
      "& > svg": {
        marginRight: 6
      }
    }
  },
  "@media(min-width: 768px)": {
    "& .order-table-wrapper > div > div > div:nth-last-child(1)": {
      flex: "unset",
      width: 25
    }
  },
  "@media(max-width: 767px)": {
    "& .order-table-wrapper > div > div > div > div > div > div > div:last-child": {
      flex: "unset",
      width: 50
    }
  }
})

const StyledTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "black",
    fontSize: 14,
    borderRadius: 8,
    border: "1px solid #E2E8F0",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
  },
  "& .menu": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 1,
    paddingY: 0.5,
    gap: "8px",
    width: 160,
    "& > div": {
      display: "flex",
      padding: "8px",
    },
    "& img": {
      marginRight: 4
    }
  }
});


const webStyles = {
  tableStyle: {
    tableDataTextStyle: {
      padding: 0,
      color: "#333",
      fontSize: 14
    },
    containerStyle: {
      width: "100%",
      minWidth: 800,
      paddingLeft: 0
    },
    paymentChipStyle: {
      width: 68,
      height: 32,
      borderRadius: 4,
    },
    contextMenuStyle: {
      leftIconStyle: {
        minHeight: 18,
        width: 20,
        marginLeft: "-5px",
        filter: "unset",
        opacity: 1
      },
      optionsTextStyle: {
        fontSize: 14
      }
    },
    textButtonStyle: {
      justifyContent: "flex-end" as "flex-end",
      maxWidth: "fit-content",
      marginTop: "20px",
      marginBottom: "20px"
    },
    textButtonTextStyle: {
      width: "fit-content",
      color: "#5BC0DE"
    }
  }
}

// Customizable Area End