import React from "react";
import { Box, Typography, styled } from "@material-ui/core";

type ChartItem = {
    color: string;
    value: number;
    label: string;
  };

type CustomPieChartProps = {
    width: number;
    strokeWidth: number;
    items: ChartItem[];
    total: number;
    formatLegendValue?: (value: number) => string;
    chartCenter?: React.ReactElement
  };

export default class CustomPieChart extends React.Component<CustomPieChartProps>{
    constructor(props: CustomPieChartProps) {
      super(props)
    }
  
    render() {
      const { width, strokeWidth, items, total, formatLegendValue, chartCenter } = this.props
      const radius = width / 2;
      const normalizedRadius = radius - strokeWidth * 2;
      const circumference = normalizedRadius * 2 * Math.PI;
      const isLessThanTotal = items.reduce((acc, item) => acc + item.value, 0) < total
      const renderAllSegments = items.reduce<{ offset: number; elements: JSX.Element[] }>((acc, item, index) => {
        const percentage = (item.value / total) * 100;
        const segmentLength = (percentage / 100) * circumference;
        const dashArray = `${segmentLength} ${circumference - segmentLength}`;
        const dashOffset = -acc.offset;
  
        acc.offset += segmentLength;
  
        acc.elements.push(
          <circle
            key={index}
            data-test-id={`${item.label}-segment`}
            stroke={item.color}
            fill="transparent"
            strokeWidth={strokeWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={(index === 0 || index === items.length - 1) && isLessThanTotal ? "round" : undefined}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            transform={`rotate(-90 ${radius} ${radius})`}
          />
        );
  
        return acc;
      }, { offset: 0, elements: [] }).elements
  
  
      return (
        <PieChartWrapper>
          <Box>
            {
              Boolean(chartCenter)
              &&
              <Box className="chart-center">
                {
                  chartCenter
                }
              </Box>
            }
            <svg height={width} width={width}>
              <circle
                stroke="#eee"
                fill="transparent"
                strokeWidth={strokeWidth}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
              />
              {
                [...renderAllSegments.slice(0, -2), ...renderAllSegments.slice(-2).reverse()]
              }
            </svg>
  
          </Box>
          <Box className="legend">
            {
              items.map(item => (
                <Box key={item.label} >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill={item.color} />
                  </svg>
                  <Typography>
                    {item.label}
                  </Typography>
                  <Typography>
                    {
                      typeof formatLegendValue === 'function' ?
                        formatLegendValue(item.value) : item.value
                    }
                  </Typography>
                </Box>
              ))
            }
          </Box>
        </PieChartWrapper>
      );
    }
  }


  const PieChartWrapper = styled(Box)((props) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gridGap: 30,
    "& > div": {
      position: "relative"
    },
    "& .chart-center": {
      position: "absolute",
      overflow: "hidden",
      inset: 0
    },
    "& .legend": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
      "& > div.MuiBox-root": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
        fontSize: 12,
        "& > svg": {
          verticalAlign: "middle"
        },
        "& > .MuiTypography-root:first-child": {
          lineHeight: 1
        },
        "& > .MuiTypography-root:last-child": {
          fontWeight: "bold"
        }
      }
    },
    [props.theme.breakpoints.up("lg")]: {
      flexDirection: "column"
    }
  }))