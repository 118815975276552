export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BackArrow = require("../assets/BackArrow.png");
export const BackBtn = require("../assets/backBtn.png");
export const Language = require("../assets/lunguage.png");
export const RadioBtn_off = require("../assets/redioBtn.png");
export const RadioBtn_on = require("../assets/redioBtnOn.png");
export const uk_flag = require('../assets/uk_Flag.jpeg');
export const Save = require('../assets/save.png');
export const en_flag = require("../assets/en_flag.png");
export const ar_flag = require("../assets/ar_flag.png");