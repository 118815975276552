import React, { Component } from "react";
import {
    Box, styled, Typography
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { profileImage, uploadIcon, deleteIcon, cameraIcon } from "./assets";
import i18n from "i18next";
// Customizable Area End


export interface Props {
    // Customizable Area Start
    inputStyles?: string;
    imageSrc: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    handleFileChnage?: () => void;
    removeProfile?: () => void;
    alt: string;
    text?: string;
    showEditProfile?: boolean;
    inputBoxStyle?: string;
    image_preview?: string;
    isEdit?: boolean;
    // Customizable Area End
}



const ProfilePopup = styled("div")({
    "& .editProfilePopup": {
        borderRadius: "0.4rem",
        width: "max-content",
        padding: "1rem",
        border: "0.1rem solid rgba(0, 0, 0, 0.21)",
        position: "absolute" as "absolute",
        top: "6rem",
        left: "8rem",
        zIndex: 9,
        backgroundColor: "white",
    },
    "& .popupOption": {
        color: "rgba(51, 51, 51, 1)",
        backgroundColor: "#fff",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        padding: "0.4rem",
        borderRadius: "0.4rem",
    },
    "& .popupOption:hover": {
        backgroundColor: "rgba(37, 99, 235, 0.09)",
    },
    "& .image": {
        width: "1rem",
        color: "black"
      },
      "& .profilePicWrapper": {
        width: "10rem",
        height: "10rem",
        borderRadius: "50%",
        overflow: "hidden",
        border: "0.2rem solid blue",
        flexShrink: 0,
        "@media (max-width: 420px)": {
          width: "8rem",
          height: "8rem",
        },
      },
      "& .profilePicDiv": {
        width: "10rem",
        height: "10rem",
        "@media (max-width: 420px)": {
          width: "100%",
          height: "100%",
        },
      },
      "& .profilePic": {
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative" as "relative",
        top: 0,
        left: 0,
        zIndex: 3,
      },
      "& .blurBg": {
        width: "10rem",
        height: "10rem",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "relative" as "relative",
        top: "-10rem",
        left: 0,
        zIndex: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as "column",
      },
      "& .cameraImage": {
        width: "2rem",
      zIndex: 4,
      cursor: "pointer",
      },
      "& .editText": {
        color: "#fff",
      zIndex: 4,
      cursor: "pointer",
      "& *":{
        textTransform: "capitalize"
      }
      },
      "& .mainProfilePic" :{
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative" as "relative",
        top: 0,
        left: 0,
        zIndex: 3,
      }
});


export default class Uploadmedia4 extends Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <ProfilePopup>
            <Box className="mainProfilePic">
            <Box className = "profilePicWrapper">
                <Box className = "profilePicDiv">
                  <img src={this.props.image_preview || profileImage} alt="pic" className = "profilePic" />
                </Box>
                {this.props.isEdit && (
                  <>
                    <Box className = "blurBg">
                      <img
                        src={cameraIcon}
                        alt="camera icon"
                        className = "cameraImage"
                        onClick={this.props.onMouseEnter}
                      />
                      <Box className = "editText"
                      onClick={this.props.onMouseEnter}
                      >
                        <Typography>
                          {i18n.t("EDITPROFILETXT")}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
              <ProfilePopup data-test-Id="popupOption"
                    style={{ display: this.props.showEditProfile ? "" : "none" }}
                    onMouseLeave={this.props.onMouseLeave}
                >
                    <div className="editProfilePopup">
                        <div className="popupOption" onClick={this.props.handleFileChnage}>
                            <img
                                className="image"
                                src={uploadIcon}
                                alt={this.props.alt}
                            />
                            <Typography>{i18n.t("UPLOADPHOTOTXT")}</Typography>
                        </div>
                        <div className="popupOption" onClick={this.props.removeProfile}>
                            <img
                                className="image"
                                src={deleteIcon}
                                alt={this.props.alt}
                            />
                            <Typography>{i18n.t("REMOVEPHOTOTXT")}</Typography>
                        </div>
                    </div>
            </ProfilePopup>
            </Box>
            </ProfilePopup>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End