import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "framework/src/Message";
const Ulti = require("./../utility");
import { successIcon } from "./assets";
import i18n from "i18next";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

export type DateRange = {
  fromDate?: string;
  toDate?: string;
};
interface TableAdminType{
  id: string,
  name: string,
  date: string,
  status: string,
  address: string,
  price: string,
  image: string,

}
export interface VendorListingFromApi {
  id: string;
  attributes:{
    product_name: string;
    created_at: string;
    id: string;
    image: string;
    date: string;
    status : string;
    delivery_address: string;
    price: string;
    order_id: string
  }
}
interface OrderItemAttributes {
  id: number;
  placed_at: string | null;
  transaction_id: string | null;
  product_name: string;
  product_image: string;
  order_id: string;
  price: string;
  status: string;
  item_status: string;
  date: string;
  delivery_address: string;
}

interface OrderItemupdate{
  id: string;
  type: string;
  attributes: OrderItemAttributes;
}

export interface OrderAdminInterface {
  date: string;
  delivery_address: string | null;
  id: string;
  item_status: string;
  order_id: string;
  placed_at: string | null;
  price: number;
  product_image: string;
  product_name: string;
  status: string;
}
export type VendorTableData = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  date: string;
  status: string;
  avatar: string;
  contact?: string;
  price?: string;
};
interface OrderAdminProduct {
  address: string | null;
  date: string;
  id: string;
  price: number; // Optional property
  productName: string;
  status: string;
}
interface DateObject {
  [date: string]: {
    startingDay?: boolean;
    endingDay?: boolean;
      color: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  vendorRowsPerPage: number;
  vendorCurrentTab: number;
  vendorDateRange: DateRange;
  vendorTableData: any[];
  vendorRowEdit: object | null;
  apiToken: string | null;
  customerListArray:VendorListingFromApi[];
  isOpenEdit: boolean,
  vendorDetail: OrderAdminProduct|null,
  showToast:boolean
  toastSuccess:string
  toastMessage:string
  isOpenDeleteModal: boolean
  deleteId: string,
  language:string,
  adminTabsData:{}[],
  adminHeaderColumns: {}[],
  checkBool:boolean,
  textSearch: string,
  sortType: string
    // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrderListAdminController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  vendorData: {}[] = [];
  vendorTabsData: string[] = [];
  vendorColumns: string[] = [];
  vendorMapDataTab: string[] = [];
  vendorPagination: object = {};
  vendorDateFormat: string;
  getVendorListCallId: string = "";
  deleteCustomerCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.vendorDateFormat = "YYYY-MM-DD";

    this.state = {
      vendorRowsPerPage: 10,
      vendorCurrentTab: 0,
      vendorDateRange: {
        fromDate: moment().startOf("week").format(this.vendorDateFormat),
        toDate: moment().endOf("week").format(this.vendorDateFormat),
      },
      vendorRowEdit: null,
      vendorTableData: [],
      apiToken: "",
      customerListArray: [],
      isOpenEdit: false,
      vendorDetail:null,
      showToast: false,
      toastMessage:"",
      toastSuccess:"",
      isOpenDeleteModal: false,
      deleteId: "",
      language:"en",
      adminTabsData:[],
      adminHeaderColumns:[],
      checkBool:false,
      textSearch:"",
      sortType:""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
    this.getTokenfunc();
    const landing_lang = await getStorageData('language') || "en";
    this.handleChangeLanguageCustomer(landing_lang)
    // Customizable Area End
  }
 
  getTokenfunc = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getMarkedDates = (fromDate?: string, toDate?: string) => {
    if (!fromDate) {
      return {};
    }
    let dateArrayList = [];
    if (!toDate) {
      dateArrayList = [fromDate];
    } else {
      let tempdate = fromDate;
      const dateEnd = moment(toDate).add(1, "day").format(this.vendorDateFormat);
      while (tempdate !== dateEnd) {
        dateArrayList.push(tempdate);
        tempdate = moment(tempdate).add(1, "day").format(this.vendorDateFormat);
      }
    }
    let result: DateObject = {};
    dateArrayList.forEach((itemIt, index) => {
      result[itemIt] = {
        ...(index === 0 && { startingDay: true }),
        color: "#E6EEFF",
        ...(index === dateArrayList.length - 1 && { endingDay: true }),
      };
    });
    return result;
  };

  handleTabChange = (value: number) => {
    this.setState({ vendorCurrentTab: value });
  };

  handleDateRangePickerPresDay = ({ dateString }: { dateString: string }) => {
    const { fromDate, toDate } = this.state.vendorDateRange;
    let newDateRangePicks = {};
    if (Boolean(fromDate) === Boolean(toDate)) {
      newDateRangePicks = { fromDate: dateString };
    } else {
      newDateRangePicks = moment(dateString).isSameOrBefore(fromDate)
        ? {
          fromDate: moment(dateString).format(this.vendorDateFormat),
          toDate: fromDate,
        }
        : {
          fromDate,
          toDate: moment(dateString).format(this.vendorDateFormat),
        };
    }
    this.setState({ vendorDateRange: newDateRangePicks });
    if (!newDateRangePicks.hasOwnProperty("toDate")) {
      newDateRangePicks = {
        ...newDateRangePicks,
        toDate: dateString
      }
    }
    this.getVendorList(newDateRangePicks)
  };

  handleDateRangePickerOnChange = (data: DateRange) => {
    this.setState({
      vendorDateRange: data,
    });
  };

  getTableData = (currentTab: {label: string}) => {
    let tableData = this.state.vendorTableData;
    if (currentTab?.label.toLowerCase() !== "all order") {
      tableData = this.state.vendorTableData.filter((data) => {
        return data.status == currentTab.label;
      });
    }

    if (
      this.state.vendorDateRange.fromDate &&
      this.state.vendorDateRange.toDate
    ) {
      tableData = this.filterTableDataByDate(tableData);
    }
    

    return tableData;
  };

  getTabStatus = () => {
    return Ulti.admintabsData[this.state.vendorCurrentTab];
  };

  filterTableDataByDate = (tableData: VendorTableData[]) => {
    let dateRange = this.state.vendorDateRange;
    const strFromDate = dateRange.fromDate;
    const strToDate = dateRange.toDate;
    const newTableData = tableData.filter((data) => {
      let compareDate = moment(data.date,'DD-MM-YY');
      let fromDate = moment(strFromDate, this.vendorDateFormat);
      let toDate = moment(strToDate, this.vendorDateFormat);
      let sameFromdate = compareDate.isSame(fromDate);
      let sameTodate = compareDate.isSame(toDate)
      return compareDate.isBetween(fromDate, toDate, null, "[]")|| sameFromdate || sameTodate;
       
    });
    return newTableData;
  };

  handleVendortableData(CustomerArrayData:VendorListingFromApi[]){
    const newData = CustomerArrayData.map((item: VendorListingFromApi) => {
      return {
        id: item.id,
        name: item.attributes.product_name,
        date: item.attributes.date,
        status: item.attributes.status,
        address: item.attributes.delivery_address,
        price: item.attributes.price,
        image: successIcon,
        orderId: item.attributes.order_id
      }
    })
    this.setState({vendorTableData: newData})
  
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguageAdminOrder(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = await getStorageData("authToken")
      if (token) {
        this.setState({ apiToken: token }, () => {
          this.getVendorList();
        });
      }
    }
    const editOrderAdminResponse= message?.properties?.RestAPIResponceSuccessMessage?.data
    if(editOrderAdminResponse && editOrderAdminResponse.id) {
      this.updateTableData(editOrderAdminResponse)
     this.setState({
      showToast: true,
      toastSuccess: "success",
      toastMessage: "Updated Succeffully",
     })
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getVendorListCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJsondata = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      if (responseJsondata?.errors) this.parseApiErrorResponse(responseJsondata);
      if(responseJsondata){
        this.setState({
          customerListArray: responseJsondata.data,
        });
      }
      this.handleVendortableData(this.state.customerListArray)
    }
    // Customizable Area End
  }
  getVendorList = (dateRange?: DateRange) => {
    const header = {
      language:this.state.language,
      token: this.state.apiToken,
      "Content-Type": configJSON.OrderListAdminContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorListCallId = requestMessage.messageId;
    let fromDate = this.state.vendorDateRange.fromDate;
    let toDate = this.state.vendorDateRange.toDate;
    if (dateRange && (dateRange as {}).hasOwnProperty('toDate') && (dateRange as {}).hasOwnProperty('toDate')) {
      fromDate = dateRange?.fromDate;
      toDate = dateRange?.toDate;
    }
    const searchQuery = this.state.textSearch ? `&query=${this.state.textSearch}&` : ""
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.OrderListAdminApiEndPoint + `?start_date="${fromDate}"&end_date="${toDate}${searchQuery}` + this.state.sortType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.OrderListAdminMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openEditVendor = (vendor: OrderAdminProduct) => {
        this.setState({ isOpenEdit: true, vendorDetail: vendor })
  }
  closeEditVendor = () => {
    this.setState({
      isOpenEdit: false,
   
    })
  }
  componentDidUpdate(prevProps: Readonly<Props>, prev: Readonly<S>): void {
    if(prev.checkBool!==this.state.checkBool){
      this.getVendorList() 
      this.setState({checkBool:false})
    }
    if(this.state.sortType !== prev.sortType ){
      this.getVendorList()
    }
  }
  handleCloseSnackBars() {
    this.setState({showToast:false})
     }
    handleChangeLanguageCustomer = (lang: string) => {
      languageConvertor(lang);
      this.setState({
        language: lang,
        adminTabsData: [

          { label:  i18n.t('ALLORDERTXT') },
          { label:  i18n.t('DASHBOARD.STATUS.INPROGRESSTXT') },
          { label:  i18n.t('DASHBOARD.STATUS.DELIVEREDTXT') },
          { label:  i18n.t('DASHBOARD.STATUS.CANCELLEDTXT')}
        ],
        adminHeaderColumns: [
          { id: 'orderId', label: i18n.t('DASHBOARD.TABLEHEADER.ORDERID'),  renderFunction: 'renderOrderId'  },
          { id: 'productName', label: i18n.t('DASHBOARD.TABLEHEADER.PRODUCTNAME'),renderFunction:"renderRowHaveImage"},
          { id: 'address', label: i18n.t('ADDRESSTXT')},
          { id: 'date', label: i18n.t('DASHBOARD.TABLEHEADER.DATE') },
          { id: 'price', label: i18n.t('PRICETXT') ,renderFunction:"renderRowPriceAdmin"},
          { id: 'status', label: i18n.t('DASHBOARD.TABLEHEADER.STATUS'), renderFunction: 'renderRowStatus' },
        ]
      })
    }
    recieveLanguageAdminOrder = (customerMessage: Message) => {
      if (customerMessage.id === getName(MessageEnum.NavigationPayLoadMessage)) {
        let currLang = customerMessage.getData(getName(MessageEnum.InfoPageTitleMessage));
        if (currLang != this.state.language) {
          this.handleChangeLanguageCustomer(currLang)
          this.setState({
            language: currLang
          })
        }
      }
    };

    updateTableData(editOrderAdminResponseData:OrderItemupdate){
      const newUpdatedTable=this.state.vendorTableData.map((item:TableAdminType)=>{
        const updatedAttributes=editOrderAdminResponseData?.attributes;
        return item.id===editOrderAdminResponseData?.id?{
          id: updatedAttributes.id,
          name: updatedAttributes.product_name,
          date: updatedAttributes.date,
          status: updatedAttributes.status,
          address: updatedAttributes.delivery_address,
          price: updatedAttributes.price,
          image: successIcon,
        }:item
      })
      this.setState({vendorTableData:newUpdatedTable,checkBool:true})
    }
    handleSearch = (text?: string) => this.setState({textSearch: text || ""}, () => this.getVendorList())
    getSortValueFn = (fieldName:string, sortType: string) => {
      this.setState({sortType: sortType })
    }
  // Customizable Area End
}
