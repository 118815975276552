import React, { Component } from "react";
import { Box, styled } from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";


type Props = {
    title: string;
    children?: any;
    image: string;
    type?: string;
    details: string;
};


const InfoCardBox = styled(Box)({
    "borderRadius": "4px",
    "border": "1px solid #C4C4C4",
    "background": "#FFF",
    "boxShadow": "0px 0px 32px 0px rgba(0, 0, 0, 0.12)",
    "padding": "38px 24px",
    "display": "flex",
    "gap": "20px",
    "alignItems": "flex-start",
    "& img":{
        width: "80px"
    },
    "& .inner":{
        display: "flex",
        flexDirection: "column",
        gap: "12px"
    },
    "& .title":{
        "fontFamily": "Roboto",
        "fontSize": "24px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "color": "#000",
        "@media(max-width: 560px)":{
            "fontSize": "18px",
        }
    },
    "& .details":{
        "color": "#333",
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontWeight": 300,
        "lineHeight": "148%"
    },
    "&.style2":{
        flexDirection: "column",
        padding: "116px 39px 77px",
        border: "0px",
        boxShadow: "none",
        background: "#eee",
        textAlign: "center",
        alignItems: "center",
        "& .details":{
            "fontWeight": 400,
        },
        "& .title":{
            "fontWeight": 600,
        }
    }
});

export default class InfoCard extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        const {title, details, image, children, type} = this.props;
        return (
            <InfoCardBox className={`infoCard ${type}`}>
                <img src={image} alt="" />
                <Box className="inner">
                    <Typography size="xl"><Box className="title">{title}</Box></Typography>
                    <Typography weight={"normal"} size="base"><Box className="details">
                        {details}{children}
                    </Box></Typography>
                </Box>
            </InfoCardBox>
        );
    }
}