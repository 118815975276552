Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productApiEndPoint = "catalogue/catalogues";

exports.getCategoriesApiEndpoint = "bx_block_categories/categories";
exports.productApiEndpoint = "bx_block_catalogue/catalogues";
exports.createProductApiMethodType = "POST";
exports.updateApiMethodType = "PUT";
exports.deleteProductApiMethodType = "DELETE";
exports.deleteProductSuccessMessage = "Catalogue deleted successfully"
// Customizable Area End