import React from "react";

// Customizable Area Start
import {
    Grid,
    Box,
    styled,
    MenuItem,
    Select,
} from "@material-ui/core";
import TextInput from "../../../components/src/design-system/TextInput.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import { Formik, FormikValues } from "formik";
import EditInventoryController, {
    Props
 } from "./EditInventoryController";
export const configJSON = require("./config");
import CustomModal from "../../../components/src/CustomModal.web";
import resources from "./utilities";
import CustomSelect from "../../../components/src/design-system/CustomSelect.web";
import Typography from "../../../components/src/design-system/Typography.web";
import Uploadmedia3 from "../../uploadmedia3/src/Uploadmedia3.web";
import { closeIcon, imageUploadIcon } from "./assets";
import { InventoryForm } from "./CreateInventoryController";
import i18n from "i18next";

// Customizable Area End
export const initialFormValues = {
    inventoryId: '',
    inventoryName: '',
    contactPerson: "",
    email: '',
    phoneNumber: '',
    status: 'active'
}

interface FormData {
    name: string;
    title: string;
    type: 'text' | 'number';
}

export const statusOptions = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]

export default class EditInventory extends EditInventoryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box>
                <CustomModal
                    isVisible={this.props.isOpenModal}
                    title={i18n.t('EDITTXT')+ ' ' + i18n.t('INVENTORYTXT')} 
                    onCloseModal={this.props.onCloseModal}
                >
                    <Formik
                        initialValues={this.state}
                        validate={this.validateInventoryForm}
                        onSubmit={this.onEdit}
                        innerRef={this.formikRef}
                        data-test-id="formik"
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }: FormikValues) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <EditInventoryWrap>
                                    <Grid container spacing={3} className="form-body">
                                    <Grid item xs={12}>

                                    <Box className="mb-30">
                                        {this.renderEditTextInput(
                                            { name: 'name',
                                             title: i18n.t('PRODUCTNAMETXT'),
                                             type: 'text'
                                            },
                                            true,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched as { [key: string]: string },
                                            errors as { [key: string]: string | undefined },
                                            setFieldValue,
                                        )}
                                    </Box>

                                    <Box className="mb-30">
                                        <label className="label-text">{i18n.t('CATEGORYTXT')}</label>
                                        {this.renderEditMultipleSelect(
                                            { name: 'category',
                                            title: i18n.t('CATEGORYTXT'),
                                            type: 'text'
                                            },
                                            values,
                                            handleChange,
                                            handleBlur,
                                            setFieldValue,
                                            touched as { [key: string]: string },
                                            errors as { [key: string]: string | undefined },
                                        )}
                                    </Box>


                                    <Box className="mb-30">
                                            {this.renderEditTextInput(
                                                { name: 'sku', title: i18n.t('SKU'), type: 'text'},
                                                true,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched as { [key: string]: string },
                                                errors as { [key: string]: string | undefined },
                                                setFieldValue,
                                            )}
                                    </Box>

                                    <Box className="input-wrap mb-30">
                                        {this.numberInputs.map((input, index) =>
                                            <Box className="input-item" key={index}>
                                                {this.renderEditTextInput(
                                                        input,
                                                        false,
                                                        handleChange,
                                                        handleBlur,
                                                        values,
                                                        touched as { [key: string]: string },
                                                        errors as { [key: string]: string | undefined },
                                                        setFieldValue,
                                                    )}
                                            </Box>
                                        )}
                                    </Box>

                                    <Box className="mb-30">
                                        <label className="label-text">{i18n.t('REFUNDTXT')} {i18n.t('STATUSTXT')}</label>
                                        <Box mt={"8"} className={`select ${this.state.selectStatus}`}>
                                            <Select
                                                name="refund_status"
                                                variant="outlined"
                                                value={values.refun_string}
                                                onChange={(event) => {
                                                        let val = event.target.value;
                                                        setFieldValue("refun_string", val)
                                                        if(val === 'Yes'){
                                                            this.setState({
                                                            selectStatus: 'selectWithYes'
                                                        })
                                                        }else{
                                                         this.setState({
                                                            selectStatus: 'selectWithNo'
                                                        })
                                                        }
                                                    }
                                                }
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem value='Yes'>Yes</MenuItem>
                                                <MenuItem value='No'>No</MenuItem>
                                            </Select>
                                            {
                                            Boolean(touched["refund_status"]) && Boolean(errors["refund_status"])
                                            && <Typography>
                                                {
                                                errors["refund_status"]
                                                }
                                            </Typography>
                                            }
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box className="upload-image-field">
                                            <label className="label-text">{i18n.t('IMAGETXT')}</label>
                                            <Box
                                                className="image-container"
                                                display="flex"
                                                flexWrap="wrap"
                                                gridGap={10}
                                                alignItems="center"
                                            >
                                                <Uploadmedia3
                                                id="file-upload"
                                                navigation={this.props.navigation}
                                                onFileUpload={(files) =>
                                                    this.setState({
                                                    images: [...this.state.images, ...files.map((file) => ({
                                                        preview: URL.createObjectURL(file),
                                                        file: file,
                                                    })),]
                                                    })
                                                }
                                                text={i18n.t('IMAGETXT')}
                                                imageUrl={imageUploadIcon}
                                                textColor="primary"
                                                textSize="xs"
                                                />
                                                {this.state.images.map((image, imageIndex) => (
                                                <Box
                                                    key={imageIndex}
                                                    className="image-thumbnail"
                                                    position="relative"
                                                >
                                                    <img
                                                    className="product-image"
                                                    src={image.preview}
                                                    width={100}
                                                    height={100}
                                                    />
                                                    <img
                                                    src={closeIcon}
                                                    data-test-id="delete-icon"
                                                    className="delete-icon"
                                                    onClick={() =>
                                                        this.setState({
                                                        images: this.state.images.filter(
                                                            (img, index) => index !== imageIndex
                                                        ),
                                                        })
                                                    }
                                                    />
                                                </Box>
                                                ))}
                                            </Box>
                                        </Box>
                                    </Box>

                                    </Grid>
                                    </Grid>
                                    <Box className="footer">
                                        <CustomButton
                                            styles={{ background: "#E6EEFF", color: "#2563EB" }}
                                            width="132px"
                                            themes="dark"
                                            display="secondaryBorderless"
                                            onClickHandle={this.props.onCloseModal}
                                            data-test-id="cancel-inventory">
                                            {i18n.t('CANCELTXT')}
                                        </CustomButton>
                                        <CustomButton
                                            width="132px"
                                            styles={{ background: "#2563EB" }}
                                            themes="dark"
                                            display="primary"
                                            onClickHandle={handleSubmit}
                                            data-test-id="save-inventory">
                                            {i18n.t('SUBMITTXT')}
                                        </CustomButton>
                                    </Box>
                                    </EditInventoryWrap>
                                </form>
                            );
                        }}
                    </Formik>
                </CustomModal>
            </Box>
            // Customizable Area End
        );
    }

    renderEditTextInput = (
        formData: FormData,
        disabled: boolean,
        handleChange: (event: React.ChangeEvent<unknown>) => void,
        handleBlur: React.FocusEventHandler,
        values: InventoryForm,
        touched: { [key: string]: string },
        errors: { [key: string]: string | undefined },
        setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
        ) => {
        
        const field = formData.name as keyof InventoryForm
    
        return (
            <TextInput
            id={formData.name + "Id"}
            name={formData.name}
            disabled={disabled}
            data-test-id={formData.name}
            labelText={formData.title}
            display="primary"
            placeholder={formData.title}
            onValueChange={(event) => {
                if (formData.type === 'number' && event?.target?.value && !/^\d+$/.test(event.target.value)){
                    return;
                }
    
                handleChange(event)
            }}
            onBlur={handleBlur}
            value={values[field] as string}
            fieldError={touched[formData.name] && errors[formData.name]}
            />
        );
    }

    renderEditMultipleSelect = (
        formData: FormData,
        values: InventoryForm,
        handleChange: (event: React.ChangeEvent<unknown>) => void,
        handleBlur: React.FocusEventHandler,
        setFieldValue: (field: keyof InventoryForm, value: string[] | string) => void,
        touched: { [key: string]: string },
        errors: { [key: string]: string | undefined },
        ) => {
        
        const field = formData.name as keyof InventoryForm
        const editSubCategories = this.getEditFilteredSubCategories(values.categoryIds);
        const editSubCategoryOption = editSubCategories.map(subCategory => ({ key: subCategory.id, name: subCategory.name }))
        
        return (
          <>
          <Box mt={"8"} className="category-select">
          <CustomSelect
            name={formData.name}
            variant="outlined"
            disabled={true}
            className="multi-select"
            multiple
            options={this.getCategoryOption()}
            value={values.categoryIds}
            onChange={(event) =>
              setFieldValue(field, event.target.value as string[])
            }
            onBlur={handleBlur}
          />
            {
              Boolean(touched[formData.name]) && Boolean(errors[formData.name])
              && <Typography>
                {
                  errors[formData.name]
                }
              </Typography>
            }
        </Box>
          {
            editSubCategoryOption.length > 0
            &&
            <Box pt="48">
              <label className="label-text">{i18n.t('SUBCATEGORYTXT')}
              </label>
              <Box mt="8" className="category-select">
                <CustomSelect
                  name="subCategory"
                  variant="outlined"
                  className="multi-select"
                  multiple
                  disabled={true}
                  options={editSubCategoryOption}
                  value={values.subCategoryIds}
                  onChange={(event) =>
                    setFieldValue("subCategory", event.target.value as string[])
                  }
                  onBlur={handleBlur}
                />
              </Box>
            </Box>
          }
          </>
        );
      }
}

const EditInventoryWrap = styled('div')({
    backgroundClor: 'red',
    "& .label-text": {
        fontSize: "1rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 500,
    },
    "& .drag-drop-box": {
        border: "0",
        background: "#E6EEFF",
        width: "110px",
        minWidth: 0,
    },
    "& .category-select": {
       
        "& > div:last-of-type": {
            marginTop: "8px",
            color: "#DC2626 !important",
        },
        "& .multi-select": {
            "& .MuiSelect-root": {
                padding: "18px 14px",
            },
            width: "100%",
        },
    },
    "& #uploadFileSection": {
        height: 100,
    },
    "& .select": {
        
        "& > div:last-of-type:not(.MuiInputBase-root)": {
            color: "#DC2626 !important",
            marginTop: "8px"
        },
        "& .MuiInputBase-root": {
            width: "100%",
        },
    },

    "& .image-thumbnail": {
        "& .delete-icon": {
            cursor: "pointer",
            position: "absolute",
            top: -10,
            right: -10,
        },
    },
    "& .product-form-header": {
        "& .header-arabic-text": {
            "&>div>div": {
                flexDirection: "column",
                alignItems: "flex-end",
            },
        },
        borderBottom: "1px solid rgb(200,200,200)",
    },
    "& .mb-30": {
        marginBottom: 30
    },

    "& .product-form-footer": {
        textAlign: "right",
        marginBottom: "20px",
        "& button": {
            marginRight: "24px",
        },
    },
    "& .input-wrap": {
        display: 'flex',
        gap: '15px',
    },
    "& .product-form-body": {
        borderTop: "1px solid rgb(200,200,200)",
        "& .MuiGrid-item": {
            padding: "24px",
        },
    },
    "& .selectWithYes": {
        "& .MuiSelect-select": {
          color: 'green'
        },
      },
     
      "& .selectWithNo": {
        "& .MuiSelect-select": {
          color: 'red'
        },
      },
});