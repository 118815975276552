import React from "react";
import {
    Box,
    styled,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import ReturnOrderController, { Props } from "./ReturnOrderController";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import i18n from "i18next";
import CustomCheckbox from "../../../components/src/design-system/CustomCheckbox.web";
import Select from "react-select";
import CustomSnackBar from "../../../components/src/design-system/CustomSnackBar.web";

export default class ReturnOrders extends ReturnOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff" }}
                />
                <Wrapper>
                    <Box className="breadcrumb">
                        <div className="breadcrumItems" onClick={() => this.navigateToPage("LandingPage")}>
                            <Typography>{i18n.t("HomeTxt")}</Typography>
                        </div>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItems">
                                <Typography>{i18n.t("ORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <div className="breadcrumItems" onClick={() => this.navigateToPage("OrderManagement")}>
                                <Typography>{i18n.t("MYORDERSTXT")}</Typography>
                            </div>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Typography>{i18n.t("RETURNTXT")}</Typography>
                        </>
                    </Box>
                    <Typography weight="bold" size="xl">{i18n.t("RETURNITEMTXT")}</Typography>
                    <Box className="choose">
                        <Typography weight="bold" size="xl">{i18n.t("CHOOSERETURNITEMTXT")}</Typography>
                    </Box>

                    <Box className="item">
                        <Box className="leftDelivered">
                            <CustomCheckbox
                                checked={true}
                                id="isAccept"
                                onValueChange={() => { }} />
                            <Box className="itemLeft">
                                <Box className="imgBox">
                                    <img src={this.state.returnItem.attributes?.catalogue.attributes.images?.[0].url} alt="product-image" width={80} />
                                </Box>
                                <Box className="itemText">
                                    <Typography size="base" weight="bold">{this.state.returnItem.attributes?.catalogue.attributes.name}</Typography>
                                    <Typography>{i18n.t("QTYTXT")} - {this.state.returnItem.attributes?.quantity}</Typography>
                                    <Typography>{i18n.t("DASHBOARD.TABLEHEADER.ORDERID")} - {this.state.returnItem.attributes?.order_number}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="itemRight">
                            <Box className="reason">
                                <Typography>{i18n.t("RETURNREASONTXT")}</Typography>
                                <Select
                                    placeholder={i18n.t("SELECTREASONTXT")}
                                    name="status"
                                    id="selectOption"
                                    options={this.state.reason}
                                    onChange={option => this.handleSelectReason(option?.value)}
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            padding: "10px",
                                            width: "250px",
                                            borderColor:this.state.errorColor
                                        }),
                                    }}
                                    components={{ IndicatorSeparator: null }}
                                />
                            </Box>
                            <Box className="reason">
                                <Box />
                                <CustomButton
                                    onClickHandle={this.doReturnItemApi}
                                    display={'primary'}
                                    styles={{ height: "56px", width: "200px" }}
                                    themes="dark">{i18n.t("CONTINUETXT")}</CustomButton>
                            </Box>

                        </Box>
                    </Box>
                </Wrapper>
                <CustomSnackBar
                        data-test-id="CustomSnackBar"
                        open={this.state.showToast}
                        errorMessage={this.state.toastMessage}
                        onClose={this.handleCloseSnackBar}
                        severity={this.state.toastType}
                    />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled("div")({
    padding: "14px 50px",
    "width": "100%",
    boxSizing: "border-box",
    maxWidth: "1980px",
    // margin:"auto",
    "& .breadcrumb": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem",
        textColor: "#333333",
        padding: "0.5rem 0"
    },
    "& .breadcrumItems": {
        cursor: "pointer",
    },
    "& .choose": {
        margin: "2rem 1rem"
    },
    "& .item": {
        display: "flex",
        justifyContent: "space-between",
        margin: "1rem 0",
        padding: "1rem",
        "& .itemLeft": {
            display: "flex",
            gap: 10,
            "& .imgBox": {
                maxWidth: "80px",
                border: "1px solid #DBDBDB",
                borderRadius: "4px",
                overflow: "hidden"
            }
        },
        "& .itemText": {
            display: "flex",
            flexDirection: "column",
            gap: 5,
        },
        "& .itemRight": {
            display: "flex",
            gap: 60,
            justifyContent: "flex-end",
            "& .reason": {
                display: "flex",
                flexDirection: "column",
                gap: 10,
                justifyContent: "space-between"
            }
        }
    },
    "& .leftDelivered": {
        display: 'flex',
        gap: 20,
        alignItems: "center",
        "& .delivery": {
            display: "flex",
            gap: 10,
            alignItems: "center"
        }
    }

})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
