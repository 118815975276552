import React from 'react';
import DropDownMenu from "../../../components/src/design-system/DropDownMenu.web";
import LanguageSelectorController , {Props} from "./LanguageSelectorController.web"
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { CountryList } from './utilities';
export default class LanguageSelector extends LanguageSelectorController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {language} = this.state;
        return (
            <DropDownMenu 
                handleTarget={this.handleLanguage} 
                label={language.code} 
                btnDisplay="textButton" 
                btnStyle={customeStyle.langBtn} 
                listData={CountryList} 
                upArrowIcon={<ExpandLess />} 
                downArrowIcon={<ExpandMore />}
                rightIcon={<img src={language.flag} alt="Right Icon" />}
                index={0} />)
    }
}

const customeStyle = {
    langBtn: {
        background: '#fff',
        minHeight: "50px",
        color: "#333",
        textTransform: "capitalize"
    }
}