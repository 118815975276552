import React, { Component } from "react";
import {Box, styled} from "@material-ui/core";
import Typography from "./Typography.web";
import CustomButton from "./CustomButton.web";

type MyProps = {
    image?: string;
    title: string;
    ImgStyle? :any;
    display: string;
    description?: string;
    descriptionStyle?: any;
    onClickHandle: () => void;
    price: string;
    onClickImage?: () => void
};

const ProductWrap = styled(Box)({
    "&.cardContainer":{
        "borderRadius": 4,
        "border": "1px solid #DFE0E0",
        "background": "var(--Accent, #FFF)",
        "boxShadow": "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
        "height": "auto",
        "width": "210px",
        "gap": "4px",
        "textAlign": "center",
        paddingTop: "20px",
        paddingBottom: "20px",
        paddingLeft: "25px",
        paddingRight: "25px",
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        justifyContent: "space-between",
        "& img": {
            height: "111px",
            width: "100%",
            objectFit: "contain"
        },
        "@media(max-width: 560px)":{
            width: "100%"
        }
    },
    "& .cardFooter":{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .price": {
        "color": "#000",
        "fontFamily": "Roboto",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": 500,
        "lineHeight": "normal",
        textAlign: "left"
    },
    "& .qtyStyle": {
        "color": "#666",
        "fontFamily": "Roboto",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": 400,
        "lineHeight": "normal",
        textAlign: "left"
    },
    "& .titleStyle": {
        "color": "#000",
        "fontFamily": "Roboto",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": 400,
        "lineHeight": "124.219%",
        textAlign: "left"
    },
    "&.cardContainer2":{
        padding: "0px",
        "& .titleStyle": {
            "padding": "22px",
            "textAlign": "center",
            "fontFamily": "Roboto",
            "fontSize": "24px",
            "fontWeight": "600",
            "color": "#000"
        },
        "& img":{
            height: "auto !important"
        }
    }
});

class ProductCard extends Component<MyProps> {

    constructor(props: any) {
        super(props);
    }

    ProductStyle1 = () => {
        const {description, image, title,  price, onClickHandle, onClickImage} = this.props;
        return (<ProductWrap className="cardContainer" >
                <img src={image} alt="" onClick={onClickImage} />
                <Typography><Box className="titleStyle">{title}</Box></Typography>
                <Typography><Box className="qtyStyle">{description}</Box></Typography>
                <Box className="cardFooter">
                    <Typography><Box className="titleStyle">{price}</Box></Typography>
                    <CustomButton
                        themes="dark"
                        onClickHandle={onClickHandle}
                        display="light"
                        styles={{paddingTop: "8px", paddingLeft: "21px", paddingRight: "21px", paddingBottom:"8px" }}
                    >Add</CustomButton>
                </Box>
            </ProductWrap>)
    }

    ProductStyle2 = () => {
        const {image, title, onClickHandle, onClickImage} = this.props;
        return (<ProductWrap className="cardContainer cardContainer2" onClick={onClickHandle}>
                    <img src={image} alt="" onClick={onClickImage}/>
                    <Typography size="base"><Box className="titleStyle">{title}</Box></Typography>
                </ProductWrap>)
    }

    render(): React.ReactNode {
        const {display} = this.props;
        return (
            display === "default" ? this.ProductStyle1() : this.ProductStyle2()
        );
    }
}

export default ProductCard;