import React from "react";

// Customizable Area Start
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Carousel from "react-material-ui-carousel";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ProductCard from "../../../components/src/design-system/ProductCard.web";
import ProductCarouselController, {
  Props
} from "./ProductCarouselController.web";
import CatalogueCard from "../../catalogue/src/CatalogueCard.web";
// Customizable Area End

export default class ProductCarousel extends ProductCarouselController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSlide(items: any, index: number) {
    const {addToCart} = this.props;
    let pStyle = this.state.productStyle;
    return (<Box className="slide" key={index}>
        {items.length > 0 && items.map((item: any) => {
            if(item.pStyle === "brands"){
                return <ProductCard 
                    title={item.name}
                    price={item.price}
                    description={item.qty}
                    image={item.image}
                    display={pStyle}
                    onClickHandle={addToCart}
                /> 
            }else{
                return <CatalogueCard 
                    id={item.id}
                    title={item.name}
                    price={item.price}
                    description={item.qty}
                    image={item.image}
                    display={pStyle}
                    navigation={this.props.navigation}
                    onClickHandle={addToCart}
                /> 
            }
           
        })}
    </Box>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {theme, sectionTitle, backgroundImage, moreBtnTxt} = this.state;
    const {onClickHandler, itemId} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
        <ProductWrap className={`${theme} carouselWrapper`}>
                {backgroundImage && <img src={backgroundImage} alt="" className="bg-image" /> }
                <Box className="container">
                    <Box className="productHeader">
                        <label className="title">{sectionTitle}</label>
                        <CustomButton 
                            onClickHandle={onClickHandler}
                            display={'textButton'}
                            itemId={itemId}
                            themes="dark">{moreBtnTxt}</CustomButton>
                    </Box>
                    <Box className="categoryWrapper">
                        <Carousel
                            autoPlay={false}
                            navButtonsAlwaysVisible={true}
                            animation="slide"
                            indicators={false}
                            cycleNavigation={false}
                            NextIcon={<ChevronRightIcon />}
                            fullHeightHover={false}
                            navButtonsWrapperProps={{
                            className: "navButtonWrapper"
                            }}
                        >
                        {this.state.slider_records.map((item:any, index: number) => {return this.renderSlide(item, index)})}
                            
                        </Carousel>
                    </Box>
                </Box>
        </ProductWrap>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const ProductWrap =  styled(Box)({
"& .productHeader":{
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    "& .title":{
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "normal"
    }
},
"& .container":{
    maxWidth: "1440px",
    padding: "0px 40px",
    margin: "auto",
    zIndex: 1,
    width: "1440px",
    boxSizing: "border-box",
    "@media(max-width: 1439px)":{
        maxWidth: "95%",
        width: "95%"
    }
},
"&.carouselWrapper":{
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    padding: "48px 0px",
    background: "white",
    position: "relative",
},
"& .categoryWrapper":{
    "& > div":{
        overflow: "visible !important"
    }
},
"& .bg-image":{
    position: "absolute",
    zIndex: 0,
    left: "0",
    top: "0",
    width: "100%",
    height: "100%"
},
"& button":{
    padding: "0 !important"
},
"&.dark":{
    padding: "32px 0px 56px",
    "& .title":{
        color: "#fff"
    },
    "& .productHeader button":{
        color: "#fff !important"
    }
},
"&. navButtonWrapper": {
    display: "flex",
    alignItems: "center",
    "& button": {
        "width": "40px",
        "height": "40px",
        "boxSizing": "border-box",
        "minWidth": "40px",
        margin: 0,
    }
},
"& .slide":{
    display: "flex",
    gap: "20px",
    justifyContent: "start",
    "@media(max-width: 560px)":{
        justifyContent: "center",
    }
}
});
// Customizable Area End