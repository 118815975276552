import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import {getStorageData} from "../../../framework/src/Utilities";
// Customizable Area Start
import {NotificationInterface} from "./NotificationsController";
import { imgBell } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string, params: Object) => void;
    getParam: (param: string, alternative?: string) => string;
    goBack: () => void;
  };
  id: string;
  isFromHeader?: boolean
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notifications: Array<NotificationInterface>;
  selected_records: NotificationInterface | null;
  token: string;
  anchorEl: null | Element;
  language: string;
  showToast: boolean;
  toastMessage: string;
  toastSuccess: "error" | "warning" | "info" | "success";
  loader: boolean;
  userId: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteNotificationCallId: string = "";
  profileApiCallId: string= "";
  markAllAsReadCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
        notifications: [],
        selected_records: null,
        token: "",
        anchorEl: null,
        language: "en",
        showToast: false,
        toastMessage: "",
        toastSuccess: "success",
        loader: false,
        userId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.notificationRead = this.notificationRead.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this)
    // Customizable Area End
  }

  async componentDidMount() {    
    // Customizable Area Start
    const lang = await getStorageData('language') || "en";
    const token = await getStorageData('authToken') || "";
    const auth = await getStorageData('roleInfoData') || "";
    const authData = JSON.parse(auth);

    this.setState({language: lang, token: token, userId: authData.data.id}, ()=>{
      this.getNotifications();
      languageConvertor(lang);
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.langSelect(message);
    this.receiveUserProfile(message);
    if (this.getDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleCloseSnackBars();
        this.receiveNotification(message);
    } else if (this.markAsReadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    } else if (this.deleteNotificationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    } else if(this.markAllAsReadCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.getNotifications()
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveUserProfile = (message: Message) => {
    if (this.profileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.getNotifications();
    }
  }

  langSelect = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selectedLng!= undefined && this.state.language != selectedLng){
        this.setState({language: selectedLng, loader: false}, ()=>{
          this.userLanguageUpdate();
          languageConvertor(selectedLng);
        });
      }
    }
  }

  isReadNotification = (items: NotificationInterface) => {
    return items.attributes.is_read ? "notificationBox isRead" : "notificationBox"
  }


  receiveNotification = (message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.closePopover();
    this.setState({notifications: responseJson.data, selected_records: null, loader: false})
  }

  handleCloseSnackBars = () => {
    this.setState({showToast: false})
  }

  iconBellProps = {
    source: imgBell,
  };

  openPopover = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, items: NotificationInterface) => {
    this.setState({ anchorEl: event?.currentTarget, selected_records: items });
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
  };

  getNotifications() {
    this.setState({loader: true, selected_records: null});
    const notificationMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDataCallId = notificationMsg.messageId;
    notificationMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.endPoint);
    notificationMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        language: this.state.language,
        token: this.state.token ? this.state.token : ""
      })
    );
    notificationMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getDataMethod);
    runEngine.sendMessage(notificationMsg.id, notificationMsg);
  }

  notificationRead(notiId?: string) {
    this.setState({loader: true});
    let endpoint = configJSON.endPoint;
    if(this.state.selected_records){
      const {id} = this.state.selected_records;
      endpoint = `${configJSON.endPoint}/${id}`
    }
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markAsReadCallId = request.messageId;
    request.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    request.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        language: this.state.language,
        token: this.state.token ? this.state.token : ""
      })
    );
    request.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.markAsReadMethod);
    runEngine.sendMessage(request.id, request);
  }

  deleteNotification(id?: number) {
    this.setState({loader: true});
    const requested_id = this.state.selected_records?.id || id;
    const deleteMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteNotificationCallId = deleteMsg.messageId;
    deleteMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.endPoint}/${requested_id}`);
    deleteMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );
    deleteMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.deleteMethod);
    runEngine.sendMessage(deleteMsg.id, deleteMsg);
  }

  userLanguageUpdate() {
    this.setState({ loader: true });

    const requestBody = new FormData();
    requestBody.append("data[attributes][language]", this.state.language);
    const headers = {token: this.state.token};
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.profileApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.userEndpoint}?id=${this.state.userId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchMethod);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), requestBody);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }
  
  markAllAsRead () {
    this.setState({loader: true});
    const markAllRead = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.markAllAsReadCallId = markAllRead.messageId;
    markAllRead.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.markAllAsReadEndPoint + `${this.state.language}`);
    markAllRead.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token
      })
    );
    markAllRead.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.patchMethod);
    runEngine.sendMessage(markAllRead.id, markAllRead);
  }
  // Customizable Area End
}
