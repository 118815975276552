Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.apiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.new_product_text = "New Launches";
exports.dealoftheday_text = "Deals of the Day";
exports.diagnostics_text = "Diagnostics Device";
exports.best_sellers_text = "Best Sellers";
exports.hospital_supplies_text = "Hospitals Supplies";
exports.housekeeping_text = "Housekeeping and Cleaning";
exports.featureBrands_text = "Featured Brands";
exports.getCategoryEndPoint = "bx_block_categories/categories";
exports.getBrandsEndPoint = "bx_block_catalogue/brands";
exports.getNewProductEndPoint = "bx_block_catalogue/catalogues/filter_products?limit=12";
exports.landingPageEndPoint = "bx_block_landingpage2/landingpage_contant"
exports.customerLandingEndPoint = "bx_block_categories/categories/dashboard_categories_list";
exports.customerPageEndPoint = "bx_block_landingpage2/landingpage_contant/landingpage_content_list?content_type=header_content,service_content"
exports.categories = [31, 16, 32, 17, 14 , null]
// Customizable Area End