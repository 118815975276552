Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.productMapDataTab = [
    'All Products',
    'In Stock',
    'Out of Stock',
]

exports.productTabsData = [
    { label: 'All Products' },
    { label: 'In Stock' },
    { label: 'Out of Stock' },
];

// use when status !== (Active | Inactive)
exports.productMapStatusColumn = {
    'In Stock': 'Active',
    'Out of Stock': 'Inactive'
}
