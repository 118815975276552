// Customizable Area Start
import React from "react";
import { Box, Grid, styled, Tooltip } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Typography from "../../../components/src/design-system/Typography.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import RevenueChart from "../../../components/src/design-system/RevenueChart.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import Loader from "../../../components/src/design-system/Loader.web";
import CustomBarChart from "../../../components/src/CustomBarChart.web";
import CustomDonutChart from "../../../components/src/CustomDonutChart.web";
import * as assets from "./assets";
import i18n from "i18next";
import AdminDashboardController, { Props } from "./AdminDashboardController";

export default class AdminDashboard extends AdminDashboardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t } = i18n;
    const { dashboardData, revenueAndSalesVolume, salesByCategory } =
      this.state;
    const cards = [
      {
        label: t("DASHBOARD.CARDS.TOTALSALESTXT"),
        icon: assets.totalSalesIcon,
        value: dashboardData.sales,
      },
      {
        label: t("DASHBOARD.CARDS.TOTALORDERSTXT"),
        icon: assets.totalOrdersIcon,
        value: dashboardData.orders,
      },
      {
        label: t("DASHBOARD.CARDS.TOTALPRODUCTSTXT"),
        icon: assets.totalProductsIcon,
        value: dashboardData.products,
      },
    ];
    return (
      <NavigationMenu navigation={this.props.navigation} id="">
        <Loader loading={false} />
        <AdminDashboardWrapper>
          <Box className="main-content">
            <Box display="flex" justifyContent="space-between" marginBottom={4}>
              <Box display="flex" alignItems="center" gridGap={30}>
                <Box color="#0F172A" fontSize={24}>
                  <Typography weight="bold">
                    {t("DASHBOARD.DASHBOARDTXT")}
                  </Typography>
                </Box>
                <Box className="date-range-picker">
                  <CustomDateRangePicker
                    displayDateFormat="MMM DD"
                    markingType="period"
                    hidePlaceHolder2
                    markedDates={this.getMarkedDates()}
                    onDayPress={this.handleDayPress}
                    onChange={this.handleChangeDate}
                  />
                </Box>
              </Box>
              <StyledTooltip
                interactive
                title={
                  <Box className="menu">
                    <Box alignItems="flex-start">
                      <img src={assets.shareIcon} />
                      {t("DASHBOARD.SHARETXT")}
                    </Box>
                    <Box>
                      <img src={assets.downloadIcon} />
                      {t("DASHBOARD.DOWNLOADTXT")}
                    </Box>
                  </Box>
                }
              >
                <MoreHorizIcon
                  data-test-id="context-menu-button"
                  className="context-menu-button"
                />
              </StyledTooltip>
            </Box>

            <Grid container spacing={4}>
              {cards.map((card, index) => (
                <Grid key={`${card.label}_${index}`} item lg={4} xs={12}>
                  <Box className="overview-card">
                    <img src={card.icon} alt={card.label} height={64} />
                    <Box>
                      <Box color="#666" marginY="5px">
                        <Typography>{card.label}</Typography>
                      </Box>
                      <Box className="overview-card-value">
                        <Typography weight="semiBold">{card.value}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}

              <Grid item lg={8} xs={12}>
                <Box padding="20px">
                  <Typography size="xs" weight="semiBold">
                    {t("DASHBOARD.CARDS.REVENUETXT")}
                  </Typography>
                  <Box fontSize={30} lineHeight="40px" marginY={1}>
                    <Typography weight="bold">
                      {this.formatArabicCurrency(revenueAndSalesVolume.revenue)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    color={
                      revenueAndSalesVolume.revenueGrowthPercentage < 0
                        ? "#D97706"
                        : "#059669"
                    }
                    marginBottom="3rem"
                    gridGap={5}
                  >
                    <img
                      src={
                        revenueAndSalesVolume.revenueGrowthPercentage < 0
                          ? assets.decreasedIcon
                          : assets.revenueIncreasedIcon
                      }
                    />
                    <Typography weight="bold">
                      {this.formatPercentage(revenueAndSalesVolume.revenueGrowthPercentage)}
                    </Typography>
                  </Box>
                  <RevenueChart
                    data={revenueAndSalesVolume.data}
                    items={[
                      {
                        label: t("DASHBOARD.CARDS.REVENUETXT"),
                        color: "#2563EB",
                        dataKey: "revenue",
                      },
                    ]}
                  />
                </Box>
              </Grid>

              <Grid item lg={4} xs={12}>
                <Box padding="20px">
                  <Typography size="xs" weight="semiBold">
                    {t("DASHBOARD.SALESVOLUMETXT")}
                  </Typography>
                  <Box fontSize={30} lineHeight="40px" marginY={1}>
                    <Typography weight="bold">
                      {revenueAndSalesVolume.salesVolume.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    color={
                      revenueAndSalesVolume.salesVolumeGrowthPercentage < 0
                        ? "#D97706"
                        : "#059669"
                    }
                    marginBottom="3rem"
                    gridGap={5}
                  >
                    <img
                      src={
                        revenueAndSalesVolume.salesVolumeGrowthPercentage < 0
                          ? assets.decreasedIcon
                          : assets.revenueIncreasedIcon
                      }
                    />
                    <Typography weight="bold">
                      {this.formatPercentage(revenueAndSalesVolume.salesVolumeGrowthPercentage)}
                    </Typography>
                  </Box>
                  <CustomBarChart
                    data={revenueAndSalesVolume.data}
                    items={[
                      {
                        label: t("DASHBOARD.SALESVOLUMETXT"),
                        color: "#2563EB",
                        dataKey: "salesVolume",
                      },
                    ]}
                  />
                </Box>
              </Grid>

              <Grid item lg={4} md={6} xs={12}>
                <Box padding="20px 10px 20px 20px">
                  <Typography size="xs" weight="semiBold" color="charcoal">
                    {t("DASHBOARD.SALESBYCATEGORYTXT")}
                  </Typography>
                  <CustomDonutChart items={salesByCategory} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </AdminDashboardWrapper>
      </NavigationMenu>
    );
  }
}

const AdminDashboardWrapper = styled(Box)({
  fontFamily: "Roboto",
  padding: 10,
  "& .date-range-picker .label": {
    justifyContent: "space-evenly",
  },
  "& .overview-card": {
    display: "flex",
    padding: "40px 24px",
    gap: "24px",
    boxShadow: "0px 0px 24px 0px #00000014",
    "& .overview-card-value": {
      fontSize: 24,
      wordBreak: "break-all",
    },
  },
});

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "black",
    fontSize: 14,
    borderRadius: 8,
    border: "1px solid #E2E8F0",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
  },
  "& .menu": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: 1,
    paddingY: 0.5,
    gap: "8px",
    width: 160,
    "& > div": {
      display: "flex",
      padding: "8px",
    },
    "& img": {
      marginRight: 4,
    },
  },
});
// Customizable Area End
