import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export type Props =  {
    // Customizable Area Start
    navigation?:  {
      navigate: (to: string, params: object) => void;
      getParam: (param: string) => string;
      goBack: () => void;
    };
    sectionTitle: string;
    theme: "light" | "dark";
    description?: string;
    backgroundImage: string | null;
    itemId: number,
    onClickHandler: (event: Event, index?: number | string) => void;
    addToCart: () => void;
    productStyle: string;
    records: Array<any>;
    responsiveItem?: number;
    moreBtnTxt: string;
    loading?: boolean
    language?: string;
    callback_id?: string;
    // Customizable Area End
}

interface S {
  // Customizable Area Start
    sectionTitle: string;
    theme: "light" | "dark";
    description?: string;
    backgroundImage: string | null;
    productStyle: string;
    records: Array<any>;
    responsiveItem?: number;
    slider_records: any;
    moreBtnTxt: string;
    loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductCarouselController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sectionTitle: "",
      theme: "light",
      records: [],
      description: "",
      backgroundImage: "",
      slider_records: [],
      moreBtnTxt: "",
      productStyle: "default",
      loading: this.props.loading || true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId != this.props.callback_id){
        this.initialSetup(this.props);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount = async() => {
    this.initialSetup(this.props);
  }

  initialSetup = (newProps: Readonly<Props>) => {
    const {records} = newProps;
    let newArray = [];
    let newData = [...records];
    let items = newProps.responsiveItem ?  newProps.responsiveItem : this.responsItem();
    while (newData.length > 0) newArray.push(newData.splice(0, items));
    this.setState({
        sectionTitle: newProps.sectionTitle,
        theme: newProps.theme,
        records: newProps.records,
        description: newProps.description,
        backgroundImage: newProps.backgroundImage,
        moreBtnTxt: newProps.moreBtnTxt,
        slider_records: newArray,
        productStyle: newProps?.productStyle
    })
  }

  responsItem = ()  => {
    const width = window.innerWidth;
        let items = 0;
        if(width > 1280){
            items = 6
        }else if(width > 1080 && width <= 1280){
            items = 5
        }else if(width > 800 && width <= 1080){
            items = 4
        }else if(width > 768 && width <= 800){
            items = 3
        }else if(width > 560 && width <= 767){
            items = 2
        }else if(width <= 560){
            items = 1
        }
        return items
    }
  // Customizable Area End
}
