// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { getStorageData } from "../../../framework/src/Utilities";
import i18n from "i18next";


export const configJSON = require("./config");

export type FooterItem = {
    label: string
    screenId?: string
    screenName?: string
    onClick?: () => void
}

export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    theme?: string
}

interface S {
    language: string
    showVendorLogin: boolean
    lists: {name: string, items: FooterItem[]}[]
}

interface SS {
    id: string;
}

export default class FoooterController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            language: "en",
            showVendorLogin: false,
            lists: []
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        const [role, storedLanguage] = await Promise.all([getStorageData("role"), getStorageData("language")])
        const shouldShowVendorLogin = window.location.pathname === '/' || (window.location.pathname === '/LandingPage' && role !== 'vendor')
        const language = storedLanguage || "en"
        this.setState({ language, showVendorLogin: shouldShowVendorLogin, lists: this.getFooterLists(language, shouldShowVendorLogin) }, async () => {
        });
    }

    receive = async (from: string, message: Message) => {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            const lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if (lang !== this.state.language) {
                this.setState({ language: lang, lists: this.getFooterLists(lang) })
            }
        }
    }

    getFooterLists = (lang: string, shouldShowVendorLogin?: boolean) => {
        languageConvertor(lang)
        const showVendorLogin = shouldShowVendorLogin !== undefined ? shouldShowVendorLogin : this.state.showVendorLogin
        const { t } = i18n
        const companyItems = [
            {
                label: t('ABOUTUSTXT'),
                screenId: "AboutUs"
            },
            {
                label: t('CUSTOMERSPEAKTXT'),
                screenId: "ContentManagement",
                screenName: "CustomersSpeak"
            },
            {
                label: t('BPARTNERTXT'),
                screenId: "ContentManagement",
                screenName: "BusinessPartnership"
            },
            {
                label: t('ContactTxt'),
                screenId: "Contactus"
            },
            {
                label: t('CAREERTXT'),
                screenId: "ContentManagement",
                screenName: "Careers"
            }
        ];

        const policiesItems = [
            {
                label: t('TERMSCONDITIONTXT'),
                screenId: "TermsConditionsUsers"
            },
            {
                label: t('PRIVACYPOLICYTXT'),
                screenId: "ContentManagement",
                screenName: "PrivacyPolicy"
            },
            {
                label: t('FEETXT'),
                screenId: "ContentManagement",
                screenName: "FeesPayments"
            },
            {
                label: t('SHIPPINGPOLICYTXT'),
                screenId: "ContentManagement",
                screenName: "ShippingPolicy"
            },
            {
                label: t('RETURNPOLICYTXT'),
                screenId: "ContentManagement",
                screenName: "ReturnPolicy"
            }
        ];
        const servicesItems = [
            {
                label: t('OURMEDICINETXT'),
                screenId: "ContentManagement",
                screenName: "OrderMedicine"
            },
            {
                label: t('HEALTHCAREPRODUCTTXT'),
                screenId: "ContentManagement",
                screenName: "HealthCareProducts"
            },
            {
                label: t('HOSPITALPRODUCTTXT'),
                screenId: "ContentManagement",
                screenName: "HospitalsProduct"
            }
        ];
        const shoppingItems = [
            {
                label: t('BROWSEAZTXT'),
                screenId: "ContentManagement",
                screenName: "BrowseAZ"
            },
            {
                label: t('BROWSEMANUFACTURETXT'),
                screenId: "ContentManagement",
                screenName: "BrowseManufacturers"
            },
            {
                label: t('HEALTHARTICLESTXT'),
                screenId: "ContentManagement",
                screenName: "HealthArticles"
            },
            {
                label: t('OFFERTXT'),
                screenId: "ContentManagement",
                screenName: "OffersCoupons"
            },
            {
                label: t('FAQSTXT'),
                screenId: "HelpCentre"
            }
        ];

        return [
            {
                name: t('COMPANYTXT'),
                items: showVendorLogin ?
                    [
                        {
                            label: t('VENDORLOGIN'),
                            screenId: "VendorEmailAccountLogin"
                        }, ...companyItems
                    ].slice(0, 5) : companyItems
            },
            {
                name: t('OURPLOICIESTXT'),
                items: policiesItems
            },
            {
                name: t('OURSERVICESTXT'),
                items: servicesItems
            },
            {
                name: t('SHOPPINGTXT'),
                items: shoppingItems
            },
        ]
    }

    handleClickFooterItem = (item: FooterItem) => {
        item.screenId && this.navigateToPage(item.screenId, item.screenName)
        item.onClick?.()
    }

    navigateToPage = (target: string, screenName?: string) => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), target);
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        screenName && request.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(request);
    }

}
// Customizable Area End
