Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "bx_block_notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.patchMethod = "PATCH";
exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.userEndpoint = "account_block/accounts/update_profile"
exports.markAllAsReadEndPoint = "bx_block_notifications/notifications/update_all?language="
// Customizable Area End