
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "catalogue/catalogues/";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.addressesApiEndpoint ="bx_block_order_management/addresses"
exports.home = "Home"
exports.allCategories = "All Categories"
exports.productHightLight = "Product Highlights"
exports.units = "Units"
exports.oneunit = "of 1 unit"
exports.addToCart = "Add To Cart"
exports.buyNow = "Buy Now"
exports.earliest = "Earliest delivery by"
exports.tomorrow = "tomorrow"
exports.deliveryTo = "Delivery to: "
exports.similarProductApi = "/bx_block_catalogue/catalogues/similar_product_list?id="
exports.similarProduct = "Similar Products"
exports.frequently = "Frequently Bought Together"

exports.newOrderApiEndPoint = "bx_block_order_management/orders/new_order"

// Customizable Area End
