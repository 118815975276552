import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikValues } from 'formik';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "framework/src/Utilities";
import { CustomerListingInterface } from "./CustomerListController";

// Customizable Area Start
import i18n from "i18next";

export interface CustomerForm {
    customerName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    city: string;
    status: string;
}

export interface CustomerFormErrors {
    customerName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    city: string;
    status: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    isOpenModal: boolean
    onCloseModal: () => void
    navigation?: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative: string) => string | Object;
        goBack: () => void;
      }
    defaultCustomer: CustomerListingInterface
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    customerName: string;
    companyName: string;
    email: string;
    phoneNumber: string;
    city: string;
    status: string;
    id: string;
    isVisible: boolean;
    token: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}

export default class EditCustomerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef: any = React.createRef();
    emailReg: RegExp;
    apiEditCustomerId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.emailReg = /\w+/;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        this.state = {
            customerName: this.props.defaultCustomer.companyName,
            companyName: this.props.defaultCustomer.companyName,
            email: this.props.defaultCustomer.email,
            phoneNumber: this.props.defaultCustomer.phoneNumber,
            city: this.props.defaultCustomer.city,
            status: this.props.defaultCustomer.status,
            id: this.props.defaultCustomer.id,
            isVisible: false,
            token: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.getTokenFn();
    }
    getTokenFn = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken")
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    validateEditCustomer = (values: CustomerForm) => {
        const errors: Partial<CustomerFormErrors> = {};

        if (!values.email) {
            errors.email = i18n.t('CUSTOMER_EMAIL_ERROR');
        }
        if (!values.companyName) {
            errors.companyName = i18n.t('CUSTOMER_COMPANY_ERROR');
        }
        if (!values.customerName) {
            errors.customerName = i18n.t('CUSTOMER_CUSTOMER_ERROR');
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = i18n.t('CUSTOMER_PHONE_NUMBER_ERROR');
        }
        if (!values.city) {
            errors.city = i18n.t('CUSTOMER_CITY_ERROR');
        }
        if (!values.status) {
            errors.status = i18n.t('CUSTOMER_STATUS_ERROR');
        }

        return errors;
    };

    onEdit = (values: FormikValues) => {
        this.setState({ customerName: values.customerName, companyName: values.companyName, email: values.email, phoneNumber: values.phoneNumber, status: values.status, city: values.city, id: values.id }, () => { this.doEditCustomer() });
    };

    // Customizable Area Start
    doEditCustomer(): boolean {
        const header = {
            "Content-Type": configJSON.editCustomerApiContentType,
            token: this.state.token,
        };
        const attrs = {
            email: this.state.email,
            full_phone_number: this.state.phoneNumber,
            full_name: this.state.customerName,
            activated: this.state.status === "Active",
            company_name: this.state.companyName,
            location_string: this.state.city
        };

        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEditCustomerId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editCustomerAPiEndPoint}${this.state.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editCustomerAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.props.onCloseModal()

        return true;
    }
    // Customizable Area End
}
