import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import React from "react";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation:  {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
}

export interface S {
  // Customizable Area Start
  isVisble: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ProfileReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      isVisble: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    const loglang = await getStorageData('language') || "en";
     await setStorageData("language", loglang);
  }
  
  handleRedirect(){
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  // Customizable Area End
}
