import React from "react";
import { Box, styled } from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import StyledRadio from "../../../components/src/design-system/StyledRadio.web";
import FilterOrderController, { Props } from "./FilterOrderController";
import i18n from "i18next";

const status = [
  { label: "MYORDERSTXT", value: "", disabled: false },
  {
    label: "DASHBOARD.STATUS.DELIVEREDTXT",
    value: "delivered",
    disabled: false,
  },
  { label: "REORDERLISTTXT", value: "reorder", disabled: false },
  { label: "CANCELLEDORDERSTXT", value: "cancelled", disabled: false },
  { label: "RETURNORDERTXT", value: "returned", disabled: false },
];
const time = [
  { label: "LASTDAYSTXT", value: 30, disabled: false, is_translation: true },
  { label: "2023", value: 2023, disabled: false, is_translation: false },
  { label: "2022", value: 2022, disabled: true, is_translation: false },
  { label: "2021", value: 2021, disabled: true, is_translation: false },
  { label: "2020", value: 2020, disabled: true, is_translation: false },
  { label: "OLDERTXT", value: 1, disabled: false, is_translation: true },
];

export default class FilterOrder extends FilterOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      // Customizable Area Start
      <Wrapper>
        <Box className="title">
          <Typography size="base" weight="bold" color="black">
            {i18n.t("FILTERTXT")}
          </Typography>
        </Box>
        <Box className="order status">
          <Typography
            transform="uppercase"
            size="base"
            weight="bold"
            color="black"
          >
            {i18n.t("ORDERSTATUSTXT")}
          </Typography>
          <Box className="statusList">
            {status.map((status, index) => {
              return (
                <Box className="option-item" key={index}>
                  <Typography
                    color={
                      status.value === this.state.orderStatus
                        ? "primary"
                        : "black"
                    }
                  >
                    {i18n.t(status.label)}
                  </Typography>
                  <StyledRadio
                    checked={status.value === this.state.orderStatus}
                    onChange={this.handleCheckSatus}
                    value={status.value}
                    disabled={status.disabled}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box className="order">
          <Typography
            transform="uppercase"
            size="base"
            weight="bold"
            color="black"
          >
            {i18n.t("ORDERTIMETXT")}
          </Typography>
          <Box className="statusList">
            {time.map((status, index) => {
              return (
                <Box className="option-item" key={index}>
                  <Typography
                    color={
                      status.value === this.state.orderTime
                        ? "primary"
                        : "black"
                    }
                  >
                    {status.is_translation
                      ? i18n.t(status.label)
                      : status.label}
                  </Typography>
                  <StyledRadio
                    checked={status.value === this.state.orderTime}
                    onChange={this.handleCheckTime}
                    value={status.value}
                    disabled={status.disabled}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Wrapper>
      //Customizable Area End
    );
  }
}
// Customizable Area Start
const Wrapper = styled("div")({
  background: "white",
  borderRadius: "6px",
  minWidth: "280px",
  maxHeight: "700px",
  "& .title": {
    borderBottom: "1px solid #00000029",
    padding: "0.5rem 1rem",
  },
  "& .order": {
    padding: "1rem",
  },
  "& .status": {
    borderBottom: "1px solid #00000029",
  },
  "& .option-item": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .statusList": {
    margin: "1rem 0",
  },
});
//Customizable Area End
