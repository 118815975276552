import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikValues } from 'formik';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "framework/src/Utilities";
import resources from "./utilities";
import i18n from "i18next";

// Customizable Area Start
export interface Product {
    id: string,
    catalogue_id: string,
    name: string,
    category: string,
    categoryIds: string[],
    subCategoryIds: string[],
    sku: string,
    incomming: string,
    commited: string,
    numberAvailable: string,
    refund: boolean,
    refun_string: string,
    date: string;
    images?: { id: string, preview: string }[]
}

type typeNumberInputs = {
    name: string,
    title: string,
    type: "text" | "number";
}[]

type categoriesApiResponse = {
    data: GetCategoriesApiResponse
  }
  
  type GetCategoriesApiResponse = Array<{
    id: string
    attributes: {
      name: string;
      ar_category_name: string;
      sub_categories: {
        data: Array<{
          id: number
          attributes: {
            name: string
            ar_sub_categories_name: string
          }
        }>
      }
    }
  }>

  type EditInventoryApiResponse = {
    data: {
      type: string;
      attributes: {
        errors?: {
          [key: string]: string[]
        }
      }
    }
  }

type Category = {
    id: string
    name: string
    arabicName: string
    subCategories: Array<{
      id: string
      name: string
      arabicName: string
    }>
}

export interface Image {
    preview: string;
    file?: Blob;
    id?: string
  }

export interface InventoryForm {
    id: string;
    name: string;
    category: string;
    sku: string;
    incomming: string;
    commited: string;
    numberAvailable: string;
    refun_string: string;
    token: string
  }

export interface InventoryFormErrors {
    [key: string]: string;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    isOpenModal: boolean
    onCloseModal: () => void
    navigation?: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative: string) => string | Object;
        goBack: () => void;
      },
    defaultInventory: Product
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    id: string,
    catalogue_id: string,
    name: string,
    category: string,
    categoryIds: string[],
    subCategoryIds: string[],
    sku: string,
    incomming: string,
    commited: string,
    numberAvailable: string,
    refund: boolean,
    refun_string: string,
    token: string;
    categories: Category[];
    loading: boolean;
    images: Image[];
    oldImages: Image[];
    selectStatus: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditInventoryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef: any = React.createRef();
    getListCategoriesApiCallId = ""
    editInventoryApiCallId = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            loading: false,
            id: this.props.defaultInventory.id,
            catalogue_id: this.props.defaultInventory.catalogue_id,
            name: this.props.defaultInventory.name,
            category: this.props.defaultInventory.category,
            categoryIds: this.props.defaultInventory.categoryIds,
            subCategoryIds: this.props.defaultInventory.subCategoryIds,
            sku: this.props.defaultInventory.sku,
            refun_string: this.props.defaultInventory.refund ? 'Yes' : 'No',
            incomming: this.props.defaultInventory.incomming,
            commited: this.props.defaultInventory.commited,
            numberAvailable: this.props.defaultInventory.numberAvailable,
            refund: this.props.defaultInventory.refund,
            token: "",
            selectStatus: "selectWithYes",
            categories: [],
            images: this.props.defaultInventory.images ?? [],
            oldImages: this.props.defaultInventory.images ?? [],
        };
        
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken")
            this.setState({ token: token });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let updateStateData: Partial<S> = {
              loading: false,
            };
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
      
            this.parseApiErrorResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
      
            
            if (apiRequestCallId === this.getListCategoriesApiCallId) {
              updateStateData.categories = this.mapEditCategories(responseJson)
            }

            if (apiRequestCallId === this.editInventoryApiCallId && responseJson?.data) {
              this.handleEditInventoryResult(responseJson)
            }
            this.setState(updateStateData as Pick<S, keyof S>);
          }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        const token = (await getStorageData("authToken")) as string;
        this.setState({ token: token });
        if (token) this.callGetCategoriesApi(token)
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    numberInputs: typeNumberInputs = [{
      name: "incomming",
      title: i18n.t('INCOMMINGTXT'),
      type: "number",
    },{
      name: "commited",
      title: i18n.t('COMMITTEDTXT'),
      type: "number"
    },{
      name: "numberAvailable",
      title: i18n.t('AVAILABLETXT'),
      type: "number"
    },
  ];

    callGetCategoriesApi = (token?: string) => {
        this.setState({ loading: true });
        const headers = {
          "Content-Type": configJSON.getCategoriesApiContentType,
          token: token || this.state.token,
        };
    
        const apiGetMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getListCategoriesApiCallId = apiGetMessage.messageId;
    
        apiGetMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCategoriesApiEndpoint
        );
    
        apiGetMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        apiGetMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getCategoriesApiMethodType
        );
        runEngine.sendMessage(apiGetMessage.id, apiGetMessage);
    };

    validateInventoryForm = (values: InventoryForm) => {
      const errors: InventoryFormErrors = {};
  
      const printError = (
        value: string | undefined,
        name: string,
        msg: string
      ) => {
        if (!(value+"")?.trim() && name) {
          errors[name] = msg;
        }
      };
  
      printError(
        values.name,
        "productName",
        resources.productName.error.en
      );
  
      printError(
        values.sku,
        "SKU",
        resources.SKU.error.en
      );
  
      printError(
        values.incomming,
        "incomming",
        i18n.t('INCOMMING_ERROR')
      );
  
  
      printError(
        values.commited,
        "commited",
        i18n.t('COMMITTED_ERROR')
      );
  
  
      printError(
        values.numberAvailable,
        "numberAvailable",
        i18n.t('AVAILABLE_ERROR')
      );
  
  
      printError(
        values.refun_string,
        "Status",
        resources.status.error.en
      );
  
      if (!values.category.length) {
        errors.category = resources.category.error.en
        errors.arabicCategory = resources.category.error.ar
      }
  
      return errors;
    };

    onEdit = (formData: FormikValues) => {
      const requestBody = this.convertToFormData(formData);

      const headers = {
        token: this.state.token,
      };
      const editInventoryMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.editInventoryApiCallId = editInventoryMsg.messageId;
  
      editInventoryMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editInventoryApiEndpoint+'/'+this.state.id
      );
  
      editInventoryMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      editInventoryMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.editInventoryApiMethodType
      );
      editInventoryMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        requestBody
      );
      runEngine.sendMessage(editInventoryMsg.id, editInventoryMsg);
    };

    // Customizable Area Start

    convertToFormData = (values: FormikValues) => {
      const formData = new FormData();
      formData.append("catalogue_id", values.catalogue_id);
      formData.append("incoming", values.incomming);
      formData.append("committted", values.commited);
      formData.append("available", values.numberAvailable);
      formData.append("refund_status", values.refun_string === 'Yes' ? 'true' : 'false');
      
      
      const productOriginalImages = this.state.oldImages
      if (productOriginalImages?.length) {
        const deletedImages = productOriginalImages.filter(originalImage => this.state.images.findIndex((image) => image.id === originalImage.id) === -1)
        deletedImages.forEach((deletedImage, index) => {
          formData.append(`catalogue_images_attributes[0${index}][id]`, deletedImage.id ? deletedImage.id.toString() : '')
          formData.append(`catalogue_images_attributes[0${index}][_destroy]`, "1")
        })
      }
      this.state.images.forEach((image, index) => {
        if (image.file) {
          formData.append(`catalogue_images_attributes[${index}][image]`, image.file)
        }
      })
      return formData;
    };

    getCategoryOption = () => 
    this.state.categories.map(category => ({ key: category.id, name: category.name }))

    getEditFilteredSubCategories = (selectedCategoryIds: string[]) => {
      return this.state.categories
      .filter(category => selectedCategoryIds.includes(category.id))
      .map(category => category.subCategories).flat()
    }

    mapEditCategories(responseJson: categoriesApiResponse){
      return responseJson?.data
                ? (responseJson.data).map((record) => ({
                  id: record.id,
                  name: record.attributes.name,
                  arabicName: record.attributes.ar_category_name,
                  subCategories: record.attributes?.sub_categories.data?.map(sub_category => ({
                    id: sub_category.id.toString(),
                    name: sub_category.attributes.name,
                    arabicName: sub_category.attributes.ar_sub_categories_name
                  }))
                }))
                : [];
    }

    handleEditInventoryResult(responseJson: EditInventoryApiResponse) {
      if (responseJson.data.type === "product") {
        this.formikRef.current?.resetForm()
        this.setState({images: []})
        this.showAlert(i18n.t('SUCCESSTXT'), i18n.t('INVENTORYTXT') + ' ' + i18n.t('EDITEDTXT'))

        this.props.onCloseModal()
      }
      
      if (responseJson.data.type === "error") {
        this.parseEditInventoryApiErrorResponse(responseJson)
      }
    }

    parseEditInventoryApiErrorResponse(responseJson: EditInventoryApiResponse) {
      if (!responseJson.data.attributes.errors || !Object.keys(responseJson.data.attributes.errors).length ) {
        return;
      }
      const { errors } = responseJson.data.attributes;
  
      let allErrors = '';
      Object.keys(errors).forEach((errorKey) => {
        const val = errors[errorKey]
          if (val.length > 0) {
            const message = `${errorKey}: ${val}`
            if (allErrors.length <= 0) {
              allErrors = message;
            } else {
              allErrors = `${allErrors}\n${message}`;
            }
          }
      });
  
      this.showAlert('Error', allErrors);
    }
    // Customizable Area End
}
