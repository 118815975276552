import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface TermsConditionsTypes {
  "id": number,
  "type": string,
  attributes: {
    "created_at": string,
    "description": string
  }
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
classes: WithStyles["classes"]
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAccept: boolean;
  termsConds: TermsConditionsTypes[];
  termsCondsUserList: TermsConditionsTypes[];
isLoading: boolean;
  language:string
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsUsersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
getTermsCondsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
isAccept: false,
      termsConds: [{
        id: 1,
        type: "terms conditions",
        attributes: {
          description: "",
          created_at: ""
        }
      }],
      termsCondsUserList: [],
isLoading: true,
      language:"en"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveLanguage(message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.termsConditionsCallBack(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
recieveLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(lang != this.state.language){
        this.setState({language: lang}, ()=>{
         this.getTermsConditiondData()
          languageConvertor(lang)
        });
      }
    }
  }
  async componentDidMount(){
    const [loglang, token] = await Promise.all([getStorageData('language'), getStorageData('authToken')]);
    const language = loglang || "en"
    this.setState({language, token}, ()=>{
      languageConvertor(loglang);
      this.getTermsConditiondData();
    });
  }

  HandleCheckbox = () => {
    this.setState({
      isAccept: !this.state.isAccept
    })
  }
  HandleCancel = async () => {
    await setStorageData('termsAccepted', false);
    this.goToSignupPage();
  }

  handleAccept = async () => {
    await setStorageData('termsAccepted', this.state.isAccept);
    this.goToSignupPage();
  };

  goToSignupPage = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailAccountRegistration"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  termsConditionsCallBack = async (message: Message) => {
    const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);
    this.setState({isLoading: false});
    if(apiRequestCallId === this.getTermsCondsCallId){
        this.setState({termsConds: responseJson.data})
    }
  }

  getTermsConditiondData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getTermsCondsCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getTermsEndPoint}?language=${this.state.language}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
