Object.defineProperty(exports, "__esModule", {
    value: true
});

exports.transactionTabsMap = ["all", "completed", "pending", "failed"]

exports.transactionTabsData = [
    { label: 'All' },
    { label: 'Completed' },
    { label: 'Pending' },
    { label: 'Cancel' },
];

// use when status !== (Active | Inactive)
exports.transactionMapStatusColumn = {
    'Completed': 'Active',
    'Pending': 'In',
    'Cancel': 'Inactive',
    'Failed': 'Inactive'
}
