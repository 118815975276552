import React, { Component } from "react";
import { Box,styled,  } from "@material-ui/core";
import Typography from "../src/design-system/Typography.web";
import CheckIcon from '@material-ui/icons/Check';

type Props = {
    steps: {
        label: string,
        percentage: number,
        isCompleted: boolean,
        step: number,
    }[]
};
export default class CustomStepper extends Component<Props> {

    constructor(props: any) {
        super(props);
    }
    render(): React.ReactNode {
        function caculateMargin(step: number) {
            let margin = "0px"
            switch (step) {
                case 2:
                    margin = "-20px"
                    break;
                case 3:
                    margin = "-40px"
                    break;
                case 4:
                    margin = "-50px"
                    break;
                default:
                    break;
            }
            return margin
        }
        return (
            <StepperBox>
                {
                    this.props.steps.map((step, index) => {
                        return (
                            <Box key={index} className="step">
                                <Box className="step-item">
                                    <Box className="icon" style={{ background: step.percentage ? "#77B300" : "#DAD9D9" }}>
                                        {
                                            step.percentage > 0 && <CheckIcon htmlColor="white" fontSize="small" />
                                        }
                                    </Box>
                                    {
                                        step.step < this.props.steps.length && <Box className="connector">
                                            <Box className="connector-done" width={`${step.percentage}%`} />
                                        </Box>
                                    }
                                </Box>
                                <Box style={{ marginLeft: caculateMargin(step.step) }}>
                                    <Typography weight="bold">{step.label}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </StepperBox>
        );
    }
}

const StepperBox = styled(Box)({
    display: "flex",
    width: "700px",
    "& .step": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: 30,
        width: "100%",
        margin: 0,
    },
    "& .step-item": {
        display: "flex",
        justifyContent: "center",
        gap: 20,
        alignItems: "center",
        width: "200px",
        position: "relative",
        "& .icon": {
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            position: "absolute",
            top: 0,
            left: 0,
        }
    },
    "& .connector": {
        position: "absolute",
        top: 4,
        left: 1.5,
        width: "217px",
        height: "12px",
        background: "#EEEEEE",
        "& .connector-done": {
            background: "#77B300",
            height: "12px",
            borderRadius: "26px",
        },
    },
});