import React from "react";
import { Checkbox, MenuItem, Select, SelectProps, styled, Box } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import i18n from "i18next"
type SelectOption = {
  key: string | number;
  name: string;
}

type Props = SelectProps & {
  options: SelectOption[]
}

export default class CustomSelect extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  renderValue = (value: unknown) => {
    const selectedOption = Array.isArray(value) ?
      value
        .map(item => this.props.options.find(option => option.key === item))
        .filter(item => item)
      : this.props.options.find(option => option.key === value)
    const renderString= (Array.isArray(selectedOption) ? selectedOption.map(option => option?.name).join(", ") : selectedOption?.name) || ""

    return <div>
      {
        i18n.t(renderString)
      }
    </div>
  }

  render() {
    return (
      <SelectWrapper>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 286,
              },
            },
            getContentAnchorEl: null,
          }}
          disableUnderline
          IconComponent={KeyboardArrowDownIcon}
          {...this.props}
          renderValue={this.props.renderValue || this.renderValue}
        >
          {
            this.props.options.map(
              option =>

                <MenuItem value={option.key} key={option.key}>
                  {
                    Boolean(this.props.multiple) &&
                    <Checkbox
                      color="default"
                      className="check-box"
                      checked={Array.isArray(this.props.value) && this.props.value.includes(option.key)}
                    />
                  }
                  {i18n.t(option.name)}
                </MenuItem>
            )
          }
        </Select>
      </SelectWrapper>
    )
  }
}

const SelectWrapper = styled(Box)({
  "& .MuiSelect-root": {
    minWidth: 210,
    borderRadius: 4,
    padding: "10px 25px 10px 12px",
    background: "white",
    border:"1px solid rgba(0,0,0,0.3)"
  },
  "& .MuiSelect-icon": {
    right: 5
  },
  "& .check-box": {
    pointerEvents: "none"
  }
})