// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { removeStorageData, getStorageData } from "../../../framework/src/Utilities";
const assets = require("./assets");
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
export const configJSON = require("./config");
type SidebarItem = {
  icon: Element
  screenId?: string
  label: string
}

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  activeScreenId?: string;
}

interface S {
  language: string
}

interface SS {
  id: string;
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {

  sidebarItems : SidebarItem[] = [
    {
      icon: assets.accountInformationSvgIcon,
      label: "USERSIDEBAR.ACCOUNTINFO",
      screenId: "UserProfileBasicBlock"
    },
    {
      icon: assets.myAddressesSvgIcon,
      label: "USERSIDEBAR.MYADDRESS",
      screenId: "UserAddress"
    },
    {
      icon: assets.paymentsSvgIcon,
      label: "USERSIDEBAR.PAYMENTS",
      screenId: "StripePayments"
    },
    {
      icon: assets.notificationsSvgIcon,
      label: "USERSIDEBAR.NOTIFICATIONS",
      screenId: "CustomerNotification",
    },
    {
      icon: assets.helpsSvgIcon,
      label: "USERSIDEBAR.HELPS",
      screenId: "HelpCentre"
    },
    {
      icon: assets.contactUsSvgIcon,
      label: "USERSIDEBAR.CONTACTUS",
      screenId: "Contactus"
    },
    {
      icon: assets.logoutSvgIcon,
      label: "USERSIDEBAR.LOGOUT"
    },
  ]; 

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    const lang = await getStorageData('language') || "en";
    this.setState({language: lang}, ()=> {
      languageConvertor(lang);
    })
  }

  handleNavigation = (screenId: string, isLogout?: boolean) => {
    if (isLogout) {
      removeStorageData("authToken");
    }
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), screenId);
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }

  handleClickSidebarItem = (clickedItem: SidebarItem) => {
    if (clickedItem.screenId) this.handleNavigation(clickedItem.screenId)
    else this.handleNavigation("Home", true)
  }
}
// Customizable Area End
