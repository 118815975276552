import React from "react";
import {
    Box,
    styled,
    Grid
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import OrderDetailManagementController, { Props, configJSON } from "./OrderDetailManagementController";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
const { successIcon } = require("./assets");
import i18n from "i18next";

export default class OrderDetailManagement extends OrderDetailManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff", borderBottom: "1px solid #A6A6A6" }}
                />
                <Wrapper>
                    <Box className="breadcrumb">
                        <Box className="breadcrumItems" onClick={() => this.navigateToPage("LandingPage")}>
                            <Typography>{i18n.t("HomeTxt")}</Typography>
                        </Box>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItems">
                                <Typography>{i18n.t("ORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Box className="breadcrumItems" onClick={() => this.navigateToPage("OrderManagement")}>
                                <Typography>{i18n.t("MYORDERSTXT")}</Typography>
                            </Box>
                        </>
                        <>
                            <Typography>&gt;</Typography>
                            <Typography>{i18n.t("ORDERDETAILTXT")}</Typography>
                        </>
                    </Box>
                    <Typography size="xl" weight="bold">{i18n.t("ORDERDETAILTXT")}</Typography>
                    <Box className="orderBox">
                        <Box className="orderHeader">
                            <Box className="headerLeft">
                                <Typography color="charcoal">{i18n.t("ORDERONTXT")} {this.formatDatePlaced(this.state.order.attributes?.placed_at ?? "")}</Typography>
                                <Box className="borderLeft" />
                                <Typography color="gray">{i18n.t("ORDERNOTXT")} {this.state.order.attributes?.order_number}</Typography>
                            </Box>
                            <CustomButton
                                onClickHandle={() => { }}
                                display={'secondaryWithBorder'}
                                styles={{ height: "32px" }}
                                themes="dark">{i18n.t("VIEWINVOICETXT")}</CustomButton>
                        </Box>
                        <Box className="line" />
                        <Grid container className="orderContent">
                            <Grid item xs={4} >
                                <Box className="address">
                                    <Typography size="sm" weight="bold">{i18n.t("SHIPPINGORDERSTXT")}</Typography>
                                    <Typography>
                                        {[this.state.order.attributes?.delivery_address?.attributes.name,
                                        this.state.order.attributes?.delivery_address?.attributes.flat_no,
                                        this.state.order.attributes?.delivery_address?.attributes.address,
                                        this.state.order.attributes?.delivery_address?.attributes.city,
                                        this.state.order.attributes?.delivery_address?.attributes.state,
                                        ]
                                            .filter((add) => add)
                                            .join(", ")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4} >
                                <Box className="payemnt">
                                    <Typography size="sm" weight="bold">{i18n.t("PAYMENTMETHODTXT")}</Typography>
                                    <Typography>{i18n.t("CREDITCARDTXT")}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box className="summary">
                                    <Typography size="sm" weight="bold">{i18n.t("ORDERSUMMARYTXT")}</Typography>
                                    <Box className="summaryItem">
                                        <Typography>{i18n.t("ITEMSSUBTOTAL")}</Typography>
                                        <Typography>{i18n.t("SARTXT")}{this.state.order.attributes?.sub_total}</Typography>
                                    </Box>
                                    <Box className="summaryItem">
                                        <Typography>{i18n.t("SHIPPINGHANDLING")}</Typography>
                                        <Typography>{i18n.t("SARTXT")}{this.state.order.attributes?.shipping}</Typography>
                                    </Box>
                                    <Box className="summaryItem">
                                        <Typography>{i18n.t("TOTALBEFOREVAT")}</Typography>
                                        <Typography>{i18n.t("SARTXT")}{this.state.order.attributes?.total_price}</Typography>
                                    </Box>
                                    <Box className="summaryItem">
                                        <Typography>{i18n.t("ESTIMATEDVAT")}</Typography>
                                        <Typography>{i18n.t("SARTXT")}{this.state.order.attributes?.total_tax}</Typography>
                                    </Box>
                                    <Box className="summaryItem">
                                        <Typography>{i18n.t("TOTAL")}</Typography>
                                        <Typography>{i18n.t("SARTXT")}{this.state.order.attributes?.total}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box className="line" />
                    </Box>
                    <Box className="product">
                        <Box  className="item">
                            <Box className="leftDelivered">
                                <Box className="itemLeft">
                                    <Box className="imgBox">
                                        <img src={this.state.order.attributes?.catalogue.attributes.images?.[0].url} alt="product-image" width={80} />
                                    </Box>
                                    <Box className="itemText">
                                        <Typography size="base" weight="bold">{this.state.order.attributes?.catalogue.attributes.name_locale}</Typography>
                                        <Typography>{i18n.t("QTYTXT")} - {this.state.order.attributes?.quantity}</Typography>
                                        <Typography>{i18n.t("DASHBOARD.TABLEHEADER.ORDERID")} - {this.state.order.attributes?.order_number}</Typography>
                                    </Box>
                                </Box>
                                {
                                    this.state.order.attributes?.status === "delivered" && <Box className="delivery">
                                        <img src={successIcon} alt="success" />
                                        <Typography>{i18n.t("DELIVEREDONTXTs")} {this.state.order.attributes.delivered_at}</Typography>
                                    </Box>
                                }
                            </Box>
                            <Box className="itemRight">
                                <CustomButton
                                    onClickHandle={() => this.navigateToPage("TrackOrder", this.state.order.id)}
                                    display={'successBorderless'}
                                    styles={{ height: "32px" }}
                                    themes="dark">{i18n.t("TRACKPACKAGETXT")}</CustomButton>
                               {this.state.order.attributes?.status === "delivered" && <CustomButton
                                    onClickHandle={() => this.navigateToPage("ReturnOrders", this.state.order.id)}
                                    display={'successWithBorder'}
                                    styles={{ height: "32px" }}
                                    themes="dark">{i18n.t("RETURNITEMTXT")}</CustomButton>}
                                <CustomButton
                                    onClickHandle={() => { }}
                                    display={'successWithBorder'}
                                    styles={{ height: "32px" }}
                                    themes="dark">{i18n.t("ARCHIVEORDERTXT")}</CustomButton>
                            </Box>
                        </Box>
                    </Box>
                </Wrapper>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const Wrapper = styled("div")({
    padding: "14px 50px",
    "width": "100%",
    boxSizing: "border-box",
    maxWidth: "1440px",
    margin: "auto",
    "& .breadcrumb": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "1rem",
        textColor: "#333333",
        padding: "0.5rem 0"
    },
    "& .breadcrumItems": {
        cursor: "pointer",
    },
    "& .orderBox": {
        background: "white",
        "& .orderHeader": {
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
            "& .headerLeft": {
                display: "flex",
                gap: 20,
                alignItems: "center",
                "& .borderLeft": {
                    width: "1px",
                    height: "50%",
                    background: "#00000029"
                }
            }
        },
        "& .line": {
            height: "1px",
            width: "100%",
            background: "#00000029",
        },
        "& .orderContent": {
            padding: "1rem",
            "& .address": {
                display: "flex",
                flexDirection: "column",
                gap: 10,
            },
            "& .payemnt": {
                display: "flex",
                flexDirection: "column",
                gap: 10,
            },
            "& .summary": {
                display: "flex",
                flexDirection: "column",
                gap: 10,
                "& .summaryItem": {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }
            }
        }
    },
    "& .product": {
        display: "flex",
        flexDirection: "column",
        background: "white",
        gap: 10,
        "& .item": {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #00000029",
            margin: "1rem 0",
            padding: "1rem",
            "& .itemLeft": {
                display: "flex",
                gap: 10,
                "& .imgBox": {
                    maxWidth: "80px",
                    border: "1px solid #DBDBDB",
                    borderRadius: "4px",
                    overflow: "hidden"
                }
            },
            "& .itemText": {
                display: "flex",
                flexDirection: "column",
                gap: 5,
            },
            "& .itemRight": {
                display: "flex",
                flexDirection: "column",
                gap: 10
            }
        },
        "& .leftDelivered": {
            display: 'flex',
            flexDirection: "column",
            gap: 20,
            "& .delivery": {
                display: "flex",
                gap: 10,
                alignItems: "center"
            }
        }
    }

})

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
