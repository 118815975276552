import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { OrderApiLatest, ProductReOrder, ReorderApiResponse } from "./types";
import moment from "moment";
import 'moment/min/locales';
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import createRequestMessage from "../../utilities/src/create-request-message";
export const configJSON = require("./config");

export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    id: string;
}

interface S {
    token: string;
    orderStatus: string;
    orderTime: {
        day?: string;
        year?: string;
        older?: string
    };
    orders: OrderApiLatest[];
    textSearch: string;
    reOderList: ProductReOrder[];
    language: string;
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderListManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    orderListApiCallId: string = '';
    getReturnOrderApi: string = ''
    createOrderApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
             getName(MessageEnum.InfoPageBodyMessage),

        ];

        this.state = {
            token: "",
            orderStatus: "",
            orderTime: { day: configJSON.initDay, year: "", older: "" },
            textSearch: "",
            reOderList: [],
            language: "en",
            orders: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            moment.locale(lang);
            if(lang!= undefined && lang != this.state.language){
              languageConvertor(lang);
              this.setState({language: lang}, ()=>{
                this.getOrderListApi();
                this.getReturnOrderListApi()
                languageConvertor(lang);
              });
            }
            this.handleCheckOrderStatus(message)
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.receiveApiResponse(message)
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        
        const lang = await getStorageData("language") || "en";
        if (lang) {
            languageConvertor(lang);
            await this.getTokenFn();
            this.getOrderListApi()
            this.getReturnOrderListApi()
            this.setState({ language: lang });
        }
        
    }
    async componentDidUpdate(prevProps: Readonly<Props>, oldState: Readonly<S>): Promise<void> {
        if (this.state.orderStatus !== oldState.orderStatus || this.state.orderTime.day !== oldState.orderTime.day || this.state.orderTime.year !== oldState.orderTime.year) {
            this.getOrderListApi()
        }
    }

    getTokenFn = async () => {
        let token = await getStorageData("authToken")
        this.setState({ token: token });
    };

    navigateTo = (target: string, screenName?: string) => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), target);
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        screenName && request.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(request);
    }

    getOrderListApi() {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
            language: this.state.language
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.orderListApiCallId = requestMessage.messageId;
        let api_url = `${configJSON.getOrderListEndPoint}?status=${this.state.orderStatus}${this.state.orderTime.day}${this.state.orderTime.year}${this.state.orderTime.older}&query=${this.state.textSearch}`;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            api_url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOrderListMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    getReturnOrderListApi() {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
            language: this.state.language
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getReturnOrderApi = requestMessage.messageId;
        let api_url = `${configJSON.getReorderListEndPoint}`;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            api_url
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getOrderListMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    filterStatus = (value: string) => {
        this.setState({ orderStatus: value })
    }
    filterTime = (value: string | number) => {
        switch (+value) {
            case 30:
                this.setState({ orderTime: { ...this.state.orderTime, day: `&days=${value}`, year: "", older: "" } })
                break;
            case 1:
                this.setState({ orderTime: { ...this.state.orderTime, day: "", year: "", older: `&older=true` } })
                break;
            default:
                this.setState({ orderTime: { ...this.state.orderTime, day: "", year: `&year=${value}`, older: "" } })

        }

    }
    handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event && event.preventDefault()
        this.getOrderListApi()
    }
    handleTextSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ textSearch: event.target.value })
    }
    navigateToProductDetail = (productId: string | number) => {
        const request: Message = new Message(getName(MessageEnum.NavigationMessage));
        request.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        request.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
        this.send(request);
      }
    
      formatDatePlaced = (datePlaced: string | null) => datePlaced ? moment(datePlaced).format("MMM DD, YYYY") : ""

      handleCheckOrderStatus = (message: Message) => {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
          let btnText = message.getData(getName(MessageEnum.InfoPageBodyMessage));
          if (btnText === "Reorder List") {
            this.filterStatus("reorder")
          }
    
        }}
    
    buyAgain = (orderItem: {catalogue_id: number; quantity: number}) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: this.state.token,
            language: this.state.language
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createOrderApiCallId = requestMessage.messageId
        const requestBody = JSON.stringify({
            data: {
                order_items_attributes: [{
                    catalogue_id: orderItem.catalogue_id,
                    quantity: orderItem.quantity
                }]
            }
        })
        createRequestMessage({ 
            requestMessage, 
            header, 
            endPoint: configJSON.newOrderApiEndPoint, 
            method: configJSON.createOrderMethod, 
            body: requestBody 
        })
    }

    receiveApiResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (apiRequestCallId === this.createOrderApiCallId) {
            if (responseJson?.data?.type === "order") {
                this.navigateTo("OrderSummary")
              }
        }

        if (apiRequestCallId && apiRequestCallId === this.orderListApiCallId) {
            if (responseJson) {
                this.setState({ orders: responseJson.data })
            } else {
                this.parseApiErrorResponse(errorReponse);
            }

        }
        if (apiRequestCallId && apiRequestCallId === this.getReturnOrderApi) {
            const dataRes: ProductReOrder[] = responseJson.data.map((response: ReorderApiResponse) => ({
                id: response.id,
                title: response.attributes.name_locale,
                price: response.attributes.price,
                image: response.attributes.images?.[0].url,
                description: response.attributes.description_locale

            }))
            this.setState({ reOderList: dataRes })
        }
    }
    // Customizable Area End
}