import React, { useEffect, useReducer, useRef, useState } from "react";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { useRunEngine } from "../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../utilities/src/hooks/useBlockHelpers";

import SelectAddressView from "./SelectAddressView";

export const configJSON = require("./config");

// Customizable Area Start
import { Address, TextFields } from "./types";

type Payment = {
  subTotal: number;
  shipping: number;
  vat: number;
  total: number;
};

const initialTextFields: TextFields[] = [
  {
    name: "phone_number",
    value: "",
    placeholder: configJSON.phoneNoPlaceholder,
    testId: "phone_number",
  },
  {
    name: "address_type",
    value: "",
    placeholder: configJSON.addressTypePlaceholder,
    testId: "address_type",
  },
  {
    name: "name",
    value: "",
    placeholder: configJSON.namePlaceholder,
    testId: "name",
  },
  {
    name: "zip_code",
    value: "",
    placeholder: configJSON.zipCodePlaceholder,
    testId: "zip_code",
  },
  {
    name: "flat_no",
    value: "",
    placeholder: configJSON.flatNoPlaceholder,
    testId: "flat_no",
  },
  {
    name: "address",
    value: "",
    placeholder: configJSON.addressPlaceholder,
    testId: "address",
  },
  {
    name: "address_line_2",
    value: "",
    placeholder: configJSON.addressLine2Placeholder,
    testId: "address_line_2",
  },
  {
    name: "landmark",
    value: "",
    placeholder: configJSON.landmarkPlaceholder,
    testId: "landmark",
  },
  {
    name: "city",
    value: "",
    placeholder: configJSON.cityPlaceholder,
    testId: "city",
  },
  {
    name: "state",
    value: "",
    placeholder: configJSON.statePlaceholder,
    testId: "state",
  },
  {
    name: "country",
    value: "",
    placeholder: configJSON.countryPlaceholder,
    testId: "country",
  },
]
// Customizable Area End

export interface ViewProps {
  testID: string;
  // Customizable Area Start
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  addresses: Address[];
  hideKeyboard: () => void;
  openCreateAddress: () => void;
  isVisibleCreateAddress: boolean;
  resetCreateModal: () => void;
  setTextFields: (action: { type: string; payload: string }) => void;
  textFields: TextFields[];
  addAddressHandler: () => void;
  addressId: number | null;
  orderId: string | null | undefined;
  selectAddress: (addressId: number | null) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  // Customizable Area End
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const subscribedMessages = [
  // Customizable Area Start
  MessageEnum.InfoPageTitleMessage,
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
  // Customizable Area End
];

const SelectAddress: React.FC<Props> = (props) => {
  // Customizable Area Start
  const orderId: null | undefined | string = props.navigation.getParam(
    "orderId",
    null
  );

  const createAddressesCallId = useRef("");
  const token = useRef<string>("");
  const updateFields: (
    state: TextFields[],
    action: { type: string; payload: string }
  ) => TextFields[] = (state, action) => {
    const updateState = state.map((item) =>
      item.name === action.type
        ? {
          ...item,
          value: action.payload,
        }
        : item
    );
    return updateState;
  };

  const [textFields, setTextFields] = useReducer(updateFields, initialTextFields);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(null);
  const [isVisibleCreateAddress, setIsVisibleCreateAddress] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Customizable Area End

  useEffect(() => {
    setReceiveCallback(receive);

    subscribedMessages.forEach((message) => subscribe(message));

    // Customizable Area Start
    getToken();
    // Customizable Area End

    return () => {
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
    };
  }, []);

  const receive = (from: string, message: Message) => {
    // Customizable Area Start
    // Customizable Area End
  };

  // Customizable Area Start
  const {
    sendBlockMessage,
    sendNetworkRequest,
    setReceiveCallback,
    subscribe,
    debugLog,
    unsubscribeFromMessage,
  } = useRunEngine();

  const { extractNetworkResponse, hideKeyboard } = useBlockHelpers();

  const getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    sendBlockMessage(message);
  };

  const addAddressHandler = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: token.current,
    };

    const httpBody: { [key: string]: string } = {};

    textFields.map((textField) => {
      if (textField.value !== "") {
        httpBody[textField.name] = textField.value;
      }
    });

    sendNetworkRequest(
      createAddressesCallId,
      configJSON.createAddressMethod,
      configJSON.createAddressEntPoint,
      headers,
      httpBody
    );
  };

  const openCreateAddress = () => {
    setIsVisibleCreateAddress(true);
  };

  const resetCreateModal = () => {
    setIsVisibleCreateAddress(false);
  };
  // Customizable Area End

  const viewProps: ViewProps = {
    testID: "SelectAddressView",
    // Customizable Area Start
    navigation: props.navigation,
    addresses,
    hideKeyboard,
    openCreateAddress,
    isVisibleCreateAddress,
    resetCreateModal,
    setTextFields,
    textFields,
    addAddressHandler,
    addressId : selectedAddressId,
    orderId,
    selectAddress : (value) => setSelectedAddressId(value),
    loading,
    setLoading
    // Customizable Area End
  };

  return <SelectAddressView {...viewProps} />;
};

export default SelectAddress;
