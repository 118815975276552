import React from "react";
import {
  Container,
  // Customizable Area Start
  Modal,
  // Customizable Area End
  Backdrop,
  Fade
} from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Flag, Done } from '@material-ui/icons';
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import ContentFlagController, {
  Props,

} from "./ContentFlagController";
export default class ContentFlag extends ContentFlagController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          maxWidth={"sm"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            flex: "0 0 100%",
          }}
        >
          <div
            style={{
              border: "1px solid black",
              borderRadius: "10px",
              width: "100%",
              height: "50px",
              display: "flex",
              flexWrap: "wrap",
              alignContent: " center",
              justifyContent: "space-between",
              padding: "0 10px",
              marginBottom: "10px"
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                verticalAlign: "center",
              }}
            >
              This is dummy Post
            </div>

            <div onClick={() => { this.handleFlagIconClick() }} data-testid='flagIcon' style={this.props.style}> <Flag /> </div>
          </div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={webStyle.modal}
            open={this.state.showFlag}
            onClose={this.handleFlagIconClick}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <Fade in={this.state.showFlag}>
              <div className="scroll" style={webStyle.paper as React.CSSProperties}>
                <h2 id="transition-modal-title" style={webStyle.h2 as React.CSSProperties
                }> Select the reason to flag the content </h2>
                <ul
                  style={
                    webStyle.reasonMainUnorderListStyle as React.CSSProperties
                  }
                >
                  {this.state.contentData.length > 0 &&
                    this.state.contentData.map((item, index) => {
                      return (
                        <li
                          data-testid="renderedReason"
                          key={index}
                          style={
                            this.state.selectedValue.id == item.id
                              ? ({
                                  ...webStyle.reasonListStyle,
                                  ...webStyle.active,
                                } as React.CSSProperties)
                              : (webStyle.reasonListStyle as React.CSSProperties)
                          }
                          onClick={() =>
                            this.selectContent({
                              id: item.id,
                              reason: item.reason,
                            })
                          }
                        >
                          {item.reason}
                          {this.state.selectedValue.id == item.id ? (
                            <Done />
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    })}
                </ul>
                <span
                  data-testid="flagButton"
                  style={{ color: "#297ACC" }}
                  onClick={
                    this.state.selectedValue.id > 0
                      ? () => {
                          this.doFlagContent(
                            this.props.userToken,
                            this.props.postID,
                            this.state.selectedValue.id
                          );
                          this.handleFlagButtonClick();
                        }
                      : () => {}
                  }
                >
                  Flag
                </span>
              </div>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={webStyle.modal}
            open={this.state.showSuccessModal}
            onClose={this.handleFlagButtonClick}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <>
              <div className="success-popup" style={webStyle.successModalMaindivStyle as React.CSSProperties}>
                <h1 style={webStyle.successMessageStyle}>Success</h1>
                <p style={webStyle.successPopupStyle}>{this.state.successmessage}</p>
                <span
                  data-testid="successbutton"
                  style={webStyle.okButtonStyle}
                  onClick={() => {
                    this.handleFlagButtonClick();
                    this.handleFlagIconClick();
                  }}
                >
                  OK
                </span>
              </div>
            </>
          </Modal>

        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
  active: {
    background: "#297ACC",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  h2: {
    fontSize: "16px",
    textAlign: "center"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  reasonMainUnorderListStyle:
  {
    textAlign: 'left',
    listStyle: 'none',
    margin: '0 0 25px',
    padding: '0',
    borderCollapse: 'collapse',
    maxHeight: "436px",
    overflow: "auto"
  },
  reasonListStyle: {
    border: '1px solid',
    borderCollapse: 'collapse',
    padding: '10px',
    cursor: "pointer",
  },
  successModalMaindivStyle: {
    background: '#fff',
    borderRadius: '10px',
    width: '300px',
    padding: '20px 0',
    textAlign: 'center'
  },
  successPopupStyle: {
    marginTop: '0',
    marginBottom: '10px'
  },
  successMessageStyle: {
    marginTop: '0',
    marginBottom: '15px'
  },
  okButtonStyle: {
    display: 'block',
    color: "#297ACC",
    borderTop: '1px solid #ddd',
    paddingTop: '15px',
    marginTop: '10px'
  }
};
// Customizable Area End
