// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from "i18next";
type documentTypes = {
    "file_url": string,
    "file_name": string,
    "file_size": number
}
type VendorType = {
    id: string;
    name: string,
    phoneNumber: string,
    email: string,
    location: string,
    bio: string,
    document: Array<documentTypes>;
    company: string
};


export type VendorTypeForm = Omit<VendorType, "id"> & {
  id?: string;
};

export const initialVendorFormValues: VendorTypeForm = {
  name: "",
  phoneNumber: "",
  email: "",
  location: "",
  bio: "",
  document: [],
  company: ""
};

export interface Props {
  navigation:  {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
}
interface S {
  initialFormValue: VendorTypeForm;
  token: string;
  loading: boolean;
  userId: number;
  image_preview: string | ArrayBuffer;
  document?: File | null,
  showToast: boolean,
  toastMessage: string,
  userRole: string;
  toastSuccess: "error" | "warning" | "info" | "success";
  language: string;
}
interface SS {
  id: string;
}

export default class ViewProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  getVendorApiCallId: string = "";
  updateVendorApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [];
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationTargetMessage),
    ];

    this.state = {
      initialFormValue: initialVendorFormValues,
      token: "",
      loading: false,
      userId: 0,
      image_preview: "",
      showToast: false,
      toastMessage: "",
      toastSuccess: "success",
      userRole: "vendor",
      language: "en"
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const vv_lang = await getStorageData('language') || "en";
    this.setState({language: vv_lang}, ()=>{
      this.getToken();
      languageConvertor(vv_lang);
    });
  }

  handleCloseSnackBars = () => {
    this.setState({showToast: false})
  }
  getDetails(token?: string) {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.addressesApiContentType,
      token: token || this.state.token,
      language: this.state.language
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVendorApiCallId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getVendorEndpoint}?id=${this.state.userId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }



  handleFileChnage = () => {
  };

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    this.selector(message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let roleInfoData = await getStorageData("roleInfoData");
      let token = await getStorageData("authToken");
      let userData = JSON.parse(roleInfoData);
      this.setState({ token: token, userId: userData.data.id, userRole: userData.data.attributes.user_type});
      this.getDetails(token);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let getUserData = {
        loading: false,
        initialFormValue: initialVendorFormValues,
        image_preview: ""
      };
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     
      if(errorReponse || responseJson.errors){
        this.setState({
            showToast: true,
            toastMessage: errorReponse || JSON.stringify(responseJson.errors),
            toastSuccess: "error"
        });
        if(responseJson?.errors[0]?.token){
            this.handleLogout();
        }
      }
      
      if(this.getVendorApiCallId === apiRequestCallId) {
        getUserData.initialFormValue = responseJson.data
            ? {
                id: responseJson.data.id,
                name: responseJson.data.attributes.full_name,
                email: responseJson.data.attributes.email,
                phoneNumber: responseJson.data.attributes.full_phone_number,
                location: responseJson.data.attributes.location_string,
                bio: responseJson.data.attributes.bio,
                document: responseJson.data.attributes.documents,
                company: responseJson.data.attributes.company_name
             }
            : this.state.initialFormValue;
            getUserData.image_preview = responseJson.data.attributes.image;
         
            this.setState(getUserData);
      }
    }
  }
  copyText = (event:Event, text: string | number | undefined ) => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(String(text));
    this.setState({
        showToast: true,
        toastMessage: i18n.t("TEXTCOPIEDTXT"),
        toastSuccess: "success"
    });
  };

  navigateToEdit = () => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    request.addData(getName(MessageEnum.NavigationTargetMessage), "UpdateProfile");
    this.send(request);
  }

  selector = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selectedLng != this.state.language){
        this.setState({language: selectedLng}, ()=>{
          this.getToken();
          languageConvertor(selectedLng);
        });
      }
    }
  }

    handleLogout = () => {
        removeStorageData("authToken");
        const requestMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
        requestMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        requestMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(requestMsg);
    }
    fileSizeByteToKb = (size: number) => {
       return  Math.round(size/1000) + "kb"
    }
}
// Customizable Area End
