// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
} from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web"
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import i18n from "i18next";
const assets = require("./assets")

import UserSidebarController, {
  Props,
} from "./UserSidebarController";

export default class UserSidebar extends UserSidebarController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { activeScreenId } = this.props

    return (
      <UserSidebarWrapper>
        {
          this.sidebarItems.map((item, index) => {
            const isActive = activeScreenId && activeScreenId === item.screenId
              ||
              window.location.pathname.toLowerCase() === `/${item.screenId?.toLowerCase()}`
            return (
              <CustomButton
                key={index}
                styles={buttonStyle}
                themes="primary"
                onClickHandle={() => this.handleClickSidebarItem(item)}
                className={isActive ? "active-item" : ""}>
                <Box className="imgText">
                  {item.icon}
                  <Typography>{i18n.t(item.label)}</Typography>
                </Box>
                {item.screenId && assets.arrowRightSvgIcon}
              </CustomButton>
            )
          })
        }
      </UserSidebarWrapper>
    )

  }
}

const UserSidebarWrapper = styled(Box)({
  boxSizing: "border-box",
  minWidth: "325px",
  height: "fit-content",
  padding: "16px 16px 25px",
  display: "flex",
  flexDirection: "column" as "column",
  backgroundColor: "white",
  borderRadius: "0.4vw",
  "@media (max-width: 768px)": {
    display: "none"
  },
  "& button": {
    width: "100% !important",
    "&.active-item": {
      color: "#2563EB !important",
      "& .imgText": {
        "& > div": {
          fontWeight: "500 !important",
        },
        "& svg path": {
          fill: "#2563EB"
        }
      },
      "& svg path": {
        stroke: "#2563EB"
      }
    }
  },
  "& .imgText": {
    display: "flex",
    gap: "0.8vw",
    alignItems: "center",
    fontSize: 16,
    lineHeight: 1,
    "& svg": {
      width: "unset",
      height: "unset"
    },
  }
})

const buttonStyle = {
  backgroundColor: "white",
  color: "black",
  border: "none",
  padding: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Roboto",
}
// Customizable Area End