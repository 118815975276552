import React, { Component } from "react";
import Card from "./Card.web";

type Props = {
    Records: Array<any>;
    clickHandler: (event: Event, category_id?: number) => void;
    cardStyles?: any;
}


export default class Cards extends Component<Props> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const {Records, clickHandler, cardStyles} = this.props;
        let cardContainer:any = [];

        Records.length > 0 && (
            cardContainer = Records.map((item) => {
                return <Card 
                            title={item.title}
                            Image={item.image}
                            cardStyles={cardStyles}
                            ItemId={item.id}
                            onClickHandle={clickHandler}
                            weight="semiBold"
                             />
            })
        )

        return cardContainer;

    }
}