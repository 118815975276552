// Customizable Area Start
import React from "react";
import HelpCentreController, {Props} from "./HelpCentreController.web";
import { Box, Container, styled } from "@material-ui/core";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import Typography from "../../../components/src/design-system/Typography.web";
import TextInput from "../../../components/src/design-system/TextInput.web";
import UserSidebar from "../../navigationmenu/src/UserSidebar.web";
import { searchIcon } from "./assets";
import Markup from "../../../components/src/design-system/Markup.web";
import i18n from "i18next";

export default class HelpCentre extends HelpCentreController {
    constructor(props: Props) {
        super(props);
    }
    render(): React.ReactNode {

        return (
            <HelpCentreWrapper>
                <FrontHeader
                    navigation={this.props.navigation}
                    customStyle={{ background: "#fff", ...!this.state.token && { borderBottom: "solid 1px #DFDFDF" } }}
                    allowGuestAccess
                />
                <Container>
                    <UserSidebar navigation={this.props.navigation} />
                    <Box className="main-content">
                        <form action="" onSubmit={() => { }}>
                            <Box className='inputContainer'>
                                <img src={searchIcon} className="searchImg" alt="" />
                                <TextInput
                                    placeholder={i18n.t('HELPSEARCHPLACEHOLDER')}                                    value={this.state.searchInput}
                                    onValueChange={(event) => this.setState({ searchInput: event.target.value })}
                                    dataTestId='search'
                                    id="search"
                                />
                            </Box>
                        </form>
                        {
                            this.state.helpCentreQA.map((item, index) => (
                                <Box className="helpcentreqa" key={item.id}>
                                    <Typography size="sm" weight="bold" font="Inter" color="dark">
                                        {item.attributes.question}
                                    </Typography>
                                    <Markup classProps="answer">{item.attributes.answer}</Markup>
                                </Box>
                            ))
                        }
                    </Box>
                </Container>
            </HelpCentreWrapper>
        );
    }
}

const HelpCentreWrapper = styled(Box)({
    background: "white",
    height: "100vh",
    overflow: "auto",
    "& .MuiContainer-root": {
        padding: "16px 40px",
        display: "flex",
        maxWidth: 1440,
        gap: 24
    },
    "& .main-content": {
        flex: 1,
        gap: 24,
        display: "flex",
        flexDirection: "column",
        maxWidth: 720,
        "& form": {
            margin: 0
        },
        "& .inputContainer": {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFFFFFA3",
            padding: "0 0.5rem",
            borderRadius: "4px",
            height: "48px",
            overflow: "hidden",
            "& > div": {
                flex: 1
            },
            "& #search": {
                borderColor: "transparent",
                width: "100%",
                marginTop: "-0.5rem",
                paddingLeft: 10,
                "&::placeholder" : {
                    color: "#64748B",
                    fontFamily: "Inter",
                }
            },
            "& .PrivateNotchedOutline-root-9": {
                border: "none"
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent !important"
            },
        },
    },
    "& .helpcentreqa": {
        padding: "16px 40px 16px 24px",
        borderRadius: 8,
        background: "white",
        whiteSpace: "pre-line",
        "& .answer": {
            color: "#334155",
            marginTop: 22,
            fontFamily: "Inter",
            fontSize: 14,
            lineHeight: "22px",
            "& p": {
                margin: 0
            }
        }
    }
});

// Customizable Area End
