import React from "react"
import { Box, styled } from "@material-ui/core"
import { AreaChart, CartesianGrid, XAxis, ResponsiveContainer, Tooltip, Area, YAxis, Legend } from "recharts";

type ActiveDotProps = {
    cx: number
    cy: number
    stroke: string
    index: number
    fill: string
    dataKey: string
    payload: {
        [key:string]: number
    }
}

type ChartItem = {
    dataKey: string
    label: string
    color: string
}

type RevenueChartProps = {
    data: {[key: string] : string | number}[]
    items: ChartItem[]
}

const formatCurrency = new Intl.NumberFormat("en-US", { style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 0, currency: "SAR" }).format

const CustomActiveDot = (props: ActiveDotProps & {dataCount: number}) => {
    const { cx, cy, stroke, payload, index, dataKey, fill, dataCount } = props;
    const isLastItem = index === dataCount - 1
    return (
      <svg>
        <circle cx={cx} cy={cy} r={7} fill={stroke} stroke="white" strokeWidth={2} />
        <circle cx={cx} cy={cy} r={3} fill={fill} stroke={fill} strokeWidth={2} />
        <text className="active-dot-text" x={isLastItem ? cx - 10 : cx + 10} y={cy + 7} textAnchor={isLastItem ? "end" : "start"} fill="#333">
          {formatCurrency(payload[dataKey])}
        </text>
      </svg>
    );
  };

const RevenueChart = (props: RevenueChartProps) => {
    const { items, data } = props
    return (
        <Box>
            <RevenueChartWrapper height={300}>
                <AreaChart
                    className="area-chart"
                    data={data}
                >
                    <defs>
                        {
                            items.map(item => (
                                <linearGradient key={item.dataKey} id={"gradient_" + item.dataKey} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="80%" stopOpacity={0.2} stopColor={item.color} />
                                </linearGradient>
                            ))

                        }
                    </defs>
                    <CartesianGrid strokeDasharray="2 2" vertical={false} />
                    <XAxis dataKey="weekday" tickLine={false} axisLine={{stroke: "lightgray", strokeWidth: 1.5}} tickMargin={11} />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickFormatter={(value: number) => {
                            if (value >= 1000) return (value % 1000 ? (value / 1000).toFixed(1) : value / 1000) + "k"
                            return value.toString()
                        }}
                        tickMargin={11}
                    />
                    <Tooltip
                        cursor={{ strokeDasharray: "2 2" }}
                        wrapperStyle={{ display: "none" }}
                    />
                    {
                        items.map((item) => <Area
                            key={item.dataKey}
                            strokeWidth={2}
                            type="monotone"
                            dataKey={item.dataKey}
                            stroke={item.color}
                            fill={`url(#gradient_${item.dataKey})`}
                            activeDot={(props) => <CustomActiveDot {...props} dataCount={data.length}/>}
                        />)
                    }
                </AreaChart>

            </RevenueChartWrapper>
            <Box display="flex" gridGap={20} alignItems="center" fontFamily="Inter" marginY={2.5} fontSize={11}>
                {
                    items.map((item) => (
                        <Box key={item.dataKey} display="flex" alignItems="center" gridGap={5}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                <circle cx="6.05652" cy="5.50037" r="5.43396" fill={item.color} />
                            </svg>
                            <Box>
                                { item.label }
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

const RevenueChartWrapper = styled(ResponsiveContainer)({
    "& .area-chart": {
        fontFamily: "Inter",
        fontSize: 11,
        margin: "10px 30px 0 -25px"
    },
    "& .active-dot-text": {
        fontWeight: "bold",
        fontFamily: "Roboto",
        fontSize: "1rem"
    },
})

export default RevenueChart