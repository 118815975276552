import React from "react";
// Customizable Area Start
import CustomButton from "../../../components/src/design-system/CustomButton.web";
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
} from "./ShoppingCartOrdersController";

export default class AddShoppingCartOrderItem extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <CustomButton
        themes="primary"
        {...this.props.addButtonProps}
        onClickHandle={this.handleAddToCart}
      >
        {this.props.addButtonLabel || "Add"}
      </CustomButton>
    );
    // Customizable Area End
  }
}
