// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, isEmpty } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from "i18next";
import { FormikProps } from "formik";

export const configJSON = require("./config.js");

type ApiResponseAddress = {
  id: string
  attributes: {
    name: string
    address: string
    flat_no: string
    city: string
    state: string
    zip_code: string
    phone_number: string
    delivery_notes: string
    is_default: boolean
  }
}

type Address = {
  id: string;
  name: string;
  address: string;
  flatNumber: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  deliveryNotes: string;
  isDefault: boolean;
};

type AddressFormErrors = {
  name: string;
  address: string;
  zipCode: string;
  phoneNumber: string;
};

export type AddressForm = Omit<Address, "id"> & {
  id?: string;
};

export const initialAddAddressFormValues: AddressForm = {
  name: "",
  address: "",
  flatNumber: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  deliveryNotes: "",
  isDefault: false,
};

export interface Props {
  navigation: any;
  isSelectAddressScreen?: boolean;
  updateSelectedAddress?: (selectedAddressId: number | null) => void
}
interface S {
  addresses: Address[];
  initialFormValue: AddressForm | null;
  token: string;
  errorMsg: string;
  loading: boolean;
  language: string;
}
interface SS {
  id: string;
}

export default class UserAddressesController extends BlockComponent<
  Props,
  S,
  SS
> {
  formikRef = React.createRef<FormikProps<AddressForm>>();
  getAddressesApiCallId: string = "";
  addAddressApiCallId: string = "";
  updateAddressApiCallId: string = "";
  deleteAddressApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      addresses: [],
      initialFormValue: null,
      token: "",
      loading: false,
      errorMsg: "",
      language: "en",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const loglang = (await getStorageData("language")) || "en";
    this.setState({ language: loglang }, async () => {
      languageConvertor(loglang);
      const token = await this.getToken();
      this.callGetAddressesApi(token);
    });
  }

  handleDeleteAddress = (deleteId: string) => {
    this.callDeleteAddressApi(deleteId);
  };

  openEditAddressForm = (address: Address) =>
    this.setState({ initialFormValue: address });

  openAddAddressForm = () => this.setState({
    initialFormValue: initialAddAddressFormValues,
  })

  handleChangeName = (event: { target: { value: string } }) => {
    if (event?.target?.value && !/^[^\d]+$/.test(event.target.value)) return;
    this.formikRef.current?.setFieldValue("name", event.target.value)
  }

  validateAddressForm = (values: AddressForm) => {
    const errors: Partial<AddressFormErrors> = {};
    if (isEmpty(values.name)) {
      errors.name = i18n.t("FULLNAMEERROR");
    }

    if (isEmpty(values.address)) {
      errors.address = i18n.t("ADDRESSERROR");
    }

    if (!/^(?:\+966|966)\d{9}$/.test(values.phoneNumber)) {
      errors.phoneNumber = i18n.t("PHONENOERROR");
    }

    if (isEmpty(values.zipCode)) {
      errors.zipCode = i18n.t("ZIPCODEERROR");
    }

    return errors;
  };

  handleSubmitAddressForm(formData: AddressForm) {
    if (formData.id) {
      this.callUpdateAddressApi(formData as Address);
    } else {
      this.callAddAddressApi(formData);
    }
  }

  callGetAddressesApi(token?: string) {
    this.setState({ loading: true });
    const headers = {
      "Content-Type": configJSON.addressesApiContentType,
      token: token || this.state.token,
    };

    const getAddressesMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressesApiCallId = getAddressesMsg.messageId;

    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressesApiEndpoint
    );

    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAddressesMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressesApiMethodType
    );
    runEngine.sendMessage(getAddressesMsg.id, getAddressesMsg);
  }

  callAddAddressApi(formData: AddressForm) {
    this.setState({ loading: true });
    const requestBody = this.convertToFormData(formData);

    const headers = {
      token: this.state.token,
    };
    const addAddressMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAddressApiCallId = addAddressMsg.messageId;

    addAddressMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressesApiEndpoint
    );

    addAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressApiMethodType
    );
    addAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );
    runEngine.sendMessage(addAddressMsg.id, addAddressMsg);
  }

  callUpdateAddressApi(updateData: Address) {
    this.setState({ loading: true });
    const requestBody = this.convertToFormData(updateData);

    const headers = {
      token: this.state.token,
    };
    const updateAddressMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAddressApiCallId = updateAddressMsg.messageId;

    updateAddressMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addressesApiEndpoint}/${updateData.id}`
    );

    updateAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAddressApiMethodType
    );
    updateAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      requestBody
    );
    runEngine.sendMessage(updateAddressMsg.id, updateAddressMsg);
  }

  callDeleteAddressApi(deleteId: string) {
    this.setState({ loading: true });
    const headers = {
      token: this.state.token,
    };

    const deleteAddressMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAddressApiCallId = deleteAddressMsg.messageId;

    deleteAddressMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addressesApiEndpoint}/${deleteId}`
    );

    deleteAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    deleteAddressMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAddressApiMethodType
    );
    runEngine.sendMessage(deleteAddressMsg.id, deleteAddressMsg);
  }

  getToken = async () => {
    const token = (await getStorageData("authToken")) as string;
    this.setState({ token: token });
    return token;
  };

  async receive(from: string, message: Message) {
    this.recieveLanguage(message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let updateStateData: Partial<S> = {
        loading: false,
      };
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getAddressesApiCallId: {
          updateStateData.addresses = responseJson?.data
            ? responseJson.data.map((addressItem: ApiResponseAddress) => ({
              id: addressItem.id,
              name: addressItem.attributes.name,
              address: addressItem.attributes.address,
              flatNumber: addressItem.attributes.flat_no,
              city: addressItem.attributes.city,
              state: addressItem.attributes.state,
              zipCode: addressItem.attributes.zip_code,
              phoneNumber: addressItem.attributes.phone_number,
              deliveryNotes: addressItem.attributes.delivery_notes,
              isDefault: addressItem.attributes.is_default,
            }))
            : [];
          updateStateData.initialFormValue = null;
          const defaultAddress = updateStateData.addresses?.find((address: Address) => address.isDefault)
          const defaultAddressId = defaultAddress !== undefined ? Number(defaultAddress.id) : null
          this.props.updateSelectedAddress?.(defaultAddressId)
          break;
        }
        case this.addAddressApiCallId:
        case this.updateAddressApiCallId: {
          if (responseJson.data) {
            this.callGetAddressesApi();
          }
          break;
        }
        case this.deleteAddressApiCallId: {
          if (responseJson.message === configJSON.deleteAddressSuccessMessage) {
            this.callGetAddressesApi();
          }
          break;
        }
        default:
          break;
      }
      this.setState(updateStateData as Pick<S, keyof S>);
    }
  }
  recieveLanguage = (message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if (lang != this.state.language) {
        this.setState({ language: lang }, () => {
          this.getToken();

          languageConvertor(lang);
        });
      }
    }
  };
  convertToFormData = (addressData: AddressForm) => {
    const formData = new FormData();
    formData.append("name", addressData.name);
    formData.append("address", addressData.address);
    formData.append("flat_no", addressData.flatNumber ?? "");
    formData.append("city", addressData.city);
    formData.append("state", addressData.state);
    formData.append("zip_code", (addressData.zipCode ?? "").toString());
    formData.append("phone_number", addressData.phoneNumber);
    formData.append("delivery_notes", addressData.deliveryNotes);
    formData.append("is_default", addressData.isDefault.toString());
    return formData;
  };

  handleCancel = () => this.setState({ initialFormValue: null })

  goToHome() {
    const navigationMessage: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
  }
  getpwdstyle = () => {
    let space_pw = this.state.language === "ar" ? "40px" : "";
    return { paddingRight: space_pw }
  }
}
// Customizable Area End
