import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import i18n from "i18next";
import * as Yup from "yup";

// Customizable Area Start
export type changePasswordTypes = {
  newPassword: string,
  confirmNewPassword: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginClicked: boolean;
  updatePasswordLinkSent: string;
  email: string;
  newPassword: string;
  confirmNewPassword: string;
  PasswordConditions: string[];
  showNewPassword: boolean;
  showConfirmNewPassword: boolean;
  passwordReset: string;
  token: string;
  userEmail: string;
  forgotEmail: string;
  showToast:boolean
  toastMessage:string
  toastSuccess:string;
  language: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  changePasswordApiCalled: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.state = {
      loginClicked: false,
      updatePasswordLinkSent: '',
      passwordReset: "",
      email: "",
      newPassword: "",
      confirmNewPassword: "",
      PasswordConditions: [],
      showNewPassword: false,
      showConfirmNewPassword: false,
      token: '',
      userEmail: '',
      forgotEmail: '',
      showToast: false,
      toastMessage: "",
      toastSuccess: "",
      language: "en"
    };
    // Customizable Area End
  }

  async componentDidMount() {
    const token = window.location?.search?.split("=")[1]?.split("&")[0];
    const userEmail = window?.location?.search?.split('=')[2];
    const forgotlang = await getStorageData('language') || "en";
    this.setState({ token, userEmail, language: forgotlang}, ()=>{
      this.handlePasswordCondition();
      languageConvertor(forgotlang)
    });
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let forgotlang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(this.state.language != forgotlang && forgotlang!= undefined){
        this.setState({language: forgotlang}, ()=>{
          languageConvertor(forgotlang);
          this.handlePasswordCondition();
        });
      }
    }

    if (
      this.getUserProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.errors) {
        this.setState({
          ...this.state,
          updatePasswordLinkSent: responseJson.data,
        });
      } else {
        this.setState({
          ...this.state,
          loginClicked: true
        });
        this.setState({showToast: true, toastSuccess: "success", toastMessage: responseJson.message})
      }
    }
    if (this.changePasswordApiCalled === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.errors) {
        this.setState({showToast: true, toastSuccess: "error", toastMessage: JSON.stringify(responseJson?.errors)})
      } else {
        this.setState({showToast: true, toastSuccess: "success", toastMessage: responseJson.message});
        setTimeout(() => {
          this.handlelLogin();
        }, 2000)
      }
    }
  }
  
  handleRedirection = async() => {
    const user_type = await getStorageData('userType');
    switch (user_type){
      case 'vendor':
        return 'VendorEmailAccountLogin';
      case 'admin':
        return 'AdminEmailAccountLogin';
      default:
        return 'CustomerEmailAccountLogin';
    }
  }
  

  handlelLogin = async () => {
    const loginPath = await this.handleRedirection();
    const requestMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    requestMsg.addData(getName(MessageEnum.NavigationTargetMessage), loginPath);
    requestMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(requestMsg);
  }

  handleCloseSnackBar = () => {
    this.setState({showToast: false})
  }

  handleForgotPassword = (values: any) => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const data = {
      email: values?.email
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangePassword = (values: changePasswordTypes) => {
    const headers = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const data = {
      token: this.state.token,
      email: this.state.userEmail,
      password: values.newPassword,
      password_confirmation: values.confirmNewPassword

    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    return runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start


  handleLoginClick = (values: any) => {
    this.setState({ forgotEmail: values?.email });
    this.handleForgotPassword(values);
  }

  validationSchema = Yup.object({
    newPassword: Yup.string()
        .matches(/[A-Z]/, "NEWPWDVALIDATION.ATLEASTONEUPPERCASELETTERTXT")
        .matches(/[a-z]/, "NEWPWDVALIDATION.ATLEASTONELOWERCASELETTERTXT")
        .matches(/\d/, "NEWPWDVALIDATION.ATLEASTONENUMBER")
        .min(8, "NEWPWDVALIDATION.MINCHARLENGTHIS8")
        .required("NEWPWDVALIDATION.NEWPWDREQUIREd"),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "NEWPWDVALIDATION.PWDMATCH")
        .required("NEWPWDVALIDATION.CONFIRMPWDREQUIRED"),
  });

  handlePasswordCondition = () => {
    const items = [
                    i18n.t("NEWPWDVALIDATION.ATLEASTONEUPPERCASELETTERTXT"),
                    i18n.t("NEWPWDVALIDATION.ATLEASTONELOWERCASELETTERTXT"),
                    i18n.t("NEWPWDVALIDATION.ATLEASTONENUMBER"),
                    i18n.t("NEWPWDVALIDATION.MINCHARLENGTHIS8")
                  ];
    this.setState({PasswordConditions: items})
  }

  getStyle = () => {
    let space = this.state.language === "ar" ? "40px" : "14px";
    return {paddingRight: space}
  }

  checkValidation = (index: any, value: any) => {
    switch (index) {
      case 0:
        return Boolean(value?.match(/[A-Z]/));
      case 1:
        return Boolean(value?.match(/[a-z]/));
      case 2:
        return Boolean(value?.match(/\d/));
      case 3:
        return value?.length >= 8;
      default:
        return false;
    }
  };
  // Customizable Area End
}