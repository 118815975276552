import React from "react";
// Customizable Area Start
import { Box, styled  } from "@material-ui/core";
import LandingPageController, {Props,configJSON} from "./LandingPageController";
const Image = require("./assets");
import Cards from "../../../components/src/design-system/Cards.web";
import Typography from "../../../components/src/design-system/Typography.web";
import Promotion from "../../../components/src/design-system/Promotion.web";
import {Ad1} from "./utilities";
import ProductCarousel from "../../carouseldisplay/src/ProductCarousel.web";
import InfoCard from "../../../components/src/design-system/InfoCard.web";
import Footer from "../../navigationmenu/src/Footer.web";
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import i18n from "i18next";
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {category} = this.state;
    // Customizable Area Start
    return (
        <LandingPageWrap>
            <FrontHeader navigation={this.props.navigation} customStyle={{background: "#fff"}} allowGuestAccess/>
            {this.state.dashboard_data.length > 0 &&
            <Box className="bannerWrapper">
                <img src={this.state.dashboard_data[0].attributes.image || Image.HeaderBanner} />
                <Box className="inner">
                    <Typography color="white" size="xl"><Box className="title">{this.state.dashboard_data[0].attributes.title}</Box></Typography>
                    <Typography color="white" size="x"><Box className="details">{this.state.dashboard_data[0].attributes.sub_title}</Box></Typography>
                </Box>
            </Box>}
            <Box className="landing-container">
                <Box className="categoryWrapper">
                    <Cards Records={category} cardStyles={{"min-width": "210px"}} clickHandler={this.goToCategoryPage} />
                </Box>
            </Box>
            
            <ProductCarousel 
                theme="light"
                records={this.state.new_products} 
                sectionTitle={i18n.t("NEWLAUNCHTXT")}
                addToCart={this.goToHome} 
                backgroundImage={null}
                moreBtnTxt={i18n.t("VIEWALL")}
                navigation={this.props.navigation}
                productStyle="default"
                language={this.state.language}
                callback_id={this.newProductCallID}
                itemId={0}
                onClickHandler={this.goToCategoryPageNewLaunch} 
                />


            <Box className={`landing-container`}>
                <Promotion bannerClass="banner" children={Ad1} />
            </Box>
            
            <ProductCarousel 
                theme="light"
                records={this.state.brands} 
                sectionTitle={i18n.t("FEATUREBRANDSTXT")}
                addToCart={this.goToHome} 
                backgroundImage={null} 
                moreBtnTxt={i18n.t("VIEWALL")}
                language={this.state.language}
                productStyle="feature"
                navigation={this.props.navigation}
                itemId={0}
                callback_id={this.brandCallBackID}
                onClickHandler={this.goToCategoryPageBrands} />

            {this.state.landingData.map((item, key) => {
                if(key == 3){
                    return <>
                        <Box className={`landing-container`}>
                            <Promotion bannerClass="mt48" children={Ad1} />
                        </Box>
                        <ProductCarousel 
                            theme={this.isTheme(item.image)}
                            records={item.products}
                            backgroundImage={item.image} 
                            navigation={this.props.navigation}
                            sectionTitle={item.title} 
                            callback_id={this.productCallBackId}
                            language={this.state.language}
                            addToCart={this.goToHome} 
                            moreBtnTxt={i18n.t("VIEWALL")}
                            productStyle="default"
                            itemId={item.id}
                            onClickHandler={this.goToCategoryPage} />
                        </>
                }else{
                    return <ProductCarousel 
                    theme={this.isTheme(item.image)}
                    records={item.products}
                    backgroundImage={item.image} 
                    sectionTitle={item.title} 
                    addToCart={this.goToHome} 
                    navigation={this.props.navigation}
                    moreBtnTxt={i18n.t("VIEWALL")}
                    callback_id={this.productCallBackId}
                    productStyle="default"
                    language={this.state.language}
                    itemId={item.id}
                    loading={this.state.loading}
                    onClickHandler={this.goToCategoryPage} />
                }
            })}
            
            {this.state.dashboard_data.length > 0 &&
            <Box className="landing-container">
                
                <Box className="infoWrapper">
                    {this.state.dashboard_data[1].attributes.landingpage_sub_contants.map((item) => {
                        return <InfoCard 
                                    image = {item.attributes.image}
                                    title={item.attributes.title}
                                    details={item.attributes.description}
                                ></InfoCard>
                    })}
                </Box>
            </Box>}
            <Footer navigation={this.props.navigation}/>
        </LandingPageWrap>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const LandingPageWrap = styled(Box)({
    display: "flex",
    background: "white",
    flexDirection: "column",
    "& .infoWrapper":{
        "display": "flex",
        "gap": "20px",
        "margin": "48px 0px"
    },
    "& .bannerWrapper":{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "48px",
        "& img":{
            width: "100%",
        },
        "& .inner":{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            position: "absolute",
            flexDirection: "column"
        },
        "& .title":{
            "color": "#FFF",
            "textAlign": "center",
            "fontFamily": "Roboto",
            "fontSize": "48px",
            "fontStyle": "normal",
            "fontWeight": 600,
            "lineHeight": "normal"
        },
        "& .details":{
            "color": "#FFF",
            "textAlign": "center",
            "fontFamily": "Roboto",
            "fontSize": "24px",
            "fontStyle": "normal",
            "fontWeight": "500",
            "lineHeight": "normal"
        }
    },
    "& .landing-container":{
        paddingLeft: "40px",
        paddingRight: "40px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        maxWidth: "1440px",
        margin: "auto",
        gap: "48px",
        "& .mt48":{
            marginTop: "48px"
        }
    },
    "& .categoryWrapper":{
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
        padding: "12px 0px",
        flexWrap: "wrap"
    },
    "& .advertContainer": {
        display: "flex",
        gap: "20px",
        justifyContent: "space-between",
        "& img":{
            maxWidth: "100%"
        }
    },
    
    "@media(max-width: 1439px)":{
        "& .container":{
            maxWidth: "95%"
        },
        "& .categoryWrapper": {
            flexWrap: "wrap",
            justifyContent: "start"
        }
    },
    "@media(max-width: 1080px)":{
        "& .container":{
            maxWidth: "100%",
            width: "100%"
        },
        "& .bannerWrapper":{
            "& .title":{
                fontSize: "34px"
            },
            "& .details":{
                fontSize: "18px"
            }
        }
    },
    "@media(max-width: 800px)":{
        "& .infoWrapper":{
            flexWrap: "wrap"
        },
        "& .bannerWrapper":{
            "& .title":{
                fontSize: "26px"
            },
            "& .details":{
                fontSize: "14px"
            }
        },
        "& .carouselWrapper button":{
            width: "auto !important"
        },
        
    },
    "@media(max-width: 560px)":{
        "& .categoryWrapper":{
            "& > div":{
                width: "100%"
            },
        },
        "& .advertContainer":{
            flexDirection: "column"
        },
        "& .bannerWrapper":{
            "& img":{
                "height": "250px",
                objectFit: "cover"
            },
            "& .inner":{
                padding: "0px 40px"
            }
        },
        "& .landing-container":{
            padding: "14px 20px"
        },
        "& .container":{
            padding: "0px 20px"
        }
    }
});
// Customizable Area End
