import React, { Component } from "react";

type MyProps = {
    children: any;
    color?: 'primary' | 'black' | 'white'| 'dark' | 'red' | 'gray' | 'charcoal' | 'limegreen';
    size?: 's' | 'ms' | 'xs' | 'sm' | 'base' | 'x' | 'xl' | 'xxl';
    weight?: 'normal' | 'medium' | 'semiBold' | 'bold';
    transform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none';
    font?: string;
};

export default class Typography extends Component<MyProps> {

    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const {children} = this.props;

        const styles = {
            color: this.props.color ? colors[this.props.color] : 'inherit',
            fontSize: this.props.size ? fontSize[this.props.size] : 'inherit',
            fontWeight: this.props.weight ? fontWeights[this.props.weight] : 400,
            textTransform: this.props.transform ? textTransformations[this.props.transform] : 'none',
            fontFamily: this.props.font ? this.props.font : 'Roboto',
            display: 'inline-block',
        };
        
        return (
            <div style={styles}>{children}</div>
        );
    }
}

const colors: any = {
    primary: '#2563EB',
    black: '#000000',
    white: '#ffffff',
    dark: '#0F172A',
    red: '#DC2626',
    gray: '#A6A6A6',
    charcoal: '#333333',
    limegreen: "#77B300"
};

const fontSize: any = {
    xxl: '2rem',
    xl: '1.375rem',
    x: '1.25rem',
    base: '1.125rem',
    sm: '1rem',
    xs: '0.875rem',
    ms: '0.75rem',
    s: '0.625rem'
};

const fontWeights: any = {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
};

const textTransformations: any = {
    capitalize: 'capitalize',
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    none: 'none',
};