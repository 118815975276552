import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
import { IBlock } from "../../../framework/src/IBlock";

export interface AboutUsDataApi {
    attributes: {
        position: number;
        sub_title: string;
        image: string;
        description: string;
        landingpage_sub_contants: {
            attributes: {
                sub_title: string;
                description: string;
                image: string
            }
        }[]
    }
}
export interface Props {
    navigation: {
        navigate: (to: string, params: object) => void;
        getParam: (param: string) => string;
        goBack: () => void;
    };
    id: string;
}

interface S {
    indexCarousel: number;
    token: string;
    language: string;
    aboutUsData: AboutUsDataApi[]
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class AboutUsManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAboutUsApiCallId = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            indexCarousel: 0,
            token: "",
            language: "en",
            aboutUsData: []
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
            let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
            if (lang != this.state.language) {
                this.setState({ language: lang }, () => {
                    languageConvertor(lang);
                    this.getToken();
                });
            }
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
            if (apiRequestCallId && apiRequestCallId === this.getAboutUsApiCallId) {
                this.setState({ aboutUsData: responseJson.data })
            }
        }

    }
    getToken = async () => {
        const token = await getStorageData("authToken");
        this.getAboutUsApiCall(token)
        this.setState({ token: token })
    };
    async componentDidMount() {
        super.componentDidMount();
        const language = await getStorageData('language') || "en";
        this.setState({ language: language }, () => {
            this.getToken();
            languageConvertor(language);
        });
    }
    handleChangeCarousel = (current: number, prev: number) => {
        this.setState({ indexCarousel: current })
    }
    getAboutUsApiCall = (token: string) => {
        const headers = {
            "Content-Type": configJSON.aboutUsContentType,
            language: this.state.language
        };
        const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getAboutUsApiCallId = requestMsg.messageId;
        requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.aboutUsApiEndPoint + this.state.language);
        requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.aboutUsApiMethod);
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    // Customizable Area End
}
