// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

import { ReceivedOrderItemType } from "./utilities";
export const configJSON = require("./config");
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
interface OrderApiResponse {
  attributes: {
    sub_total: string | null,
    shipping_total: string | null,
    total_tax: number | null,
    total: string | null,
    order_items: ReceivedOrderItemType[], id: string
  }
}

interface SimilarProductFromApi {
  id: string
  attributes: {
    name: string
    name_locale: string
    description: string
    description_locale: string
    price: number
    block_qty: number | null
    images: { id: number, url: string }[]
  }
}

interface OrderApiResponse {
  attributes: {
    sub_total: string | null,
    shipping_total: string | null,
    total_tax: number | null,
    total: string | null,
    order_items: ReceivedOrderItemType[], id: string
  }
}

interface SimilarProductFromApi {
  id: string
  attributes: {
    name: string
    name_locale: string
    description: string
    description_locale: string
    price: number
    block_qty: number | null
    images: { id: number, url: string }[]
  }
}

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
}

type Payment = {
  subTotal: number;
  shipping: number;
  vat: number;
  total: number;
};
export interface ShoppingCartOrderItem {
  id: string;
  productId: number;
  type?: string;
  name: string;
  image: string;
  stockQty: number;
  orderQty: number;
  price: number;
  saveAmount: number;
}

export type SimilarProductItem = {
  id: number
  name: string
  description: string
  image: string
  price: number
  blockQty: number | null
}

interface S {
  token: string;
  loading: boolean;
  orderId: string;
  orderItems: ShoppingCartOrderItem[];
  payment: Payment;
  similarProducts: SimilarProductItem[];
  language: string;
}

interface SS {
  id: string;
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSimilarProductsApiCallId = ''
  selectedProductIds: number[] = []

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      loading: false,
      orderId: "",
      orderItems: [],
      payment: {
        subTotal: 0,
        shipping: 0,
        vat: 0,
        total: 0
      },
      similarProducts: [],
      language: "en"
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData("authToken");
    const lang = await getStorageData("language") || "en";
    if (token) {
      languageConvertor(lang);
      this.setState({ token: token, language: lang });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (!prevState.orderItems.length && this.state.orderItems.length && this.state.token) {
      this.getSimilarProductsApi()
    }
  }

  receive = async (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let lang = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(lang!= undefined && lang != this.state.language){
        languageConvertor(lang);
        this.setState({language: lang, loading: false}, ()=>{
          this.getSimilarProductsApi(true);
          languageConvertor(lang);
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({loading: false});
      if (responseJson?.data?.type === 'order') {
        this.receiveInCartOrderList(responseJson.data)
      }
      if (apiRequestCallId === this.getSimilarProductsApiCallId && responseJson?.data) {
        this.receiveSimilarProducts(responseJson.data)
      }
    }
  }

  receiveInCartOrderList = async (data: OrderApiResponse) => {
    const { order_items, id, sub_total, total_tax, shipping_total, total } = data.attributes
    order_items.sort((itemA, itemB) => new Date(itemB.attributes.created_at).getTime() - new Date(itemA.attributes.created_at).getTime())

    this.setState({
      orderId: id,
      orderItems: order_items
        .map((order_item) => ({
          id: order_item.id,
          productId: order_item.attributes.catalogue_id,
          name: order_item.attributes.catalogue.attributes.name_locale,
          image: order_item.attributes.catalogue.attributes.images?.[0].url || "",
          orderQty: order_item.attributes.quantity,
          stockQty: order_item.attributes.catalogue.attributes.stock_qty,
          price: Number(order_item.attributes.total_price),
          saveAmount: order_item.attributes.old_unit_price ?
            (parseFloat(order_item.attributes.old_unit_price) - Number(order_item.attributes.unit_price)) * order_item.attributes.quantity
            : 0,
        })),
      payment: {
        subTotal: Number(sub_total),
        shipping: Number(shipping_total),
        vat: Number(total_tax),
        total: Number(total),
      }
    })
  }

  receiveSimilarProducts = (data: SimilarProductFromApi[]) => {
    this.setState({
      similarProducts: data
        .filter(similarProduct => !this.state.orderItems
          .some(orderItem => orderItem.productId === (Number(similarProduct.id))))
        .map((product: SimilarProductFromApi) => ({
          id: Number(product.id),
          name: product.attributes.name_locale,
          image: product.attributes.images?.[0].url || "",
          price: product.attributes.price,
          description: product.attributes.description_locale,
          blockQty: product.attributes.block_qty
        }))
    }, () => {
      if (!data.length) {
        this.getSimilarProductsApi()
      }
    })
  }

  getSimilarProductsApi = (isChangLang?: boolean) => {
    const filteredProductIds = isChangLang ? this.state.orderItems.map(orderItem => orderItem.productId) : ( this.state.orderItems
      .filter(orderItem => !this.selectedProductIds.includes(orderItem.productId))
      .map(orderItem => orderItem.productId))
    if(isChangLang ) this.selectedProductIds = [];
    if (!filteredProductIds.length) return;

    const randomIndex = crypto.getRandomValues(new Uint16Array(1))[0] % filteredProductIds.length
    const randomProductId = filteredProductIds[randomIndex]
    this.selectedProductIds.push(randomProductId)
    

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
      language: this.state.language
    };

    this.setState({loading: true});

    const getRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSimilarProductsApiCallId = getRequestMessage.messageId;
    getRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.similarProductsApi}${randomProductId}`
    );

    getRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getRequestMessage.id, getRequestMessage);
  }

  callApiUpdateProductQuantity = (orderItemId: string, productId: number, quantity: number) => {
    if (!this.state.orderId) return;
    const headers = {
      token: this.state.token,
      "Content-Type": configJSON.apiContentType,
      language: this.state.language
    };
    const requestBody = {
      data:
      {
        order_items_attributes: [
          {
            id: Number(orderItemId),
            catalogue_id: productId,
            status: "in_cart",
            ...(quantity ? { quantity } : { "_destroy": true })
          }
        ]
      }
    }
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.ordersApiEndPoint}/${this.state.orderId}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody))
    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  goToProductDetail = (productId: string | number) => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    request.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(request);
  }

  goToLandingPage = () => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }
}
// Customizable Area End