// Customizable Area Start
import React from "react";
import {
    Box,
    Popper,
    ClickAwayListener,
    styled,
} from "@material-ui/core";
import Typography from "../../../components/src/design-system/Typography.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import CustomSnackBar from '../../../components/src/design-system/CustomSnackBar.web';
import { multiplication } from "./assets";
import i18n from "i18next";
import HeaderShoppingCartController, {
    Props
} from "./HeaderShoppingCartController";

const CartIcon = ({ itemCount, isFocus }: { itemCount?: number, isFocus?: boolean }) => <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9 21.75C9 22.0467 8.91203 22.3367 8.7472 22.5834C8.58238 22.83 8.34811 23.0223 8.07403 23.1358C7.79994 23.2494 7.49834 23.2791 7.20736 23.2212C6.91639 23.1633 6.64912 23.0204 6.43934 22.8107C6.22956 22.6009 6.0867 22.3336 6.02882 22.0426C5.97094 21.7517 6.00065 21.4501 6.11418 21.176C6.22771 20.9019 6.41997 20.6676 6.66665 20.5028C6.91332 20.338 7.20333 20.25 7.5 20.25C7.89782 20.25 8.27936 20.408 8.56066 20.6893C8.84196 20.9706 9 21.3522 9 21.75ZM17.25 20.25C16.9533 20.25 16.6633 20.338 16.4166 20.5028C16.17 20.6676 15.9777 20.9019 15.8642 21.176C15.7506 21.4501 15.7209 21.7517 15.7788 22.0426C15.8367 22.3336 15.9796 22.6009 16.1893 22.8107C16.3991 23.0204 16.6664 23.1633 16.9574 23.2212C17.2483 23.2791 17.5499 23.2494 17.824 23.1358C18.0981 23.0223 18.3324 22.83 18.4972 22.5834C18.662 22.3367 18.75 22.0467 18.75 21.75C18.75 21.3522 18.592 20.9706 18.3107 20.6893C18.0294 20.408 17.6478 20.25 17.25 20.25ZM21.7172 8.47031L19.0425 17.1619C18.9024 17.6226 18.6175 18.0259 18.2301 18.312C17.8427 18.5981 17.3734 18.7517 16.8919 18.75H7.88156C7.3931 18.7482 6.91837 18.5882 6.52848 18.2939C6.13858 17.9997 5.85449 17.587 5.71875 17.1178L2.32687 5.25H0.75C0.551088 5.25 0.360322 5.17098 0.21967 5.03033C0.0790176 4.88968 0 4.69891 0 4.5C0 4.30109 0.0790176 4.11032 0.21967 3.96967C0.360322 3.82902 0.551088 3.75 0.75 3.75H2.32687C2.65257 3.75108 2.96916 3.85761 3.22925 4.05365C3.48934 4.24969 3.67895 4.5247 3.76969 4.8375L4.53 7.5H21C21.1174 7.49996 21.2331 7.52746 21.3379 7.58029C21.4427 7.63313 21.5336 7.70982 21.6034 7.80421C21.6732 7.89859 21.7198 8.00803 21.7396 8.12372C21.7593 8.23941 21.7517 8.35812 21.7172 8.47031ZM19.9847 9H4.95844L7.16062 16.7062C7.20543 16.8629 7.30002 17.0007 7.43009 17.0988C7.56016 17.1969 7.71864 17.25 7.88156 17.25H16.8919C17.0524 17.2501 17.2086 17.1986 17.3377 17.1033C17.4668 17.008 17.5619 16.8737 17.6091 16.7203L19.9847 9Z"
        fill={isFocus ? "#2563EB" : "#333"}
    />
    {
        Boolean(itemCount) && <>
            <circle cx="20" cy="6.5" r="6" fill="#2563EB" />
            <text x="20" y="6.5" textAnchor="middle" dominantBaseline="middle" fill='white'>{itemCount}</text>
        </>
    }
</svg>

export default class HeaderShoppingCart extends HeaderShoppingCartController {
    constructor(props: Props) {
        super(props);
    }

    formatCurrency = (value?: number) => `${i18n.t("SARTXT")} ${(value || 0).toFixed(2)}`;

    handleClickAway = () => { if (this.state.anchorEl) this.setState({ anchorEl: null }) }


    render() {
        const { cartItems, total, anchorEl } = this.state

        return (
            <HeaderShoppingCartWrapper data-test-id="cart-box" dir="ltr" onClick={(event) => this.setState({ anchorEl: event.currentTarget })}>
                <Box className='cart-icon'>
                    <CartIcon itemCount={cartItems.reduce((prev, current) => prev + current.quantity, 0)} isFocus={Boolean(anchorEl)} />
                </Box>
                <Popper
                    disablePortal
                    placement="bottom-end"
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    popperOptions={{
                        modifiers: {
                            offset: {
                                enabled: true,
                                offset: "80 25",
                            },
                        }
                    }}

                >
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <OrderSummaryWrapper>
                            <Box className='order-summary-title'>
                                <Typography transform='uppercase' color='charcoal'>
                                    {i18n.t("ORDERSUMMARYTXT")}
                                </Typography>
                                <Typography weight='medium'>
                                    {cartItems.length || 0} {i18n.t("ITEMSTXT")}
                                </Typography>
                            </Box>
                            <Box className="order-summary-items-list">
                            {
                                cartItems.map(cartItem => (
                                    <Box key={cartItem.orderItemId} className='order-summary-cart-item'>
                                        <Typography weight='medium' color='charcoal' size='xs'>
                                            {cartItem.name}
                                        </Typography>
                                        <Typography weight='medium' color='charcoal' size='xs'>
                                            {this.formatCurrency(cartItem.price)}<img src={multiplication} />{cartItem.quantity}
                                        </Typography>
                                    </Box>
                                ))
                            }
                            </Box>
                            <Box className='order-summary-footer'>
                                <Box display="flex" flexDirection="column" justifyContent="center">
                                    <Typography size='x' weight='medium'>
                                        {this.formatCurrency(total)}
                                    </Typography>
                                    <Typography color='limegreen' size='ms'>
                                        {i18n.t("YOUSAVETXT")} {i18n.t("SARTXT")} 0.00
                                    </Typography>
                                </Box>
                                <CustomButton themes='primary' onClickHandle={() => this.goToOrderSummary()}>
                                    <Typography weight='medium' size='ms'>{i18n.t("PROCESSTOCARTTXT")}</Typography>
                                </CustomButton>
                            </Box>
                        </OrderSummaryWrapper>
                    </ClickAwayListener>
                </Popper>
                <CustomSnackBar
                    data-test-id="CustomSnackBarHeader"
                    open={this.state.showToast}
                    errorMessage={this.state.toastMessage}
                    onClose={() => this.setState({showToast: false})}
                    severity={this.state.toastSuccess}
                />
            </HeaderShoppingCartWrapper>

        );
    }
}

const HeaderShoppingCartWrapper = styled(Box)({
    "& .cart-icon" :{
        cursor: "pointer"
    },
    "& .cart-icon svg > text": {
        fontFamily: "Roboto",
        fontSize: 8,
        lineHeight: "12px"
    }
})

const OrderSummaryWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: 340,
    background: "white",
    padding: "20px 0",
    gap: 10,
    border: "1px solid #00000033",
    boxShadow: "0px 4px 24px 0px #0000001F",
    "& > div:first-of-type, & > div:last-of-type": {
        display: "flex",
        justifyContent: "space-between",
    },
    "& .order-summary-title": {
        borderBottom: "1px solid #0000001F",
        margin: "0 20px",
        paddingBottom: 20
    },
    "& .order-summary-items-list": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        maxHeight: 350,
        overflowY: "auto",
        padding: "0 20px",
        "& > div": {
            display: "flex",
            justifyContent: "space-between"
        },
    },
    "& .order-summary-cart-item": {
        padding: "10px 0",
        "& > div:first-of-type": {
            maxWidth: 200,
            wordBreak: "break-word"
        },
        "& > div:last-of-type": {
            display: "flex !important",
            alignItems: "center",
            gap: "3px"
        }
    },
    "& .order-summary-footer": {
        padding: "0 20px",
        marginTop: 5,
        "& button": {
            borderRadius: 0,
            padding: "14px 17px",
            maxWidth: "fit-content",
            maxHeight: 40
        }
    }
})

// Customizable Area End
