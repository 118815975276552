import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikValues } from 'formik';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { VendorTableData } from "./VendorListController";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area Start
import i18n from "i18next";

export interface VendorForm {
    vendorId: string;
    vendorName: string;
    contactPerson: string;
    email: string;
    phoneNumber: string;
    status: string;
}

export interface VendorFormErrors {
    vendorId: string;
    vendorName: string;
    contactPerson: string;
    email: string;
    phoneNumber: string;
    status: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    isOpenModal: boolean
    onCloseModal: () => void
    navigation?: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative: string) => string | Object;
        goBack: () => void;
      },
    defaultVendor: VendorTableData
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    vendorId: string;
    vendorName: string;
    contactPerson: string;
    email: string;
    phoneNumber: string;
    status: string;
    isVisible: boolean;
    token: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditVendorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef: any = React.createRef();
    emailReg: RegExp;
    apiEditVendorId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.emailReg = /\w+/;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            vendorId: this.props.defaultVendor.id,
            vendorName: this.props.defaultVendor.name,
            contactPerson: this.props.defaultVendor.contact ?? "",
            email: this.props.defaultVendor.email ,
            phoneNumber: this.props.defaultVendor.phoneNumber,
            status: this.props.defaultVendor.status,
            isVisible: false,
            token: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken")
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }
    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    // Customizable Area Start
    validateEditVendor = (values: VendorForm) => {
        const errors: Partial<VendorFormErrors> = {};

        if (!values.email) {
            errors.email = i18n.t('CUSTOMER_EMAIL_ERROR');
        }
        if (!values.vendorId) {
            errors.vendorId = i18n.t('VENDOR_ID_ERROR');
        }
        if (!values.vendorName) {
            errors.vendorName = i18n.t('VENDOR_NAME_ERROR');
        }
        if (!values.phoneNumber) {
            errors.phoneNumber = i18n.t('CUSTOMER_PHONE_NUMBER_ERROR');
        }
        if (!values.status) {
            errors.status = i18n.t('CUSTOMER_STATUS_ERROR');
        }
        if (!values.contactPerson) {
            errors.contactPerson = i18n.t('VENDOR_CONTACT_PERSON_ERROR');
        }

        return errors;
    };

    onEdit = (values: FormikValues) => {
        this.setState({ vendorId: values.vendorId, vendorName: values.vendorName, email: values.email, phoneNumber: values.phoneNumber, status: values.status, contactPerson: values.contactPerson }, () => { this.doEditVendor() });
    };

    // Customizable Area Start
    doEditVendor(): boolean {
        const header = {
            "Content-Type": configJSON.editCustomerApiContentType,
            token: this.state.token,
        };

        const attrs = {
            email: this.state.email,
            full_phone_number: this.state.phoneNumber,
            activated: this.state.status === "Active" ? true : false,
            ID: this.state.vendorId,
            company_name: this.state.vendorName,
            full_name: this.state.contactPerson,
        };

        const data = {
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEditVendorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editCustomerAPiEndPoint}${this.state.vendorId}${configJSON.editVendorParams}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editCustomerAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.props.onCloseModal()

        return true;
    }
    // Customizable Area End
}
