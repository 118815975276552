import React, { Component } from "react";
import i18n from "i18next";
type Props = {
    date: string;
};

export default class TimeFormat extends Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    render(): React.ReactNode {
        const {date} = this.props;        
        let seconds = Math.floor(
        (new Date().valueOf() - new Date(date).valueOf()) / 1000
        );
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) +" "+ i18n.t("TIMERTXT.YEARTXT");
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) +" "+ i18n.t("TIMERTXT.MONTHSTXT");
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) +" "+ i18n.t("TIMERTXT.DAYSTXT");
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) +" "+ i18n.t("TIMERTXT.HoursTXT");
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) +" "+ i18n.t("TIMERTXT.MINUTESTXT");
        }
        return Math.floor(seconds) +" "+ i18n.t("TIMERTXT.SECONDSTXT");
    }
}
