import React from "react";
// Customizable Area Start
import { Box, styled  } from "@material-ui/core";
import MainLandingController, {Props, LandingDataTypes, SubcontentTypes} from "./MainLandingController.web";
const Image = require("./assets");
import Cards from "../../../components/src/design-system/Cards.web";
import Typography from "../../../components/src/design-system/Typography.web";
import InfoCard from "../../../components/src/design-system/InfoCard.web";
import Footer from "../../navigationmenu/src/Footer.web";
import SectionTitle from "../../../components/src/design-system/SectionTitle.web";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import Markup from "../../../components/src/design-system/Markup.web";
import Grid from '@material-ui/core/Grid';
import FrontHeader from "../../navigationmenu/src/FrontHeader.web";
import HelpCenterQA from "../../helpcentre/src/HelpCanterQA.web";
import i18n from "i18next";


const LandingPage = styled(Box)({
    "& *":{
        boxSizing: "border-box"
    },
    "& img":{
        maxWidth: "100%"
    },
    "& .header":{
        paddingTop: "168px",
        background: `url(${Image.mBg1})`,
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "flexDirection": "column",
        backgroundRepeat: "repeat",
        backgroundSize: "100% auto",
        gap: "56px",
        "& .header-inner":{
            "display": "flex",
            "flexDirection": "column",
            "justifyContent": "center",
            "alignItems": "center",
            "gap": "16px",
            textAlign: "center",
            "& .button-wraper": {
                "display": "flex",
                "gap": "16px",
                "padding": "12px",
                "& button":{
                    minWidth: "197px",
                    whiteSpace: "nowrap"
                }
            },
            
            "@media(min-width: 1024px)":{
                maxWidth: "962px",
                "& .title":{
                    fontSize: "56px"
                },
            }
        },
        "& .desktop-icon":{
            maxWidth: "50%",
            marginBottom: "-153px"
        }
    },
    "& .section-1":{
        background: `url(${Image.mBg2}) no-repeat`,
        padding: "160px 0px 260px",
        backgroundSize: "100% 100%",
        marginTop: "153px",
        "& .inner-wrapper":{
            width: "88.88%",
            margin: "80px auto",
            "& img":{
                maxWidth: "398px",
                "@media(max-width: 768px)":{
                    maxWidth: "100%",
                }
            },
            "& > div":{
                "&:nth-child(2n) > div:nth-child(1)":{
                    order: 2
                },
                "& > div":{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }
            }
        }
    },
    "& .section-2":{
        "& .info-wrapper":{
            width: "88.88%",
            "margin": "76px auto",
            "display": "flex",
            "flexWrap": "wrap",
            "gap": "20px",
            "& > div":{
                width: "calc(33.33% - 20px)"
            }
        },
        "& .inner-faq":{
            width: "88.88%",
            "margin": "0 auto 105px",
        }
    },
    "& .health-section":{
        "& .inner-wrapper":{
            width: "88.88%",
            margin: "76px auto",
            background: "#E6EEFF",
            textAlign: "center",
            padding: "68px 155px",
            "display": "flex",
            flexDirection: "column",
            borderRadius: "4px",
            alignItems: "center",
            "& img": {
                maxWidth: "80px",
                margin: "32px 0px 24px"
            },
            "& .meta":{
                marginTop: "10px",
                "display": "flex",
                "flexDirection": "column",
                "justifyContent": "center",
                "textAlign": "center",
                fontFamily: "Roboto"
            }
        }
    },
    "& .solution-section":{
        background: "#4474DB",
        padding: "47px 0px",
        "& .inner-wrapper":{
            width: "88.88%",
            margin: "auto"
        },
        "& .grid-row":{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            justifyContent: "center"
        }
    },
    "@media(max-width: 768px)":{
        "& .section-1":{
            "& .inner_wrapper":{
                "& img":{
                    maxWidth: "100% !important"
                }
            }
        },
        "& .header":{
            "& .header-inner":{
                padding: "0px 40px"
            }
        },
        "& .solution-section":{
            "& .grid-row":{
                justifyContent: "center"
            }
        },
        "& .health-section": {
            "& .inner-wrapper":{
                padding: "48px 60px !important"
            }
        },
        "&  .section-2":{
            "& .info-wrapper":{
                "& > div":{
                    width: "100% !important"
                }
            }
        },
        "& .sectionTitle":{
            maxWidth: "90%"
        },
        "& .action-box":{
            "width": "100%",
            "margin": "20px 0px 0px",
            "justifyContent": "center"
        }
    },
    "@media(max-width: 560px)":{
        "& .health-section": {
            "& .inner-wrapper":{
                "& .title":{
                    fontSize: "18px"
                }
            }
        },
        "& .section-1":{
            padding: "60px 0px 160px",
            backGroundSize: "cover"
        },
        "& .header":{
            "& .title":{
                fontSize: "18px"
            },
            "& .details":{
                fontSize: "14px"
            },
            "& .header-inner":{
                "& .button-wraper":{
                    "& button":{
                        "whiteSpace": "nowrap",
                        "padding": "12px 20px !important",
                        "fontSize": "12px !important",
                        "minWidth": "50% !important"
                    }
                }
            }
        },
    }
})
// Customizable Area End

export default class MainLanding extends MainLandingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <LandingPage>
        <FrontHeader navigation={this.props.navigation} customStyle={headerStyle} allowGuestAccess/>
        {this.state.landingData.map((item: LandingDataTypes) => {
            switch (item.attributes.contant_type){
                case "top_contant":
                    const title_arr = item.attributes.sub_title.split("|");
                    return <Box className="header">
                            <Box className="header-inner">
                                <Typography size="xxl" weight="bold" color="black">
                                    <Box className="title">{title_arr[0]} <Typography color="primary" weight="bold">{title_arr[1]}</Typography></Box>
                                </Typography>
                                <Typography size="x" color="charcoal" weight="medium"><Box className="details">{item.attributes.description}</Box></Typography>
                                <Box className="button-wraper">
                                    <CustomButton size="xl" dataTestId="explore-product" onClickHandle={this.goToHome} themes="primary" display="primary">{i18n.t('explore_product_txt')}</CustomButton>
                                    <CustomButton size="xl" onClickHandle={this.goToHome} styles={{background: "#fff"}}  themes="primary" display="primaryWithBorder">{i18n.t('lets_talk_txt')}</CustomButton>
                                </Box>
                            </Box>
                            <img src={item.attributes.image || Image.mSystem} alt="" className="desktop-icon" />
                        </Box>
                case "we_offer":
                    return <Box className="section-1">
                                <SectionTitle 
                                    align="center"
                                    itemAlign="center"
                                    theme="light"
                                    subTitle={item.attributes.title}
                                    title = {item.attributes.sub_title}
                                    details={item.attributes.description}
                                    />
                
                                <Box className="inner-wrapper">
                                    {item.attributes.landingpage_sub_contants.map((sub_item: SubcontentTypes) => {
                                        return <Grid container spacing={3}>
                                                    <Grid item xs={6}><img src={sub_item.attributes.image || Image.offer1} alt="" /></Grid>
                                                    <Grid item xs={6}>
                                                        <SectionTitle 
                                                            theme="light"
                                                            subTitle={sub_item.attributes.title}
                                                            title = {sub_item.attributes.sub_title}
                                                            details={sub_item.attributes.description}
                                                            />
                                                    </Grid>
                                                </Grid>
                                    })}
                                </Box>
                        </Box>
                case "solution_for_all":
                    return <Box className="section-2">
                                <SectionTitle 
                                    align="center"
                                    theme="light"
                                    itemAlign="center"
                                    subTitle={item.attributes.title}
                                    title = {item.attributes.sub_title}
                                    details={item.attributes.description}
                                    />
                    
                                    <Box className="info-wrapper">
                                    {item.attributes.landingpage_sub_contants.map((sub_content: SubcontentTypes) => {
                                        return <InfoCard 
                                                    image = {sub_content.attributes.image}
                                                    title= {sub_content.attributes.sub_title}
                                                    details= {sub_content.attributes.description}
                                                    type = "style2"
                                                ></InfoCard>
                                    })}
                                    </Box>
                                    
                            </Box>
                default:
                    return <Box className="health-section">
                            <Box className="inner-wrapper">
                                <Typography size="xxl" weight="bold"><Box className="title">{item.attributes.sub_title}</Box></Typography>
                                <img src={item.attributes.image || Image.quotes} alt="" />
                                <Markup classProps="meta">{item.attributes.description}</Markup>
                            </Box>
                        </Box>
            }
        })}
        
        <Box className="section-2">
            <SectionTitle 
                align="center"
                theme="light"
                itemAlign="center"
                subTitle={i18n.t("GETANSWERE")}
                title = {i18n.t("FAQTITLE")}
                details={i18n.t("FAQDETAILS")}
                />
            <Box className="inner-faq">
                <HelpCenterQA limit={10} />
            </Box>
        </Box>

        <Box className="solution-section">
            <Box className="inner-wrapper">
                <SectionTitle 
                    align="center"
                    theme="dark"
                    itemAlign="center"
                    subTitle=""
                    classProp="dark"
                    title = {i18n.t("EXPLOREHEALTHCARESOLUTION")}
                    details={i18n.t("EXPLOREHEALTHCARESOLUTIONDETAILS")}
                    >
                        <CustomButton onClickHandle={this.goToHome} themes="dark" dataTestId="explore" display="textButton" styles={{background: "#fff"}}>{i18n.t("EXPLORENOW")}</CustomButton>
                    </SectionTitle>
                <Box className="grid-row">
                <Cards Records={this.state.category} cardStyles={{"min-width": "210px"}} clickHandler={() => {}} />
                </Box>
            </Box>
        </Box>
        <Footer theme="dark" navigation={this.props.navigation}/>
    </LandingPage>
    // Customizable Area End
  }
}
const headerStyle: object = {
    position: "absolute",
    top: "0",
    left: "0"
}