export const aboutusbanner = require("../assets/aboutusbanner.png");
export const collabImg = require("../assets/UsersThree.png");
export const dataImg = require("../assets/Database.png");
export const regularImg = require("../assets/Note.png");
export const packageImg = require("../assets/Package.png");
export const pricingImg = require("../assets/group_pricing.png");
export const calendarImg = require("../assets/Calendar.png");
export const qouteImg = require("../assets/Vector.png");


