Object.defineProperty(exports, "__esModule", {
    value: true
});


exports.tabsData = [
    { label: 'All Order' },
    { label: 'In Progress' },
    { label: 'Delivered' },
    { label: 'Cancelled' },
];
exports.admintabsData = [
    { label: 'All Order' },
    { label: 'In Progress' },
    { label: 'Delivered' },
    { label: 'Cancelled' }
];

exports.columns = [
    { id: 'companyName', label: 'Company' },
    { id: 'email', label: 'Email' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'city', label: 'City' },
    { id: 'status', label: 'Status', renderFunction: 'renderRowStatus' },
];


exports.vendorColumns = [
    { id: 'orderid', label: 'Order Id',  renderFunction: 'renderRowId'  },
    { id: 'productName', label: 'Product Name',renderFunction:"renderRowHaveImage"},
    { id: 'address', label: 'Address' },
    { id: 'date', label: 'Date' },
    { id: 'payment', label: 'Payment',renderFunction: 'renderPaymentStatus' },
    { id: 'total', label: 'Total' },
    { id: 'status', label: 'Status', renderFunction: 'renderRowStatus' },
];
exports.adminColumns = [
    { id: 'orderid', label: 'Order Id',  renderFunction: 'renderRowId'  },
    { id: 'productName', label: 'Product Name',renderFunction:"renderRowHaveImage"},
    { id: 'address', label: 'Address' },
    { id: 'date', label: 'Date' },
    { id: 'price', label: 'Price' },
    { id: 'status', label: 'Status', renderFunction: 'renderRowStatus' },
];
exports.orderData = [
    {
        id: "1",
        productName: 'Product 1',
        address: '123 Street, City',
        date: '2024-03-01',
        payment: 'Paid',
        total: '$50.00',
        status: 'In Progress'
    },
    {
        id: "2",
        productName: 'Product 2',
        address: '456 Avenue, Town',
        date: '2024-03-02',
        payment: 'Paid',
        total: '$75.00',
        status: 'Delivered'
    },
    {
        id: "3",
        productName: 'Product 3',
        address: '456 Avenue, Town',
        date: '2024-03-02',
        payment: 'Unpaid',
        total: '$75.00',
        status: 'In progress'
    },
    // Add more data as needed
];


exports.adminOrderData = [
    {
        id: "1",
        productName: 'Product 1',
        address: '123 Street, City',
        date: '2024-03-01',
        price: '$50.00',
        status: 'In Progress'
    },
    {
        id: "2",
        productName: 'Product 1',
        address: '123 Street, City',
        date: '2024-03-01',
        price: '$50.00',
        status: 'Delivered'
    },   {
        id: "3",
        productName: 'Product 1',
        address: '123 Street, City',
        date: '2024-03-01',
        price: '$50.00',
        status: 'Cancelled'
    },
];