import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {createRequestMessage} from "../../utilities/src/create-request-message";
export interface CategoryTypes {id: number, title: string, image: string}
import { getStorageData } from "../../../framework/src/Utilities";
import { languageConvertor } from "../../languageoptions/src/LanguageSelectorController.web";
export interface SubcontentTypes{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "is_active": boolean,
      "position": number,
      "created_at": string,
      "title": string,
      "sub_title": string,
      "description": string,
      "image": string
  }
}
export interface LandingDataTypes{
  id: string;
  type: string;
  attributes: {
    "id": number,
    "is_active": boolean,
    contant_type: string,
    "position": number,
    "created_at": string,
    "title": string,
    "sub_title": string,
    "description": string,
    "image": string | null,
    "landingpage_sub_contants": Array<SubcontentTypes>
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
  identifiable: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: Array<CategoryTypes>
  token: string
  loading: boolean
  landingData: Array<LandingDataTypes>
  language: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      category:[],
      landingData: [],
      language: "en"
    };
    this.goToHome = this.goToHome.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      let selectedLng = message.getData(getName(MessageEnum.InfoPageTitleMessage));
      if(selectedLng != undefined && selectedLng != this.state.language){
        this.setState({language: selectedLng}, ()=>{
          this.getToken();
          languageConvertor(selectedLng);
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = await message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const errorResponse = await message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
      this.setState({loading: false});
      this.recieveLandingData(message, apiRequestCallId);
      this.recieveCategory(message, apiRequestCallId);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  landingPageCallID:string = "";
  categoryCallID: string = "";
  handleLogin = () => {
    const request: Message = new Message(getName(MessageEnum.NavigationMessage));
    request.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    request.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(request);
  }

  async componentDidMount() {
    super.componentDidMount();
    const lang = await getStorageData('language') || "en";
    this.setState({language: lang}, ()=>{
      this.getToken();
      languageConvertor(lang);
    });
  }

  recieveCategory = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let categ: Array<CategoryTypes> = [];
    if(messageID === this.categoryCallID){
      for (const category_item of responseJson.data) {
        if(category_item.attributes.is_show_header){
          let inner_item = ({id: category_item.attributes.id, title: category_item.attributes.name_locale, image: category_item.attributes.image});
          categ.push(inner_item);
        }
      }
     return this.setState({category: categ})
    }
  }

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    return msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  }

  getToken = async () => {
    const token = await getStorageData("authToken");
    if(token){
      this.setState({token: token});
      this.handleLogin()
    }else{
      this.getLandingPage();
      this.getCategory();
    }
  };

  recieveLandingData = async(message: Message, messageID: string) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.landingPageCallID === messageID){
      return this.setState({landingData: responseJson.data})
    }
  }

  getLandingPage = () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.landingPageCallID = requestMessage.messageId;
    this.setState({loading: true});
    return createRequestMessage({
      requestMessage: requestMessage,
      endPoint: `${configJSON.landingPageEndPoint}?language=${this.state.language}`,
      method: configJSON.getApiMethodType,
      header: {language: this.state.language}
    });
  }
  getCategory = () => {
    this.setState({loading: true});
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.categoryCallID = requestMessage.messageId;
    return createRequestMessage({
      requestMessage: requestMessage,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.getCategoryEndPoint}?language=${this.state.language}`,
      header: {language: this.state.language}
    });
  }
  // Customizable Area End
}
