

export const balanceIcon = require("../assets/balance.png")
export const ordersIcon = require("../assets/orders.png")
export const revenueIcon = require("../assets/revenue.png")
export const earningsIcon = require("../assets/earnings.png")
export const arrowRightIcon = require("../assets/arrow_right.png")
export const arrowDownIcon = require("../assets/arrow_down.png")
export const revenueIncreasedIcon = require("../assets/revenue_increased.png")
export const increasedIcon = require("../assets/increased.svg")
export const decreasedIcon = require("../assets/decreased.png")
export const shareIcon = require("../assets/share.png")
export const downloadIcon = require("../assets/download.png")
export const editIcon = require("../assets/edit.svg")
export const deleteIcon = require("../assets/delete.svg")
export const revenueLegendIcon = require("../assets/revenue_legend_icon.svg")
export const totalSalesIcon = require("../assets/total_sales.png")
export const totalOrdersIcon = require("../assets/total_orders.png")
export const totalProductsIcon = require("../assets/total_products.png")