// Customizable Area Start
import React from 'react';
import FooterController, { Props } from './FooterController'
import { Box, styled } from '@material-ui/core';
import { InstaLogo, FbLogo, TwitterLogo, LinkedInLogo } from "./assets";
import Typography from '../../../components/src/design-system/Typography.web';
import i18n from 'i18next';


export default class Footer extends FooterController {
  static propTypes = {};

  constructor(props: Props) {
    super(props);

  }

  render() {
    const { theme } = this.props;
    const { lists } = this.state;
    const { t } = i18n;

    return (
      <FooterStyle>
        <Box className={`footerContainer ${theme || ''}`}>
          <Box className='footerContent'>
            {
              lists.map((list, index) => (
                <Box className='footerSection' key={index}>
                  <Typography size='xl' weight='medium' color={theme === 'dark' ? 'white' : 'black'}>
                    <Box className='footer-title'>
                      {list.name}
                    </Box>
                  </Typography>

                  <Box className='listContainer'>
                    {list.items.map((item, index) => (
                      <Box className='listItem' key={index} onClick={() => this.handleClickFooterItem(item)}>
                        {item.label}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))
            }
            <Box className='footerSection'>
              <Typography size='x' weight='medium' color={theme === 'dark' ? 'white' : 'black'}>{t('CONNECTTXT')}</Typography>
              <Box className='listContainer socialContainer'>
                <Typography size='xs' weight='normal' color={theme === 'dark' ? 'white' : 'black'}>{t('SOCIALLINKSTXT')}</Typography>
                <Box className='logo'>
                  <img src={InstaLogo} alt="Instagram" />
                  <img src={FbLogo} alt="Facebook" />
                  <img src={TwitterLogo} alt="Twitter" />
                  <img src={LinkedInLogo} alt="LinkedIn" />
                </Box>
                <Typography size='xs' weight='normal' color={theme === 'dark' ? 'white' : 'black'}> {t('SOCIALDETAILSTXT')}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </FooterStyle>
    );
  }
}

const FooterStyle = styled(Box)({
  "& *": {
    boxSizing: "border-box"
  },
  "& .footerContainer": {
    backgroundColor: 'rgba(209, 228, 234, 1)',
    color: 'white',
    padding: '20px 0',
    bottom: 0,
    width: '100%',
    marginTop: 'auto',
    "&.dark .listContainer .listItem": {
      color: "white"
    }
  },
  "& .footerContent": {
    display: 'flex',
    "justifyContent": "space-between",
    "maxWidth": "1440px",
    "margin": "auto",
    "padding": "0px 40px",
    width: "100%",
    "boxSizing": "border-box"
  },
  "& .listItem": {
    fontFamily: 'Roboto',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    color: '#333333',
    cursor: 'pointer',
    "&:hover": {
      textDecoration: "underline"
    }
  },
  "& .footer-title": {
    fontSize: "20px",
    textTransform: "uppercase"
  },
  "& .footerSection": {
    marginTop: '3vh',
    marginBottom: '20px',
    color: 'rgba(0, 0, 0, 1)',
  },
  "& .listContainer": {
    listStyle: 'none',
    "marginTop": "16px",
    "display": "flex",
    "flexDirection": "column",
    "gap": "12px"
  },
  "& .logo": {
    display: 'flex',
    gap: "16px",
    marginBottom: '2vh',
    marginTop: '2vh',
    "& > img": {
      cursor: "pointer"
    }
  },
  "& .dark": {
    padding: "57px 0 106px",
    backgroundColor: "#1B2D54",
    marginTop: "102px"
  },
  "@media(max-width: 767px)": {
    "& .footerContent": {
      flexDirection: "column"
    },
    "& .socialContainer": {
      paddingTop: '2vh'
    }
  },
  "@media(max-width: 520px)": {
    "& .footerContent": {
      padding: "0px 20px"
    }
  }
})
// Customizable Area End
