import React from "react";
import {
    Box, 
    Container,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import GenericTab from "../../../components/src/GenericTab.web";
import CustomDateRangePicker from "../../../components/src/design-system/CustomDateRangePicker.web";
import DataTable from "../../../components/src/DataTable.web";
import InventoryListController, { Props } from "./InventoryListController";
import EditInventory from "./EditInventory.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Typography from "../../../components/src/design-system/Typography.web";
import i18n from "i18next";
import CustomButton from "../../../components/src/design-system/CustomButton.web";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SortingTable from "../../../components/src/SortingTable.web";
const INVENTORY_LIST_SORTED_FIELD = [{ label: "Product Name", field: "product_name" }, { label: "Category Name", field: "category_name" }, { label: "SKU", field: "sku" }, {label:"Incomming", field:"imcomming"}, {label:"Commited", field:"commited"}, {label:"Available", field:"available"}, {label:"Refund Status", field:"refund_status"}]
// Customizable Area End
export default class InventoryList extends InventoryListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (
            // Customizable Area Start
            <NavigationMenu id="" navigation={this.props.navigation} pageBackgroundMode="light" handleSearch={this.handleSearchHeader}>
                <InventoryListWrapper>
                    <Box className="titleHeader">
                        <Box className="titleWrap">
                            <Box className="title">{ i18n.t('INVENTORYTXT')}</Box>
                            <Typography size="base">
                                {this.getTableData(this.getTabStatus()).length} { i18n.t('AVAILABLETXT')}
                            </Typography>
                        </Box>
                        <CustomButton themes="primary" dataTestId="btnAdd" className="btnAdd" onClickHandle={() => this.navigateToAddInventory()}>
                            <AddCircleOutlineIcon />
                            <Typography>{i18n.t('ADDNEWITEMTXT')}</Typography>
                        </CustomButton>
                        </Box>
                    <Box className="tabsWrap">
                        <GenericTab
                            currentTab={this.state.productsCurrentTab}
                            onTabChange={this.handleTabChange.bind(this)}
                            tabsData={this.state.inventoryTabsData}
                        />
                        
                        <Box className="dateRangeWrap">
                             <SortingTable columnSorted={INVENTORY_LIST_SORTED_FIELD} getSortValueFn={this.getSortValueFn} />
                            <Box className="dateRangeSize">
                                <CustomDateRangePicker
                                    displayDateFormat="MMM DD"
                                    markingType="period"
                                    hidePlaceHolder2
                                    calenderContainer={{ left: "-200px"}}
                                    current=""
                                    markedDates={this.getMarkedDates(
                                        this.state.productsDateRange.fromDate,
                                        this.state.productsDateRange.toDate
                                    )}
                                    onDayPress={this.handleDateRangePickerDayPress}
                                    onChange={this.handleDateRangePickerOnChange}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <DataTable
                        data={this.getTableData(this.getTabStatus())} 
                        columns={this.state.inventoryColumns}
                        rowsPerPage={this.state.productsRowsPerPage}
                        onEditClick={this.openEditInventory}
                    />
                        {
                            this.state.isOpenEdit && <EditInventory defaultInventory={this.state.inventoryDetail} isOpenModal={this.state.isOpenEdit} onCloseModal={this.closeEditInventory} />
                        }
                </InventoryListWrapper>
            </NavigationMenu>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const InventoryListWrapper = styled("div")({
    "& .titleWrap": {
        display: "flex",
        fontSize: 25,
        margin:"1rem 0",
        gap:10,
        alignItems:"center"
    },
    "& .title": {
        fontWeight: "bold",
        marginRight: 3,
    },
    "& .tabsWrap": {
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        backgroundColor: '#fff',
        borderRadius: '6px',
        "@media (max-width: 420px)": {
            flexDirection: "column",
            padding: "16px 10px",
        },
    },
    "& .dateRangeWrap": {
        borderRadius: 8,
        padding: "0px 15px 0px 35px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#EEEEEE",
    },
    "& .dateRangeSize": {
        maxWidth: 150,
    },

  "& .titleHeader": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px'
  },
});
// Customizable Area End
