import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { FormikValues, FormikProps } from 'formik';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "framework/src/Utilities";


// Customizable Area Start
type Order = {
    address: string,
    date: string,
    id: string,
    name: string,
    status: string,
    total: number,
    payment: string
}
export interface VendorForm {
    vendorName: string;
    vendorId: string;
    email: string;
    contactPerson: string;
    phoneNumber: string;
    status: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    onCloseModal: () => void
    navigation?: {
        navigate: (to: string, params: Object) => void;
        getParam: (param: string, alternative: string) => string | Object;
        goBack: () => void;
      },
    isOpenModal: boolean,
    defaultVendor: Order
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    vendorId: string;
    status: string;
    isVisible: boolean;
    token: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditOrderVendorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef = React.createRef<FormikProps<S>>();
    emailReg: RegExp;
    apiEditVendorId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.emailReg = /\w+/;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            vendorId: this.props.defaultVendor.id,
            status: this.props.defaultVendor.status,
            isVisible: false,
            token: ""
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = await getStorageData("authToken")
            this.setState({ token: token });
        }
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTokenfn();
        // Customizable Area Start
        // Customizable Area End
    }
    getTokenfn = () => {
        const msgtext: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msgtext);
    };

    // Customizable Area Start
    onEdit = (values: FormikValues) => {
        this.setState({ vendorId: values.vendorId, status: values.status}, () => { this.doEditVendor() });
    };

    // Customizable Area Start
    doEditVendor(): boolean {
        const header = {
            "Content-Type": configJSON.editVendorOrderApiContentType,
            token: this.state.token,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEditVendorId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.editVendorOrderApiEndPoint}${this.state.vendorId}&status=${this.state.status}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.editVendorOrderApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.props.onCloseModal()

        return true;
    }
    // Customizable Area End
}
