import React from "react";

// Customizable Area Start
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { emptyMyOrdersIcon, Line, SplashBg1 } from "./assets"; // Customizable Area End
import DeliveryEstimatorController, { configJSON, Props } from "./DeliveryEstimatorController";

export default class DeliveryEstimator extends DeliveryEstimatorController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    //  this.getOrderStatusUpdate()
    return (
      // Customizable Area Start
      <div>
        <div style={styles.container}>
          <p
            style={{
              fontFamily: "verdana",
              color: "#7B4397",
              marginTop: "2.2%"
            }}
          >
            {" "}
            Track Order Details
          </p>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "10%"
            }}
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  marginTop: 50,
                  backgroundColor: "#F6FBFF",
                  borderRadius: 10,
                  height: 280,
                  alignSelf: "center",
                  flexDirection: "column",
                  width: "120%",
                  padding: "5%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 5
                  }}
                >
                  <p
                    style={{
                      marginTop: 10,
                      marginLeft: 10,
                      color: "black"
                    }}
                  >
                    {" "}
                    {configJSON.labelTitleText1}:
                  </p>
                  <p style={{ marginTop: 10, marginLeft: 2, color: "black" }}>
                    {this.OrderID}
                  </p>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      color: "grey",
                      fontSize: 12
                    }}
                  >
                    {" "}
                    {configJSON.labelTitleText2}:{" "}
                  </p>
                  <p
                    style={{
                      marginTop: 10,
                      marginLeft: 2,
                      color: "grey",
                      fontSize: 12,
                      fontWeight: "bold"
                    }}
                  >
                    {JSON.parse(sessionStorage.getItem("OrderDate") as string)}
                  </p>
                  <p
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      color: "grey",
                      fontSize: 12
                    }}
                  >
                    {" "}
                    {configJSON.labelTitleText3}:
                  </p>
                  <p
                    style={{
                      marginTop: 10,
                      marginLeft: 2,
                      color: "grey",
                      fontSize: 12,
                      fontWeight: "bold"
                    }}
                  >
                    {JSON.parse(
                      sessionStorage.getItem("OrderedDelivered") as string
                    )}
                  </p>
                </div>
                <div style={{ marginTop: 20, marginLeft: 30 }}>
                  <img src={Line} />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: 20,
                    flexDirection: "row",
                    marginTop: 10
                  }}
                >
                  <img
                    src={emptyMyOrdersIcon}
                    style={{ height: 100, width: 100 }}
                  />
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}
                    >
                      <p
                        style={{
                          marginTop: 20,
                          color: "black",
                          fontSize: 12,
                          marginLeft: 10
                        }}
                      >
                        {JSON.parse(
                          sessionStorage.getItem("productName") as string
                        )}
                      </p>
                      <p
                        style={{
                          marginTop: 20,
                          marginLeft: 45,
                          color: "black",
                          fontSize: 12
                        }}
                      >
                        USD$ {sessionStorage.getItem("Price")}
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p
                        style={{
                          marginTop: 10,
                          color: "grey",
                          marginLeft: 10
                        }}
                      >
                        {JSON.parse(sessionStorage.getItem("color") as string)}
                      </p>
                      <p
                        style={{
                          marginTop: 10,
                          color: "grey",
                          marginLeft: "auto"
                        }}
                      >
                        {configJSON.labelTitleText4}:
                      </p>
                      <p
                        style={{
                          marginTop: 10,
                          color: "grey"
                        }}
                      >
                        {JSON.parse(
                          sessionStorage.getItem("quantity") as string
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 10
                      }}
                    >
                      <p
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          marginLeft: 10,
                          width: 150
                        }}
                      >
                        {" "}
                        {this.state.status.toUpperCase()}
                      </p>
                      <p
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          marginLeft: 10,
                          width: 150
                        }}
                      >
                        {" "}
                        {this.state.partner}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Stepper activeStep={this.state.position} alternativeLabel>
          {this.labels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    height: 100,
    backgroundColor: "#f3f3f3",
    fontSize: 20
  },
  btnTitle: {
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    padding: 15,
    color: "white",
    fontWeight: "bold",
    fontSize: 16
  },
  containerView: {
    flex: 1
  },
  bgImg: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${SplashBg1})`
  },
  dataContainer: {
    marginTop: "2%",
    backgroundColor: "#F6FBFF",
    padding: "2%",
    height: 280,
    borderRadius: 10
  },

  trackButton: {
    display: "flex",
    height: 35,
    borderRadius: 12,
    justifyContent: "center",
    backgroundColor: "#7B4397",
    width: 120,
    fontSize: 16,
    color: "white",
    marginLeft: 150
  },
  supportButton: {
    minHeight: 20,
    borderRadius: 12,
    justifyContent: "center",
    backgroundColor: "#F0F0F0",
    width: "26%",
    height: "45%",
    alignSelf: "center"
  },
  buyButton: {
    minHeight: 20,
    borderRadius: 12,
    justifyContent: "center",
    backgroundColor: "#7B4397",
    width: "27%",
    height: "45%",
    alignSelf: "center"
  },
  btnContainer: {
    width: "90%",
    height: 45,
    backgroundColor: "#363F91",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    marginVertical: 20
  },
  btnTxt: {
    fontSize: 15,
    color: "#FFF"
  },
  ImageBackgroundStyle: {
    flex: 1,
    width: "100%",
    height: "100%"
  },
  sortPopupFilter: {
    marginTop: 40,
    borderRadius: 10,
    marginLeft: 20
  },
  popupSeparator: {
    marginHorizontal: 5,
    borderBottomColor: "gray",
    borderBottomWidth: 0.5
  },
  offerText: {
    textDecorationLine: "line-through",
    textDecorationStyle: "solid",
    color: "grey"
  },
  addCartBtnTxt: {
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
    padding: 10,
    color: "white",
    fontSize: 12
  },
  btnView: {
    padding: 5,
    paddingBottom: 50
  },
  addCartBtn: {
    borderRadius: 10,
    backgroundColor: "#7B4397"
  }
};

// Customizable Area End
